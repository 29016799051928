import React, { Component } from "react"
import { connect } from "react-redux"
import toastr from "toastr"
import { updateProfile, updateProfilePassword } from "../../actions/profileActions"

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slogan: props.profile.profile.settings?.feeder?.slogan,
      govtRegNo: props.profile.profile.settings?.feeder?.govtRegNo,
      paymentInfo: props.profile.profile.settings?.feeder?.paymentInfo,
      name: props.profile.profile.name,
      company: props.profile.profile.company,
      mobile: props.profile.profile.mobile,
      email: props.profile.profile.email,
      fullAddress: props.profile.profile.fullAddress,
      nidNumber: props.profile.profile.nid?.number,
      referenceName: props.profile.profile.reference?.name,
      referenceMobile: props.profile.profile.reference?.mobile,
      signature: props.profile.profile.signature,
      signature2: props.profile.profile.signature2,
      newPassword: "",
      newPasswordAgain: "",
      fatherName: "",
      mobile2: "",
      website: "",
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profile.isLoaded) {
      this.setState({
        slogan: nextProps.profile.profile.settings?.feeder?.slogan,
        govtRegNo: nextProps.profile.profile.settings?.feeder?.govtRegNo,
        paymentInfo: nextProps.profile.profile.settings?.feeder?.paymentInfo,
        name: nextProps.profile.profile.name,
        company: nextProps.profile.profile.company,
        mobile: nextProps.profile.profile.mobile,
        email: nextProps.profile.profile.email,
        fullAddress: nextProps.profile.profile.fullAddress,
        nidNumber: nextProps.profile.profile.nid?.number,
        referenceName: nextProps.profile.profile.reference?.name,
        referenceMobile: nextProps.profile.profile.reference?.mobile,
        signature: nextProps.profile.profile.signature,
        signature2: nextProps.profile.profile.signature2,
      })
    }
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onSubmitPasswordUpdate = (e) => {
    e.preventDefault()
    let { newPassword, newPasswordAgain } = this.state
    let data = {
      password: newPassword,
      password2: newPasswordAgain,
    }
    if (newPassword !== newPasswordAgain) {
      toastr.error("পাসওয়ার্ড ম্যাচ হয়নি")
      return
    }
    this.props.updateProfilePassword(data)
  }

  onSubmitProfileUpdate = (e) => {
    e.preventDefault()
    this.props.updateProfile(this.state)
  }

  render() {
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>প্রোফাইল</h4>
            </div>
            <div className="container">
              <div className="row justify-content-center p-3 bg-white align-items-start">
                <div className="col-md-6">
                  <br />
                  <h5 className="text-center">প্রোফাইল আপডেট</h5>
                  <form onSubmit={this.onSubmitProfileUpdate}>
                    <div className="form-group">
                      <label htmlFor="name">নাম</label>
                      <input
                        className="form-control"
                        onChange={this.onChangeHandler}
                        value={this.state.name}
                        type="text"
                        name="name"
                        disabled={this.props.server.requestPending}
                      />
                    </div>
                    {this.props.auth.user.type === "feeder" && (
                      <div className="form-group">
                        <label htmlFor="company">কোম্পানী</label>
                        <input
                          className="form-control"
                          onChange={this.onChangeHandler}
                          value={this.state.company}
                          type="text"
                          name="company"
                          disabled={this.props.server.requestPending}
                        />
                      </div>
                    )}

                    <div className="form-group">
                      <label htmlFor="mobile">মোবাইল</label>
                      <input
                        className="form-control"
                        onChange={this.onChangeHandler}
                        value={this.state.mobile}
                        type="text"
                        name="mobile"
                        disabled
                      />
                    </div>

                    {this.props.auth.user.type === "feeder" && (
                      <>
                        <div className="form-group">
                          <label htmlFor="slogan">শ্লোগান</label>
                          <input
                            className="form-control"
                            onChange={this.onChangeHandler}
                            value={this.state.slogan}
                            type="text"
                            name="slogan"
                            disabled={this.props.server.requestPending}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="govtRegNo">সরকারী নবন্ধন তথ্য</label>
                          <input
                            className="form-control"
                            onChange={this.onChangeHandler}
                            value={this.state.govtRegNo}
                            type="text"
                            name="govtRegNo"
                            disabled={this.props.server.requestPending}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="paymentInfo">বিকাশ</label>
                          <input
                            className="form-control"
                            onChange={this.onChangeHandler}
                            value={this.state.paymentInfo}
                            type="text"
                            name="paymentInfo"
                            disabled={this.props.server.requestPending}
                          />
                        </div>
                      </>
                    )}

                    <div className="form-group">
                      <label htmlFor="email">ইমেইল</label>
                      <input
                        className="form-control"
                        onChange={this.onChangeHandler}
                        value={this.state.email}
                        type="email"
                        name="email"
                        disabled={this.props.server.requestPending}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="fullAddress">ঠিকানা</label>
                      <input
                        className="form-control"
                        onChange={this.onChangeHandler}
                        value={this.state.fullAddress}
                        type="text"
                        name="fullAddress"
                        disabled={this.props.server.requestPending}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="nidNumber">জাতীয় পরিচয়পত্র নম্বর</label>
                      <input
                        className="form-control"
                        onChange={this.onChangeHandler}
                        value={this.state.nidNumber}
                        type="text"
                        name="nidNumber"
                        disabled={this.props.server.requestPending}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="referenceName">রেফারেন্স নাম</label>
                      <input
                        className="form-control"
                        onChange={this.onChangeHandler}
                        value={this.state.referenceName}
                        type="text"
                        name="referenceName"
                        disabled={this.props.server.requestPending}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="referenceMobile">রেফারেন্স মোবাইল</label>
                      <input
                        className="form-control"
                        onChange={this.onChangeHandler}
                        value={this.state.referenceMobile}
                        type="text"
                        name="referenceMobile"
                        disabled={this.props.server.requestPending}
                      />
                    </div>
                    {this.props.auth.user.type === "feeder" && (
                      <div className="form-group">
                        <label htmlFor="signature">নেটওয়ার্কের নাম ও মোবাইল নম্বর লিখুন</label>
                        <input
                          className="form-control"
                          onChange={this.onChangeHandler}
                          value={this.state.signature}
                          type="text"
                          name="signature"
                          disabled={this.props.server.requestPending || this.props.auth.user.type === "manager"}
                        />
                      </div>
                    )}
                    {this.props.auth.user.type === "feeder" &&
                      this.props.profile?.profile?.bpSettings?.signature2 === "yes" && (
                        <div className="form-group">
                          <label htmlFor="signature2">নেটওয়ার্কের নাম ও মোবাইল নম্বর লিখুন (ইন্টারনেট)</label>
                          <input
                            className="form-control"
                            onChange={this.onChangeHandler}
                            value={this.state.signature2}
                            type="text"
                            name="signature2"
                            disabled={this.props.server.requestPending || this.props.auth.user.type === "manager"}
                          />
                        </div>
                      )}

                    <button
                      style={{ minWidth: 100 }}
                      type="submit"
                      className="btn btn-primary w-100"
                      disabled={this.props.server.requestPending}
                    >
                      {this.props.server.requestPending ? <i className="fas fa-sync fa-spin"></i> : "আপডেট"}
                    </button>
                  </form>
                </div>
                <div className="col-md-6">
                  <br />
                  <h5 className="text-center">পাসওয়ার্ড পরিবর্তন করুন</h5>
                  <form onSubmit={this.onSubmitPasswordUpdate}>
                    <div className="form-group">
                      <label htmlFor="newPassword">পাসওয়ার্ড</label>
                      <input
                        className="form-control"
                        onChange={this.onChangeHandler}
                        value={this.state.newPassword}
                        type="password"
                        name="newPassword"
                        placeholder="নতুন পাসওয়ার্ড লিখুন"
                        disabled={this.props.server.requestPending}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="newPasswordAgain">পাসওয়ার্ড লিখুন</label>
                      <input
                        className="form-control"
                        onChange={this.onChangeHandler}
                        value={this.state.newPasswordAgain}
                        type="password"
                        name="newPasswordAgain"
                        placeholder="পুনরায় লিখুন"
                        disabled={this.props.server.requestPending}
                      />
                    </div>

                    <button
                      style={{ minWidth: 100 }}
                      type="submit"
                      className="btn btn-primary w-100"
                      disabled={this.props.server.requestPending}
                    >
                      {this.props.server.requestPending ? <i className="fas fa-sync fa-spin"></i> : "পাসওয়ার্ড আপডেট"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile,
    server: state.server,
  }
}

export default connect(mapStateToProps, {
  updateProfile,
  updateProfilePassword,
})(Profile)
