export const Data = {
  menus: [
    {
      name: "ড্যাশবোর্ড",
      icon: "fa fa-fire",
      url: "/"
    },
    {
      name: "এরিয়া",
      icon: "fa fa-street-view",
      url: "/area"
    },
    {
      url: "/manager",
      icon: "fa fa-user-alt",
      name: "ম্যানেজার"
    },
    {
      name: "কালেক্টর",
      icon: "fa fa-hand-holding-usd",
      url: "/collector"
    },
    {
      name: "রিসেলার",
      icon: "fa fa-bullhorn",
      url: "/reseller"
    },
    {
      name: "অপারেটর",
      icon: "fa fa-user-tie",
      url: "/operator"
    },
    {
      dropdown: true,
      name: "গ্রাহক",
      icon: "fa fa-users",
      children: [
        {
          name: "সকল গ্রাহক",
          icon: "fa fa-users",
          url: "/customers"
        },
        {
          name: "এক্টিভ গ্রাহক",
          icon: "fa fa-user",
          url: "/customers/active"
        },
        {
          name: "ফ্রি গ্রাহক",
          icon: "fa fa-user-secret",
          url: "/customers/free"
        },
        {
          name: "বন্ধ গ্রাহক",
          icon: "fa fa-user-times",
          url: "/customers/inactive"
        }
      ]
    },
    {
      dropdown: true,
      name: "বিল",
      icon: "fa fa-dollar-sign",
      children: [
        {
          name: "বকেয়া",
          icon: "fa fa-battery-empty",
          url: "/customers/unpaid"
        },

        {
          name: "আংশিক",
          icon: "fa fa-battery-quarter",
          url: "/customers/partial"
        },

        {
          name: "পরিশোধ",
          icon: "fa fa-battery-full",
          url: "/customers/paid"
        },

        {
          name: "অগ্রিম",
          icon: "fa fa-check-square",
          url: "/customers/advanced"
        }
      ]
    },
    {
      dropdown: true,
      name: "রিপোর্ট",
      icon: "fa fa-chart-bar",
      children: [
        {
          name: "বর্তমান মাস",
          icon: "fa fa-check-circle",
          url: "/report"
        },
        {
          name: "সকল মাস",
          icon: "fa fa-calendar",
          url: "/report/all"
        },
        {
          name: "বকেয়া",
          icon: "fa fa-exclamation-triangle",
          url: "/report/due"
        },
        {
          name: "পূর্বের সামারি",
          icon: "fa fa-book",
          url: "/report/summary"
        },
        {
          name: "পেমেন্ট মেসেজ",
          icon: "fa fa-comment",
          url: "/report/webhook-message"
        },
        {
          name: "মেসেজ লগ",
          icon: "fa fa-comments",
          url: "/report/message"
        }
        // {
        //   name: "কালেকশন গ্রাফ",
        //   icon: "fa fa-dollar-sign",
        //   url: "/report/collection-graph",
        // },
      ]
    },
    {
      dropdown: true,
      name: "ডিপোজিট",
      icon: "fa fa-university",
      children: [
        {
          name: "ম্যানেজার",
          icon: "fa fa-user-alt",
          url: "/deposit/manager"
        },
        {
          name: " কালেক্টর",
          icon: "fa fa-users",
          url: "/deposit/collector"
        }
      ]
    },
    {
      name: "খরচ হিসাব",
      icon: "fa fa-comments-dollar",
      url: "/expenditure"
    },
    {
      name: "খরচের খাত",
      icon: "fa fa-comments-dollar",
      url: "/expenditure/purpose"
    },
    {
      name: "কর্মচারী",
      icon: "fa fa-users",
      url: "/staff"
    },
    {
      name: "কালেকশন",
      icon: "fa fa-chart-bar",
      url: "/collection"
    },

    {
      dropdown: true,
      name: "মাইক্রোটিক",
      icon: "fa fa-wifi",
      children: [
        {
          name: "কনফিগার",
          icon: "fa fa-cog",
          url: "/mikrotik"
        },
        {
          name: "এক্টিভ কানেকশন",
          icon: "fa fa-users",
          url: "/mikrotik/connections"
        }
      ]
    },

    {
      name: "টিউটোরিয়াল",
      icon: "fa fa-video",
      url: "/tutorial"
    }
  ]
};
