import React, { Component } from "react"
import { connect } from "react-redux"
import CountUp from "react-countup"
import Select from "react-select"
import toastr from "toastr"

import DashboardMeter from "./DashboardMeter"
import Manager from "./Manager"
import Card from "./Card"
import Collector from "./Collector"

import { getDashboardSummary } from "../../../actions/dashboardActions"

import { formattedNumber, monthsArr, toBangla, yearOptions, monthOptions } from "../../../components/common/Utils"

const d = new Date()
const firstDay = new Date(d.getFullYear(), d.getMonth(), 1)
let year = d.getFullYear()
let month = d.getMonth()
month = month - 1
if (month < 0) {
  year = year - 1
  month = 11
}

const start = new Date(year, month, 1)
let end = new Date(start.getFullYear(), start.getMonth() + 1, 0)
// end = new Date(end.setDate(end.getDate() + 1))
console.log(start, end)

let _due = {}
for (let y = 2020; y <= year; y++) {
  for (let m = 0; m <= 11; m++) {
    _due[y + "_" + m] = true
    if (y === year && m === d.getMonth()) break
  }
}

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dashboard: props.dashboard.isDashboardSummaryLoaded ? props.dashboardSummary : {},
      amount: 0,
      isLoaded: false,
      year: { label: toBangla(year), value: year },
      month: { label: monthsArr[month], value: month },
      header: `${monthsArr[month]} - ${toBangla(year)}`,
    }
  }

  componentDidMount() {
    if (!this.props.dashboard.isDashboardSummaryLoaded)
      this.props.getDashboardSummary(this.state.year.value, this.state.month.value)
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.dashboard)
    if (nextProps.dashboard.isDashboardSummaryLoaded) {
      let amount = nextProps?.dashboard?.dashboardSummary?.manager?.balance
        ? nextProps.dashboard?.dashboardSummary?.manager.balance
        : 0

      this.setState({
        dashboard: nextProps.dashboard,
        amount,
        isLoaded: true,
      })
    }
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onSubmit = (e) => {
    e.preventDefault()

    const date = new Date()

    if (this.state.year.value == date.getFullYear() && this.state.month.value >= date.getMonth()) {
      toastr.error("মাস সঠিক নয়")
      return
    }

    this.props.getDashboardSummary(this.state.year.value, this.state.month.value)

    this.setState({ header: `${this.state.month.label} - ${this.state.year.label}` })
  }

  render() {
    const { auth } = this.props

    let salary,
      expenditure,
      manager,
      collectors,
      collection,
      user,
      duration = 1.25,
      totalBillCollectionByCollector = 0

    console.log(this.state.dashboard)

    if (this.state?.dashboard?.isDashboardSummaryLoaded) {
      salary = this.state?.dashboard?.dashboardSummary?.salary
      expenditure = this.state?.dashboard?.dashboardSummary?.expenditure
      manager = this.state?.dashboard?.dashboardSummary?.manager
      collectors = this.state?.dashboard?.dashboardSummary?.collectors
      collection = this.state?.dashboard?.dashboardSummary?.collection
      user = this.state?.dashboard?.dashboardSummary?.user
      totalBillCollectionByCollector = this.state?.dashboard?.dashboardSummary?.totalBillCollectionByCollector

      // user.expected.total = 14525415
      // manager.totalCollection = 45254140
      // manager.totalDeposit = 54848545
      // collection.total.amount = 12541452
    }

    return (
      <div className="main-content">
        <section className="section">
          <div className="container pt-4 pb-2 mb-4 bg-white text-left border-rounded">
            <div className="row">
              <div className="col-md-4">
                <p className="h3 font-weight-bold text-danger">
                  ব্যালেন্সঃ&nbsp;
                  {this.state?.dashboard?.isDashboardSummaryLoaded ? (
                    auth.user.type === "feeder" ? (
                      <CountUp
                        start={0}
                        end={manager?.totalDeposit || 0}
                        duration={duration}
                        separator=","
                        prefix="৳ "
                      />
                    ) : (
                      <CountUp start={0} end={manager?.balance || 0} duration={duration} separator="," prefix="৳ " />
                    )
                  ) : (
                    0
                  )}
                </p>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <Select value={this.state.year} options={yearOptions} onChange={(year) => this.setState({ year })} />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <Select
                    value={this.state.month}
                    options={monthOptions}
                    onChange={(month) => this.setState({ month })}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <button
                    onClick={this.onSubmit}
                    disabled={this.props?.server?.requestPending}
                    className="btn btn-success w-100"
                  >
                    সাবমিট
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            {this.props?.server?.requestPending ? (
              <div className="fa-3x text-center mt-5">
                <i style={{ fontSize: 40 }} className="fas fa-sync fa-spin"></i>
              </div>
            ) : (
              <div style={{ minHeight: 230 }} className="row justify-content-center p-3 bg-white align-items-center">
                {this.state?.dashboard?.isDashboardSummaryLoaded ? (
                  <>
                    <div className="col-12">
                      <div className="clearfix pt-5">
                        <h4 className="text-center">{this.state.header}</h4>
                      </div>
                      <hr />
                    </div>
                    {/* METER */}
                    {auth.user.type !== "operator" && (
                      <DashboardMeter manager={manager} collection={collection} user={user} auth={auth} />
                    )}

                    {/* MANAGER */}
                    {auth.user.type !== "operator" && <Manager manager={manager} auth={auth} />}

                    {/* CARD */}
                    <Card collection={collection} user={user} expenditure={expenditure} salary={salary} auth={auth} />

                    {/* Collector */}
                    {auth.user.type !== "operator" && (
                      <Collector collectors={collectors} totalCollection={totalBillCollectionByCollector} />
                    )}
                  </>
                ) : (
                  <p>দুঃখিত, ডাটা লোড ব্যার্থ হয়েছে।</p>
                )}
              </div>
            )}
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  dashboard: state.dashboard,
  server: state.server,
  profile: state.profile,
})

export default connect(mapStateToProps, {
  getDashboardSummary,
})(Dashboard)
