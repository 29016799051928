import axios from "axios"
import toastr from "toastr"
import { SERVER_URL } from "../config/config"
import setAuthToken from "../utils/setAuthToken"

export const login = (userData) => (dispatch) => {
  axios
    .post(SERVER_URL + "/api/users/login", userData)
    .then((res) => {
      const { token, status, data } = res.data
      if (!status) {
        for (const property in data) toastr.error(`${data[property]}`)
      } else {
        localStorage.setItem("bayannoPayToken", token)
        window.location.href = "/"
      }
    })
    .catch((err) => {
      console.log("LOGIN ERROR: ", err)
      toastr.error("Something went again. TRY AGAIN !!")
    })
}
export const register = (userData) => (dispatch) => {
  axios
    .post(SERVER_URL + "/api/users/register", userData)
    .then((res) => {
      const { status, msg, data } = res.data
      if (status) {
        alert(msg)
        window.location.href = "/"
      } else {
        // alert("Something went wrong");
        for (const property in data) toastr.error(`${data[property]}`)
      }
    })
    .catch((err) => {
      console.log("LOGIN ERROR: ", err)
      toastr.error("Something went again. TRY AGAIN !!")
    })
}

// Get Date Api
export const getDate = () => (dispatch) => {
  axios
    .get(SERVER_URL + "/api/users/info")
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: "GET_INFO",
          payload: res.data.data,
        })
      }
    })
    .catch((err) => toastr.error(err))
}

// Get Status Api
export const getStatus = () => (dispatch) => {
  axios
    .get(SERVER_URL + "/api/users/check/status")
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: "GET_STATUS",
          payload: res.data.data,
          invoice: res.data.invoice,
        })
      }
    })
    .catch((err) => toastr.error(err))
}

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: "SET_CURRENT_USER",
    payload: decoded,
  }
}

// Log user out
export const logout = () => (dispatch) => {
  localStorage.removeItem("bayannoPayToken")
  setAuthToken(false)
  dispatch(setCurrentUser({}))
  window.location.href = "/auth/login"
}
