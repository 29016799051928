import * as FileSaver from "file-saver"
import React from "react"
import * as XLSX from "xlsx"

export const ExportToExcel = ({ apiData, fileName }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  const fileExtension = ".xlsx"

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }
  return (
    <button
      style={{
        border: "none",
        background: "none",
      }}
      type="button"
    >
      <i
        onClick={(e) => exportToCSV(apiData, fileName)}
        style={{ fontSize: "25px", color: "#1D6F42" }}
        className="fas fa-file-excel ml-2"
      ></i>
    </button>
  )
}
