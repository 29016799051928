import moment from "moment"
import React, { Component } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { connect } from "react-redux"
import { assignRolesToManager, getManager } from "../../actions/profileActions"
import { CheckBox } from "../../components/common/CheckBox"
import { formattedNumber } from "../../components/common/Utils"

const { SearchBar } = Search

const _ROLES = [
  {
    id: "CUSTOMER_ADD",
    value: "গ্রাহক সংযোগ",
    isChecked: false,
  },
  {
    id: "CUSTOMER_EDIT",
    value: "গ্রাহক আপডেট",
    isChecked: false,
  },
  {
    id: "CUSTOMER_DEACTIVATE",
    value: "গ্রাহক বন্ধ",
    isChecked: false,
  },
  {
    id: "BILL_EDIT",
    value: "বিল আপডেট",
    isChecked: false,
  },
  {
    id: "MONTHLY_FEE_EDIT",
    value: "মাসিক ফি আপডেট",
    isChecked: false,
  },
  {
    id: "SEND_SMS",
    value: "ম্যাসেজ বোর্ড",
    isChecked: false,
  },
  {
    id: "CUSTOMER_ACTIVATE",
    value: "গ্রাহক একটিভ",
    isChecked: false,
  },
  {
    id: "CUSTOMER_DELETE",
    value: "গ্রাহক ডিলিট",
    isChecked: false,
  },
]

function resetRoles() {
  _ROLES.map((role) => (role.isChecked = false))
}

class Manager extends Component {
  constructor(props) {
    super(props)
    this.state = {
      roles: [],
    }
  }
  componentDidMount() {
    if (this.props.profile.isLoaded) {
      if (!this.props.profile.managerLoaded && !this.props.profile.managerLoading)
        this.props.getManager(this.props.profile.profile.managers[0])
    }
    if (this.props.profile.managerLoaded) {
      resetRoles()
      let roles = [..._ROLES]
      roles.forEach((role) => {
        if (this.props.profile.manager.roles.includes(role.id)) role.isChecked = true
      })
      this.setState({ roles })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profile.managerLoading) return
    if (nextProps.profile.managerLoaded) {
      resetRoles()
      let roles = [..._ROLES]
      roles.forEach((role) => {
        if (nextProps.profile.manager.roles.includes(role.id)) role.isChecked = true
      })
      this.setState({ roles })
    }
    if (nextProps.profile.isLoaded) {
      if (!nextProps.profile.managerLoaded && !nextProps.profile.managerLoading)
        nextProps.getManager(nextProps.profile.profile.managers[0])
    }
  }

  handleCheckChieldElement = (e) => {
    let roles = this.state.roles
    roles.forEach((role) => {
      if (role.value === e.target.value) role.isChecked = e.target.checked
    })
    this.setState({ roles })
  }
  onSubmitPermissonChange = () => {
    let roles = []
    this.state.roles.filter((role) => {
      if (role.isChecked) {
        roles.push(role.id)
      }
    })
    this.props.assignRolesToManager({ roles }, this.props.profile.profile.managers[0])
  }

  render() {
    let columns = [
      {
        // style: { height: "41px" },
        // headerStyle: { height: "41px" },
        dataField: "amount",
        text: "জমা",
        sort: true,
        headerAlign: "center",
        align: "center",
        formatter: (cell) => `${formattedNumber(cell)}`,
      },
      {
        // style: { height: "41px" },
        // headerStyle: { height: "41px" },
        dataField: "status",
        text: "স্ট্যাটাস",
        sort: true,
        headerAlign: "center",
        align: "center",
        formatter: (cell) => {
          let status
          if (cell === "PENDING") {
            status = <span class="badge badge-warning text-dark">{cell}</span>
          }
          if (cell === "ACCEPTED") {
            status = <span class="badge badge-success">{cell}</span>
          }
          if (cell === "REJECTED") {
            status = <span class="badge badge-danger">{cell}</span>
          }
          return status
        },
      },
      {
        // style: { height: "41px" },
        // headerStyle: { height: "41px" },
        dataField: "depositedAt",
        text: "তারিখ",
        sort: true,
        headerAlign: "center",
        align: "center",
        formatter: (cell) => moment(cell).format("DD/MM/yyyy hh:mm A"),
      },
    ]
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>ম্যানেজার প্রোফাইল</h4>
            </div>

            <div className="container">
              <div className="row justify-content-center p-3 bg-white align-items-start">
                {this.props.profile.managerLoaded ? (
                  <>
                    <div className="col-sm mt-3">
                      <div className="text-center mb-2">
                        {this.props.profile.manager.photo ? (
                          <img
                            style={{ height: "50px", width: "50px" }}
                            alt="image"
                            src={"data:image/png;base64, " + this.props.profile.manager.photo}
                            className="rounded-circle mr-2"
                          />
                        ) : (
                          <i className="fas fa-user-circle" style={{ fontSize: "25px" }} title="avatar" />
                        )}
                      </div>
                      <p className="text-center font-weight-bold  mb-0">{this.props.profile.manager.name}</p>
                      <p className="text-center mb-0">{this.props.profile.manager.fullAddress} </p>
                      <p className="text-center  mb-2">{this.props.profile.manager.mobile}</p>
                    </div>
                    <div className="col-sm mt-3">
                      <h5>পারমিশান পরিবর্তন করুন</h5>
                      <br />
                      <React.Fragment>
                        <ul>
                          {this.state.roles.map((role) => {
                            return <CheckBox handleCheckChieldElement={this.handleCheckChieldElement} {...role} />
                          })}
                        </ul>
                      </React.Fragment>
                      <button
                        style={{ minWidth: 100 }}
                        type="submit"
                        className="btn btn-primary ml-4"
                        disabled={this.props.server.requestPending}
                        onClick={this.onSubmitPermissonChange}
                      >
                        {this.props.server.requestPending ? <i className="fas fa-sync fa-spin"></i> : "আপডেট"}
                      </button>
                    </div>
                    <div className="col-sm-12 mt-3">
                      <hr />
                      <PaginationProvider
                        pagination={paginationFactory({
                          sizePerPageList: [
                            { text: "10", value: 10 },
                            { text: "20", value: 20 },
                            { text: "50", value: 50 },
                            {
                              text: "All",
                              value: this.props.profile.manager.deposits.length,
                            },
                          ],
                        })}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            bootstrap4
                            keyField="_id"
                            data={this.props.profile.manager.deposits.slice().reverse()}
                            columns={columns}
                            search
                          >
                            {(props) => (
                              <div>
                                <div className="row justify-content-between">
                                  <div className="col-md-4">
                                    <h5>বিল সংগ্রহ</h5>
                                  </div>
                                  <div className="col-md-6 text-right">
                                    <SearchBar {...props.searchProps} />
                                  </div>
                                </div>
                                <BootstrapTable striped hover {...props.baseProps} {...paginationTableProps} />
                              </div>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </div>
                  </>
                ) : (
                  <div class="fa-3x">
                    <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile,
    server: state.server,
  }
}

export default connect(mapStateToProps, {
  getManager,
  assignRolesToManager,
})(Manager)
