import moment from "moment";
import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import Select from "react-select";
import ReactToPrint from "react-to-print";

import { getDueReport } from "../../actions/reportActions";
import PdfComponent from "../../components/common/PdfComponent";
import {
  formattedNumber,
  monthsArr,
  toBangla,
  yearOptions,
  monthOptions
} from "../../components/common/Utils";

const { SearchBar } = Search;
const d = new Date();
const fy = d.getFullYear();

let _due = {};
for (let y = 2020; y <= fy; y++) {
  for (let m = 0; m <= 11; m++) {
    _due[y + "_" + m] = true;
    if (y === fy && m === d.getMonth()) break;
  }
}

class DueReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      area: { label: "সকল এরিয়া", value: -1 },
      areaOptions: [],
      year: { label: toBangla(d.getFullYear()), value: d.getFullYear() },
      month: { label: monthsArr[d.getMonth()], value: d.getMonth() },
      dueLineUsers: [],
      dueLineUsers2: [],
      filterDishOrIsp: { label: "ডিশ এবং ইন্টারনেট", value: "all" },
      totalMonthlyBill: 0,
      totalNextBill: 0
    };
  }

  componentDidMount() {
    if (!this.props.report.isDueReportLoaded) {
      this.props.getDueReport(this.state.year.value, this.state.month.value);
    }

    if (this.props.profile.isLoaded) {
      let areaOptions = [{ label: "সকল এরিয়া", value: -1 }];
      this.props.profile.feeder.areas.map(area => {
        areaOptions.push({ label: area.name, value: area._id });
      });
      this.setState({ areaOptions });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profile.isLoaded) {
      let areaOptions = [{ label: "সকল এরিয়া", value: -1 }];
      nextProps.profile.feeder.areas.map(area => {
        areaOptions.push({ label: area.name, value: area._id });
      });
      this.setState({ areaOptions });
    }

    if (nextProps.report.isDueReportLoaded) {
      let totalNextBill = 0,
        totalMonthlyBill = 0;
      nextProps.report.dueLineUsers.map(lineuser => {
        totalNextBill += lineuser.nextBill;
        totalMonthlyBill += lineuser.monthlyBill;
      });
      this.setState({
        dueLineUsers: nextProps.report.dueLineUsers,
        dueLineUsers2: nextProps.report.dueLineUsers,
        totalMonthlyBill,
        totalNextBill
      });
    }
  }

  onSubmit = e => {
    e.preventDefault();
    let { year, month } = this.state;
    this.props.getDueReport(year.value, month.value);
  };

  onFilter = e => {
    e.preventDefault();

    let dueLineUsers = [],
      totalMonthlyBill = 0,
      totalNextBill = 0;

    this.state.dueLineUsers2.map(lineuser => {
      if (this.state.area.value === -1) {
      } else {
        if (lineuser.assignedArea != this.state.area.value) return;
      }

      if (this.state.filterDishOrIsp.value !== "all") {
        if (!lineuser.connectionType) {
          if (this.state.filterDishOrIsp.value == 2) return;
        } else if (lineuser.connectionType != this.state.filterDishOrIsp.value)
          return;
      }

      totalMonthlyBill += lineuser.monthlyBill;
      totalNextBill += lineuser.nextBill;
      dueLineUsers.push(lineuser);
    });

    this.setState({ dueLineUsers, totalMonthlyBill, totalNextBill });
  };

  columns = [
    {
      dataField: "connectionType",
      text: "",
      sort: true,
      style: {
        wordWrap: "break-word",
        paddingLeft: "2px",
        paddingRight: "2px"
      },
      // headerAlign: "center",
      align: "center",
      headerStyle: { width: "35px" },
      formatter: cell =>
        cell === 1 ? (
          <>
            <i class="fas fa-tv"></i>
            {/* &nbsp;<i class="fas fa-tv"></i> */}
          </>
        ) : (
          <>
            <i class="fas fa-wifi"></i>
            {/* &nbsp;<i class="fas fa-wifi"></i> */}
          </>
        )
    },
    {
      dataField: "customerID",
      text: "আইডি",
      sort: true
    },
    {
      dataField: "name",
      text: "গ্রাহক",
      sort: true
    },
    {
      dataField: "houseOwnerName",
      text: "পরিচিতি",
      sort: true
    },
    {
      dataField: "mobile",
      text: "মোবাইল",
      sort: true,
      formatter: cell => (cell === "01700000000" ? null : cell)
    },
    {
      dataField: "monthlyBill",
      text: "মাসিক ফি",
      sort: true,
      formatter: cell => `৳ ${cell}`
    },
    {
      dataField: "nextBill",
      text: "বকেয়া",
      sort: true,
      formatter: cell => `৳ ${cell}`
    }
  ];

  render() {
    const { dueLineUsers, totalMonthlyBill, totalNextBill } = this.state;
    let company =
      this.props.auth.user.type === "feeder"
        ? this.props.auth.user.profile.company
        : this.props.auth.user.feeder.company;
    let feederType =
      this.props.auth.user.type === "feeder"
        ? this.props.auth.user.profile.feederType
        : this.props.auth.user.feeder.feederType;

    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>বকেয়া রিপোর্ট</h4>
            </div>
            <div className="container">
              <div
                style={{ minHeight: 230 }}
                className="row justify-content-center p-3 bg-white align-items-center"
              >
                {!this.props.server.requestPending &&
                this.props.report.isDueReportLoaded &&
                this.props.profile.isLoaded ? (
                  <div className="col-12">
                    <div className="clearfix py-2">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <Select
                              value={this.state.year}
                              options={yearOptions}
                              onChange={year => this.setState({ year })}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <Select
                              value={this.state.month}
                              options={monthOptions}
                              onChange={month => this.setState({ month })}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <button
                              onClick={this.onSubmit}
                              className="btn btn-success w-100"
                            >
                              সাবমিট
                            </button>
                          </div>
                        </div>
                        <div
                          className="col-md-12"
                          style={{
                            position: "relative",
                            top: "-20px",
                            minHeight: "10px"
                          }}
                        >
                          <hr />
                        </div>

                        {feederType === 3 && (
                          <div className="col-md-3">
                            <div className="form-group">
                              <Select
                                value={this.state.filterDishOrIsp}
                                options={[
                                  {
                                    label: "ডিশ এবং ইন্টারনেট",
                                    value: "all"
                                  },
                                  {
                                    label: "ডিশ",
                                    value: 1
                                  },
                                  {
                                    label: "ইন্টারনেট",
                                    value: 2
                                  }
                                ]}
                                onChange={filterDishOrIsp =>
                                  this.setState({
                                    filterDishOrIsp
                                  })
                                }
                              />
                            </div>
                          </div>
                        )}

                        <div className="col-md-3">
                          <div className="form-group">
                            <Select
                              value={this.state.area}
                              options={this.state.areaOptions}
                              onChange={area => this.setState({ area })}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <button
                              onClick={this.onFilter}
                              className="btn btn-primary w-100"
                            >
                              ফিল্টার
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <PaginationProvider
                      pagination={paginationFactory({
                        sizePerPageList: [
                          { text: "20", value: 20 },
                          { text: "50", value: 50 },
                          { text: "100", value: 100 },
                          { text: "200", value: 200 },
                          { text: "500", value: 500 },
                          { text: "1000", value: 1000 },
                          { text: "2000", value: 2000 }
                        ]
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          bootstrap4
                          keyField="createdAt"
                          data={dueLineUsers}
                          columns={this.columns}
                          search
                        >
                          {props => (
                            <div>
                              <div className="row justify-content-between">
                                <div className="col-md-8">
                                  <div className="mb-3">
                                    <strong>
                                      মোটঃ{" "}
                                      {formattedNumber(dueLineUsers.length)} জন
                                      | মোট বকেয়াঃ{" "}
                                      {formattedNumber(totalNextBill)} টাকা (
                                      চলতি মাসঃ{" "}
                                      {formattedNumber(totalMonthlyBill)},
                                      পূর্বের বকেয়াঃ{" "}
                                      {formattedNumber(
                                        totalNextBill - totalMonthlyBill
                                      )}{" "}
                                      )
                                      <hr />
                                    </strong>
                                  </div>
                                </div>
                                <div className="col-md-4 text-right">
                                  <SearchBar {...props.searchProps} />
                                  <CSVLink
                                    filename={`বকেয়া রিপোর্ট - ${company}.csv`}
                                    data={[
                                      [
                                        "আইডি",
                                        "গ্রাহক",
                                        "ঠিকানা",
                                        "মোবাইল",
                                        "মাসিক ফি",
                                        "বকেয়া"
                                      ],
                                      ...this.state.dueLineUsers.map(row => [
                                        row.customerID,
                                        row.name,
                                        row.houseOwnerName,
                                        row.mobile !== "01700000000"
                                          ? row.mobile
                                          : "",
                                        row.monthlyBill,
                                        row.nextBill
                                      ])
                                    ]}
                                  >
                                    <i
                                      style={{ fontSize: "25px" }}
                                      className="fas fa-file-csv ml-2"
                                    ></i>
                                  </CSVLink>
                                  <ReactToPrint
                                    trigger={() => (
                                      <button
                                        style={{
                                          border: "none",
                                          background: "none"
                                        }}
                                        type="button"
                                      >
                                        <i
                                          style={{ fontSize: "25px" }}
                                          className="fas fa-file-pdf"
                                        ></i>
                                      </button>
                                    )}
                                    content={() => this.componentRef}
                                  />
                                  <div style={{ display: "none" }}>
                                    <PdfComponent
                                      ref={el => (this.componentRef = el)}
                                    >
                                      <table
                                        style={{ color: "#000" }}
                                        className="table table-bordered mt-5 table-md"
                                      >
                                        <tbody>
                                          <tr>
                                            <td colSpan="2">
                                              <h5 className="text-center">
                                                {this.state.area.label}
                                                <br />
                                                {this.state.month.label},
                                                {this.state.year.label}
                                                <br />
                                                মোটঃ{" "}
                                                {formattedNumber(
                                                  dueLineUsers.length
                                                )}{" "}
                                                জন
                                              </h5>
                                            </td>
                                            <td colSpan="5">
                                              <h4 className="text-center">
                                                <u>বকেয়া রিপোর্ট</u>
                                              </h4>
                                              <h5 className="text-center">
                                                {
                                                  this.props.auth.user.profile
                                                    ?.company
                                                }
                                                <br />
                                                {
                                                  this.props.auth.user.profile
                                                    ?.fullAddress
                                                }
                                                <br />

                                                <span className="font-italic">
                                                  {moment(d).format(
                                                    "DD/MM/yyyy hh:mm A"
                                                  )}
                                                </span>
                                              </h5>
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>আইডি</th>
                                            <th>গ্রাহক</th>
                                            <th>ঠিকানা</th>
                                            <th>মোবাইল</th>
                                            <th>মাসিক ফি</th>
                                            <th>বকেয়া</th>
                                          </tr>

                                          {this.state.dueLineUsers.map(
                                            (row, idx) => {
                                              return (
                                                <tr key={idx}>
                                                  <td>{row.customerID}</td>
                                                  <td>{row.name}</td>
                                                  <td>{row.houseOwnerName}</td>
                                                  <td>{row.mobile}</td>
                                                  <td>{row.monthlyBill}</td>
                                                  <td>{row.nextBill}</td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </PdfComponent>
                                  </div>
                                </div>
                              </div>

                              <BootstrapTable
                                striped
                                hover
                                {...props.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </div>
                ) : (
                  <div class="fa-3x">
                    <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile,
    server: state.server,
    report: state.report,
    auth: state.auth
  };
};

export default connect(mapStateToProps, {
  getDueReport
})(DueReport);
