import { combineReducers } from "redux"
import authReducer from "./authReducer"
import dashboardReducer from "./dashboardReducer"
import collectorReducer from "./collectorReducer"
import expenditureReducer from "./expenditureReducer"
import lineUserReducer from "./lineUserReducer"
import operatorReducer from "./operatorReducer"
import profielReducer from "./profielReducer"
import resellerReducer from "./resellerReducer"
import serverReducer from "./serverReducer"
import staffReducer from "./staffReducer"
import reportReducer from "./reportReducer"

export default combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  profile: profielReducer,
  collector: collectorReducer,
  lineUser: lineUserReducer,
  server: serverReducer,
  operator: operatorReducer,
  staff: staffReducer,
  expenditure: expenditureReducer,
  reseller: resellerReducer,
  report: reportReducer,
})
