import moment from "moment";
import React, { Component, createRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import toastr from "toastr";
import {
  addCollector,
  allowedCollectors,
  assignAreasToCollector,
  assignRolesToCollector,
  getAllCollectors,
  updateCollector
} from "../../actions/collectorActions";
import {
  changeMobileNumber,
  resetPassword,
  sendSingleSMS
} from "../../actions/profileActions";
import {
  CheckBox,
  CheckBoxOperatorSettings
} from "../../components/common/CheckBox";
import PdfComponent from "../../components/common/PdfComponent";
import { formattedNumber, toBangla } from "../../components/common/Utils";
import AreaAssignModal from "../../components/modals/AreaAssignModal";
import Modal, { ProfileModal } from "../../components/modals/Modal";
import NumberChangeModal from "../../components/modals/NumberChangeModal";
import SendSmsModal from "../../components/modals/SendSmsModal";
const { SearchBar } = Search;

const d = new Date();
const _ROLES = [
  {
    id: "BILL_PRINT",
    value: "বিল প্রিন্ট",
    isChecked: false
  },
  {
    id: "VIEW_REPORT",
    value: "ভিউ রিপোর্ট",
    isChecked: false
  },
  {
    id: "CUSTOMER_MOBILE_EDIT",
    value: "গ্রাহকের মোবাইল নম্বর আপডেট",
    isChecked: false
  }
];
function resetRoles() {
  _ROLES.map(role => (role.isChecked = false));
}

class Collector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      year: { label: toBangla(d.getFullYear()), value: d.getFullYear() },
      month: this.months[d.getMonth()],
      areas: [],
      assignedArea: { label: "সকল এরিয়া", value: "all" },
      modalTitle: "",
      collectorToModify: {},
      addCollector: false,
      collectorsForSettings: [],
      currentMonthDeposits: [],
      collector: {}
    };

    this.nameRef = createRef();
    this.mobileRef = createRef();
  }
  months = [
    { label: "জানুয়ারী", value: 0 },
    { label: "ফেব্রুয়ারী", value: 1 },
    { label: "মার্চ", value: 2 },
    { label: "এপ্রিল", value: 3 },
    { label: "মে", value: 4 },
    { label: "জুন", value: 5 },
    { label: "জুলাই", value: 6 },
    { label: "আগস্ট", value: 7 },
    { label: "সেপ্টেম্বর", value: 8 },
    { label: "অক্টোবর", value: 9 },
    { label: "নভেম্বর", value: 10 },
    { label: "ডিসেম্বর", value: 11 }
  ];
  years = [
    { label: "২০২১", value: 2021 },
    { label: "২০২০", value: 2020 },
    { label: "২০১৯", value: 2019 }
  ];

  onChangeHandler = e => {
    this.setState({
      collectorToModify: {
        ...this.state.collectorToModify,
        [e.target.name]: e.target.value
      }
    });
  };

  showAddCollectorModal = () => {
    this.setState({
      modalTitle: "কালেক্টর অ্যাড করুন।",
      collectorToModify: {
        name: "",
        fatherName: "",
        mobile: "",
        email: "",
        fullAddress: "",
        assignedArea: "",
        assignedAreas: [],
        flat: "",
        house: "",
        area: "",
        thana: "",
        district: "",
        nidNumber: "",
        referenceName: "",
        referenceMobile: ""
      },
      addCollector: true
    });
  };

  showEditCollectorModal = collector => {
    this.setState({
      collectorToModify: {
        _id: collector._id,
        name: collector.name,
        fatherName: collector.fatherName,
        mobile: collector.mobile,
        email: collector.email,
        fullAddress: collector.fullAddress,
        assignedArea: collector.assignedArea,
        assignedAreas: collector.assignedAreas,
        flat: collector.address?.flat,
        house: collector.address?.house,
        area: collector.address?.area,
        thana: collector.address?.thana,
        district: collector.address?.district,
        nidNumber: collector.nid?.number,
        referenceName: collector.reference?.name || "",
        referenceMobile: collector.reference?.mobile || ""
      },
      modalTitle: "কালেক্টর এডিট করুন",
      addCollector: false
    });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    let { name, mobile } = this.state.collectorToModify;
    if (!name) {
      toastr.error("নাম ফিল্ড পুরন করুন");
      this.nameRef.current.focus();
      return;
    } else if (!mobile) {
      toastr.error("মোবাইল ফিল্ড পুরন করুন");
      this.mobileRef.current.focus();
      return;
    }
    if (this.state.addCollector) {
      this.props.addCollector(this.state.collectorToModify);
    } else {
      this.props.updateCollector(this.state.collectorToModify);
    }
  };

  selectArea = area => {
    this.setState({ assignedArea: area });
  };

  sortByArea = collectors => {
    const { profile } = this.props;
    const { assignedArea } = this.state;

    if (profile.isLoaded) {
      const collectionType = profile.profile.collectorBillCollection;
      if (collectionType === "one2one") {
        collectors = collectors.filter(
          collector => collector.assignedArea === assignedArea.value
        );
      } else {
        collectors = collectors.filter(
          collector => collector.assignedAreas.indexOf(assignedArea.value) >= 0
        );
      }
    }

    return collectors;
  };

  componentDidMount() {
    const { collector, getAllCollectors } = this.props;

    if (!collector.isLoaded) {
      getAllCollectors();
    }
  }

  componentWillReceiveProps(nextProps) {
    let areas = [];

    if (nextProps.profile?.feeder?.areas?.length) {
      nextProps.profile.feeder.areas.forEach(area => {
        areas.push({ value: area._id, label: area.name });
      });
    }

    this.setState({
      areas
    });
  }

  viewHandler = collector => {
    resetRoles();
    let roles = [..._ROLES];
    roles.forEach(role => {
      if (collector.roles.includes(role.id)) role.isChecked = true;
    });
    this.setState({
      collectorToModify: {
        role: "collector",
        userID: collector.userID,
        _id: collector._id,
        assignedAreas: collector.assignedAreas.length
          ? collector.assignedAreas
          : [],
        name: collector.name,
        mobile: collector.mobile,
        fullAddress: collector.fullAddress || "",
        createdAt: collector.createdAt,
        company: this.props.profile.feeder.company,
        roles,
        signature:
          this.props.profile.feeder.signature ||
          "সিগনেচার পাওয়া যায়নি। প্রোফাইলে কোম্পানীর নাম ও মোবাইল নম্বর ঘরটি পূরণ করুন।"
      }
    });
  };

  collectorsForSettingsHandler = () => {
    let areas = this.props.profile.feeder.areas;
    let allowedBillCollectors = this.props.profile.feeder.allowedBillCollectors;
    let collectors = this.props.collector.collectors;
    let collectorsForSettings = [];

    collectors.map(collector => {
      let areaNames = "";
      collector.assignedAreas.map(id => {
        let temp = areas.find(area => area._id == id);
        if (temp) areaNames += temp.name + ", ";
      });

      collectorsForSettings.push({
        id: collector.userID,
        value: collector.name,
        isChecked: false,
        areaNames
      });
    });

    allowedBillCollectors.map(id => {
      collectorsForSettings.map(col => {
        if (col.id == id) col.isChecked = true;
      });
    });
    this.setState({ collectorsForSettings });
  };

  handleAllCheckedForSetting = e => {
    let collectors = this.state.collectorsForSettings;
    collectors.forEach(operator => (operator.isChecked = e.target.checked));
    this.setState({ collectorsForSettings: collectors });
  };

  handleCheckChieldElementForSetting = e => {
    let collectorsForSettings = this.state.collectorsForSettings;
    collectorsForSettings.forEach(operator => {
      if (operator.value === e.target.value)
        operator.isChecked = e.target.checked;
    });
    this.setState({ collectorsForSettings });
  };

  handleCheckChieldElement = e => {
    let roles = this.state.collectorToModify.roles;
    roles.forEach(role => {
      if (role.value === e.target.value) role.isChecked = e.target.checked;
    });
    this.setState({
      collectorToModify: { ...this.state.collectorToModify, roles }
    });
  };

  onSubmitPermissonChange = e => {
    e.preventDefault();
    let roles = [];
    this.state.collectorToModify.roles.filter(role => {
      if (role.isChecked) {
        roles.push(role.id);
      }
    });
    this.props.assignRolesToCollector(
      { roles },
      this.state.collectorToModify._id
    );
  };

  onSubmitCollectorSettings = e => {
    e.preventDefault();
    let allowedCollectors = [];
    this.state.collectorsForSettings.filter(collector => {
      if (collector.isChecked) allowedCollectors.push(collector.id);
    });
    this.props.allowedCollectors(
      { allowedBillCollectors: allowedCollectors },
      this.props.profile.profile
    );
  };

  resetPassword = row => {
    let sure = window.confirm("Are you sure to reset password?");
    if (sure) this.props.resetPassword({ mobile: row.mobile });
  };

  onSubmitBillCollecionModal = e => {
    e.preventDefault();
    let currentMonthDeposits = [],
      totalDeposit = 0;
    this.state.alldeposits.map(deposit => {
      if (
        this.state.year.value == deposit.year &&
        this.state.month.value == deposit.month
      ) {
        if (deposit.status === "ACCEPTED") {
          totalDeposit += parseInt(deposit.amount);
        }
        currentMonthDeposits.push(deposit);
      }
    });
    this.setState({ currentMonthDeposits, totalDeposit });
  };

  viewBillCollectionHandler = (collector, type) => {
    let alldeposits = [],
      currentMonthDeposits = [],
      totalDeposit = 0;

    if (type === "deposit") {
      let dateObj, month, day, year;

      collector.deposits.map(deposit => {
        dateObj = new Date(deposit.depositedAt);
        month = dateObj.getUTCMonth();
        day = dateObj.getUTCDate();
        year = dateObj.getUTCFullYear();
        deposit.year = year;
        deposit.month = month;
        deposit.day = day;

        if (
          this.state.year.value === year &&
          this.state.month.value === month
        ) {
          currentMonthDeposits.push(deposit);
          if (deposit.status === "ACCEPTED") {
            totalDeposit += parseInt(deposit.amount);
          }
        }
        alldeposits.push(deposit);
      });
    }
    this.setState({
      collector: collector,
      alldeposits,
      currentMonthDeposits,
      totalDeposit
    });
  };

  render() {
    let depositColumnInfo = [
      {
        dataField: "amount",
        text: "জমা",
        sort: true,
        headerAlign: "center",
        align: "center",
        formatter: cell => `${formattedNumber(cell)}`
      },
      {
        dataField: "depositedAt",
        text: "জমার তারিখ",
        sort: true,
        headerAlign: "center",
        align: "center",
        formatter: cell => moment(cell).format("DD/MM/yyyy hh:mm A")
      },
      {
        dataField: "status",
        text: "স্ট্যাটাস",
        sort: true,
        headerAlign: "center",
        align: "center",
        formatter: cell => {
          let status;
          if (cell === "PENDING") {
            status = <span class="badge badge-warning text-dark">{cell}</span>;
          }
          if (cell === "ACCEPTED") {
            status = <span class="badge badge-success">{cell}</span>;
          }
          if (cell === "REJECTED") {
            status = <span class="badge badge-danger">{cell}</span>;
          }
          return status;
        }
      },
      {
        dataField: "_id",
        text: "Action",
        formatExtraData: this.props.server.requestPending,
        headerAlign: "center",
        align: "center",
        formatter: (cell, row, rowIndex, requestPending) => {
          return row.status === "PENDING" ? (
            <div>
              <button
                disabled={requestPending}
                className="btn btn-success btn-sm"
                onClick={() => this.onConfirmDeposit(row, "ACCEPTED")}
              >
                গ্রহণ
              </button>
              {"  "}
              <button
                disabled={requestPending}
                className="btn btn-danger btn-sm"
                onClick={() => this.onConfirmDeposit(row, "REJECTED")}
              >
                বাতিল
              </button>
            </div>
          ) : (
            ""
          );
        }
      }
    ];
    let company =
      this.props.auth.user.type === "feeder"
        ? this.props.auth.user.profile.company
        : this.props.auth.user.feeder.company;

    let columns = [
      {
        dataField: "name",
        text: "নাম",
        sort: true,
        headerAlign: "center",
        align: "center"
      },
      {
        dataField: "mobile",
        text: "মোবাইল",
        sort: true,
        headerAlign: "center",
        align: "center"
      },
      {
        dataField: "status",
        text: "স্টাট্যাস",
        headerAlign: "center",
        align: "center",
        sort: true,
        formatExtraData: this.props.profile.feeder.allowedBillCollectors,
        sortValue: (cell, row) =>
          this.props.profile.feeder.allowedBillCollectors?.includes(row.userID),
        formatter: (cell, row, rowIndex, allowedBillCollectors) =>
          allowedBillCollectors?.includes(row.userID) ? (
            <i className="fas fa-check text-success"></i>
          ) : (
            <i className="fas fa-ban text-danger"></i>
          )
      },
      {
        headerAlign: "center",
        align: "center",
        dataField: "createdAt",
        text: "তারিখ",
        // headerStyle: { width: "265px" },
        formatter: cell => moment(cell).format("DD/MM/yyyy hh:mm A"),
        sort: true
      },
      {
        dataField: "_id",
        text: "Action",
        align: "center",
        headerAlign: "center",
        formatter: (cell, row) => (
          <ul className="navbar-nav navbar-right">
            <li className="dropdown">
              <a
                href="#"
                data-toggle="dropdown"
                className="nav-link nav-link-lg"
              >
                <i className="fas fa-ellipsis-h" style={{ fontSize: 24 }}></i>
              </a>
              <div className="dropdown-menu p-1">
                <a
                  type="button"
                  onClick={() => this.viewHandler(row)}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#profileModal"
                >
                  <i className="fas fa-user" />
                  প্রোফাইল
                </a>

                <a
                  type="button"
                  onClick={() => this.showEditCollectorModal(row)}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#CollectorAddEditModal"
                >
                  <i className="fas fa-user-edit"></i>
                  এডিট করুন
                </a>
                {/* <a
                  type="button"
                  // onClick={() => this.showEditCustomerMolal(cell)}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <i className="fas fa-chart-bar" />
                  রিপোর্ট দেখুন
                </a> */}
                <a
                  type="button"
                  onClick={() => this.viewHandler(row)}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#sendSMSmodal"
                >
                  <i className="fas fa-paper-plane" />
                  সেন্ড এস.এম.এস
                </a>
                {/* <a
                  type="button"
                  onClick={() => this.viewBillCollectionHandler(row, "deposit")}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#billCollecionModal"
                >
                  <i className="fas fa-dollar-sign" />
                  বিল সংগ্রহ
                </a> */}
                <a
                  type="button"
                  onClick={() => this.resetPassword(row)}
                  className="dropdown-item has-icon"
                >
                  <i className="fas fa-fingerprint" />
                  রিসেট পাসওয়ার্ড
                </a>
                <a
                  type="button"
                  onClick={() => this.viewHandler(row)}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#changeMobile"
                >
                  <i className="fas fa-mobile" />
                  কালেক্টর পরিবর্তন
                </a>
                <a
                  type="button"
                  onClick={() => this.viewHandler(row)}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#areaAssignModal"
                >
                  <i className="fas fa-edit" />
                  এরিয়া পরিবর্তন
                </a>
              </div>
            </li>
          </ul>
        )
      }
    ];
    let { collector, server, profile } = this.props;
    let { areas, assignedArea, modalTitle, collectorToModify } = this.state;
    let collectors = [];
    collectors = collector.collectors;
    if (assignedArea.value != "all") {
      collectors = this.sortByArea(collectors);
    }
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>কালেক্টর</h4>
            </div>
            <div className="container">
              <div
                style={{ minHeight: 230 }}
                className="row justify-content-center p-3 bg-white align-items-center"
              >
                {collector.isLoaded && profile.isLoaded ? (
                  <div className="col-12">
                    <div className="clearfix py-3">
                      <h4 className="float-left">সকল কালেক্টর</h4>
                      <button
                        onClick={this.collectorsForSettingsHandler}
                        className="btn btn-success float-right"
                        data-toggle="modal"
                        data-target="#collectorSettingsModal"
                      >
                        <i class="fas fa-cog" title="কালেক্টর সেটিংস"></i>
                      </button>

                      <button
                        onClick={this.showAddCollectorModal}
                        className="btn btn-success float-right mx-2"
                        data-toggle="modal"
                        data-target="#CollectorAddEditModal"
                      >
                        <i class="fas fa-user-plus" title="নতুন কালেক্টর"></i>
                      </button>
                    </div>
                    <hr />
                    <div className="mb-3">
                      <strong>মোটঃ {collectors.length} জন</strong>
                    </div>

                    {collectors.length > 0 && (
                      <PaginationProvider
                        pagination={paginationFactory({
                          sizePerPageList: [
                            { text: "10", value: 10 },
                            { text: "20", value: 20 },
                            { text: "50", value: 50 }
                            // { text: "All", value: collectors.length },
                          ]
                        })}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            bootstrap4
                            keyField="_id"
                            data={collectors}
                            columns={columns}
                            search
                          >
                            {props => (
                              <div>
                                <div className="row justify-content-between">
                                  <div className="col-md-4">
                                    {/* <Select
                                      value={assignedArea}
                                      options={[{ label: "সকল এরিয়া", value: "all" }, ...areas]}
                                      onChange={this.selectArea}
                                    /> */}
                                  </div>
                                  <div className="col-md-6 text-right">
                                    <SearchBar {...props.searchProps} />
                                    <CSVLink
                                      filename={`কালেক্টর - ${company}.csv`}
                                      data={[
                                        ["নাম", "মোবাইল"],
                                        ...collectors.map(item => [
                                          item.name,
                                          item.mobile
                                        ])
                                      ]}
                                    >
                                      <i
                                        style={{ fontSize: "25px" }}
                                        className="fas fa-file-csv ml-2"
                                      ></i>
                                    </CSVLink>
                                    <ReactToPrint
                                      trigger={() => (
                                        <button
                                          style={{
                                            border: "none",
                                            background: "none"
                                          }}
                                          type="button"
                                        >
                                          <i
                                            style={{ fontSize: "25px" }}
                                            className="fas fa-file-pdf"
                                          ></i>
                                        </button>
                                      )}
                                      content={() => this.componentRef}
                                    />
                                    <div style={{ display: "none" }}>
                                      <PdfComponent
                                        ref={el => (this.componentRef = el)}
                                      >
                                        <table
                                          style={{ color: "#000" }}
                                          className="table table-bordered mt-5 table-md"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                colSpan="6"
                                                className="text-center"
                                              >
                                                <span className="text-center">
                                                  <strong>{`${this.props.auth.user.profile?.company}`}</strong>
                                                  <br />
                                                  {this.props.auth.user.profile
                                                    ?.fullAddress
                                                    ? this.props.auth.user
                                                        .profile?.fullAddress
                                                    : ""}
                                                  <br />
                                                  <strong>
                                                    <ul>সকল কালেক্টর</ul>
                                                  </strong>
                                                </span>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th>নাম</th>
                                              <th>মোবাইল</th>
                                            </tr>

                                            {collectors.map((item, idx) => {
                                              return (
                                                <tr key={idx}>
                                                  <td>{item.name}</td>
                                                  <td>{item.mobile}</td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </PdfComponent>
                                    </div>
                                  </div>
                                </div>
                                {collectors.length > 0 && (
                                  <BootstrapTable
                                    striped
                                    hover
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                )}
                              </div>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    )}
                  </div>
                ) : (
                  <div className="fa-3x">
                    <i
                      style={{ fontSize: 40 }}
                      className="fas fa-sync fa-spin"
                    ></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        <Modal
          modalTitle={modalTitle}
          onSubmit={this.onSubmitHandler}
          pending={server.requestPending}
          id="CollectorAddEditModal"
        >
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="name">নাম</label>
                <input
                  ref={this.nameRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={collectorToModify.name}
                  type="text"
                  name="name"
                  disabled={server.requestPending}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">ইমেইল</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={collectorToModify.email}
                  type="email"
                  name="email"
                  disabled={server.requestPending}
                />
              </div>
              <div className="form-group">
                <label htmlFor="referenceName">রেফারেন্স নাম</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={collectorToModify.referenceName}
                  type="text"
                  name="referenceName"
                  disabled={server.requestPending}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="fatherName">পিতার নাম</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={collectorToModify.fatherName}
                  type="text"
                  name="fatherName"
                  disabled={server.requestPending}
                />
              </div>
              <div className="form-group">
                <label htmlFor="fullAddress">ঠিকানা</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={collectorToModify.fullAddress}
                  type="text"
                  name="fullAddress"
                  disabled={this.props.server.requestPending}
                />
              </div>
              <div className="form-group">
                <label htmlFor="referenceMobile">রেফারেন্স মোবাইল</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={collectorToModify.referenceMobile}
                  type="text"
                  name="referenceMobile"
                  disabled={server.requestPending}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="mobile">মোবাইল</label>
                <input
                  ref={this.mobileRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={collectorToModify.mobile}
                  type="text"
                  name="mobile"
                  disabled={!this.state.addCollector || server.requestPending}
                />
              </div>
              <div className="form-group">
                <label htmlFor="nidNumber">জাতীয় পরিচয়পত্র নম্বর</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={collectorToModify.nidNumber}
                  type="text"
                  name="nidNumber"
                  disabled={server.requestPending}
                />
              </div>
            </div>

            {/* <div className="form-group">
            <label htmlFor="flat">বাড়ী নং / মহল্লা</label>
            <input
              className="form-control"
              onChange={this.onChangeHandler}
              value={collectorToModify.flat}
              type="text"
              name="flat"
              disabled={server.requestPending}
            />
          </div>

          <div className="form-group">
            <label htmlFor="house">রোড নং / গ্রাম</label>
            <input
              className="form-control"
              onChange={this.onChangeHandler}
              value={collectorToModify.house}
              type="text"
              name="house"
              disabled={server.requestPending}
            />
          </div>

          <div className="form-group">
            <label htmlFor="area">পোষ্ট অফিস</label>
            <input
              className="form-control"
              onChange={this.onChangeHandler}
              value={collectorToModify.area}
              type="text"
              name="area"
              disabled={server.requestPending}
            />
          </div>

          <div className="form-group">
            <label htmlFor="thana">থানা</label>
            <input
              className="form-control"
              onChange={this.onChangeHandler}
              value={collectorToModify.thana}
              type="text"
              name="thana"
              disabled={server.requestPending}
            />
          </div>
          <div className="form-group">
            <label htmlFor="district">জেলা</label>
            <input
              className="form-control"
              onChange={this.onChangeHandler}
              value={collectorToModify.district}
              type="text"
              name="district"
              disabled={server.requestPending}
            />
          </div> */}
          </div>
        </Modal>

        {/* Profile Modal */}
        <Modal
          onSubmit={this.onSubmitPermissonChange}
          pending={server.requestPending}
          id="profileModal"
        >
          <div className="row">
            <div className="col-sm">
              <div className="text-center mb-2">
                <i className="fas fa-user-circle fa-5x" title="avatar" />
              </div>
              <p className="text-center font-weight-bold  mb-0">
                {this.state.collectorToModify.name}
              </p>
              <p className="text-center mb-0">
                {this.state.collectorToModify.company}
              </p>
              <p className="text-center mb-0">
                {this.state.collectorToModify.fullAddress || ""}
              </p>
              <p className="text-center  mb-2">
                {this.state.collectorToModify.mobile}
              </p>
              <p className="text-center font-weight-bold  mb-0">
                সংযোগ তারিখঃ{" "}
                {moment(this.state.collectorToModify.createdAt).format(
                  "DD/MM/yyyy"
                )}
              </p>
            </div>
            <div className="col-sm">
              <h5>পারমিশন পরিবর্তন করুন</h5>
              <br />
              <React.Fragment>
                <ul>
                  {this.state.collectorToModify.roles?.map(role => {
                    return (
                      <CheckBox
                        handleCheckChieldElement={this.handleCheckChieldElement}
                        {...role}
                      />
                    );
                  })}
                </ul>
              </React.Fragment>
            </div>
          </div>
        </Modal>

        {/* Collector Settings Modal*/}
        <Modal
          modalTitle="কালেক্টর সেটিংস"
          onSubmit={this.onSubmitCollectorSettings}
          pending={this.props.server.requestPending}
          id="collectorSettingsModal"
        >
          <React.Fragment>
            <input
              type="checkbox"
              onClick={this.handleAllCheckedForSetting}
              value="checkedall"
            />{" "}
            সকল কালেক্টর
            <ul>
              {this.state.collectorsForSettings.map(collector => {
                return (
                  <CheckBoxOperatorSettings
                    handleCheckChieldElement={
                      this.handleCheckChieldElementForSetting
                    }
                    {...collector}
                  />
                );
              })}
            </ul>
          </React.Fragment>
        </Modal>

        {/* Bill collectoin Modal*/}
        <ProfileModal id="billCollecionModal">
          <div className="mb-2 mt-3">
            <h4 className="text-center">
              {this.state.collector.name} - বিল সংগ্রহ
            </h4>
            <hr />
            <div className="row">
              <div className="col-md-5">
                <div className="form-group">
                  <Select
                    value={this.state.year}
                    options={this.years}
                    onChange={year => this.setState({ year })}
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group">
                  <Select
                    value={this.state.month}
                    options={this.months}
                    onChange={month => this.setState({ month })}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <button
                    onClick={this.onSubmitBillCollecionModal}
                    className="btn btn-success w-100"
                  >
                    সাবমিট
                  </button>
                </div>
              </div>
            </div>
          </div>
          <PaginationProvider
            pagination={paginationFactory({
              sizePerPageList: [
                { text: "10", value: 10 },
                { text: "20", value: 20 },
                { text: "50", value: 50 }
              ]
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                bootstrap4
                keyField="_id"
                data={this.state.currentMonthDeposits}
                columns={depositColumnInfo}
                search
              >
                {props => (
                  <div>
                    <div className="row justify-content-between">
                      <div className="col-md-6">
                        <strong>মোট ডিপোজিটঃ {this.state.totalDeposit}</strong>
                      </div>
                      <div className="col-md-6 text-right">
                        <SearchBar {...props.searchProps} />
                      </div>
                    </div>
                    <BootstrapTable
                      striped
                      hover
                      {...props.baseProps}
                      {...paginationTableProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </ProfileModal>

        {/* Send sms Modal*/}
        <SendSmsModal
          data={this.state.collectorToModify}
          pending={this.props.server.requestPending}
          action={this.props.sendSingleSMS}
        />

        {/* Number change Modal */}
        <NumberChangeModal
          data={this.state.collectorToModify}
          pending={this.props.server.requestPending}
          action={this.props.changeMobileNumber}
        />

        {/* Area assign  Modal*/}
        <AreaAssignModal
          data={this.state.collectorToModify}
          areas={this.props.profile.feeder.areas}
          pending={this.props.server.requestPending}
          action={this.props.assignAreasToCollector}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile,
    collector: state.collector,
    server: state.server,
    auth: state.auth
  };
};

export default connect(mapStateToProps, {
  getAllCollectors,
  addCollector,
  allowedCollectors,
  updateCollector,
  assignAreasToCollector,
  sendSingleSMS,
  resetPassword,
  changeMobileNumber,
  assignRolesToCollector
})(Collector);
