import React from "react";

export const CheckBox = (props) => {
  return (
    <li style={{ listStyle: "none" }}>
      <input
        key={props.id}
        onClick={props.handleCheckChieldElement}
        type="checkbox"
        checked={props.isChecked}
        value={props.value}
      />{" "}
      {props.value}
    </li>
  );
};

export const CheckBoxOperatorSettings = (props) => {
  return (
    <li style={{ listStyle: "none" }}>
      <input
        key={props.id}
        onClick={props.handleCheckChieldElement}
        type="checkbox"
        checked={props.isChecked}
        value={props.value}
      />{" "}
      <strong>{props.value}</strong>
      <br />
      <small>{props.areaNames}</small>
    </li>
  );
};
