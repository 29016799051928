import React, { Component, createRef } from "react"
import { connect } from "react-redux"
import toastr from "toastr"
import { editLineUserBill } from "../../actions/lineUserActions"
import Modal from "../../components/modals/Modal"

class BillUpdate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lineUser: props.lineUserToAddOrEdit || {},
      sendSms: false,
      billChangeNote: "",
      newNextBill: 0,
      mobile: "",
    }
    this.newNextBillRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { requestPending, errorMsg } = nextProps.server
    if (requestPending || errorMsg) return

    let mobile
    if (nextProps.lineUserToAddOrEdit.mobile && nextProps.lineUserToAddOrEdit.mobile !== "01700000000") {
      mobile = nextProps.lineUserToAddOrEdit.mobile
    }

    this.setState({
      lineUser: nextProps.lineUserToAddOrEdit,
      modalTitle: nextProps.modalTitle,
      newNextBill: nextProps.lineUserToAddOrEdit.dueAmount,
      mobile,
      billChangeNote: "",
    })
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleCheckBoxClick() {
    this.setState({ sendSms: !this.state.sendSms })
  }

  onSubmitHandler = (e) => {
    e.preventDefault()
    let { id, name, dueAmount } = this.state.lineUser
    let { sendSms, billChangeNote, newNextBill, mobile } = this.state
    let msg, type

    if (!newNextBill) {
      toastr.error("বিল পুরন করুন")
      this.newNextBillRef.current.focus()
      return
    } else if (newNextBill) {
      let isNum = /^\d+$/.test(newNextBill)
      if (!isNum) {
        toastr.error("বিল সঠিক নয়")
        this.newNextBillRef.current.focus()
        return
      }

      if (newNextBill > 100000) {
        toastr.error("বিল সঠিক নয়। সর্বোচ্চঃ 100000")
        this.newNextBillRef.current.focus()
        return
      }
    }

    if (newNextBill < dueAmount) {
      msg = `আপনি যদি ${name} এর বিল ${dueAmount - newNextBill} টাকা কমাতে চান, তাহলে OK বাটনে ক্লিক করুন।`
      type = "DISCOUNT"
    } else if (newNextBill > dueAmount) {
      msg = `আপনি যদি ${name} এর বিল ${newNextBill - dueAmount} টাকা বৃদ্ধি করতে চান, তাহলে OK বাটনে ক্লিক করুন।`
      type = "INCREMENT"
    } else {
      toastr.error("পূর্বের বিল এবং পরিবর্তিত বিল সমান")
      this.newNextBillRef.current.focus()
      return
    }

    let con = window.confirm(msg)
    if (con) {
      const data = {
        type,
        sendSms: sendSms ? "yes" : "no",
        newNextBill: newNextBill,
        billChangeNote: billChangeNote,
        id,
      }
      this.props.editLineUserBill(data)
    }
  }

  render() {
    const { lineUser, newNextBill, sendSms, billChangeNote, mobile } = this.state
    return (
      <>
        <Modal
          modalTitle="বিল আপডেট"
          onSubmit={this.onSubmitHandler}
          pending={this.props.server.requestPending}
          id="billUpdate"
          modalWidth="w-50"
        >
          <div className="row">
            <div className="col-md-6 text-left mb-1">
              আইডিঃ <strong>{lineUser.customerID}</strong>
            </div>
            <div className="col-md-6 text-left mb-1">
              মাসিক ফিঃ <strong>{lineUser.monthlyBill} টাকা</strong>
            </div>

            <div className="col-md-6 text-left mb-1">
              গ্রাহকঃ <strong>{lineUser.name}</strong>
            </div>
            <div className="col-md-6 text-left mb-2">
              বিলঃ <strong>{lineUser.dueAmount} টাকা</strong>
            </div>
            {<div className="col-md-6 text-left mb-2">মোবাইলঃ {mobile ? <strong>{mobile}</strong> : "নাই"}</div>}
            <div className="col-md-6"></div>

            {/* New Monthly Bill  */}
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="newNextBill">নতুন বিল</label>
                <input
                  ref={this.newNextBillRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={newNextBill}
                  type="number"
                  name="newNextBill"
                />
              </div>
            </div>

            {/* নোট  */}
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="billChangeNote">নোট</label>
                <input
                  ref={this.billChangeNoteRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={billChangeNote}
                  type="text"
                  name="billChangeNote"
                />
              </div>
            </div>

            {mobile && <div className="col-md-6"></div>}
            {mobile && (
              <div className="col-md-6 text-right">
                <div className="form-group">
                  <input
                    // className="form-control"
                    type="checkbox"
                    name="sendSms"
                    checked={sendSms}
                    onClick={this.handleCheckBoxClick.bind(this)}
                    disabled={!mobile}
                  />
                  &nbsp; গ্রাহকের মোবাইলে মেসেজ যাবে
                </div>
              </div>
            )}
          </div>
          {/*END OF ROW */}
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    feederID: state.auth.user.profile.profileID,
    profile: state.profile,
    lineUser: state.lineUser,
    server: state.server,
  }
}

export default connect(mapStateToProps, { editLineUserBill })(BillUpdate)
