import React, { Component } from "react"
import { connect } from "react-redux"

class Help extends Component {
  render() {
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white border-rounded text-center">
              <h4>সাপোর্ট টিম</h4>
            </div>
            <div className="container">
              <div style={{ minHeight: 230 }} className="row p-5 bg-white align-items-start">
                <div className="col-md-6">
                  <div class="card text-white bg-success mb-3" style={{ maxWidth: "18rem" }}>
                    <div class="card-body">
                      <h5 class="card-title">তৌকির হোসেন অভি</h5>
                      <p class="card-text font-weight-bold">মোবাইল: 01321141785</p>
                      <p class="card-text small">সকাল ১০ টা - রাত ১০ টা (ছুটির দিন ব্যতীত)</p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div class="card text-white bg-info mb-3" style={{ maxWidth: "18rem" }}>
                    <div class="card-body">
                      <h5 class="card-title">রাসেল আলী</h5>
                      <p class="card-text font-weight-bold">মোবাইল: 01321141787</p>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div class="card text-white bg-info mb-3" style={{ maxWidth: "18rem" }}>
                    <div class="card-body">
                      <h5 class="card-title">শারমিন আক্তার</h5>
                      <p class="card-text font-weight-bold">মোবাইল: 01321141783</p>
                      <p class="card-text small">সকাল ১০ টা - বিকাল ৬ টা (ছুটির দিন ব্যতীত)</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="card text-white bg-success mb-3" style={{ maxWidth: "18rem" }}>
                    <div class="card-body">
                      <h5 class="card-title">পারভেজ জয়</h5>
                      <p class="card-text font-weight-bold">মোবাইল: 01321141788</p>
                      <p class="card-text small">সকাল ১০ টা - রাত ১০ টা (ছুটির দিন ব্যতীত)</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div class="card text-white bg-info mb-3" style={{ maxWidth: "18rem" }}>
                    <div class="card-body">
                      <h5 class="card-title">তাপসী চৌধুরী</h5>
                      <p class="card-text font-weight-bold">মোবাইল: 01321141784</p>
                      <p class="card-text small">সকাল ১০ টা - বিকাল ৬ টা (ছুটির দিন ব্যতীত)</p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div class="card text-white bg-info mb-3" style={{ maxWidth: "18rem" }}>
                    <div class="card-body">
                      <h5 class="card-title">আব্দুর রাজ্জাক</h5>
                      <p class="card-text font-weight-bold">মোবাইল: 01321141789</p>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                  <div class="card text-white bg-info mb-3" style={{ maxWidth: "18rem" }}>
                    <div class="card-body">
                      <h5 class="card-title">শাহরিয়ার আলম</h5>
                      <p class="card-text font-weight-bold">মোবাইল: 01321141790</p>
                    </div>
                  </div>
                </div> */}
                <div className="col-12 mt-5">
                  <h3 className="text-center font-weight-bold">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.shunno.project.bayannopay"
                      target="_blank"
                    >
                      বায়ান্ন পে মোবাইল এপ ডাউনলোড করতে এখানে ক্লিক করুন
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

export default connect()(Help)
