import axios from "axios"
import React, { Component, createRef } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { connect } from "react-redux"
import toastr from "toastr"
import { changeMobileNumber, resetPassword, sendSingleSMS } from "../../actions/profileActions"
import { SERVER_URL } from "../../config/config"
import store from "../../store"

const { SearchBar } = Search

class ActiveConnection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalTitle: "",
      resellerToModify: {},
      addReseller: false,
      resellersForSettings: [],
      updateData: [],
    }

    this.nameRef = createRef()
    this.mobileRef = createRef()
  }
  componentDidMount() {
    this.updateData()
  }
  onChangeHandler = (e) => {
    this.setState({
      resellerToModify: {
        ...this.state.resellerToModify,
        [e.target.name]: e.target.value,
      },
    })
  }

  onSubmitHandler = (e) => {
    e.preventDefault()
    let { name, mobile } = this.state.resellerToModify
    if (!name) {
      toastr.error("নাম ফিল্ড পুরন করুন")
      this.nameRef.current.focus()
      return
    } else if (!mobile) {
      toastr.error("মোবাইল ফিল্ড পুরন করুন")
      this.mobileRef.current.focus()
      return
    }
    let roles = []
    this.state.resellerToModify.roles.filter((role) => {
      if (role.isChecked) {
        roles.push(role.id)
      }
    })
    if (this.state.addReseller) {
      this.props.addReseller({ ...this.state.resellerToModify, roles })
    } else {
      this.props.updateReseller({ ...this.state.resellerToModify, roles })
    }
  }

  columnssss = [
    {
      dataField: "name",
      text: "নাম",
    },
    {
      dataField: "mobile",
      text: "মোবাইল",
    },
    {
      dataField: "_id",
      text: "Action",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => (
        <ul className="navbar-nav navbar-right">
          <li className="dropdown">
            <a href="#" data-toggle="dropdown" className="nav-link nav-link-lg">
              <i className="fas fa-ellipsis-h" style={{ fontSize: 24 }}></i>
            </a>
            <div style={{ width: 50 }} className="dropdown-menu p-1">
              <a
                type="button"
                onClick={() => this.viewHandler(row)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#profileModal"
              >
                <i className="fas fa-id-card" />
                প্রোফাইল দেখুন
              </a>
              <a
                type="button"
                onClick={() => this.viewHandler(row)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#sendSMSmodal"
              >
                <i className="fas fa-paper-plane" />
                সেন্ড এসএমএস
              </a>
              <a
                type="button"
                onClick={() => this.showEditResellerModal(row)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#Modal"
              >
                <i className="fas fa-edit" />
                এডিট করুন
              </a>
              <a type="button" onClick={() => this.resetPassword(row)} className="dropdown-item has-icon">
                <i className="fas fa-fingerprint" />
                রিসেট পাসওয়ার্ড
              </a>
              <a
                type="button"
                onClick={() => this.viewHandler(row)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#changeMobile"
              >
                <i className="fas fa-mobile" />
                রিসেলার পরিবর্তন
              </a>
              <a
                type="button"
                onClick={() => this.viewHandler(row)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#areaAssignModal"
              >
                <i className="fas fa-map-marker-alt" />
                এরিয়া পরিবর্তন
              </a>
            </div>
          </li>
        </ul>
      ),
    },
  ]
  updateData = async () => {
    store.dispatch({ type: "LOADING" })
    axios(SERVER_URL + "/api/mikrotik/ppp/interface")
      .then((res) => {
        store.dispatch({ type: "RESET" })
        const { status, data } = res.data
        if (status) {
          let updateData = []
          data.forEach((item) => {
            if (item.name.includes("<pppoe")) {
              let myString = item.name
              let myRegexp = /pppoe-(.*?)>/g
              let match = myRegexp.exec(myString)
              let extractName = match[1]
              item.extractName = extractName
              updateData.push(item)
            }
          })
          this.setState({ updateData })
          toastr.success("New data loaded!")
        }
      })
      .catch((err) => toastr.error(err))
  }

  render() {
    let columns = [
      {
        dataField: "extractName",
        text: "Name",
      },
      {
        dataField: "txByte",
        text: "Tx",
        formatter: (cell) => parseFloat(cell / 8 / 1024 / 1024).toFixed(2) + "MByte",
      },
      {
        dataField: "rxByte",
        text: "Rx",
        formatter: (cell) => parseFloat(cell / 8 / 1024 / 1024).toFixed(2) + "MByte",
      },
      {
        dataField: "status",
        isDummyField: true,
        text: "Status",
        headerAlign: "center",
        align: "center",
        formatter: (cell, row) =>
          row.running && row.dynamic ? (
            <i className="fas fa-check text-success"></i>
          ) : (
            <i className="fas fa-ban text-danger"></i>
          ),
      },
    ]
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>Mikrotik active connections</h4>
            </div>
            <div className="container">
              <div className="row justify-content-center p-5 bg-white align-items-center">
                <PaginationProvider
                  pagination={paginationFactory({
                    sizePerPageList: [
                      { text: "10", value: 10 },
                      { text: "20", value: 20 },
                      { text: "50", value: 50 },
                    ],
                  })}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider bootstrap4 keyField="_id" data={this.state.updateData} columns={columns} search>
                      {(props) => (
                        <div>
                          <div className="row justify-content-between">
                            <div className="col-md-4">
                              <button onClick={this.updateData} className="btn btn-success">
                                <i
                                  class={this.props.server.requestPending ? "fas fa-sync fa-spin" : "fas fa-sync"}
                                  title="মাইক্রোটিক কনফিগারেশন"
                                ></i>
                              </button>
                            </div>
                            <div className="col-md-6 text-right">
                              <SearchBar {...props.searchProps} />
                            </div>
                          </div>
                          <BootstrapTable striped hover {...props.baseProps} {...paginationTableProps} />
                        </div>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    server: state.server,
    reseller: state.reseller,
    profile: state.profile,
    auth: state.auth,
  }
}

export default connect(mapStateToProps, {
  sendSingleSMS,
  resetPassword,
  changeMobileNumber,
})(ActiveConnection)
