import moment from "moment";
import React, { Component, createRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import toastr from "toastr";
import {
  addOperator,
  allowedOperators,
  assignAreasToOperator,
  getAllOperators,
  updateOperator
} from "../../actions/operatorActions";
import {
  changeMobileNumber,
  resetPassword,
  sendSingleSMS
} from "../../actions/profileActions";
import {
  CheckBox,
  CheckBoxOperatorSettings
} from "../../components/common/CheckBox";
import PdfComponent from "../../components/common/PdfComponent";
import AreaAssignModal from "../../components/modals/AreaAssignModal";
import Modal, { ProfileModal } from "../../components/modals/Modal";
import NumberChangeModal from "../../components/modals/NumberChangeModal";
import SendSmsModal from "../../components/modals/SendSmsModal";

const { SearchBar } = Search;

const _ROLES = [
  {
    id: "VIEW_CUSTOMER_LIST",
    value: "গ্রাহকলিস্ট দেখতে পারবে",
    isChecked: false
  },
  {
    id: "CUSTOMER_ADD",
    value: "নতুন গ্রাহক সংযোজন",
    isChecked: false
  },
  {
    id: "CUSTOMER_EDIT",
    value: "গ্রাহক আপডেট",
    isChecked: false
  },
  {
    id: "BILL_EDIT",
    value: "গ্রাহকের মাসিক ফি/বিল আপডেট",
    isChecked: false
  },
  {
    id: "BILL_EDIT_INCREMENT",
    value: "গ্রাহকের মাসিক ফি/বিল আপডেট (শুধুমাত্র বৃদ্ধি করতে পারবে)",
    isChecked: false
  },
  {
    id: "CUSTOMER_ACTIVATE",
    value: "গ্রাহক এক্টিভ",
    isChecked: false
  },
  {
    id: "CUSTOMER_DEACTIVATE",
    value: "গ্রাহক বন্ধ",
    isChecked: false
  },
  {
    id: "PRINT",
    value: "গ্রাহক প্রিন্ট",
    isChecked: false
  },
  {
    id: "SEND_SMS",
    value: "সেন্ড এসএমএস",
    isChecked: false
  }
];

function resetRoles() {
  _ROLES.map(role => (role.isChecked = false));
}

class Operator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: "",
      operatorToModify: {},
      addOperator: false,
      operatorsForSettings: []
    };

    this.nameRef = createRef();
    this.mobileRef = createRef();
  }
  componentDidMount() {
    const { operator, getAllOperators } = this.props;
    if (!operator.isLoaded) {
      getAllOperators();
    }
  }
  onChangeHandler = e => {
    this.setState({
      operatorToModify: {
        ...this.state.operatorToModify,
        [e.target.name]: e.target.value
      }
    });
  };

  handleAllChecked = e => {
    let roles = this.state.operatorToModify.roles;
    roles.forEach(roles => (roles.isChecked = e.target.checked));
    this.setState({
      operatorToModify: {
        ...this.state.operatorToModify,
        roles
      }
    });
  };

  handleAllCheckedForSetting = e => {
    let operators = this.state.operatorsForSettings;
    operators.forEach(operator => (operator.isChecked = e.target.checked));
    this.setState({ operatorsForSettings: operators });
  };

  handleCheckChieldElement = e => {
    let roles = this.state.operatorToModify.roles;
    roles.forEach(role => {
      if (role.value === e.target.value) role.isChecked = e.target.checked;
    });
    this.setState({
      operatorToModify: {
        ...this.state.operatorToModify,
        roles
      }
    });
  };

  handleCheckChieldElementForSetting = e => {
    let operatorsForSettings = this.state.operatorsForSettings;
    operatorsForSettings.forEach(operator => {
      if (operator.value === e.target.value)
        operator.isChecked = e.target.checked;
    });
    this.setState({ operatorsForSettings });
  };

  showAddOperatorModal = () => {
    resetRoles();
    this.setState({
      modalTitle: "অপারেটর অ্যাড করুন",
      operatorToModify: {
        name: "",
        fatherName: "",
        mobile: "",
        email: "",
        fullAddress: "",
        nidNumber: "",
        referenceName: "",
        referenceMobile: "",
        roles: _ROLES
      },
      addOperator: true
    });
  };

  showEditOperatorModal = operator => {
    resetRoles();
    let roles = [..._ROLES];
    roles.forEach(role => {
      if (operator.roles.includes(role.id)) role.isChecked = true;
    });
    this.setState({
      operatorToModify: {
        _id: operator._id,
        name: operator.name,
        fatherName: operator.fatherName,
        mobile: operator.mobile,
        email: operator.email,
        fullAddress: operator.fullAddress,
        nidNumber: operator.nid?.number,
        referenceName: operator.reference?.name,
        referenceMobile: operator.reference?.mobile,
        roles
      },
      modalTitle: "অপারেটর এডিট করুন",
      addOperator: false
    });
  };

  viewHandler = operator => {
    this.setState({
      operatorToModify: {
        role: "operator",
        userID: operator.userID,
        _id: operator._id,
        assignedAreas: operator.assignedAreas,
        name: operator.name,
        mobile: operator.mobile,
        fullAddress: operator.fullAddress,
        createdAt: operator.createdAt,
        company: this.props.profile.profile.company,
        signature:
          this.props.auth.user.profile.signature ||
          "সিগনেচার পাওয়া যায়নি। প্রোফাইলে কোম্পানীর নাম ও মোবাইল নম্বর ঘরটি পূরণ করুন।"
      }
    });
  };

  operatorsForSettingsHandler = () => {
    let areas = this.props.profile.profile.areas;
    let allowedOperators = this.props.profile.profile.allowedOperators;
    let operators = this.props.operator.operators;
    let operatorsForSettings = [];

    operators.map(operator => {
      let areaNames = "";
      operator.assignedAreas.map(id => {
        let temp = areas.find(area => area._id == id);
        if (temp) areaNames += temp.name + ", ";
      });

      operatorsForSettings.push({
        id: operator.userID,
        value: operator.name,
        isChecked: false,
        areaNames
      });
    });

    allowedOperators.map(id => {
      operatorsForSettings.map(col => {
        if (col.id == id) col.isChecked = true;
      });
    });
    this.setState({ operatorsForSettings });
  };

  onSubmitHandler = e => {
    e.preventDefault();
    let { name, mobile } = this.state.operatorToModify;
    if (!name) {
      toastr.error("নাম ফিল্ড পুরন করুন");
      this.nameRef.current.focus();
      return;
    } else if (!mobile) {
      toastr.error("মোবাইল ফিল্ড পুরন করুন");
      this.mobileRef.current.focus();
      return;
    }
    let roles = [];
    this.state.operatorToModify.roles.filter(role => {
      if (role.isChecked) {
        roles.push(role.id);
      }
    });
    if (this.state.addOperator) {
      this.props.addOperator({ ...this.state.operatorToModify, roles });
    } else {
      this.props.updateOperator({ ...this.state.operatorToModify, roles });
    }
  };

  onSubmitOperatorSettings = e => {
    e.preventDefault();
    let allowedOperators = [];
    this.state.operatorsForSettings.filter(operator => {
      if (operator.isChecked) allowedOperators.push(operator.id);
    });
    this.props.allowedOperators(
      { allowedOperators },
      this.props.profile.profile
    );
  };

  resetPassword = row => {
    let sure = window.confirm("Are you sure to reset password?");
    if (sure) this.props.resetPassword({ mobile: row.mobile });
  };

  render() {
    let company =
      this.props.auth.user.type === "feeder"
        ? this.props.auth.user.profile.company
        : this.props.auth.user.feeder.company;

    let columns = [
      {
        dataField: "name",
        text: "নাম",
        sort: true,
        headerAlign: "center",
        align: "center"
        // headerStyle: { width: "250px" },
      },
      {
        // headerStyle: { width: "150px" },
        dataField: "mobile",
        text: "মোবাইল",
        sort: true,
        headerAlign: "center",
        align: "center"
      },
      {
        dataField: "status",
        text: "স্টাট্যাস",
        // headerStyle: { width: "80px" },
        headerAlign: "center",
        align: "center",
        sort: true,
        formatExtraData: this.props.profile.profile.allowedOperators,
        sortValue: (cell, row) =>
          this.props.profile.profile.allowedOperators?.includes(row.userID),
        formatter: (cell, row, rowIndex, allowedOperators) =>
          allowedOperators?.includes(row.userID) ? (
            <i className="fas fa-check text-success"></i>
          ) : (
            <i className="fas fa-ban text-danger"></i>
          )
      },
      {
        headerAlign: "center",
        align: "center",
        dataField: "createdAt",
        text: "তারিখ",
        // headerStyle: { width: "265px" },
        formatter: cell => moment(cell).format("DD/MM/yyyy hh:mm A"),
        sort: true
      },
      {
        dataField: "_id",
        text: "Action",
        align: "center",
        headerAlign: "center",
        formatter: (cell, row) => (
          <ul className="navbar-nav navbar-right">
            <li className="dropdown">
              <a
                href="#"
                data-toggle="dropdown"
                className="nav-link nav-link-lg"
              >
                <i className="fas fa-ellipsis-h" style={{ fontSize: 24 }}></i>
              </a>
              <div style={{ width: 50 }} className="dropdown-menu p-1">
                <a
                  type="button"
                  onClick={() => this.viewHandler(row)}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#profileModal"
                >
                  <i className="fas fa-id-card" />
                  প্রোফাইল দেখুন
                </a>
                <a
                  type="button"
                  onClick={() => this.showEditOperatorModal(row)}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#Modal"
                >
                  <i className="fas fa-edit" />
                  এডিট করুন
                </a>
                <a
                  type="button"
                  onClick={() => this.viewHandler(row)}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#sendSMSmodal"
                >
                  <i className="fas fa-paper-plane" />
                  সেন্ড এসএমএস
                </a>
                <a
                  type="button"
                  onClick={() => this.resetPassword(row)}
                  className="dropdown-item has-icon"
                >
                  <i className="fas fa-fingerprint" />
                  রিসেট পাসওয়ার্ড
                </a>
                <a
                  type="button"
                  onClick={() => this.viewHandler(row)}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#changeMobile"
                >
                  <i className="fas fa-mobile" />
                  অপারেটর পরিবর্তন
                </a>
                <a
                  type="button"
                  onClick={() => this.viewHandler(row)}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#areaAssignModal"
                >
                  <i className="fas fa-map-marker-alt" />
                  এরিয়া পরিবর্তন
                </a>
              </div>
            </li>
          </ul>
        )
      }
    ];
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>অপারেটর</h4>
            </div>
            <div className="container">
              <div
                style={{ minHeight: 230 }}
                className="row justify-content-center p-3 bg-white align-items-center"
              >
                {this.props.operator.isLoaded && this.props.profile.isLoaded ? (
                  <div className="col-12">
                    <div className="clearfix py-3">
                      <h4 className="float-left">সকল অপারেটর</h4>
                      <button
                        onClick={this.operatorsForSettingsHandler}
                        className="btn btn-success float-right"
                        data-toggle="modal"
                        data-target="#operatorSettingsModal"
                      >
                        <i class="fas fa-cog" title="অপারেটর সেটিংস"></i>
                      </button>

                      <button
                        onClick={this.showAddOperatorModal}
                        className="btn btn-success float-right mx-2"
                        data-toggle="modal"
                        data-target="#Modal"
                      >
                        <i class="fas fa-user-plus" title="নতুন অপারেটর"></i>
                      </button>
                    </div>
                    <hr />

                    <PaginationProvider
                      pagination={paginationFactory({
                        sizePerPageList: [
                          { text: "10", value: 10 },
                          { text: "20", value: 20 },
                          { text: "50", value: 50 },
                          { text: "100", value: 100 },
                          { text: "200", value: 200 },
                          { text: "500", value: 500 },
                          { text: "1000", value: 1000 },
                          { text: "2000", value: 2000 },
                          {
                            text: "All",
                            value: this.props.operator.operators.length
                          }
                        ]
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          bootstrap4
                          keyField="_id"
                          data={this.props.operator.operators}
                          columns={columns}
                          search
                        >
                          {props => (
                            <div>
                              <div className="row justify-content-between">
                                <div className="col-md-4">
                                  <strong>
                                    মোটঃ {this.props.operator.operators.length}
                                  </strong>
                                </div>
                                <div className="col-md-6 text-right">
                                  <SearchBar {...props.searchProps} />
                                  <CSVLink
                                    filename={`সকল অপারেটর - ${company}.csv`}
                                    data={[
                                      ["নাম", "মোবাইল"],
                                      ...this.props.operator.operators.map(
                                        item => [item.name, item.mobile]
                                      )
                                    ]}
                                  >
                                    <i
                                      style={{ fontSize: "25px" }}
                                      className="fas fa-file-csv ml-2"
                                    ></i>
                                  </CSVLink>
                                  <ReactToPrint
                                    trigger={() => (
                                      <button
                                        style={{
                                          border: "none",
                                          background: "none"
                                        }}
                                        type="button"
                                      >
                                        <i
                                          style={{ fontSize: "25px" }}
                                          className="fas fa-file-pdf"
                                        ></i>
                                      </button>
                                    )}
                                    content={() => this.componentRef}
                                  />
                                  <div style={{ display: "none" }}>
                                    <PdfComponent
                                      ref={el => (this.componentRef = el)}
                                    >
                                      <table
                                        style={{ color: "#000" }}
                                        className="table table-bordered mt-5 table-md"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              colSpan="6"
                                              className="text-center"
                                            >
                                              <span className="text-center">
                                                <strong>
                                                  {
                                                    this.props.auth.user.profile
                                                      ?.company
                                                  }
                                                </strong>
                                                <br />
                                                {
                                                  this.props.auth.user.profile
                                                    ?.fullAddress
                                                }
                                                <br />
                                                <strong>
                                                  <ul>সকল অপারেটর</ul>
                                                </strong>
                                              </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>নাম</th>
                                            <th>মোবাইল</th>
                                          </tr>

                                          {this.props.profile.profile.areas.map(
                                            (item, idx) => {
                                              return (
                                                <tr key={idx}>
                                                  <td>{item.name}</td>
                                                  <td>{item.mobile}</td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </PdfComponent>
                                  </div>
                                </div>
                              </div>

                              <BootstrapTable
                                striped
                                hover
                                {...props.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </div>
                ) : (
                  <div class="fa-3x">
                    <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>

        {/* add/edit modal */}
        <Modal
          modalTitle={this.state.modalTitle}
          onSubmit={this.onSubmitHandler}
          pending={this.props.server.requestPending}
          id="Modal"
        >
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <React.Fragment>
                  <input
                    type="checkbox"
                    onClick={this.handleAllChecked}
                    value="checkedall"
                  />{" "}
                  সবগুলো
                  <ul>
                    {this.state.operatorToModify.roles &&
                      this.state.operatorToModify.roles.map(role => {
                        return (
                          <CheckBox
                            handleCheckChieldElement={
                              this.handleCheckChieldElement
                            }
                            {...role}
                          />
                        );
                      })}
                  </ul>
                </React.Fragment>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="name">নাম</label>
                <input
                  ref={this.nameRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.operatorToModify.name}
                  type="text"
                  name="name"
                  disabled={this.props.server.requestPending}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="fatherName">পিতার নাম</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.operatorToModify.fatherName}
                  type="text"
                  name="fatherName"
                  disabled={this.props.server.requestPending}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="mobile">মোবাইল</label>
                <input
                  ref={this.mobileRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.operatorToModify.mobile}
                  type="text"
                  name="mobile"
                  disabled={this.props.server.requestPending}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="email">ইমেইল</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.operatorToModify.email}
                  type="email"
                  name="email"
                  disabled={this.props.server.requestPending}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="fullAddress">ঠিকানা</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.operatorToModify.fullAddress}
                  type="text"
                  name="fullAddress"
                  disabled={this.props.server.requestPending}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="referenceName">রেফারেন্স নাম</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.operatorToModify.referenceName}
                  type="text"
                  name="referenceName"
                  disabled={this.props.server.requestPending}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="referenceMobile">রেফারেন্স মোবাইল</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.operatorToModify.referenceMobile}
                  type="text"
                  name="referenceMobile"
                  disabled={this.props.server.requestPending}
                />
              </div>
            </div>
          </div>
        </Modal>

        {/* Profile Modal */}
        <ProfileModal id="profileModal">
          <div className="text-center mb-2">
            <i className="fas fa-user-circle fa-5x" title="avatar" />
          </div>
          <p className="text-center font-weight-bold  mb-0">
            {this.state.operatorToModify.name}
          </p>
          <p className="text-center mb-0">
            {this.state.operatorToModify.company}
          </p>
          <p className="text-center mb-0">
            {this.state.operatorToModify.fullAddress}
          </p>
          <p className="text-center  mb-2">
            {this.state.operatorToModify.mobile}
          </p>
          <p className="text-center font-weight-bold  mb-0">
            সংযোগ তারিখঃ{" "}
            {moment(this.state.operatorToModify.createdAt).format("DD/MM/yyyy")}
          </p>
        </ProfileModal>

        {/* Operator Settings Modal*/}
        <Modal
          modalTitle="অপারেটর সেটিংস"
          onSubmit={this.onSubmitOperatorSettings}
          pending={this.props.server.requestPending}
          id="operatorSettingsModal"
        >
          <React.Fragment>
            <input
              type="checkbox"
              onClick={this.handleAllCheckedForSetting}
              value="checkedall"
            />{" "}
            সকল অপারেটর
            <ul>
              {this.state.operatorsForSettings.map(operator => {
                return (
                  <CheckBoxOperatorSettings
                    handleCheckChieldElement={
                      this.handleCheckChieldElementForSetting
                    }
                    {...operator}
                  />
                );
              })}
            </ul>
          </React.Fragment>
        </Modal>

        {/* Send sms Modal*/}
        <SendSmsModal
          data={this.state.operatorToModify}
          pending={this.props.server.requestPending}
          action={this.props.sendSingleSMS}
        />

        {/* Number change Modal */}
        <NumberChangeModal
          data={this.state.operatorToModify}
          pending={this.props.server.requestPending}
          action={this.props.changeMobileNumber}
        />

        {/* Area assign  Modal*/}
        <AreaAssignModal
          data={this.state.operatorToModify}
          areas={this.props.profile.profile.areas}
          pending={this.props.server.requestPending}
          action={this.props.assignAreasToOperator}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    server: state.server,
    operator: state.operator,
    profile: state.profile,
    auth: state.auth
  };
};

export default connect(mapStateToProps, {
  assignAreasToOperator,
  getAllOperators,
  addOperator,
  updateOperator,
  allowedOperators,
  sendSingleSMS,
  resetPassword,
  changeMobileNumber
})(Operator);
