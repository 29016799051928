import React, { Component } from "react"
import moment from "moment"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { bdt, rowStyleFormat } from "../../../components/common/Utils"
const { SearchBar } = Search
const d = new Date()

class Manager extends Component {
  columns = [
    {
      dataField: "name",
      text: "নাম",
      style: { wordWrap: "break-word", paddingLeft: "10px", paddingRight: "10px" },
      sort: false,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "totalCollection",
      text: "কালেকশন",
      style: { wordWrap: "break-word", paddingLeft: "10px", paddingRight: "10px" },
      sort: false,
      formatter: (col, row) => `${bdt(row.totalCollection)} (${row.collectionCount})`,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "totalDeposit",
      text: "ডিপোজিট",
      style: { wordWrap: "break-word", paddingLeft: "10px", paddingRight: "10px" },
      sort: false,
      formatter: (col) => bdt(col),
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "balance",
      text: "ব্যালেন্স",
      style: { wordWrap: "break-word", paddingLeft: "10px", paddingRight: "10px" },
      sort: false,
      formatter: (col) => bdt(col),
      headerAlign: "center",
      align: "center",
    },
  ]

  render() {
    const { totalCollection, collectors } = this.props

    let duration = 1.25

    return (
      <>
        {/* Collector */}
        {collectors?.length ? (
          <div className="col-12">
            <div className="clearfix py-3">
              <h4 className="text-center">কালেক্টর</h4>
            </div>

            <PaginationProvider
              pagination={paginationFactory({
                sizePerPageList: [
                  { text: "10", value: 10 },
                  { text: "20", value: 20 },
                  { text: "50", value: 50 },
                  { text: "100", value: 100 },
                  {
                    text: "All",
                    value: collectors.length,
                  },
                ],
              })}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider bootstrap4 keyField="_id" data={collectors} columns={this.columns} search>
                  {(props) => (
                    <div>
                      <div className="row justify-content-between">
                        <div className="col-md-4">
                          <strong>মোট কালেকশনঃ {bdt(totalCollection)}</strong>
                        </div>
                        <div className="col-md-6 text-right">
                          <SearchBar {...props.searchProps} />
                        </div>
                      </div>

                      <BootstrapTable
                        rowStyle={rowStyleFormat}
                        striped
                        hover
                        {...props.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </div>
        ) : (
          <p className="text-center font-italic">*** কালেক্টর পাওয়া যায়নি ***</p>
        )}
      </>
    )
  }
}

export default Manager
