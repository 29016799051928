import {
  GET_BILL_REPORT,
  GET_MANAGER_DEPOSIT,
  GET_DUE_REPORT,
  GET_INVOICES,
  GET_WEBHOOK_MESSAGE,
  GET_MESSAGE_LOG
} from "../actions/types";

const intialState = {
  isLoaded: false,
  isLoadedOldBill: false,
  isDueReportLoaded: false,
  isInvoicesLoaded: false,
  isWebhookMessagesLoaded: false,
  isMessageLogLoaded: false,
  bills: [],
  oldBills: [],
  managerDeposits: [],
  dueLineUsers: [],
  invoices: [],
  webhookMessages: [],
  messages: []
};

export default (state = intialState, action) => {
  switch (action.type) {
    case GET_BILL_REPORT:
      if (action.flag === "previous") {
        return {
          ...state,
          isLoadedOldBill: true,
          oldBills: action.payload
        };
      } else {
        return {
          ...state,
          isLoaded: true,
          bills: action.payload
        };
      }

    case GET_MANAGER_DEPOSIT:
      return {
        ...state,
        managerDeposits: action.payload
      };

    case GET_DUE_REPORT:
      return {
        ...state,
        isDueReportLoaded: true,
        dueLineUsers: action.payload
      };
    case GET_INVOICES:
      return {
        ...state,
        isInvoicesLoaded: true,
        invoices: action.payload
      };
    case GET_WEBHOOK_MESSAGE:
      return {
        ...state,
        isWebhookMessagesLoaded: true,
        webhookMessages: action.payload
      };
    case GET_MESSAGE_LOG:
      return {
        ...state,
        isMessageLogLoaded: true,
        messages: action.payload
      };

    default:
      return state;
  }
};
