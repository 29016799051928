import React, { Component, createRef } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import toastr from "toastr"
import { payBill } from "../../actions/lineUserActions"
import { advanceMonth, monthsArr, toBangla } from "../../components/common/Utils"
import Modal from "../../components/modals/Modal"

class BillCollect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lineUser: props.lineUser || {},
      amount: props.billType === "advanced" ? props.lineUser.monthlyBill : props.lineUser.nextBill,
      year: props.year,
      month: props.month,
      yearDropdown: { label: toBangla(props.year), value: props.year },
      monthDropdown: { label: monthsArr[props.month], value: props.month },
      billType: props.billType,
      advanceMonth: { label: "১ মাস", value: 1 },
      selectedMonths: props.selectedMonths,
    }
    this.amountRef = createRef()
    this.yearRef = createRef()
    this.monthRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { requestPending, errorMsg } = nextProps.server
    if (requestPending || errorMsg) return

    this.setState({
      lineUser: nextProps.lineUser,
      amount: nextProps.billType === "advanced" ? nextProps.lineUser.monthlyBill : nextProps.lineUser.nextBill,
      modalTitle: nextProps.modalTitle,
      year: nextProps.year,
      month: nextProps.month,
      yearDropdown: { label: toBangla(nextProps.year), value: nextProps.year },
      monthDropdown: { label: monthsArr[nextProps.month], value: nextProps.month },
      billType: nextProps.billType,
      advanceMonth: { label: "১ মাস", value: 1 },
      selectedMonths: nextProps.selectedMonths,
    })
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onClickMonthChange = (selected) => {
    let amount = this.state.lineUser.monthlyBill * selected.value

    let months = "",
      month,
      year = this.state.year
    for (let i = this.state.month + 1; i <= parseInt(selected.value) + this.state.month; i++) {
      if (i > 11) {
        let temp = i - 12
        month = temp
        year = this.state.year + 1
        months += monthsArr[temp] + "-" + year + ", "
      } else {
        months += monthsArr[i] + ", "
      }
    }

    this.setState({
      amount: amount.toString(),
      selectedMonths: months,
      numberOfMonth: parseInt(selected.value),
      billType: "advanced",
      advanceMonth: selected,
    })
  }

  onSubmitHandler = (e) => {
    e.preventDefault()

    const { lineUser } = this.state

    let con = window.confirm(`${lineUser.name} এর নিকট হতে ${this.state.amount} টাকা সংগ্রহ করতে চান?`)
    if (!con) {
      return
    }

    if (this.state.amount === "") {
      toastr.error("বিল পুরন করুন")
      this.amountRef.current.focus()
      return
    }

    if (this.state.amount < 50 || this.state.amount > 500000) {
      toastr.error("50 টাকার কম বিল সংগ্রহ করা সম্ভব নয়")
      this.amountRef.current.focus()
      return
    }

    const re = /^[0-9\b]+$/
    if (!re.test(this.state.amount)) {
      toastr.error("সঠিক নয়")
      this.amountRef.current.focus()
      return
    }

    // Validation for Postpaid feeder
    let dt = new Date()
    let currentYear = dt.getFullYear()
    let currentMonth = dt.getMonth()
    let newPayment = {}

    if (this.state.billType != "advanced") {
      if (
        this.props.profile.profile.billCollectionType === 2 ||
        (this.props.profile.profile.billCollectionType === 3 && lineUser.billPayType === 2)
      ) {
        if (this.state.month < 0 || this.state.month > 11) {
          toastr.error("মাস সঠিক নয়")
          this.monthRef.current.focus()
          return
        }

        if (this.state.year > currentYear) {
          toastr.error("বছর সঠিক নয়")
          this.yearRef.current.focus()
          return
        } else if (this.state.year === currentYear) {
          if (this.state.month >= currentMonth) {
            toastr.error("মাস সঠিক নয়")
            this.monthRef.current.focus()
            return
          }
        } else if (this.state.year === currentYear - 1) {
        } else {
          toastr.error("বছর সঠিক নয়")
          this.yearRef.current.focus()
          return
        }
      }

      // validation for prepaid feeder
      if (
        this.props.profile.profile.billCollectionType === 1 ||
        (this.props.profile.profile.billCollectionType === 3 && lineUser.billPayType === 1)
      ) {
        if (this.state.month < 0 || this.state.month > 11) {
          toastr.error("মাস সঠিক নয়")
          this.monthRef.current.focus()
          return
        }

        if (this.state.year > currentYear) {
          toastr.error("বছর সঠিক নয়")
          this.yearRef.current.focus()
          return
        } else if (this.state.year === currentYear) {
          if (this.state.month > currentMonth) {
            toastr.error("মাস সঠিক নয়")
            this.monthRef.current.focus()
            return
          }
        } else if (this.state.year === currentYear - 1) {
        } else {
          toastr.error("বছর সঠিক নয়")
          this.yearRef.current.focus()
          return
        }
      }

      newPayment = {
        year: this.state.year,
        month: this.state.month,
        day: this.state.day,
        amount: this.state.amount,
        lineUserID: lineUser.id,
      }
    }
    // for advanced bill
    else {
      let year, month
      if (this.state.advanceMonth.value + this.state.month > 11) {
        year = this.state.year + 1
        month = this.state.advanceMonth.value + this.state.month - 12
      } else {
        month = this.state.advanceMonth.value + this.state.month
        year = this.state.year
      }

      newPayment = {
        year: year,
        month: month,
        day: this.state.day,
        amount: this.state.amount,
        billType: "advanced",
        lineUserID: lineUser.id,
      }

      let con = window.confirm(
        `আপনি ${this.state.advanceMonth.value} মাসের অগ্রীম বিল ${this.state.amount} টাকা নিতে চাচ্ছেন?`
      )
      if (!con) return
    }

    let result = this.props.payBill(newPayment, this.props.auth.user.type, lineUser.currentMonthBS)
    if (result) {
      this.setState({ modalOpen: false })
    }
  }

  render() {
    const { lineUser, newNextBill, sendSms, billChangeNote, mobile } = this.state
    const connectionType = ["", "ডিশ", "ইন্টারনেট"]
    const billPayType = ["", "প্রিপেইড", "পোস্টপেইড"]

    return (
      <>
        <Modal
          modalTitle="বিল সংগ্রহ"
          onSubmit={this.onSubmitHandler}
          pending={this.props.server.requestPending}
          id="billCollect"
          modalWidth="w-50"
        >
          <div className="row">
            <div className="col-md-6 text-center">
              <div className="form-group">
                <span>{lineUser.customerID}</span>
                <h5>{lineUser.name}</h5>
                {this.props.profile.profile && this.props.profile.profile.feederType === 3 && (
                  <>সংযোগ টাইপঃ {connectionType[lineUser.connectionType]}</>
                )}
                <br />
                {this.props.profile.profile && this.props.profile.profile.billCollectionType === 3 && (
                  <> বিল টাইপঃ {billPayType[lineUser.billPayType]}</>
                )}
                <br />
                মাসিক ফিঃ {lineUser.monthlyBill} টাকা <br />
                বিলঃ {lineUser.nextBill} টাকা
              </div>
            </div>
            <div className="col-md-6">
              {this.state.billType !== "advanced" && (
                <div className="form-group">
                  <Select
                    ref={this.yearRef}
                    value={this.state.yearDropdown}
                    isDisabled
                    onChange={(yearDropdown) => this.setState({ yearDropdown })}
                  />
                </div>
              )}
              {this.state.billType !== "advanced" && (
                <div className="form-group">
                  <Select
                    ref={this.monthRef}
                    value={this.state.monthDropdown}
                    isDisabled
                    onChange={(monthDropdown) => this.setState({ monthDropdown })}
                  />
                </div>
              )}
              {this.state.billType === "advanced" && (
                <div className="form-group">
                  আপনি যে কয় মাসের অগ্রীম বিল নিতে চান সিলেক্ট করুন
                  <Select
                    // ref={this.monthRef}
                    value={this.state.advanceMonth}
                    options={advanceMonth}
                    onChange={(advanceMonth) => this.onClickMonthChange(advanceMonth)}
                  />
                </div>
              )}

              <div className="form-group">
                <input
                  className="form-control"
                  ref={this.amountRef}
                  onChange={this.onChangeHandler}
                  value={this.state.amount || lineUser.amount}
                  type="number"
                  name="amount"
                  disabled={this.props.server.requestPending}
                />
              </div>
              {this.state.billType === "advanced" && <div>মাসঃ {this.state.selectedMonths}</div>}
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    feederID: state.auth.user.profile.profileID,
    profile: state.profile,
    server: state.server,
    auth: state.auth,
  }
}

export default connect(mapStateToProps, { payBill })(BillCollect)
