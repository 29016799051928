import { RESET, LOADING, ERROR_MSG } from "../actions/types";

const initialState = {
  requestPending: false,
  errorMsg: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...initialState,
        requestPending: true,
      };
    case ERROR_MSG:
      return {
        requestPending: false,
        errorMsg: true,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};
