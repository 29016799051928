import React, { Component } from "react"
import toastr from "toastr"
import Modal from "./Modal"

function isDoubleByte(str) {
  for (var i = 0, n = str.length; i < n; i++) if (str.charCodeAt(i) > 255) return true
  return false
}

class SendSmsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: "",
      msgCount: 0,
      letterCount: 0,
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    if (this.state.message == "") {
      toastr.error("মেসেজ লিখুন")
      return
    }
    let data = {
      message: this.state.message,
      sendTo: this.props.data.mobile,
    }
    this.props.action(data)
    this.setState({
      message: "",
      msgCount: 0,
      letterCount: 0,
    })
  }

  onChangeSendSMS = (e) => {
    let msg = e.target.value
    let isUnicode = isDoubleByte(msg)
    let singleSms = isUnicode ? 67 : 160
    let letterCount = [...msg].length
    let msgCount = Math.ceil(letterCount / singleSms)

    this.setState({
      [e.target.name]: e.target.value,
      msgCount,
      letterCount,
    })
  }

  render() {
    return (
      <Modal
        modalTitle={this.props.data.company + " - সেন্ড এসএমএস"}
        onSubmit={this.onSubmit}
        pending={this.props.pending}
        id="sendSMSmodal"
      >
        <p>
          সিগনেচারঃ <strong>{this.props.data.signature}</strong>
        </p>
        <p>
          নামঃ <strong>{this.props.data.name}</strong>
        </p>
        <p>
          মোবাইলঃ <strong>{this.props.data.mobile === "01700000000" ? "নাই" : this.props.data.mobile}</strong>
        </p>
        <div className="form-group">
          <p className="text-right">
            SMS: {this.state.msgCount} , অক্ষরঃ {this.state.letterCount}
          </p>
          <textarea
            placeholder={`এখানে আপনার মেসেজ লিখুন। মেসেজ টাইপ করার সময় এই বক্সের উপড়ে ডান দিকে মেসেজ সাইজ ও অক্ষর সংখ্যা দেখতে পাবেন। - ${this.props.data.company}`}
            className="form-control"
            onChange={this.onChangeSendSMS}
            value={this.state.message}
            name="message"
            disabled={this.props.pending || this.props.data.mobile === "01700000000"}
          ></textarea>
        </div>
      </Modal>
    )
  }
}

export default SendSmsModal
