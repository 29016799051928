import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import { register } from "../../actions/authActions";
import { Districts } from "../../common/bd-districts";
import SelectListGroup from "../../common/selectListGroup";

export class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      company: "",
      district: "",
      mobile: "",
      nidNumber: "",
      email: "",
      bpPackage: "",
      referenceName: "",
      referenceMobile: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmit(e) {
    e.preventDefault();

    if (!this.state.district) {
      alert("জেলা সিলেক্ট করুন");
      return;
    }

    const rule = /(^(01){1}[3456789]{1}(\d){8})$/;
    if (!this.state.mobile.match(rule)) {
      alert("মোবাইল নম্বর সঠিক নয়");
      return;
    }
    if (!this.state.referenceMobile.match(rule)) {
      alert("রেফারেন্স নাম্বার নম্বর সঠিক নয়");
      return;
    }

    const userData = {
      mobile: this.state.mobile,
      name: this.state.name,
      company: this.state.company,
      district: this.state.district,
      nidNumber: this.state.nidNumber,
      email: this.state.email,
      bpPackage: this.state.bpPackage,
      referenceName: this.state.referenceName,
      referenceMobile: this.state.referenceMobile,
      serverType: "Regular"
    };

    this.props.register(userData);
  }

  componentDidMount() {
    $(".needs-validation").submit(function(event) {
      let form = $(this);
      if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.addClass("was-validated");
    });
  }

  render() {
    let districtOptions = [],
      districts = [...Districts];
    districts.shift();
    districts.shift();
    districts.unshift({
      id: "",
      division_id: "",
      name: "জেলা সিলেক্ট করুন",
      bn_name: "সিলেক্ট জেলা"
    });
    districts.map(d => {
      districtOptions.push({ label: d.name, value: d.id });
    });
    return (
      <div id="app">
        <section className="section">
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className="col-12">
                <div className="login-brand">
                  <img
                    src="../logo.png"
                    alt="logo"
                    width="100"
                    className="shadow-light rounded-circle"
                  />
                </div>

                <div className="card card-primary col-12 col-lg-12 col-md-6">
                  <div className="card-header">
                    <h4 style={{ margin: "0 auto" }}>বায়ান্ন পে রেজিস্টার</h4>
                  </div>

                  <div className="card-body">
                    <form
                      noValidate
                      className="needs-validation"
                      onSubmit={this.onSubmit}
                    >
                      <div className="row">
                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <label htmlFor="name">নাম*</label>
                          <input
                            id="mobile"
                            type="text"
                            className="form-control"
                            name="name"
                            tabIndex="1"
                            required
                            autoFocus
                            value={this.state.name}
                            onChange={this.onChange}
                          />
                          <div className="invalid-feedback">নাম পূরণ করুন</div>
                        </div>

                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <label htmlFor="company">নেটওয়ার্কের নাম*</label>
                          <input
                            id="mobile"
                            type="text"
                            className="form-control"
                            name="company"
                            tabIndex="1"
                            required
                            autoFocus
                            value={this.state.company}
                            onChange={this.onChange}
                          />
                          <div className="invalid-feedback">
                            নেটওয়ার্কের নাম পূরণ করুন
                          </div>
                        </div>

                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <label htmlFor="mobile">মোবাইল*</label>
                          <input
                            id="mobile"
                            type="text"
                            className="form-control"
                            name="mobile"
                            tabIndex="1"
                            required
                            autoFocus
                            value={this.state.mobile}
                            onChange={this.onChange}
                          />
                          <div className="invalid-feedback">
                            মোবাইল নম্বর পূরণ করুন
                          </div>
                        </div>

                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <label htmlFor="district">জেলা</label>
                          <SelectListGroup
                            placeholder="district"
                            name="district"
                            value={this.state.district}
                            onChange={this.onChange}
                            options={districtOptions}
                            required="required"
                            info=""
                          />
                          <div className="invalid-feedback">
                            জেলা সিলেক্ট করুন
                          </div>
                        </div>

                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <label htmlFor="email">প্যাকেজ</label>
                          <select
                            onChange={this.onChange}
                            name="bpPackage"
                            className="custom-select"
                            aria-label="Default select example"
                            required
                          >
                            <option selected value="">
                              প্যাকেজ
                            </option>
                            <option value="Basic">Basic</option>
                            <option value="Bronze">Bronze</option>
                            <option value="Silver">Silver</option>
                            <option value="Gold">Gold</option>
                            <option value="Platinum">Platinum</option>
                            <option value="Diamond">Diamond</option>
                            <option value="P1">P1</option>
                            <option value="P2">P2</option>
                            <option value="P3">P3</option>
                          </select>
                          <div className="invalid-feedback">
                            প্যাকেজ সিলেক্ট করুন
                          </div>
                        </div>

                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <label htmlFor="email">জাতীয় পরিচয়পত্র</label>
                          <input
                            id="mobile"
                            type="text"
                            className="form-control"
                            name="nidNumber"
                            tabIndex="1"
                            required
                            autoFocus
                            value={this.state.nidNumber}
                            onChange={this.onChange}
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <label htmlFor="email">ইমেইল</label>
                          <input
                            id="mobile"
                            type="text"
                            className="form-control"
                            name="email"
                            tabIndex="1"
                            autoFocus
                            value={this.state.email}
                            onChange={this.onChange}
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <label htmlFor="email">রেফারেন্স নাম</label>
                          <input
                            id="mobile"
                            type="text"
                            className="form-control"
                            name="referenceName"
                            tabIndex="1"
                            required
                            autoFocus
                            value={this.state.referenceName}
                            onChange={this.onChange}
                          />
                          <div className="invalid-feedback">
                            রেফারেন্স নাম পূরণ করুন
                          </div>
                        </div>

                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <label htmlFor="email">রেফারেন্স নাম্বার</label>
                          <input
                            id="mobile"
                            type="text"
                            className="form-control"
                            name="referenceMobile"
                            tabIndex="1"
                            required
                            autoFocus
                            value={this.state.referenceMobile}
                            onChange={this.onChange}
                          />
                          <div className="invalid-feedback">
                            রেফারেন্স মোবাইল নম্বর পূরণ করুন
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-success btn-lg btn-block"
                          tabIndex="4"
                        >
                          রেজিস্টার
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="mt-5 text-center">
                  <h3>
                    <a
                      className="font-weight-bold"
                      href="https://play.google.com/store/apps/details?id=com.shunno.project.bayannopay"
                      target="_blank"
                    >
                      বায়ান্ন পে মোবাইল এপ ডাউনলোড করতে এখানে ক্লিক করুন
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </section>
        <div style={{ textAlign: "center" }}>
          <a href="https://bayannopay.com">Home</a> &nbsp;|&nbsp;
          <a href="https://bayannopay.com/about">About</a> &nbsp;|&nbsp;
          <a href="https://bayannopay.com/privacyPolicy">Privacy Policy</a>
          &nbsp;|&nbsp;
          <a href="https://bayannopay.com/termAndCondition">
            Terms & Conditions
          </a>
          &nbsp;|&nbsp;
          <a href="https://bayannopay.com/return">Return & Refund Policy</a>
          <img
            src="../sslcommerz.png"
            alt="ssl commerz"
            height="200px"
            width="90%"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { register })(Register);
