import React from "react";

export default class PdfToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return this.props.children;
  }
}
