export const Districts = [
  {
    id: "-1",
    division_id: "-1",
    name: "All",
    bn_name: "সকল জেলা",
  },
  {
    id: "0",
    division_id: "0",
    name: "Demo_ID",
    bn_name: "ডেমো আইডি",
  },
  {
    id: "1",
    division_id: "3",
    name: "Dhaka",
    bn_name: "ঢাকা",
  },
  {
    id: "2",
    division_id: "3",
    name: "Faridpur",
    bn_name: "ফরিদপুর",
  },
  {
    id: "3",
    division_id: "3",
    name: "Gazipur",
    bn_name: "গাজীপুর",
  },

  {
    id: "4",
    division_id: "3",
    name: "Gopalganj",
    bn_name: "গোপালগঞ্জ",
  },
  {
    id: "5",
    division_id: "8",
    name: "Jamalpur",
    bn_name: "জামালপুর",
  },
  {
    id: "6",
    division_id: "3",
    name: "Kishoreganj",
    bn_name: "কিশোরগঞ্জ",
  },
  {
    id: "7",
    division_id: "3",
    name: "Madaripur",
    bn_name: "মাদারীপুর",
  },
  {
    id: "8",
    division_id: "3",
    name: "Manikganj",
    bn_name: "মানিকগঞ্জ",
  },
  {
    id: "9",
    division_id: "3",
    name: "Munshiganj",
    bn_name: "মুন্সিগঞ্জ",
  },
  {
    id: "10",
    division_id: "8",
    name: "Mymensingh",
    bn_name: "ময়মনসিংহ",
  },
  {
    id: "11",
    division_id: "3",
    name: "Narayanganj",
    bn_name: "নারায়াণগঞ্জ",
  },
  {
    id: "12",
    division_id: "3",
    name: "Narsingdi",
    bn_name: "নরসিংদী",
  },
  {
    id: "13",
    division_id: "8",
    name: "Netrokona",
    bn_name: "নেত্রকোণা",
  },
  {
    id: "14",
    division_id: "3",
    name: "Rajbari",
    bn_name: "রাজবাড়ি",
  },
  {
    id: "15",
    division_id: "3",
    name: "Shariatpur",
    bn_name: "শরীয়তপুর",
  },
  {
    id: "16",
    division_id: "8",
    name: "Sherpur",
    bn_name: "শেরপুর",
  },
  {
    id: "17",
    division_id: "3",
    name: "Tangail",
    bn_name: "টাঙ্গাইল",
  },
  {
    id: "18",
    division_id: "5",
    name: "Bogura",
    bn_name: "বগুড়া",
  },
  {
    id: "19",
    division_id: "5",
    name: "Joypurhat",
    bn_name: "জয়পুরহাট",
  },
  {
    id: "20",
    division_id: "5",
    name: "Naogaon",
    bn_name: "নওগাঁ",
  },
  {
    id: "21",
    division_id: "5",
    name: "Natore",
    bn_name: "নাটোর",
  },
  {
    id: "22",
    division_id: "5",
    name: "Nawabganj",
    bn_name: "নবাবগঞ্জ",
  },
  {
    id: "23",
    division_id: "5",
    name: "Pabna",
    bn_name: "পাবনা",
  },
  {
    id: "24",
    division_id: "5",
    name: "Rajshahi",
    bn_name: "রাজশাহী",
  },
  {
    id: "25",
    division_id: "5",
    name: "Sirajgonj",
    bn_name: "সিরাজগঞ্জ",
  },
  {
    id: "26",
    division_id: "6",
    name: "Dinajpur",
    bn_name: "দিনাজপুর",
  },
  {
    id: "27",
    division_id: "6",
    name: "Gaibandha",
    bn_name: "গাইবান্ধা",
  },
  {
    id: "28",
    division_id: "6",
    name: "Kurigram",
    bn_name: "কুড়িগ্রাম",
  },
  {
    id: "29",
    division_id: "6",
    name: "Lalmonirhat",
    bn_name: "লালমনিরহাট",
  },
  {
    id: "30",
    division_id: "6",
    name: "Nilphamari",
    bn_name: "নীলফামারী",
  },
  {
    id: "31",
    division_id: "6",
    name: "Panchagarh",
    bn_name: "পঞ্চগড়",
  },
  {
    id: "32",
    division_id: "6",
    name: "Rangpur",
    bn_name: "রংপুর",
  },
  {
    id: "33",
    division_id: "6",
    name: "Thakurgaon",
    bn_name: "ঠাকুরগাঁও",
  },
  {
    id: "34",
    division_id: "1",
    name: "Barguna",
    bn_name: "বরগুনা",
  },
  {
    id: "35",
    division_id: "1",
    name: "Barishal",
    bn_name: "বরিশাল",
  },
  {
    id: "36",
    division_id: "1",
    name: "Bhola",
    bn_name: "ভোলা",
  },
  {
    id: "37",
    division_id: "1",
    name: "Jhalokati",
    bn_name: "ঝালকাঠি",
  },
  {
    id: "38",
    division_id: "1",
    name: "Patuakhali",
    bn_name: "পটুয়াখালী",
  },
  {
    id: "39",
    division_id: "1",
    name: "Pirojpur",
    bn_name: "পিরোজপুর",
  },
  {
    id: "40",
    division_id: "2",
    name: "Bandarban",
    bn_name: "বান্দরবান",
  },
  {
    id: "41",
    division_id: "2",
    name: "Brahmanbaria",
    bn_name: "ব্রাহ্মণবাড়িয়া",
  },
  {
    id: "42",
    division_id: "2",
    name: "Chandpur",
    bn_name: "চাঁদপুর",
  },
  {
    id: "43",
    division_id: "2",
    name: "Chattogram",
    bn_name: "চট্টগ্রাম",
  },
  {
    id: "44",
    division_id: "2",
    name: "Cumilla",
    bn_name: "কুমিল্লা",
  },
  {
    id: "45",
    division_id: "2",
    name: "Cox's Bazar",
    bn_name: "কক্স বাজার",
  },
  {
    id: "46",
    division_id: "2",
    name: "Feni",
    bn_name: "ফেনী",
  },
  {
    id: "47",
    division_id: "2",
    name: "Khagrachari",
    bn_name: "খাগড়াছড়ি",
  },
  {
    id: "48",
    division_id: "2",
    name: "Lakshmipur",
    bn_name: "লক্ষ্মীপুর",
  },
  {
    id: "49",
    division_id: "2",
    name: "Noakhali",
    bn_name: "নোয়াখালী",
  },
  {
    id: "50",
    division_id: "2",
    name: "Rangamati",
    bn_name: "রাঙ্গামাটি",
  },
  {
    id: "51",
    division_id: "7",
    name: "Habiganj",
    bn_name: "হবিগঞ্জ",
  },
  {
    id: "52",
    division_id: "7",
    name: "Maulvibazar",
    bn_name: "মৌলভীবাজার",
  },
  {
    id: "53",
    division_id: "7",
    name: "Sunamganj",
    bn_name: "সুনামগঞ্জ",
  },
  {
    id: "54",
    division_id: "7",
    name: "Sylhet",
    bn_name: "সিলেট",
  },
  {
    id: "55",
    division_id: "4",
    name: "Bagerhat",
    bn_name: "বাগেরহাট",
  },
  {
    id: "56",
    division_id: "4",
    name: "Chuadanga",
    bn_name: "চুয়াডাঙ্গা",
  },
  {
    id: "57",
    division_id: "4",
    name: "Jashore",
    bn_name: "যশোর",
  },
  {
    id: "58",
    division_id: "4",
    name: "Jhenaidah",
    bn_name: "ঝিনাইদহ",
  },
  {
    id: "59",
    division_id: "4",
    name: "Khulna",
    bn_name: "খুলনা",
  },
  {
    id: "60",
    division_id: "4",
    name: "Kushtia",
    bn_name: "কুষ্টিয়া",
  },
  {
    id: "61",
    division_id: "4",
    name: "Magura",
    bn_name: "মাগুরা",
  },
  {
    id: "62",
    division_id: "4",
    name: "Meherpur",
    bn_name: "মেহেরপুর",
  },
  {
    id: "63",
    division_id: "4",
    name: "Narail",
    bn_name: "নড়াইল",
  },
  {
    id: "64",
    division_id: "4",
    name: "Satkhira",
    bn_name: "সাতক্ষীরা",
  },
];
