import moment from "moment"
import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import paginationFactory from "react-bootstrap-table2-paginator"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import { formattedNumber, paymentStatus } from "../../components/common/Utils"

export default (props) => {
  const { lineUser } = props

  let ud = props.lineUserDetail
  let bcs = [],
    sch = []

  if (ud && ud.billChanges) {
    for (let prop in ud.billChanges) {
      let dt = new Date(parseInt(prop))
      let arr = ud.billChanges[prop].split("_")
      let temp = {
        type: arr[0],
        old: arr[1],
        new: arr[2],
        by: arr[3],
        userID: arr[4],
        dt,
        billChangeNote: arr[5],
      }
      bcs.push(temp)
    }
  }

  if (ud && ud.statusChanges) {
    for (let prop in ud.statusChanges) {
      let dt = new Date(parseInt(prop))
      let val = ud.statusChanges[prop]
      val = isNaN(val) ? val : val + "_n/a_n/a_n/a"
      let arr = val.split("_")

      let temp = {
        status: arr[0],
        by: arr[1],
        name: arr[2],
        dt,
        note: arr[4],
      }
      sch.push(temp)
    }
  }

  bcs = bcs.reverse()
  sch = sch.reverse()

  // Bills Changes table
  let billChangesColumnInfo = [
    {
      dataField: "type",
      text: "টাইপ",

      formatter: (cell) => {
        if (cell === "INCREMENT") return <span className="text-danger">বিল বৃদ্ধি</span>
        else if (cell === "DISCOUNT") return <span className="text-success">বিল ডিসকাউন্ট</span>
        else if (cell === "BILL") return <span className="text-primary">মাসিক ফি পরিবর্তন</span>
      },
    },
    {
      dataField: "old",
      text: "আপডেটের পূর্বে",

      formatter: (cell) => {
        return <span className="text-muted">{formattedNumber(cell)}</span>
      },
    },
    {
      dataField: "new",
      text: "আপডেটের পর",

      formatter: (cell) => {
        return <span className="text-info">{formattedNumber(cell)}</span>
      },
    },
    {
      dataField: "by",
      text: "আপডেট করেছেন",

      formatter: (cell) => {
        if (cell === "M") return <span className="">ম্যানেজার</span>
        else if (cell === "A") return <span className="text-secondary">এডমিন</span>
        else if (cell === "O") return <span className="text-info">অপারেটর</span>
      },
    },
    {
      dataField: "dt",
      text: "তারিখ",

      formatter: (cell) => {
        return moment(cell).format("DD/MM/yyyy hh:mm A")
      },
    },
    {
      dataField: "billChangeNote",
      text: "নোট",

      formatter: (cell) => {
        return cell
      },
    },
  ]

  // Status Changes table
  let statusChangesColumnInfo = [
    {
      dataField: "status",
      text: "স্ট্যাটাস",

      formatter: (cell) => {
        if (cell === 3 || cell === 5) return <span className="text-danger">বন্ধ</span>
        else if (cell === 2) return <span className="text-success">এক্টিভ</span>
      },
    },
    {
      dataField: "name",
      text: "আপডেট করেছেন",

      formatter: (cell) => {
        return <span className="text-info">{cell}</span>
      },
    },
    {
      dataField: "by",
      text: "রোল",

      formatter: (cell) => {
        if (cell === "manager") return <span className="">ম্যানেজার</span>
        else if (cell === "feeder") return <span className="">এডমিন</span>
        else if (cell === "operator") return <span className="text-info">অপারেটর</span>
        else return <span className="text-info">n/a</span>
      },
    },
    {
      dataField: "dt",
      text: "তারিখ",

      formatter: (cell) => {
        return moment(cell).format("DD/MM/yyyy hh:mm A")
      },
    },
    {
      dataField: "note",
      text: "নোট",

      formatter: (cell) => {
        return cell
      },
    },
  ]

  return (
    <div className="modal fade" id={props.id} tabindex="-1" role="dialog">
      <div className="modal-dialog mw-100 w-75" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="close close-fixed" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>

            <div className="profile mb-2">
              <div className="text-center mb-2">
                <i className="fas fa-user-circle fa-5x" title="avatar" />
              </div>
              <p className="text-center font-weight-bold mb-0">{lineUser.customerID}</p>
              <p className="text-center font-weight-bold  mb-0">{lineUser.name}</p>
              <p className="text-center font-weight-bold  mb-2">{lineUser.mobile}</p>
              <p className="text-center font-weight-bold  mb-2">{lineUser.houseOwnerName}</p>

              <p className="text-center mb-1 font-italic">{lineUser.fullAddress || ""}</p>

              <p className="text-center mb-0">
                সংযোগ টাইপঃ <strong>{lineUser.connectionType === 1 ? "ডিশ" : "ইন্টারনেট"}</strong>
              </p>
              <p className="text-center mb-0">
                পেমেন্ট টাইপঃ <strong>{lineUser.billPayType === 1 ? "প্রিপেইড" : "পোস্টপেইড"}</strong>
              </p>
              <p className="text-center mb-0">
                মাসিক ফিঃ <strong>{lineUser.monthlyBill} টাকা</strong>
              </p>
              <p className="text-center mb-0">
                বিলঃ <strong>{lineUser.nextBill} টাকা</strong>
              </p>

              <p className="text-center mb-2">
                সংযোগ তারিখঃ{" "}
                <strong>
                  {lineUser.connectionDate ? moment(lineUser.connectionDate).format("DD/MM/yyyy") : "নাই"}
                </strong>
              </p>
              <p className="text-center font-italic">
                পেমেন্ট স্ট্যাটাসঃ <strong>{paymentStatus[lineUser.currentMonthBS]}</strong>
              </p>
            </div>

            <p className="text-left font-weight-bold pt-2">বিল আপডেট রিপোর্টঃ</p>
            {bcs.length > 0 ? (
              <BootstrapTable
                bootstrap4
                striped
                hover
                columns={billChangesColumnInfo}
                data={bcs}
                keyField={"id"}
                pagination={paginationFactory()}
              />
            ) : (
              <p className="text-center font-italic">*** বিল পরির্তনের তথ্য পাওয়া যায়নি ***</p>
            )}
            <p className="text-left font-weight-bold pt-2">গ্রাহক এক্টিভ/বন্ধ রিপোর্টঃ</p>
            {sch.length > 0 ? (
              <BootstrapTable
                bootstrap4
                striped
                hover
                columns={statusChangesColumnInfo}
                data={sch}
                keyField={"id"}
                pagination={paginationFactory()}
              />
            ) : (
              <p className="text-center font-italic">*** স্ট্যাটাস পরির্তনের তথ্য পাওয়া যায়নি ***</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
