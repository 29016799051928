import React, { Component } from "react"
import { connect } from "react-redux"

class Failed extends Component {
  render() {
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="login-brand">
              <img src="../logo.png" alt="logo" width="100" className="shadow-light rounded-circle" />
            </div>
            <div className="container pt-4 pb-2 bg-white border-rounded text-center">
              <h4 className="text-danger">পেমেন্ট সফল হয়নি</h4>
              <hr />
            </div>
            <div className="container">
              <div style={{ minHeight: 230 }} className="row text-center p-3 bg-white align-items-center">
                <div className="col-md-12">
                  <h3>সাপোর্ট টিমের সাথে যোগাযোগ করুন</h3>
                </div>
                <br />
                <div className="col-md-12">
                  <a href="/">Back To Home</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile,
  }
}

export default connect(mapStateToProps)(Failed)
