import { useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../../config/config";

const ShunnoITInvoiceModal = ({ invoice }) => {
  const bKash = window.bkash;

  useEffect(() => {
    let paymentID = "";
    if (invoice?.merchantInvoiceNumber) {
      bKash.init({
        paymentMode: "checkout", //fixed value ‘checkout’
        paymentRequest: invoice,
        createRequest: async function(request) {
          try {
            const { data } = await axios.post(
              `${SERVER_URL}/api/bkash-pg/create-payment`,
              request
            );

            if (data?.paymentID) {
              paymentID = data?.paymentID;
              bKash.create().onSuccess(data);
              console.log("create payment success", data);
            } else {
              console.log("create payment error", data);
              bKash.create().onError();
              window.location.href = "bkash-payment/failed";
            }
          } catch (error) {
            console.log("create payment error", error);
            bKash.create().onError();
            window.location.href = "bkash-payment/failed";
          }
        },
        executeRequestOnAuthorization: async function() {
          try {
            const { data } = await axios.post(
              `${SERVER_URL}/api/bkash-pg/execute-payment?paymentID=${paymentID}`,
              invoice
            );

            if (data?.data.status === "paid") {
              console.log("execute payment success", data);
              window.location.href = "/bkash-payment-success";
            } else {
              console.log("execute payment error", data);
              window.location.href = "/bkash-payment-failed";
              bKash.execute().onError();
            }
          } catch (error) {
            console.log("execute payment error", error);
            bKash.execute().onError();
            // window.location.href = "/bkash-payment-failed";
          }
        }
      });
    }
  }, [invoice]);

  return true;

  // return (
  //   <button className="btn btn-success btn-lg mt-4 h2" id="bKash_button">
  //     Payment ("test")
  //   </button>
  // );
};

export default ShunnoITInvoiceModal;
