export const userDetail = {
  userName: "ঊশা মাল্টিমিডিয়া শপ",
  userImg: "../../../assets/img/avatar/avatar-1.png",
  logoutIcon: "fas fa-sign-out-alt",
  logoutTitle: "লগআউট",
  datas: [
    {
      link: "/profile",
      icode: "fas fa-user-alt",
      title: "প্রোফাইল",
    },
    {
      link: "/account",
      icode: "fas fa-home",
      title: "একাউন্ট",
    },
    {
      link: "/message",
      icode: "fas fa-comment-alt",
      title: "ম্যাসেজ বোর্ড",
    },
    {
      link: "/pay/bill",
      icode: "fas fa-money-check-alt",
      title: "পেমেন্ট",
    },
    {
      link: "/invoice",
      icode: "fas fa-file-invoice-dollar",
      title: "ইনভয়েস",
    },
    {
      link: "/help",
      icode: "fas fa-question-circle",
      title: "সাহায্য",
    },
  ],
}
