import React, { Component } from "react"
import { connect } from "react-redux"

import { confirmDeposit } from "../../actions/dashboardActions"
import { bdt } from "../../components/common/Utils"

class ManagerDeposit extends Component {
  onConfirmDeposit(status) {
    let conf = false
    if (status === "ACCEPTED") {
      conf = window.confirm("আপনি কি এই জমাটি গ্রহণ করতে চান?")
    }
    if (status === "REJECTED") {
      conf = window.confirm("আপনি কি এই জমাটি বাতিল করতে চান?")
    }
    if (!conf) return

    const confirmDepositData = {
      managerID: this.props.manager._id,
      depositID: this.props.manager.depositID,
      status: status,
    }

    this.props.confirmDeposit(confirmDepositData)
  }

  render() {
    const { manager } = this.props

    return (
      <>
        <div className="col-md-4 text-center">
          <div className="clearfix py-3">
            <div className="card text-center">
              <div className="card-body text-center">
                <p className="h4 card-text font-weight-bold">{manager.name}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 text-center">
          <div className="clearfix py-3">
            <div className="card text-center">
              <div className="card-body text-center">
                <p className="h4 card-text font-weight-bold">{bdt(manager.depositAmount)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2 text-right">
          <button
            type="button"
            className="btn btn-success btn-lg"
            style={{ fontSize: "1.3rem" }}
            onClick={() => this.onConfirmDeposit("ACCEPTED")}
            disabled={this.props.server.requestPending}
          >
            &nbsp;&nbsp;&nbsp;গ্রহণ&nbsp;&nbsp;&nbsp;
          </button>
        </div>
        <div className="col-md-2 text-center">
          <button
            type="button"
            className="btn btn-danger btn-lg"
            style={{ fontSize: "1.3rem" }}
            onClick={() => this.onConfirmDeposit("REJECTED")}
            disabled={this.props.server.requestPending}
          >
            &nbsp;&nbsp;&nbsp;বাতিল&nbsp;&nbsp;&nbsp;
          </button>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  server: state.server,
})

export default connect(mapStateToProps, {
  confirmDeposit,
})(ManagerDeposit)
