import axios from "axios"
import $ from "jquery"
import toastr from "toastr"
import { SERVER_URL } from "../config/config"
import store from "../store"
import {
  ADD_LINEUSER,
  DELETE_LINEUSER,
  ERROR_MSG,
  GET_ALL_LINE_USERS,
  GET_ERRORS,
  GET_LINEUSER_DETAIL,
  LOADING,
  RESET,
  UPDATE_LINEUSER,
  STATUS_UPDATE_LINEUSER,
  BILL_COLLECT_LINEUSER,
  BILL_REPORT_LINEUSER,
} from "./types"

export const getAllLineUsers = () => (dispatch) => {
  let api
  if (store.getState().auth.user.type === "feeder") {
    api = `${SERVER_URL}/api/lineusers/`
  } else if (store.getState().auth.user.type === "manager") {
    api = `${SERVER_URL}/api/lineusers/`
  } else if (store.getState().auth.user.type === "operator") {
    api = `${SERVER_URL}/api/lineusers/`
  } else if (store.getState().auth.user.type === "reseller") {
    api = `${SERVER_URL}/api/lineusers/`
  }
  axios
    .get(api)
    .then((res) => {
      const { status, data } = res.data

      if (status) {
        // data.all = [...data.unpaid, ...data.partial, ...data.paid, ...data.free, ...data.inactive, ...data.advanced]
        // data.allCount =
        //   data.unpaidCount +
        //   data.partialCount +
        //   data.paidCount +
        //   data.advancedCount +
        //   data.freeCount +
        //   data.inactiveCount

        dispatch({
          type: GET_ALL_LINE_USERS,
          payload: data,
        })
      } else {
        toastr.error("Something Went Wrong!")
        dispatch({
          type: GET_ERRORS,
          payload: data,
        })
      }
    })
    .catch((err) => toastr.error("গ্রাহক লোড ব্যর্থ হয়েছে!!"))
}

export const getDetailLineUser = (id) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .get(`${SERVER_URL}/api/lineusers/detail/${id}`)
    .then((res) => {
      const { status, data } = res.data
      dispatch({ type: RESET })
      if (status) {
        dispatch({
          type: GET_LINEUSER_DETAIL,
          payload: data,
        })
      } else {
        toastr.error("Something Went Wrong!")
        dispatch({
          type: GET_ERRORS,
          payload: data,
        })
      }
    })
    .catch((err) => toastr.error("গ্রাহক লোড ব্যর্থ হয়েছে!!"))
}

export const addLineUser = (lineUserData) => (dispatch) => {
  dispatch({
    type: LOADING,
  })

  console.log(lineUserData)

  // fix area
  lineUserData.assignedArea = lineUserData.assignedArea.value
  // lineUserData.resellerID = lineUserData.resellerID.value
  if (lineUserData.resellerID?.value) lineUserData.resellerID = lineUserData.resellerID.value

  axios
    .post(`${SERVER_URL}/api/lineusers/add/`, lineUserData)
    .then((res) => {
      let { status, data, msg } = res.data

      if (status) {
        dispatch({
          type: RESET,
        })
        toastr.success(msg)
        $("#lineUserAddOrEditModal").modal("hide")
        dispatch({
          type: ADD_LINEUSER,
          payload: data,
        })
      } else {
        // for (const property in data) {
        //   toastr.error(`${data[property]}`)
        // }
        dispatch({
          type: ERROR_MSG,
        })
        toastr.error(msg)
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("নতুন গ্রাহক এড সফল হয়নি।")
    })
}

export const updateLineUser = (lineUserData) => (dispatch) => {
  dispatch({
    type: LOADING,
  })

  // fix area
  lineUserData.assignedArea = lineUserData.assignedArea.value
  // lineUser.resellerID = lineUser.resellerID.value
  if (lineUserData.resellerID?.value) lineUserData.resellerID = lineUserData.resellerID.value

  axios
    .put(`${SERVER_URL}/api/lineusers/edit/${lineUserData.id}`, lineUserData)
    .then((res) => {
      let { status, data, msg } = res.data

      if (status) {
        dispatch({
          type: RESET,
        })
        $("#lineUserAddOrEditModal").modal("hide")
        dispatch({
          type: UPDATE_LINEUSER,
          payload: { ...data, ...lineUserData },
        })
        toastr.success(msg)
      } else {
        // for (const property in data) {
        //   toastr.error(`${data[property]}`)
        // }
        dispatch({
          type: ERROR_MSG,
        })
        toastr.error(msg)
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("গ্রাহক আপডেট সফল হয়নি।")
    })
}

export const editLineUserBill = (lineUser) => (dispatch) => {
  dispatch({
    type: LOADING,
  })

  axios
    .put(`${SERVER_URL}/api/lineusers/bill/edit/${lineUser.id}`, lineUser)
    .then((res) => {
      let { status, data, msg } = res.data

      if (status) {
        dispatch({
          type: RESET,
        })
        if (lineUser.type === "BILL") {
          $("#monthlyBillUpdate").modal("hide")
        } else {
          $("#billUpdate").modal("hide")
        }

        dispatch({
          type: UPDATE_LINEUSER,
          payload: { ...data, ...lineUser },
        })
        toastr.success(msg)
      } else {
        dispatch({
          type: ERROR_MSG,
        })
        toastr.error(msg)
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("গ্রাহক মাসিক ফি/বিল আপডেট সফল হয়নি।")
    })
}

// Update lineUser status
export const updateLineUserStatus = (profileData) => (dispatch) => {
  dispatch({
    type: LOADING,
  })

  axios
    .put(`${SERVER_URL}/api/lineusers/update/status`, profileData)
    .then((res) => {
      let { status, data, msg } = res.data
      let remark = "deactivate"
      if (status) {
        dispatch({
          type: RESET,
        })
        $("#deactivate").modal("hide")
        if (profileData.status == 2) remark = "activate"
        dispatch({
          type: STATUS_UPDATE_LINEUSER,
          payload: { ...data, ...profileData },
          remark,
        })
        toastr.success(msg)
      } else {
        dispatch({
          type: ERROR_MSG,
        })
        toastr.error(msg)
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("সংযোগ বন্ধ সফল হয়নি।")
    })
}

// Delete LineUser
export const deleteLineUser = (profileData) => (dispatch) => {
  axios
    .put(`${SERVER_URL}/api/lineusers/update/status`, profileData)
    .then((res) => {
      let { status, data, msg } = res.data
      if (status) {
        dispatch({
          type: DELETE_LINEUSER,
          payload: profileData.lineUserID,
        })
        toastr.success("গ্রাহক ডিলিট সফল হয়েছে।")
      } else {
        dispatch({
          type: ERROR_MSG,
        })
        toastr.error(msg)
      }
    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: RESET,
      })
      toastr.error("সফল হয়নি।")
    })
}

// Delete LineUser Bill
export const deleteLineUserBill = (bill) => (dispatch) => {
  console.log(bill)
  // return
  dispatch({
    type: LOADING,
  })
  axios
    .put(`${SERVER_URL}/api/lineusers/delete/bill`, bill)
    .then((res) => {
      dispatch({
        type: RESET,
      })
      let { status, data, msg } = res.data
      if (status) {
        $("#profileModal").modal("hide")
        dispatch({
          type: UPDATE_LINEUSER,
          payload: { ...data },
        })
        toastr.success(msg)
      } else toastr.error(msg)
    })
    .catch((err) => {
      console.log(err)
      toastr.error("সফল হয়নি।")
    })
}

// Paybill
export const payBill = (paymentData, accountType, currentMonthBS) => (dispatch) => {
  dispatch({ type: LOADING })
  console.log(paymentData)
  axios
    .post(`${SERVER_URL}/api/lineusers/collect/bill`, paymentData)
    .then((res) => {
      let { status, data, msg } = res.data
      if (status) {
        dispatch({ type: RESET })
        $("#billCollect").modal("hide")
        dispatch({ type: BILL_COLLECT_LINEUSER, payload: { ...data, ...paymentData }, oldBS: currentMonthBS })
        toastr.success(msg)
      } else {
        dispatch({ type: ERROR_MSG })
        toastr.error(msg)
      }
    })
    .catch((err) => {
      console.log(err)
      dispatch({ type: RESET })
      toastr.error("বিল গ্রহণ সফল হয়নি।")
    })
}

// Get bill report
export const getSingleUserBillReport = (id) => (dispatch) => {
  console.log("calling bill report")
  dispatch({ type: LOADING })
  axios
    .get(`${SERVER_URL}/api/lineusers/bill/report?lineUserID=${id}`)
    .then((res) => {
      let { status, data, msg } = res.data
      if (status) {
        dispatch({ type: RESET })
        dispatch({ type: BILL_REPORT_LINEUSER, payload: data })
      } else {
        dispatch({ type: ERROR_MSG })
        toastr.error(msg)
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
