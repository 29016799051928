import jwt_decode from "jwt-decode";
import React from "react";
// import "toastr/build/toastr.css"
import { Provider } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import toastr from "toastr";
import { logout, setCurrentUser, getStatus } from "./actions/authActions";
import { setProfile } from "./actions/profileActions";
import { getAllResellers } from "./actions/resellerActions";
import { getAllCollectors } from "./actions/collectorActions";
import { Footer, Header, Sidebar } from "./components/admin";
import PrivateRoute from "./components/common/PrivateRoute";
import Area from "./pages/Area/Area";
import Login from "./pages/Auth/Login";
import CollectorDeposit from "./pages/Deposit/CollectorDeposit";
import ManagerDeposit from "./pages/Deposit/ManagerDeposit";
import Collector from "./pages/Collector/Collector";
import Dashboard from "./pages/Dashboard/Dashboard";
import Expenditure from "./pages/Expenditure/Expenditure";
import ExpenditurePurpose from "./pages/Expenditure/ExpenditurePurpose";
import LineUser from "./pages/LineUser/LineUser";
import ActiveConnection from "./pages/Mikrotik/ActiveConnection";
import Configure from "./pages/Mikrotik/Configure";
import Operator from "./pages/Operator/Operator";
import Account from "./pages/Others/Account";
import Invoice from "./pages/Others/Invoice";
import Collection from "./pages/Others/Collection";
import Failed from "./pages/Others/Failed";
import Help from "./pages/Others/Help";
import Manager from "./pages/Others/Manager";
import Message from "./pages/Others/Message";
import Payment from "./pages/Others/Payment";
import Profile from "./pages/Others/Profile";
import Success from "./pages/Others/Success";
import DueReport from "./pages/Report/DueReport";
import Report from "./pages/Report/Report";
import ReportPrevious from "./pages/Report/ReportPrevious";
import ReportSummary from "./pages/Report/Summary/Dashboard";
import WebhookMessage from "./pages/Report/WebhookMessage";
import CollectionGrahp from "./pages/Report/CollectionGrahp";
import Reseller from "./pages/Reseller/Reseller";
import Staff from "./pages/Staff/Staff";
import Tutorial from "./pages/Tutorial/Tutorial";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import Register from "./pages/Auth/Register";
import MessageLog from "./pages/Report/MessageLog";

toastr.options = {
  // positionClass: "toast-top-center",
  // preventDuplicates: true,
};

const Error403 = React.lazy(() => import("./pages/Errors/403"));
const Error404 = React.lazy(() => import("./pages/Errors/404"));
const Error500 = React.lazy(() => import("./pages/Errors/500"));
const Error503 = React.lazy(() => import("./pages/Errors/503"));

const history = React.lazy(() => import("./history"));
console.log(window.location.href.split("/"));
if (
  !localStorage.bayannoPayToken &&
  window.location.href.split("/").slice(-1)[0] != "login"
) {
  if (
    !window.location.href.split("/").includes("success") &&
    !window.location.href.split("/").includes("failed") &&
    !window.location.href.split("/").includes("pay")
  ) {
    window.location.href = "/auth/login";
  }
} else if (
  localStorage.bayannoPayToken &&
  window.location.href.split("/").slice(-1)[0] == "login"
) {
  window.location.href = "/";
}
if (localStorage.bayannoPayToken) {
  const decoded = jwt_decode(localStorage.bayannoPayToken);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logout());
    window.location.href = "/auth/login";
  } else {
    setAuthToken(localStorage.bayannoPayToken);
    store.dispatch(setCurrentUser(decoded));
    store.dispatch(setProfile());
    store.dispatch(getStatus());
    store.dispatch(getAllCollectors());

    if (
      store.getState().auth.user.type === "feeder" &&
      store.getState().auth.user.profile.hasReseller === "yes"
    ) {
      store.dispatch(getAllResellers());
    } else if (
      store.getState().auth.user.type === "manager" &&
      store.getState().auth.user.feeder.hasReseller === "yes"
    ) {
      // store.dispatch(getAllResellers())
    }
  }
}

function App() {
  let location = useLocation().pathname;

  let locationSplit = location.split("/");
  let locationParent = locationSplit[1];
  let WithoutRouter = [
    "auth",
    "error",
    "utilities",
    "success",
    "failed",
    "pay"
  ];
  if (store.getState().auth.user.type === "collector") {
    localStorage.removeItem("bayannoPayToken");
    window.alert(
      store.getState().auth.user.profile.name + " use our mobile app to login"
    );
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.shunno.project.bayannopay";
    return <></>;
  }

  return (
    <Provider store={store}>
      <div className="App">
        <>
          {!WithoutRouter.includes(locationParent) ? (
            <>
              <Header />
              <Sidebar />
            </>
          ) : (
            ""
          )}
          <React.Suspense fallback={<h1>Still Loading…</h1>}>
            <Switch history={history}>
              <Route path="/" exact component={Dashboard} />
              <Route path="/collection" exact component={Collection} />
              <Route path="/auth/login" exact component={Login} />
              <Route path="/auth/register" exact component={Register} />

              {/* Routes for LineUser Module */}
              <PrivateRoute exact path="/customers" component={LineUser} />
              <PrivateRoute
                exact
                path="/customers/:type"
                component={LineUser}
              />

              {/* Routes for area module */}
              <PrivateRoute path="/area" component={Area} />

              <PrivateRoute path="/invoice" component={Invoice} />
              <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute path="/manager" component={Manager} />
              <PrivateRoute path="/message" component={Message} />
              <Route path="/pay/:short_id" component={Payment} />
              <Route path="/failed" component={Failed} />
              <Route path="/success" component={Success} />
              <PrivateRoute path="/help" component={Help} />
              <PrivateRoute path="/account" component={Account} />
              <PrivateRoute path="/collector" component={Collector} />

              <PrivateRoute path="/operator" component={Operator} />
              <PrivateRoute path="/reseller" component={Reseller} />

              <PrivateRoute exact path="/report" component={Report} />
              <PrivateRoute
                exact
                path="/report/all"
                component={ReportPrevious}
              />
              <PrivateRoute exact path="/report/due" component={DueReport} />
              <PrivateRoute
                exact
                path="/report/collection-graph"
                component={CollectionGrahp}
              />
              <PrivateRoute
                exact
                path="/report/summary"
                component={ReportSummary}
              />
              <PrivateRoute
                exact
                path="/report/webhook-message"
                component={WebhookMessage}
              />
              <PrivateRoute
                exact
                path="/report/message"
                component={MessageLog}
              />

              <PrivateRoute path="/staff" component={Staff} />
              <PrivateRoute
                path="/deposit/collector"
                component={CollectorDeposit}
              />
              <PrivateRoute
                path="/deposit/manager"
                component={ManagerDeposit}
              />
              <PrivateRoute
                path="/expenditure/purpose"
                component={ExpenditurePurpose}
              />
              <PrivateRoute path="/expenditure" component={Expenditure} />

              <PrivateRoute exact path="/mikrotik" component={Configure} />
              <PrivateRoute
                exact
                path="/mikrotik/connections"
                component={ActiveConnection}
              />

              <PrivateRoute exact path="/tutorial" component={Tutorial} />
            </Switch>
          </React.Suspense>
          <Footer />
        </>
      </div>
    </Provider>
  );
}

export default App;
