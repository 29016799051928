import React, { Component, createRef } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { connect } from "react-redux"
import toastr from "toastr"
import {
  addMikrotik,
  mtkPackageDelete,
  mtkPackageUpdate,
  mtkPppPackageSync,
  syncPPPUsers,
  testMikrotikConnection,
  updateMtkQPackage,
  syncQUsers,
} from "../../actions/profileActions"
import Modal from "../../components/modals/Modal"

const { SearchBar } = Search

class Configure extends Component {
  constructor(props) {
    super(props)
    this.state = {
      host: "",
      user: "",
      password: "",
      port: "",
      newPackageRate: "",
      mtkPackage: {},
      mtkPackages: [],
      mtkQPackages: [],
    }

    this.hostRef = createRef()
    this.userRef = createRef()
    this.passwordRef = createRef()
    this.portRef = createRef()
    this.newPackageRateRef = createRef()
  }
  componentDidMount() {
    if (this.props?.profile?.profile?.mikrotiks?.length) {
      const { host, user, port } = this.props.profile.profile.mikrotiks[0]
      this.setState({
        host,
        user,
        port,
        mtkPackages: this.props?.profile?.feeder?.mtkPackages,
        mtkQPackages: this.props?.profile?.feeder?.mtkQPackages,
      })
    }
  }
  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }
  onChangeMtkQPackHandler = (e) => {
    let temp = [...this.state.mtkQPackages]
    temp.map((item) => (item.name === e.target.name ? (item.rate = parseInt(e.target.value)) : null))
    // temp[e.target.name] = e.target.value
    this.setState({
      mtkQPackages: temp,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps?.profile?.profile?.mikrotiks?.length) {
      const { host, user, port } = nextProps.profile.profile.mikrotiks[0]
      this.setState({
        host,
        user,
        port,
        mtkPackages: nextProps?.profile?.feeder?.mtkPackages,
        mtkQPackages: nextProps?.profile?.feeder?.mtkQPackages,
      })
    }
  }

  packageEditHandler(pkg) {
    this.setState({ mtkPackage: pkg, newPackageRate: pkg.rate })
  }

  onSubmitMTKPackageUpdate(e) {
    e.preventDefault()
    let { mtkPackage } = this.state
    mtkPackage.rate = this.state.newPackageRate
    // return
    this.props.mtkPackageUpdate(mtkPackage)
  }

  addEditHandler = (e) => {
    e.preventDefault()
    let { host, user, password, port } = this.state
    if (!host) {
      toastr.error("আইপি ফিল্ড পুরন করুন")
      this.hostRef.current.focus()
      return
    } else if (!user) {
      toastr.error("ইউজারনেম ফিল্ড পুরন করুন")
      this.userRef.current.focus()
      return
    } else if (!password) {
      toastr.error("<strong>পাসওয়ার্ড ফিল্ড পুরন করুন</strong>")
      this.passwordRef.current.focus()
      return
    } else if (!port) {
      toastr.error("পোর্ট ফিল্ড পুরন করুন")
      this.portRef.current.focus()
      return
    }

    this.props.addMikrotik({ ...this.state })
  }

  packageDeleteHandler(pkg) {
    let con = window.confirm(`${pkg.name} ডিলিট করতে চান?`)
    if (!con) return
    this.props.mtkPackageDelete(pkg)
  }

  syncLineUser() {
    let con = window.confirm(`মাইক্রোটিকের PPPoE গ্রাহক Sync করতে চান?`)
    if (!con) return
    this.props.syncPPPUsers()
  }

  onSubmitUpdateMtkQPackage(e) {
    e.preventDefault()
    let data = { mtkQPackage: JSON.stringify(this.state.mtkQPackages) }
    this.props.updateMtkQPackage(data)
  }

  syncQLineUser() {
    let con = window.confirm(`মাইক্রোটিকের QUEUE গ্রাহক Sync করতে চান?`)
    if (!con) return
    this.props.syncQUsers()
  }

  columns = [
    {
      dataField: "name",
      text: "প্যাকেজ",
    },
    {
      dataField: "rate",
      text: "রেট",
    },
    {
      dataField: "_id",
      text: "Action",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => (
        <ul className="navbar-nav navbar-right">
          <li className="dropdown">
            <a href="#" data-toggle="dropdown" className="nav-link nav-link-lg">
              <i className="fas fa-ellipsis-h" style={{ fontSize: 24 }}></i>
            </a>
            <div style={{ width: 50 }} className="dropdown-menu p-1">
              <a
                type="button"
                onClick={() => this.packageEditHandler(row)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#packageUpdateModal"
              >
                <i className="fas fa-edit" />
                আপডেট
              </a>
              <a
                type="button"
                onClick={() => this.packageDeleteHandler(row)}
                className="dropdown-item text-danger has-icon"
              >
                <i className="fas fa-stop" />
                ডিলিট করুন
              </a>
            </div>
          </li>
        </ul>
      ),
    },
  ]

  render() {
    let { host, user, password, port } = this.state
    const { server, profile } = this.props
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>মাইক্রোটিক</h4>
            </div>
            <div className="container">
              <div className="row justify-content-center p-3 bg-white align-items-center">
                {profile.isLoaded ? (
                  <div className="col-12">
                    <div className="clearfix py-3">
                      <h4 className="float-left">কনফিগারেশন</h4>
                      <button
                        className="btn btn-info float-right"
                        data-toggle="modal"
                        data-target="#mikrotikConfigModal"
                      >
                        <i class="fas fa-cog" title="মাইক্রোটিক কনফিগারেশন"></i>
                      </button>

                      <button
                        onClick={() => this.props.testMikrotikConnection()}
                        className="btn btn-success float-right mx-2"
                        data-toggle="modal"
                        disabled={server.requestPending}
                      >
                        {server.requestPending ? (
                          <i class="fas fa-sync fa-spin"></i>
                        ) : (
                          <i class="fas fa-plug" title="টেস্ট কানেকশন"></i>
                        )}
                      </button>
                    </div>
                    <hr />
                    <div className="col-md-6 text-left">
                      <p className="">
                        আইপিঃ <strong>{host}</strong>
                      </p>
                      <p className="">
                        ইউজারনেমঃ <strong>{user}</strong>
                      </p>
                      <p className="">
                        পোর্টঃ <strong>{port}</strong>
                      </p>
                    </div>
                    <br />
                    <div className="clearfix py-3">
                      <h4 className="float-left">PPPoE প্যাকেজ</h4>
                      <button
                        onClick={() => this.props.mtkPppPackageSync()}
                        className="btn btn-success float-right mx-2"
                        data-toggle="modal"
                      >
                        <i class="fas fa-sync" title="Sync মাইক্রোটিক প্যাকেজ"></i>
                      </button>
                    </div>
                    <hr />
                    <PaginationProvider
                      pagination={paginationFactory({
                        sizePerPageList: [
                          { text: "10", value: 10 },
                          { text: "20", value: 20 },
                          { text: "50", value: 50 },
                          { text: "100", value: 100 },
                          { text: "200", value: 200 },
                          { text: "500", value: 500 },
                          { text: "1000", value: 1000 },
                          { text: "2000", value: 2000 },
                          {
                            text: "All",
                            value: profile.profile.mtkPackages.length,
                          },
                        ],
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          bootstrap4
                          keyField="_id"
                          data={this.state.mtkPackages}
                          columns={this.columns}
                          search
                        >
                          {(props) => (
                            <div>
                              <div className="row justify-content-between">
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <strong>মোটঃ {this.state.mtkPackages.length}</strong>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <SearchBar {...props.searchProps} />
                                </div>
                              </div>

                              <BootstrapTable striped hover {...props.baseProps} {...paginationTableProps} />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>

                    <br />
                    <hr />
                    <div className="clearfix py-3">
                      <h4 className="float-left">PPPoE গ্রাহক</h4>
                      <button
                        onClick={() => this.syncLineUser()}
                        className="btn btn-success float-right mx-2"
                        disabled={server.requestPending}
                      >
                        <i
                          className={server.requestPending ? "fas fa-sync fa-spin" : "fas fa-sync"}
                          title="Sync মাইক্রোটিক গ্রাহক"
                        ></i>
                      </button>
                    </div>
                    <hr />
                    <hr />

                    <div className="clearfix py-3">
                      <h4 className="float-left">QUEUE প্যকেজ</h4>
                      <button
                        className="btn btn-info float-right mx-2"
                        data-toggle="modal"
                        data-target="#queuePackageModal"
                      >
                        <i class="fas fa-cog" title="QUEUE প্যকেজ রেট"></i>
                      </button>
                    </div>
                    <hr />
                    <div className="clearfix py-3">
                      <h4 className="float-left">QUEUE গ্রাহক</h4>
                      <button
                        onClick={() => this.syncQLineUser()}
                        className="btn btn-success float-right mx-2"
                        disabled={server.requestPending}
                      >
                        <i
                          className={server.requestPending ? "fas fa-sync fa-spin" : "fas fa-sync"}
                          title="Sync মাইক্রোটিক গ্রাহক"
                        ></i>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div class="fa-3x">
                    <i style={{ fontSize: 40 }} class="fas fa-plug fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>

        <Modal
          modalTitle="মাইক্রোটিক কনফিগারেশন"
          onSubmit={this.addEditHandler}
          pending={server.requestPending}
          id="mikrotikConfigModal"
        >
          <div className="form-group">
            <label htmlFor="name">মাইক্রোটিক আইপি</label>
            <input
              ref={this.hostRef}
              className="form-control"
              onChange={this.onChangeHandler}
              value={this.state.host}
              type="text"
              name="host"
              disabled={server.requestPending}
            />
          </div>
          <div className="form-group">
            <label htmlFor="user">ইউজারনেম</label>
            <input
              ref={this.userRef}
              className="form-control"
              onChange={this.onChangeHandler}
              value={this.state.user}
              type="text"
              name="user"
              disabled={server.requestPending}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">পাসওয়ার্ড</label>
            <input
              ref={this.passwordRef}
              className="form-control"
              onChange={this.onChangeHandler}
              value={this.state.password}
              type="password"
              name="password"
              disabled={server.requestPending}
            />
          </div>

          <div className="form-group">
            <label htmlFor="port">পোর্ট</label>
            <input
              ref={this.portRef}
              className="form-control"
              onChange={this.onChangeHandler}
              value={this.state.port}
              type="number"
              name="port"
              disabled={server.requestPending}
            />
          </div>
        </Modal>

        <Modal
          modalTitle={this.state.mtkPackage.name}
          onSubmit={this.onSubmitMTKPackageUpdate.bind(this)}
          pending={server.requestPending}
          id="packageUpdateModal"
        >
          <div className="form-group">
            <label htmlFor="newPackageRate">রেট লিখুন</label>
            <input
              ref={this.newPackageRateRef}
              className="form-control"
              onChange={this.onChangeHandler}
              value={this.state.newPackageRate}
              type="number"
              name="newPackageRate"
              disabled={server.requestPending}
            />
          </div>
        </Modal>
        <Modal
          modalTitle="আপডেট: QUEUE প্যাকেজ রেট"
          onSubmit={this.onSubmitUpdateMtkQPackage.bind(this)}
          pending={server.requestPending}
          id="queuePackageModal"
        >
          <div className="row">
            {this.state?.mtkQPackages?.length &&
              this.state.mtkQPackages.map((pack, i) => {
                return (
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="name">{pack.name}</label>
                      <input
                        // ref={this.hostRef}
                        className="form-control"
                        onChange={this.onChangeMtkQPackHandler}
                        value={this.state.mtkQPackages[i]["rate"]}
                        type="number"
                        name={pack.name}
                        disabled={server.requestPending}
                      />
                    </div>
                  </div>
                )
              })}
          </div>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    server: state.server,
    reseller: state.reseller,
    profile: state.profile,
    auth: state.auth,
  }
}

export default connect(mapStateToProps, {
  addMikrotik,
  testMikrotikConnection,
  mtkPackageDelete,
  mtkPackageUpdate,
  mtkPppPackageSync,
  syncPPPUsers,
  updateMtkQPackage,
  syncQUsers,
})(Configure)
