import axios from "axios"
import $ from "jquery"
import toastr from "toastr"
import {
  ADD_RESELLER,
  GET_ALL_RESELLERS,
  LOADING,
  RESELLER_ASSIGN_AREAS,
  RESET,
  SET_PROFILE,
  UPDATE_RESELLER,
} from "../actions/types"
import { SERVER_URL } from "../config/config"

export const getAllResellers = () => (dispatch) => {
  axios
    .get(`${SERVER_URL}/api/resellers/list/`)
    .then((res) => {
      const { status, data } = res.data
      if (status) {
        dispatch({
          type: GET_ALL_RESELLERS,
          payload: data,
        })
      } else {
        toastr.error("Something Went Wrong!")
      }
    })
    .catch((err) => toastr.error("রিসেলার লোড ব্যর্থ হয়েছে!!"))
}

export const addReseller = (resellerData) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .post(`${SERVER_URL}/api/resellers/add`, resellerData)
    .then((res) => {
      let { status, data } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#Modal").modal("hide")
        dispatch({
          type: ADD_RESELLER,
          payload: data,
        })
        toastr.success("রিসেলার অ্যাড হয়েছে।")
      } else {
        for (const property in data) {
          toastr.error(`${data[property]}`)
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("রিসেলার অ্যাড সফল হয়নি।")
    })
}

export const updateReseller = (reseller) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(`${SERVER_URL}/api/resellers/edit/${reseller._id}`, reseller)
    .then((res) => {
      let { status, data } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#Modal").modal("hide")
        dispatch({
          type: UPDATE_RESELLER,
          payload: data,
        })
        toastr.success("রিসেলার আপডেট হয়েছে।")
      } else {
        for (const property in data) {
          toastr.error(`${data[property]}`)
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("রিসেলার আপডেট সফল হয়নি।")
    })
}

export const allowedResellers = (params, profile) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(`${SERVER_URL}/api/resellers/allow/`, params)
    .then((res) => {
      let { status, data, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#resellerSettingsModal").modal("hide")
        dispatch({
          type: SET_PROFILE,
          payload: { ...profile, ...data },
          feeder: { ...profile, ...data },
        })
        toastr.success(msg)
      } else {
        for (const property in data) toastr.error(`${data[property]}`)
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("এক্টিভ রিসেলারের লিস্ট আপডেট সফল হয়নি!")
    })
}

export const assignAreasToReseller = (areas, id) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(`${SERVER_URL}/api/resellers/assign/areas/${id}`, areas)
    .then((res) => {
      let { status, data, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#areaAssignModal").modal("hide")
        dispatch({
          type: RESELLER_ASSIGN_AREAS,
          payload: data,
        })
        toastr.success(msg)
      } else {
        for (const property in data) toastr.error(`${data[property]}`)
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("আপডেট সফল হয়নি!")
    })
}

export const resellerRecharge = (data, id) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .post(`${SERVER_URL}/api/resellers/feeder/recharge/${id}`, data)
    .then((res) => {
      let { status, data } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#rechargeModal").modal("hide")
        dispatch({
          type: UPDATE_RESELLER,
          payload: data,
        })
        toastr.success("Successfull!")
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })

      toastr.error(err)
    })
}

export const deleteResellerRecharge = (reseller_id, id) => (dispatch) => {
  console.log(reseller_id, id)
  dispatch({ type: LOADING })
  axios
    .delete(`${SERVER_URL}/api/resellers/feeder/recharge/${reseller_id}/${id}`)
    .then((res) => {
      let { status, data, msg } = res.data
      dispatch({ type: RESET })
      if (status) {
        $("#rechargeModal").modal("hide")
        dispatch({
          type: UPDATE_RESELLER,
          payload: data,
        })
        toastr.success("Successfull!")
      } else {
        toastr.error(msg)
      }
    })
    .catch((err) => {
      dispatch({ type: RESET })
      toastr.error(err)
    })
}
