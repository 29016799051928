import React, { Component, createRef } from "react"
import { connect } from "react-redux"
import toastr from "toastr"
import moment from "moment"

import { updateLineUserStatus } from "../../actions/lineUserActions"
import Modal from "../../components/modals/Modal"

class Deactivate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lineUser: props.lineUserToAddOrEdit || {},
      sendSms: false,
      note: "",
      newNextBill: 0,
      mobile: "",
    }
    this.newNextBillRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { requestPending, errorMsg } = nextProps.server
    if (requestPending || errorMsg) return

    let mobile
    if (nextProps.lineUserToAddOrEdit.mobile && nextProps.lineUserToAddOrEdit.mobile !== "01700000000") {
      mobile = nextProps.lineUserToAddOrEdit.mobile
    }

    this.setState({
      lineUser: nextProps.lineUserToAddOrEdit,
      modalTitle: nextProps.modalTitle,
      newNextBill: nextProps.lineUserToAddOrEdit.dueAmount,
      mobile,
      note: "",
    })
  }

  onChangeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleCheckBoxClick() {
    this.setState({ sendSms: !this.state.sendSms })
  }

  onSubmitHandler = (e) => {
    e.preventDefault()
    let { id, currentMonthBS } = this.state.lineUser

    e.preventDefault()

    let lineUserdata = {
      lineUserID: id,
      status: currentMonthBS == "PARTIAL" || currentMonthBS == "FULL" ? 5 : 3,
      note: this.state.note,
      feederID: this.props.feederID,
    }

    this.props.updateLineUserStatus(lineUserdata, this.props.history)
  }

  render() {
    const { lineUser, newNextBill, sendSms, note, mobile } = this.state
    return (
      <>
        <Modal
          modalTitle="সংযোগ বন্ধ"
          onSubmit={this.onSubmitHandler}
          pending={this.props.server.requestPending}
          id="deactivate"
          modalWidth="w-50"
        >
          <div className="row">
            <div className="col-md-6 text-left mb-1">
              আইডিঃ <strong>{lineUser.customerID}</strong>
            </div>
            <div className="col-md-6 text-left mb-1">
              মাসিক ফিঃ <strong>{lineUser.monthlyBill} টাকা</strong>
            </div>

            <div className="col-md-6 text-left mb-1">
              গ্রাহকঃ <strong>{lineUser.name}</strong>
            </div>
            <div className="col-md-6 text-left mb-2">
              বিলঃ <strong>{lineUser.dueAmount} টাকা</strong>
            </div>
            {<div className="col-md-6 text-left mb-2">মোবাইলঃ {mobile ? <strong>{mobile}</strong> : "নাই"}</div>}
            <div className="col-md-6"></div>

            {/* নোট  */}
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="note">নোট লিখুন</label>
                <input
                  ref={this.noteRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={note}
                  type="text"
                  name="note"
                />
              </div>
            </div>
          </div>
          {/*END OF ROW */}
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    feederID: state.auth.user.profile.profileID,
    profile: state.profile,
    lineUser: state.lineUser,
    server: state.server,
  }
}

export default connect(mapStateToProps, { updateLineUserStatus })(Deactivate)
