import React, { Component } from "react";
import store from "../../../store";
import { userDetail } from "./Data";
import UserDropdown from "./UserDropdown";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail,
    };
  }

  componentDidMount() {
    const reduxStore = store.getState();
    if (!reduxStore.auth.isAuthenticated) {
      window.location.href = "/auth/login";
    }

    let ud = { ...this.state.userDetail };
    if (this.props.store) {
      ud.userName = this.props.store.storeName;
      this.setState({
        userDetail: ud,
      });
    }

    // store.dispatch(getStore());
  }
  render() {
    return (
      <div>
        <div className="navbar-bg" />
        <nav className="navbar navbar-expand-lg main-navbar">
          <ul className="navbar-nav mr-auto">
            <li>
              <a
                href="#"
                data-toggle="sidebar"
                className="nav-link nav-link-lg"
              >
                <i className="fas fa-bars" />
              </a>
            </li>
          </ul>
          <ul className="navbar-nav navbar-right">
            <UserDropdown userDetail={userDetail} />
          </ul>
        </nav>
      </div>
    );
  }
}

export default Header;
