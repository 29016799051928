import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import { getWebhookMessages } from "../../actions/reportActions";

import PdfComponent from "../../components/common/PdfComponent";
import Modal from "../../components/modals/Modal";

const { SearchBar } = Search;

class WebhookMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      _id: "",
      update: false
    };
  }

  componentDidMount() {
    const { report, getWebhookMessages, auth } = this.props;

    const id =
      auth.user.type === "feeder" ? auth.user.mobile : auth.user.feeder.mobile;

    if (!report.isWebhookMessagesLoaded && id) {
      getWebhookMessages(id);
    }
  }

  columns = [
    {
      dataField: "createdAt",
      text: "SL",
      headerStyle: { width: "10px" },
      sort: true,
      headerAlign: "center",
      align: "center",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      headerStyle: { width: "10px" }
    },
    {
      dataField: "message",
      text: "মেসেজ",
      style: {
        wordWrap: "break-word",
        paddingLeft: "10px",
        paddingRight: "10px"
      },
      sort: true,
      headerAlign: "center",
      align: "left",
      headerStyle: { width: "50%" }
    },
    {
      dataField: "status",
      text: "স্ট্যাটাস",
      headerStyle: { width: "100px" },
      sort: true,
      headerAlign: "center",
      align: "center",
      formatter: cell => {
        if (cell === "SENT")
          return <span class="badge bg-success text-white">{cell}</span>;
        else if (cell === "UNSENT")
          return <span class="badge bg-warning">{cell}</span>;
        else if (cell === "REJECTED")
          return <span class="badge bg-danger text-white">{cell}</span>;
      }
    },
    {
      dataField: "trxID",
      text: "TrxID",
      headerStyle: { width: "120px" },
      sort: true,
      headerAlign: "center",
      align: "center"
    },
    {
      dataField: "from",
      text: "মিডিয়াম",
      headerStyle: { width: "100px" },
      sort: true,
      headerAlign: "center",
      align: "center",
      formatter: cell => <span class="badge bg-secondary">{cell}</span>
    },
    {
      dataField: "createdAt",
      text: "At",
      style: {
        wordWrap: "break-word",
        paddingLeft: "10px",
        paddingRight: "10px"
      },
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: { width: "11%" },
      formatter: cell => {
        return moment(cell).format("DD/MM/yyyy hh:mm A");
      }
    }
  ];

  render() {
    let company =
      this.props.auth.user.type === "feeder"
        ? this.props.auth.user.profile.company
        : this.props.auth.user.feeder.company;

    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>পেমেন্ট মেসেজ</h4>
            </div>
            <div className="container">
              <div
                style={{ minHeight: 230 }}
                className="row justify-content-center p-3 bg-white align-items-center"
              >
                {this.props.profile.isLoaded &&
                this.props.report.isWebhookMessagesLoaded ? (
                  <div className="col-12">
                    <PaginationProvider
                      pagination={paginationFactory({
                        sizePerPageList: [
                          { text: "10", value: 10 },
                          { text: "20", value: 20 },
                          { text: "50", value: 50 },
                          { text: "100", value: 100 }
                          // { text: "200", value: 200 },
                          // { text: "500", value: 500 },
                          // { text: "1000", value: 1000 },
                          // { text: "2000", value: 2000 },
                          // {
                          //   text: "All",
                          //   value: this.props.report.webhookMessages?.length
                          // }
                        ]
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          bootstrap4
                          keyField="_id"
                          data={this.props.report.webhookMessages}
                          columns={this.columns}
                          search
                        >
                          {props => (
                            <div>
                              <div className="row justify-content-between">
                                <div className="col-md-4">
                                  <strong>
                                    মোটঃ{" "}
                                    {this.props.report.webhookMessages.length}
                                  </strong>
                                </div>
                                <div className="col-md-6 text-right">
                                  <SearchBar {...props.searchProps} />
                                </div>
                              </div>

                              <BootstrapTable
                                striped
                                hover
                                {...props.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </div>
                ) : (
                  <div class="fa-3x">
                    <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile,
    server: state.server,
    report: state.report,
    auth: state.auth
  };
};

export default connect(mapStateToProps, {
  getWebhookMessages
})(WebhookMessage);
