const initialState = {
  isLoaded: false,
  isDashboardSummaryLoaded: false,
  dashboard: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case "GET_DASHBOARD":
      return {
        ...state,
        isLoaded: true,
        dashboard: action.payload,
      }
    case "GET_DASHBOARD_SUMMARY_LOADING":
      return {
        ...state,
        isDashboardSummaryLoaded: false,
      }
    case "GET_DASHBOARD_SUMMARY":
      return {
        ...state,
        isDashboardSummaryLoaded: true,
        dashboardSummary: action.payload,
      }
    case "GET_DASHBOARD_NOTICE":
      return {
        ...state,
        notice: action.payload,
      }
    case "GET_DASHBOARD_STATUS":
      return {
        ...state,
        status: action.payload,
      }
    case "CONFIRM_MANAGER_DEPOSIT":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          manager: {
            ...state.dashboard.manager,
            pendingDeposit: false,
            depositAmount: 0,
            depositID: "",
          },
        },
      }
    default:
      return state
  }
}
