import {
  ADD_CUSTOMER,
  GET_ALL_LINE_USERS,
  GET_LINEUSER_DETAIL,
  ADD_LINEUSER,
  UPDATE_LINEUSER,
  DELETE_LINEUSER,
  STATUS_UPDATE_LINEUSER,
  BILL_COLLECT_LINEUSER,
  BILL_REPORT_LINEUSER,
} from "../actions/types"

const intialState = {
  isLoaded: false,
  lineUsers: {},
  bills: [],
}

export default (state = intialState, action) => {
  switch (action.type) {
    case GET_ALL_LINE_USERS:
      return {
        ...state,
        isLoaded: true,
        lineUsers: action.payload,
      }

    case GET_LINEUSER_DETAIL:
      return {
        ...state,
        lineUserDetail: action.payload,
      }

    case BILL_REPORT_LINEUSER:
      return {
        ...state,
        isLoaded: true,
        bills: action.payload,
      }

    case ADD_LINEUSER:
      return {
        ...state,
        lineUsers: {
          ...state.lineUsers,
          unpaid: [...state.lineUsers.unpaid, action.payload],
          unpaidCount: state.lineUsers.unpaidCount + 1,
        },
      }

    case UPDATE_LINEUSER:
      let index,
        item,
        types = ["unpaid", "paid", "partial", "advanced", "free", "inactive"]

      for (let i = 0; i < types.length; i++) {
        let type = types[i]
        index = state.lineUsers[type].findIndex((col) => col._id === action.payload._id)
        console.log(type, index)
        if (index > -1) {
          item = state.lineUsers[type][index]
          console.log(index, type, item, action.payload)
          if (type === "unpaid") {
            let updated = [
              ...state.lineUsers.unpaid.slice(0, index),
              action.payload,
              ...state.lineUsers.unpaid.slice(index + 1),
            ]
            return {
              ...state,
              lineUsers: {
                ...state.lineUsers,
                unpaid: updated,
                unpaidMonthlyBill: state.lineUsers.unpaidMonthlyBill - item.monthlyBill + action.payload.monthlyBill,
                unpaidNextBill: state.lineUsers.unpaidNextBill - item.nextBill + action.payload.nextBill,
              },
            }
          } else if (type === "partial") {
            let updated = [
              ...state.lineUsers.partial.slice(0, index),
              action.payload,
              ...state.lineUsers.partial.slice(index + 1),
            ]
            return {
              ...state,
              lineUsers: {
                ...state.lineUsers,
                partial: updated,
                partialMonthlyBill: state.lineUsers.partialMonthlyBill - item.monthlyBill + action.payload.monthlyBill,
                partialNextBill: state.lineUsers.partialNextBill - item.nextBill + action.payload.nextBill,
              },
            }
          } else {
            let updated = [
              ...state.lineUsers[type].slice(0, index),
              action.payload,
              ...state.lineUsers[type].slice(index + 1),
            ]
            console.log({
              ...state,
              lineUsers: {
                ...state.lineUsers,
                [`${type}`]: updated,
              },
            })

            return {
              ...state,
              lineUsers: {
                ...state.lineUsers,
                [`${type}`]: updated,
              },
            }
          }
        }
      }

    case DELETE_LINEUSER:
      let index2 = state.lineUsers.inactive.findIndex((col) => col._id === action.payload)
      state.lineUsers.inactive.splice(index2, 1)
      return {
        ...state,
        lineUsers: {
          ...state.lineUsers,
          inactive: state.lineUsers.inactive,
        },
      }

    case STATUS_UPDATE_LINEUSER:
      let item3, type3, index3

      if (action.payload.monthlyBill === 0) type3 = "free"
      else if (action.payload.currentMonthBS === "UNPAID") type3 = "unpaid"
      else if (action.payload.currentMonthBS === "PARTIAL") type3 = "partial"
      else if (action.payload.currentMonthBS === "FULL") type3 = "paid"
      else if (action.payload.currentMonthBS === "ADVANCED") type3 = "advanced"

      if (action.remark === "deactivate") {
        index3 = state.lineUsers[type3].findIndex((col) => col._id === action.payload._id)
        item3 = state.lineUsers[type3][index3]
        state.lineUsers[type3].splice(index3, 1) // remove from free/unpaid/paid/partial/advanced list
        state.lineUsers.inactive.push(item3) // insert into inactive list
      } else {
        index3 = state.lineUsers.inactive.findIndex((col) => col._id === action.payload._id)
        item3 = state.lineUsers.inactive[index3]
        state.lineUsers.inactive.splice(index3, 1) // remove from inactive list
        state.lineUsers[type3].push(item3) // insert into free/unpaid/paid/partial/advanced list
      }

      return {
        ...state,
        lineUsers: {
          ...state.lineUsers,
          inactive: state.lineUsers.inactive,
          [type3]: state.lineUsers[type3],
        },
      }

    case BILL_COLLECT_LINEUSER:
      let index4, removeFrom, insertTo, item4

      if (action.oldBS === "UNPAID") removeFrom = "unpaid"
      else if (action.oldBS === "PARTIAL") removeFrom = "partial"
      else if (action.oldBS === "FULL") removeFrom = "paid"
      else if (action.oldBS === "ADVANCED") removeFrom = "advanced"

      if (action.payload.currentMonthBS === "PARTIAL") insertTo = "partial"
      else if (action.payload.currentMonthBS === "FULL") insertTo = "paid"
      else if (action.payload.currentMonthBS === "ADVANCED") insertTo = "advanced"

      index4 = state.lineUsers[removeFrom].findIndex((col) => col._id === action.payload._id)
      item4 = state.lineUsers[removeFrom][index4]
      state.lineUsers[removeFrom].splice(index4, 1) // remove
      state.lineUsers[insertTo].push(action.payload) // insert

      console.log(
        {
          ...state,
          lineUsers: {
            ...state.lineUsers,
            [removeFrom]: state.lineUsers[removeFrom],
            [insertTo]: state.lineUsers[insertTo],
          },
        },
        action,
        removeFrom,
        insertTo,
        index4,
        item4
      )

      let lineUsers = {
        ...state.lineUsers,
        [removeFrom]: state.lineUsers[removeFrom],
        [insertTo]: state.lineUsers[insertTo],
      }

      if (action.oldBS === "UNPAID") {
        lineUsers.unpaidCount = lineUsers.unpaidCount - 1
        lineUsers.unpaidMonthlyBill -= item4.monthlyBill
        lineUsers.unpaidNextBill -= item4.nextBill
      }
      if (action.oldBS === "UNPAID" && action.payload.currentMonthBS === "PARTIAL") {
        lineUsers.partialCount = lineUsers.partialCount + 1
        lineUsers.partialMonthlyBill = lineUsers.partialMonthlyBill + item4.monthlyBill
        lineUsers.partialNextBill = lineUsers.partialMonthlyBill + action.payload.nextBill
      }

      // if item removed from partial
      if (action.oldBS === "PARTIAL" && action.payload.currentMonthBS !== "PARTIAL") {
        lineUsers.partialCount = lineUsers.partialCount - 1
        lineUsers.partialMonthlyBill -= item4.monthlyBill
        lineUsers.partialNextBill -= item4.nextBill
      }

      // if not removed
      if (action.oldBS === "PARTIAL" && action.payload.currentMonthBS === "PARTIAL") {
        lineUsers.partialNextBill = lineUsers.partialNextBill - parseInt(action.payload.amount)
      }

      return {
        ...state,
        lineUsers,
      }

    default:
      return state
  }
}
