import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import toastr from "toastr";
import moment from "moment";

import { SERVER_URL } from "../../config/config";
import { confirmDeposit } from "../../actions/dashboardActions";
import { monthsArr, formattedNumber, bdt } from "../../components/common/Utils";
import ShunnoITInvoiceModal from "./ShunnoITInvoiceModal";
class Payment extends Component {
  initPayment = () => {
    axios
      .post(SERVER_URL + "/api/pay/init", this.props.invoice)
      .then(res => {
        const { url, status, msg } = res.data;
        if (status) window.location.replace(url);
        else toastr.error(msg);
      })
      .catch(err => {
        toastr.error("Something went wrong!");
        console.log(err);
      });
  };

  render() {
    const { status, invoice } = this.props;

    return (
      <>
        {status.paymentLastDate || status.paymentStatus === "overdue" ? (
          <div className="col-md-12 text-center">
            <p className="card-text font-weight-bold text-danger">
              {status.txt}
            </p>
          </div>
        ) : (
          <div className="col-md-12 text-center">
            <p className="card-text font-weight-bold">{status.txt}</p>
          </div>
        )}
        <div className="col-md-4 text-center">
          <div className="clearfix ">
            <div className="card">
              <div className="card-body text-center text-primary">
                <p className="h4 card-text font-weight-bold">
                  {monthsArr[invoice?.month]} {invoice?.year}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 text-center">
          <div className="clearfix ">
            <div className="card text-center">
              <div className="card-body text-center">
                <p className="h4 card-text font-weight-bold text-primary">
                  বকেয়াঃ {bdt(status.amount)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 text-center">
          <div className="clearfix ">
            <div className="card text-center">
              <div className="card-body text-center text-primary">
                <p className="h5 card-text">পেমেন্টের শেষ তারিখ</p>
                <p className="h6 card-text font-weight-bold">
                  {moment(invoice.dueDate).format("DD-MM-YYYY hh:mm A")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <h4 className="">পেমেন্ট করুন</h4>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            className="btn"
            // onClick={this.initBkashPayment}
            id="bKash_button"
          >
            <h4 className="">
              <img
                src="../bkash_payment_logo.png"
                alt="Pay With bKash"
                width="200"
                className="shadow-light"
              />
            </h4>
          </button>
        </div>
        <div className="col-md-3">
          <button type="button" className="btn" onClick={this.initPayment}>
            <img
              src="../sslcommerz_payment_logo.png"
              alt="Pay With SSL Commerz"
              width="200"
              className="shadow-light"
            />
          </button>
        </div>

        <ShunnoITInvoiceModal
          invoice={{
            ...this.props.invoice,
            merchantInvoiceNumber: new Date().getTime()
          }}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  server: state.server
});

export default connect(mapStateToProps, {
  confirmDeposit
})(Payment);
