import React, { Component } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import { getAllCollectors } from "../../actions/collectorActions"
import { getAllExpenditures } from "../../actions/expenditureActions"
import { getAllLineUsers } from "../../actions/lineUserActions"
import { getManager } from "../../actions/profileActions"
import { getAllStaffs } from "../../actions/staffActions"
import CanvasJSReact from "../../components/common/canvasjs.react"
import { formattedNumber, toBangla } from "../../components/common/Utils"

var CanvasJSChart = CanvasJSReact.CanvasJSChart
const d = new Date()
const dt = new Date()

function getLineUsersFunc(lineUsers, that) {
  let collectedAmount = 0,
    totalMonthlyBill = 0,
    totalNextBill = 0,
    customerBillCollectionByManager = 0,
    securityMoney = 0

  let free = [],
    paid = [],
    unpaid = []

  let bills = [],
    lusers = []

  // check if this feeder has any manager
  if (lineUsers) {
    lusers = lineUsers

    let managerBillCollection = 0,
      collectorBillCollection = 0

    if (lusers) {
      console.log("luser", lusers)
      lusers.advanced.map((user) => {
        if (that.state.month.value === dt.getMonth() && that.state.year.value === dt.getFullYear() && user.status === 3)
          return

        if (that.state.month.value !== dt.getMonth() || that.state.year.value !== dt.getFullYear()) {
          if (user.status !== 3) {
            totalMonthlyBill += user.monthlyBill
            totalNextBill += user.nextBill
          }
        } else {
          totalMonthlyBill += user.monthlyBill
          totalNextBill += user.nextBill
        }

        if (user.billStatus) {
          for (let prop in user.billStatus) {
            let bills2 = user.billStatus[prop].split(",")

            bills2.map((bill2) => {
              let items = bill2.split("_")

              let pAt = new Date(items[3])

              if (pAt.getMonth() === that.state.month.value && pAt.getFullYear() === that.state.year.value) {
                if (items[1] === "S") return

                if (items[1] === "M" && items[4] === "SECURITY") {
                  securityMoney += parseInt(items[0])
                } else if (items[1] === "M" && items[4] !== "SECURITY") {
                  managerBillCollection += parseInt(items[0])
                } else if (items[1] === "C") {
                  collectorBillCollection += parseInt(items[0])
                }
              }
            })
          }
        }
      })

      that.setState({
        lineUsers2: lineUsers,
        unPaidLineUsers: unpaid,
        freeLineUsers: free,
        paidLineUsers: paid,
        lineUsers: unpaid,
        billsArray: bills,
        // dueAmount: totalAmount - collectedAmount,
        collectedAmount: collectedAmount,
        // totalAmount: totalAmount,

        customerBillCollectionByManager,
        securityMoney,
        managerBillCollection,
        collectorBillCollection,
        totalMonthlyBill,
        totalNextBill,
      })

      // set bills at redux store
      // this.props.setBills(bills)
    }
  }
}

function getCollectorsFunc(collectors, that) {
  let managerCollectionFromCollector = 0
  collectors.forEach((c) => {
    c.deposits.forEach((d) => {
      let depositedAt = new Date(d.depositedAt)
      if (
        d.status === "ACCEPTED" &&
        depositedAt.getMonth() === that.state.month.value &&
        depositedAt.getFullYear() === that.state.year.value
      ) {
        managerCollectionFromCollector += d.amount
      }
    })
  })

  that.setState({
    collectors: collectors,
    collectorsByArea: collectors,
    managerCollectionFromCollector: managerCollectionFromCollector,
  })
}

function getManagerFunc(manager, that) {
  let cableControllerCollection = 0
  let deposits = manager.deposits.slice().reverse()
  deposits.map((d) => {
    let depositedAt = new Date(d.depositedAt)
    if (
      d.status === "ACCEPTED" &&
      depositedAt.getMonth() === that.state.month.value &&
      depositedAt.getFullYear() === that.state.year.value
    ) {
      cableControllerCollection += d.amount
    }
  })

  that.setState({
    manager: manager,
    deposits: deposits,
    cableControllerCollection: cableControllerCollection,
  })
}

class Collection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalStaffSalary: 0,
      totalExpenditure: 0,
      manager: {},
      deposits: [],
      feeder: {},
      lineUsers: [],
      lineUsers2: [],
      unPaidLineUsers: [],
      paidLineUsers: [],
      freeLineUsers: [],
      type: "unpaid",
      billsArray: [],
      collectedAmount: 0,
      dueAmount: 0,
      totalAmount: 0,
      managerCollectionFromCollector: 0,
      cableControllerCollection: 0,
      customerBillCollectionByManager: 0,
      securityMoney: 0,
      managerBillCollection: 0,
      collectorBillCollection: 0,
      year: { label: toBangla(d.getFullYear()), value: d.getFullYear() },
      month: this.months[d.getMonth()],
      totalMonthlyBill: 0,
      totalNextBill: 0,
      address: "",
      errors: {},
      loading: false,
    }
  }
  months = [
    { label: "জানুয়ারী", value: 0 },
    { label: "ফেব্রুয়ারী", value: 1 },
    { label: "মার্চ", value: 2 },
    { label: "এপ্রিল", value: 3 },
    { label: "মে", value: 4 },
    { label: "জুন", value: 5 },
    { label: "জুলাই", value: 6 },
    { label: "আগস্ট", value: 7 },
    { label: "সেপ্টেম্বর", value: 8 },
    { label: "অক্টোবর", value: 9 },
    { label: "নভেম্বর", value: 10 },
    { label: "ডিসেম্বর", value: 11 },
  ]

  years = [
    { label: "২০২১", value: 2021 },
    { label: "২০২০", value: 2020 },
    { label: "২০১৯", value: 2019 },
  ]

  onSubmit = () => {
    this.setState({ loading: true })
    let totalExpenditure = 0
    this.props.expenditure.expenditures.map((row) => {
      let date2 = new Date(row.createdAt)
      if (date2.getFullYear() === this.state.year.value && date2.getMonth() === this.state.month.value) {
        totalExpenditure += row.amount
      }
    })
    this.setState({ totalExpenditure })
    getLineUsersFunc(this.state.lineUsers2, this)
    getManagerFunc(this.state.manager, this)
    getCollectorsFunc(this.state.collectors, this)
    this.setState({ loading: false })
  }
  componentDidMount() {
    console.log(this.props)
    if (!this.props.staff.isLoaded) {
      this.props.getAllStaffs()
    } else {
      let totalStaffSalary = 0

      this.props.staff.staffs?.staffs?.map((staff) => {
        staff.recievedSalaries.map((salary) => {
          if (d.getFullYear() === salary.year && d.getMonth() === salary.month) {
            totalStaffSalary += salary.amount
          }
        })
      })
      console.log(">>>", totalStaffSalary)
      this.setState({ totalStaffSalary })
    }
    if (!this.props.lineUser.isLoaded) {
      this.props.getAllLineUsers()
    }
    if (!this.props.collector.isLoaded) {
      this.props.getAllCollectors()
    }
    if (this.props.profile.isLoaded) {
      this.setState({ feeder: this.props.feeder })

      if (!this.props.profile.managerLoaded && !this.props.profile.managerLoading) {
        this.props.getManager(this.props.profile.profile.managers[0])
      }
      if (this.props.profile.managerLoaded) {
        getManagerFunc(this.props.profile.manager, this)
      }
    }
    if (this.props.lineUser.isLoaded) {
      getLineUsersFunc(this.props.lineUser.lineUsers, this)
    }

    if (this.props.collector.isLoaded) {
      getCollectorsFunc(this.props.collector.collectors, this)
    }
    if (!this.props.expenditure.isLoaded) {
      this.props.getAllExpenditures()
    } else {
      let totalExpenditure = 0
      this.props.expenditure.expenditures.map((row) => {
        let date2 = new Date(row.createdAt)
        if (date2.getFullYear() === this.state.year.value && date2.getMonth() === this.state.month.value) {
          totalExpenditure += row.amount
        }
      })
      this.setState({ totalExpenditure })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps)
    if (nextProps.staff.isLoaded) {
      let totalStaffSalary = 0
      nextProps.staff?.staffs?.staffs?.map((staff) => {
        staff.recievedSalaries.map((salary) => {
          if (d.getFullYear() === salary.year && d.getMonth() === salary.month) {
            totalStaffSalary += salary.amount
          }
        })
      })
      console.log(">>>", totalStaffSalary)
      this.setState({ totalStaffSalary })
    }

    if (nextProps.profile.isLoaded) {
      // network call
      if (!nextProps.profile.managerLoaded && !nextProps.profile.managerLoading) {
        this.props.getManager(nextProps.profile.profile.managers[0])
      }
      this.setState({ feeder: nextProps.feeder })

      // populate
      if (nextProps.profile.managerLoaded) {
        getManagerFunc(nextProps.profile.manager, this)
      }
    }
    if (nextProps.lineUser.isLoaded) {
      getLineUsersFunc(nextProps.lineUser.lineUsers, this)
    }

    if (this.props.collector.isLoaded) {
      getCollectorsFunc(nextProps.collector.collectors, this)
    }
    if (nextProps.expenditure.isLoaded) {
      let totalExpenditure = 0
      nextProps.expenditure.expenditures.map((row) => {
        let date2 = new Date(row.createdAt)
        if (date2.getFullYear() === this.state.year.value && date2.getMonth() === this.state.month.value) {
          totalExpenditure += row.amount
        }
      })
      this.setState({ totalExpenditure })
    }
  }

  render() {
    const { user } = this.props.auth
    const { profile } = this.props.auth.user

    let tCollection = 0,
      balance = 0

    tCollection =
      this.state.managerCollectionFromCollector + this.state.managerBillCollection + this.state.securityMoney

    balance = tCollection - this.state.cableControllerCollection

    let months = [
      "জানুয়ারী",
      "ফেব্রুয়ারী",
      "মার্চ",
      "এপ্রিল",
      "মে",
      "জুন",
      "জুলাই",
      "আগস্ট",
      "সেপ্টেম্বর",
      "অক্টোবর",
      "নভেম্বর",
      "ডিসেম্বর",
    ]

    let totalCollection =
      this.state.managerBillCollection + this.state.collectorBillCollection + this.state.securityMoney

    let totalAmount = this.state.managerBillCollection + this.state.collectorBillCollection + this.state.totalNextBill

    const options = {
      title: {
        // text: "কালেকশন সামারি"
      },
      data: [
        {
          // Change type to "column", "doughnut", "line", "splineArea", etc.
          type: "column",
          dataPoints: [
            { label: "সম্ভাব্য মোট বিল", y: totalAmount + this.state.securityMoney, color: "Blue" },
            { label: "কালেকশন", y: totalCollection, color: "Green" },
            { label: "বাকি", y: this.state.totalNextBill, color: "Red" },
          ],
        },
      ],
    }
    const options2 = {
      title: {
        // text: "কালেকশন সামারি"
      },
      data: [
        {
          // Change type to "column", "doughnut", "line", "splineArea", etc.
          type: "column",
          dataPoints: [
            { label: "ম্যানেজার", y: this.state.managerBillCollection },
            { label: "কালেক্টর", y: this.state.managerCollectionFromCollector, color: "Orange" },
            { label: "জামানত", y: this.state.securityMoney },
            { label: "ব্যালেন্স", y: balance },
            { label: "কন্ট্রোলার", y: this.state.cableControllerCollection },
          ],
        },
      ],
    }
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>কালেকশন</h4>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                {!this.state.loading && this.props.auth.isAuthenticated && this.props.profile.isLoaded ? (
                  <>
                    <div className="col-md-5">
                      <div className="form-group">
                        <Select
                          value={this.state.year}
                          options={this.years}
                          onChange={(year) => this.setState({ year })}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <Select
                          value={this.state.month}
                          options={this.months}
                          onChange={(month) => this.setState({ month })}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <button onClick={this.onSubmit} className="btn btn-success w-100">
                          সাবমিট
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <br />
                      <CanvasJSChart options={options} />
                    </div>

                    <div className="col-md-6">
                      <br />
                      <CanvasJSChart options={options2} />
                    </div>

                    <div className="col-md-3 text-center">
                      <br />
                      <br />
                      <h4>
                        {months[this.state.month.value]}, {this.state.year.value}
                      </h4>

                      <p>
                        সম্ভাব্য মোটঃ{" "}
                        <span style={{ color: "red" }}>
                          <strong>{formattedNumber(totalAmount + this.state.securityMoney)}</strong>
                        </span>{" "}
                        টাকা <br />
                        <small>
                          (গ্রাহক ফিঃ{" "}
                          {formattedNumber(
                            this.state.totalNextBill +
                              this.state.managerBillCollection +
                              this.state.collectorBillCollection
                          )}{" "}
                          জামানতঃ {formattedNumber(this.state.securityMoney)})
                        </small>
                      </p>
                    </div>
                    <div className="col-md-3 text-center">
                      <br />
                      <br />
                      <h4 className="">বিল কালেকশন</h4>
                      <p>
                        মোটঃ{" "}
                        <span style={{ color: "Green" }}>
                          <strong>{formattedNumber(totalCollection)}</strong>
                        </span>{" "}
                        টাকা
                        <br />
                        <small>
                          ম্যানেজারঃ {formattedNumber(this.state.managerBillCollection + this.state.securityMoney)} টাকা
                          (গ্রাহকঃ {formattedNumber(this.state.managerBillCollection)} জামানতঃ{" "}
                          {formattedNumber(this.state.securityMoney)}){" "}
                        </small>{" "}
                        <br />
                        <small>কালেক্টরঃ {formattedNumber(this.state.collectorBillCollection)} টাকা</small>
                      </p>
                      <p>
                        বকেয়াঃ{" "}
                        <span style={{ color: "red" }}>
                          <strong>{formattedNumber(this.state.totalNextBill)}</strong>
                        </span>{" "}
                        টাকা
                      </p>
                    </div>
                    <div className="col-md-3 text-center">
                      <br />
                      <br />
                      <h4>ডিপোজিট (ম্যানেজার)</h4>
                      <p>
                        মোট আদায়ঃ <strong>{formattedNumber(tCollection)}</strong> টাকা
                        <br />
                        <small>
                          (গ্রাহকঃ {formattedNumber(this.state.managerBillCollection)} কালেক্টরঃ{" "}
                          {formattedNumber(this.state.managerCollectionFromCollector)}&nbsp; জামানতঃ{" "}
                          {formattedNumber(this.state.securityMoney)})
                        </small>
                      </p>
                      <p>
                        মোট জমাঃ <strong>{formattedNumber(this.state.cableControllerCollection)}</strong> টাকা
                        <br />
                        মোট খরচ <strong>{formattedNumber(this.state.totalExpenditure)}</strong> টাকা
                        <br />
                        মোট স্টাফ স্যালারি <strong>{formattedNumber(this.state.totalStaffSalary)}</strong> টাকা
                        <br />
                        ব্যালেন্সঃ{" "}
                        {/* <span style={{ color: "red" }}>
                          <strong>
                            {formattedNumber(this.state.cableControllerCollection - this.state.totalExpenditure)}
                          </strong>
                        </span>{" "} */}
                        <span style={{ color: "red" }}>
                          <strong>
                            {formattedNumber(
                              tCollection -
                                this.state.totalExpenditure -
                                this.state.cableControllerCollection -
                                this.state.totalStaffSalary
                            )}
                          </strong>
                        </span>{" "}
                        টাকা
                      </p>
                    </div>
                    <div className="col-md-3 text-center">
                      <br />
                      <br />
                      <h4>এডমিন</h4>
                      <p className="pb-5">
                        মোট আদায়ঃ{" "}
                        <span style={{ color: "red" }}>
                          <strong>{formattedNumber(this.state.cableControllerCollection)}</strong>
                        </span>{" "}
                        টাকা
                      </p>
                    </div>
                  </>
                ) : (
                  <div class="fa-3x">
                    <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  feeder: state.profile.profile,
  profile: state.profile,
  collector: state.collector,
  lineUser: state.lineUser,
  expenditure: state.expenditure,
  staff: state.staff,
})

export default connect(mapStateToProps, {
  getAllCollectors,
  getAllLineUsers,
  getManager,
  getAllExpenditures,
  getAllStaffs,
})(Collection)
