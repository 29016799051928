import {
  ADD_RESELLER, GET_ALL_RESELLERS,


  RESELLER_ASSIGN_AREAS, UPDATE_RESELLER,

  UPDATE_RESELLER_NUMBER
} from "../actions/types";

const initialState = {
  isLoaded: false,
  resellers: [],
};

export default (state = initialState, action) => {
  let index, updated
  switch (action.type) {
    case GET_ALL_RESELLERS:
      return {
        isLoaded: true,
        resellers: action.payload,
      };
    case ADD_RESELLER:
      return {
        ...state,
        resellers: [...state.resellers, action.payload],
      };

    case UPDATE_RESELLER:
      index = state.resellers.findIndex(
        (col) => col._id === action.payload._id
      );
      updated = [
        ...state.resellers.slice(0, index),
        action.payload,
        ...state.resellers.slice(index + 1),
      ];
      return {
        ...state,
        resellers: updated,
      };

    case UPDATE_RESELLER_NUMBER:
      index = state.resellers.findIndex(
        (col) => col.userID === action.payload.userID
      );
      updated = [
        ...state.resellers.slice(0, index),
        { ...state.resellers[index], mobile: action.payload.newMobile },
        ...state.resellers.slice(index + 1),
      ];
      return {
        ...state,
        resellers: updated,
      };

    case RESELLER_ASSIGN_AREAS:
      index = state.resellers.findIndex(
        (col) => col._id === action.payload._id
      );

      updated = [
        ...state.resellers.slice(0, index),
        action.payload,
        ...state.resellers.slice(index + 1),
      ];

      return {
        ...state,
        resellers: updated,
      };

    default:
      return state;
  }
};
