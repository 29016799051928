import {
  ADD_COLLECTOR,
  COLLECTOR_ASSIGN_AREAS,
  GET_ALL_COLLECTORS,
  UPDATE_COLLECTOR,
  UPDATE_COLLECTOR_NUMBER,
  GET_COLLECTOR_DEPOSIT,
} from "../actions/types"

const initialState = {
  isLoaded: false,
  collectors: [],
  deposits: [],
}

export default (state = initialState, action) => {
  let index, updated
  switch (action.type) {
    case GET_ALL_COLLECTORS:
      return {
        isLoaded: true,
        collectors: action.payload,
      }

    case ADD_COLLECTOR:
      return {
        ...state,
        collectors: [...state.collectors, action.payload],
      }

    case UPDATE_COLLECTOR:
      index = state.collectors.findIndex((col) => col._id === action.payload._id)
      updated = [...state.collectors.slice(0, index), action.payload, ...state.collectors.slice(index + 1)]
      return {
        ...state,
        collectors: updated,
      }

    case UPDATE_COLLECTOR_NUMBER:
      index = state.collectors.findIndex((col) => col.userID === action.payload.userID)
      updated = [
        ...state.collectors.slice(0, index),
        { ...state.collectors[index], mobile: action.payload.newMobile },
        ...state.collectors.slice(index + 1),
      ]
      return {
        ...state,
        collectors: updated,
      }

    case COLLECTOR_ASSIGN_AREAS:
      index = state.collectors.findIndex((col) => col._id === action.payload._id)

      updated = [...state.collectors.slice(0, index), action.payload, ...state.collectors.slice(index + 1)]

      return {
        ...state,
        collectors: updated,
      }

    case GET_COLLECTOR_DEPOSIT:
      return {
        ...state,
        deposits: action.payload,
      }

    default:
      return state
  }
}
