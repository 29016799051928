import React, { Component } from "react"
import { connect } from "react-redux"
import Select from "react-select"
import toastr from "toastr"
import { checkSmsBeforeSend, getManager, sendSingleSMS } from "../../actions/profileActions"
import { CheckBox } from "../../components/common/CheckBox"

function isDoubleByte(str) {
  for (var i = 0, n = str.length; i < n; i++) {
    if (str.charCodeAt(i) > 255) {
      return true
    }
  }
  return false
}

class Message extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userType: { label: "গ্রাহক সিলেক্ট করুন", value: -1 },
      sendTo: { label: "সকল গ্রাহক", value: "all_customer" },
      area: { label: "সকল এরিয়া", value: "all_area" },
      message: "",
      msgCount: 0,
      letterCount: 0,
      feederType: "",
      mobile: "",
      sendSingleSms: false,
      areaOptions: [],
    }
  }

  componentDidMount() {
    if (this.props.profile.isLoaded && this.props.auth.user.type === "feeder") {
      if (!this.props.profile.managerLoaded && !this.props.profile.managerLoading)
        this.props.getManager(this.props.profile.profile.managers[0])
    }
      
    if (this.props.profile.isLoaded) {
      let tempAreas = [] 
      let feederType = this.props.auth.user.type === "feeder" ? this.props.auth.user.profile.feederType : this.props.auth.user.feeder.feederType
      if(this.props.auth.user.type === "feeder") {
        tempAreas = this.props.profile.profile.areas
      }
      else if(this.props.auth.user.type === "manager") {
        tempAreas = this.props.profile.feeder.areas
      }
      const areas =
        tempAreas &&
        tempAreas.map((area) => ({
          id: area._id,
          value: area.name,
          isChecked: false,
        }))

      this.setState({
        areas,
        feederType,
      })
    }

    // if (this.props.auth.user.type === "manager") {
    //   this.setState({
    //     feederType: this.props.auth.user.feeder.feederType,
    //   })
    // }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profile.isLoaded && nextProps.auth.user.type === "feeder") {
      if (!nextProps.profile.managerLoaded && !nextProps.profile.managerLoading)
        nextProps.getManager(nextProps.profile.profile.managers[0])
    }

    if (nextProps.profile.isLoaded) {
      let tempAreas = []   
      const feederType = this.props.auth.user.type === "feeder" ? nextProps.auth.user.profile.feederType : nextProps.auth.user.feeder.feederType 
      
      if(this.props.auth.user.type === "feeder") {
        tempAreas = nextProps.profile.profile.areas
      }
      else if(this.props.auth.user.type === "manager") {
        tempAreas = nextProps.profile.feeder.areas
      }

      const areas =
        tempAreas &&
        tempAreas.map((area) => ({
          id: area._id,
          value: area.name,
          isChecked: false,
        }))

      this.setState({
        areas,
        feederType,
      })
    }

    if (this.props.auth.user.type === "manager") {
      this.setState({
        feederType: this.props.auth.user.feeder.feederType,
      })
    }
  }

  handleAllChecked = (e) => {
    let areas = this.state.areas
    areas.forEach((area) => (area.isChecked = e.target.checked))
    this.setState({ areas })
  }

  handleCheckChieldElement = (e) => {
    let areas = this.state.areas
    areas.forEach((area) => {
      if (area.value === e.target.value) area.isChecked = e.target.checked
    })
    this.setState({ areas })
  }

  onChangeSendSMS = (e) => {
    let msg = e.target.value
    let isUnicode = isDoubleByte(msg)
    let singleSms = isUnicode ? 67 : 160
    let letterCount = [...msg].length
    let msgCount = Math.ceil(letterCount / singleSms)

    this.setState({
      [e.target.name]: e.target.value,
      msgCount,
      letterCount,
    })
  }

  onSubmit = () => {
    if (this.state.sendSingleSms) {
      if (!this.state.mobile) {
        toastr.error("মোবাইল নম্বর লিখুন। (ফরম্যাটঃ 01712456789)")
        return
      } else {
        const mobileRule = new RegExp(/(^(01){1}[3456789]{1}(\d){8})$/)
        if (!mobileRule.test(this.state.mobile)) {
          toastr.error("মোবাইল নম্বর সঠিক নয়। (সঠিক ফরম্যাটঃ 01712456789)")
          return
        }
      }
      if (this.state.message == "") {
        toastr.error("মেসেজ লিখুন")
        return
      }

      let data = {}
      data.message = this.state.message.trim()
      data.sendTo = this.state.mobile.trim()

      let con = window.confirm(`${data.sendTo}\nনম্বরে মেসেজ সেন্ড করতে চাইলে OK বাটনে ক্লিক করুন`)

      if (con) {
        this.props.sendSingleSMS(data)
      }
    } else {
      if (this.state.feederType === 3 && this.state.userType.value == -1) {
        toastr.error("গ্রাহক সিলেক্ট করুন")
        return
      }

      if (this.state.message == "") {
        toastr.error("মেসেজ লিখুন")
        return
      }

      let selectedAreas = []
      this.state.areas.filter((area) => {
        if (area.isChecked) {
          selectedAreas.push(area.id)
        }
      })

      let data = {}
      data.userType = this.state.userType.value
      data.sendTo = this.state.sendTo.value
      data.message = this.state.message
      data.areas = selectedAreas

      this.props.checkSmsBeforeSend(data)
    }
  }
  render() {
    const { feederType } = this.state
    const userTypeOptions = [
      {
        label: "গ্রাহক সিলেক্ট করুন",
        value: -1,
      },
      {
        label: "ডিশ + ইন্টারনেট",
        value: "both",
      },
      {
        label: "ডিশ",
        value: "dish",
      },
      {
        label: "ইন্টারনেট",
        value: "internet",
      },
    ]
    const sendToOptions = [
      {
        label: "সকল গ্রাহক",
        value: "all_customer",
      },
      {
        label: "ফ্রী গ্রাহক",
        value: "free",
      },
      {
        label: "বন্ধ গ্রাহক",
        value: "inactive",
      },
      {
        label: "বকেয়া",
        value: "unpaid",
      },
      {
        label: "আংশিক পরিশোধ",
        value: "partial_paid",
      },
      {
        label: "পরিশোধ",
        value: "full",
      },
      {
        label: "অগ্রীম পরিশোধ",
        value: "advanced",
      },
    ]
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white border-rounded text-center">
              <h4>মেসেজ বোর্ড</h4>
            </div>
            <div className="container">
              <div style={{ minHeight: 230 }} className="row justify-content-center p-3 bg-white align-items-center">
                {this.props.profile.isLoaded ? (
                  (this.props.auth.user.type === "manager" && this.props.profile.profile.roles?.includes("SEND_SMS")) ||
                  this.props.auth.user.type === "feeder" ? (
                    <>
                      <div className="col-md-6">
                        <h5 className="text-primary">যেভাবে আপনি গ্রাহকদের মোবাইলে মেসেজ দিবেনঃ </h5>

                        <p>
                          ১। যে গ্রাহকদের মেসেজ দিতে চান ড্রপডাউন মেনু থেকে সিলেক্ট করুন।
                          <br />
                          ২। মেসেজ বক্সে আপনার মেসেজ লিখুন এবং মেসেজের শেষে অবশ্যই <br /> আপনার{" "}
                          <strong>প্রতিষ্ঠানের নাম</strong> লিখুন অথবা <strong>সিগনেচার</strong> যুক্ত করে দিন (নিচের{" "}
                          <br /> মেসেজ বক্সে যেভাবে আছে)
                          <br />
                          ৩। এবার <strong>সেন্ড মেসেজ</strong> বাটনে ক্লিক করে একটু অপেক্ষা করুন।
                          <br />
                          ৪। কিছুক্ষণের মধ্যে আপনি দেখতে পাবেন কতজন গ্রাহক মেসেজ পাবে, <br /> মেসেজ সাইজ এবং কত টাকা
                          মেসেজের জন্য খরচ হবে।
                          <br />
                          ৫। এখন আপনি যদি মেসেজ দিতে চান তাহলে <strong>OK</strong> বাটনে ক্লিক করে <br /> কনফার্ম করুন
                          এবং একটু অপেক্ষা করুন।
                          <br />
                          ৬। আপনি একটি কনফার্মেশন মেসেজ দেখতে পাবেন। এবং স্বল্প <br /> সময়ের মধ্যে আপনার সম্মানিত
                          গ্রাহকদের মোবাইলে মেসেজ পৌঁছে যাবে।
                        </p>
                      </div>
                      <div className="col-md-6">
                        <h6 className="text-center">মেসেজ লিখুন</h6>
                        <hr />
                        <div className="form-group border border-primary p-2 w-100">
                          <input
                            type="checkbox"
                            name="sendSingleSms"
                            checked={this.state.sendSingleSms}
                            onClick={() =>
                              this.setState({
                                sendSingleSms: !this.state.sendSingleSms,
                              })
                            }
                          />
                          {"   "}একটি নির্দিষ্ট মোবাইল নম্বরে মেসেজ সেন্ড
                        </div>
                        {this.state.sendSingleSms ? (
                          <div className="form-group">
                            <input
                              className="form-control"
                              onChange={(e) => this.setState({ mobile: e.target.value })}
                              value={this.state.mobile}
                              type="text"
                              name="mobile"
                              maxLength="11"
                              placeholder="Mobile Number (Format: 01712456789)"
                              disabled={this.props.server.requestPending}
                            />
                          </div>
                        ) : (
                          <>
                            {feederType === 3 && (
                              <div className="form-group">
                                <Select
                                  value={this.state.userType}
                                  options={userTypeOptions}
                                  isDisabled={this.props.server.requestPending}
                                  onChange={(userType) => this.setState({ userType })}
                                />
                              </div>
                            )}
                            <div className="form-group">
                              <React.Fragment>
                                <input type="checkbox" onClick={this.handleAllChecked} value="checkedall" /> সকল এরিয়া
                                <ul>
                                  {this.state?.areas?.map((area) => {
                                    return (
                                      <CheckBox handleCheckChieldElement={this.handleCheckChieldElement} {...area} />
                                    )
                                  })}
                                </ul>
                              </React.Fragment>
                            </div>
                            <div className="form-group">
                              <Select
                                value={this.state.sendTo}
                                options={sendToOptions}
                                isDisabled={this.props.server.requestPending}
                                onChange={(sendTo) => this.setState({ sendTo })}
                              />
                            </div>
                          </>
                        )}
                        <div className="form-group">
                          <p className="text-right">
                            SMS: {this.state.msgCount} , অক্ষরঃ {this.state.letterCount}
                          </p>
                          <textarea
                            placeholder={`এখানে আপনার মেসেজ লিখুন। বক্সের উপরে ডান দিকে মেসেজ সাইজ ও অক্ষর সংখ্যা দেখতে পাবেন। - ${this.props.auth.user.profile.company}`}
                            className="w-100"
                            style={{ minHeight: "170px" }}
                            onChange={this.onChangeSendSMS}
                            value={this.state.message}
                            name="message"
                            disabled={this.props.server.requestPending}
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <button
                            onClick={this.onSubmit}
                            disabled={this.props.server.requestPending}
                            className="btn btn-success w-100"
                          >
                            সেন্ড মেসেজ
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <h4 className="text-danger">এডমিনের কাছে ম্যাসেজ করার পারমিশান নিন</h4>
                  )
                ) : (
                  <div className="fa-3x">
                    <i style={{ fontSize: 40 }} className="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile,
    server: state.server,
  }
}

export default connect(mapStateToProps, {
  sendSingleSMS,
  checkSmsBeforeSend,
  getManager,
})(Message)
