import React, { Component } from "react";
// import SidebarGlobal from "../../../js/SidebarGlobal";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import toastr from "toastr";
import "../../../js/js/scripts";
import "../../../js/js/stisla";
import store from "../../../store";
import { Data } from "./data";
import { SERVER_URL } from "../../../config/config";

let flag = true;

export class SideBar extends Component {
  feederUrls = [
    "/manager",
    "/",
    "/report",
    "/report/all",
    "/report/due",
    "/report/summary",
    "/report/collection-graph",
    "/report/webhook-message",
    "/report/message",
    "/expenditure",
    "/expenditure/purpose",
    "/staff",
    // "/collection",
    "/area",
    "/collector",
    "/customers",
    "/customers/active",
    "/customers/free",
    "/customers/inactive",
    "/customers/unpaid",
    "/customers/partial",
    "/customers/paid",
    "/customers/advanced",
    "/mikrotik",
    "/mikrotik/connections",
    "/deposit/collector",
    "/deposit/manager",
    "/tutorial"
  ];
  managerUrls = [
    "/",
    "/report",
    "/report/all",
    "/report/due",
    "/report/summary",
    "/report/collection-graph",
    "/report/webhook-message",
    "/report/message",
    "/expenditure",
    "/expenditure/purpose",
    "/collector",
    "/staff",
    "/customers",
    "/customers/active",
    "/customers/free",
    "/customers/inactive",
    "/customers/unpaid",
    "/customers/partial",
    "/customers/paid",
    "/customers/advanced",
    "/deposit/manager",
    "/deposit/collector",
    "/tutorial"
  ];

  operatorUrls = [
    "/report",
    "/report/all",
    "/report/due",
    "/",
    "/customers",
    "/customers/active",
    "/customers/free",
    "/customers/inactive",
    "/customers/unpaid",
    "/customers/partial",
    "/customers/paid",
    "/customers/advanced",
    "/tutorial"
  ];

  resellerUrls = [
    "/report",
    "/report/all",
    "/report/due",
    "/",
    "/customers",
    "/customers/active",
    "/customers/free",
    "/customers/inactive",
    "/customers/unpaid",
    "/customers/partial",
    "/customers/paid",
    "/customers/advanced",
    "/tutorial"
  ];

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (flag && nextProps.auth?.status?.paymentStatus === "overdue") {
      flag = false;
      let con = window.confirm(
        "\n" +
          "আপনার বিল প্রদানের শেষ তারিখ অতিবাহিত হয়েছে। দয়া করে বিল পরিশোধ করুন।" +
          "\n\n" +
          nextProps.auth?.status?.txt
      );

      if (con || !con) {
        this.initPayment(nextProps.auth.invoice);
      }
    }

    if (flag && nextProps.auth?.status?.paymentLastDate) {
      flag = false;
      window.confirm(
        "\n\n" +
          "বিল পরিশোধের আজকে শেষ দিন!" +
          "\n\n" +
          nextProps.auth?.status?.txt
      );
    }
  }

  initPayment = invoice => {
    axios
      .post(SERVER_URL + "/api/pay/init", invoice)
      .then(res => {
        const { url, status, msg } = res.data;
        if (status) window.location.replace(url);
        else toastr.error(msg);
      })
      .catch(err => {
        toastr.error("Something went wrong!");
        console.log(err);
      });
  };

  render() {
    var width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (store.getState().auth.user.profile.hasOperator === "yes")
      this.feederUrls.push("/operator");
    if (store.getState().auth.user.profile.hasReseller === "yes")
      this.feederUrls.push("/reseller");
    if (
      store.getState().profile.isLoaded &&
      store.getState().profile?.feeder?.bpSettings?.hasMikrotik !== "yes"
    ) {
      let index;
      index = this.feederUrls.indexOf("/mikrotik");
      if (index > -1) this.feederUrls.splice(index, 1);
      index = this.feederUrls.indexOf("/mikrotik/connections");
      if (index > -1) this.feederUrls.splice(index, 1);
    }

    let sidebarTitle = "বায়ান্ন পে";
    if (store.getState().auth.user.type === "feeder") {
      sidebarTitle = `বায়ান্ন পে (এডমিন)`;
    } else if (store.getState().auth.user.type === "manager") {
      sidebarTitle = `বায়ান্ন পে (ম্যানেজার)`;
    } else if (store.getState().auth.user.type === "operator") {
      sidebarTitle = `বায়ান্ন পে (অপারেটর)`;
    } else if (store.getState().auth.user.type === "reseller") {
      sidebarTitle = `বায়ান্ন পে (রিসেলার)`;
    }
    return (
      <div className="main-sidebar">
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
            <Link to="/"> {sidebarTitle} </Link>{" "}
          </div>{" "}
          <div className="sidebar-brand sidebar-brand-sm">
            <Link to="/"> BP </Link>{" "}
          </div>{" "}
          <ul className="sidebar-menu mb-5">
            {" "}
            {Data.menus.map((menu, iMenu) => {
              let comp;
              if (menu.header) {
                comp = (
                  <li key={iMenu} className="menu-header">
                    {" "}
                    {menu.name}{" "}
                  </li>
                );
              } else if (menu.dropdown) {
                // if (
                //   store.getState().auth.user.type !== "manager" &&
                //   menu.name === "বিল"
                // )
                //   return;
                if (store.getState().auth.user.type === "feeder") {
                  if (
                    menu.name === "মাইক্রোটিক" &&
                    this.props.profile.profile.bpSettings?.hasMikrotik === "no"
                  )
                    return;
                } else {
                  if (menu.name === "মাইক্রোটিক") return;
                }
                if (menu.active) {
                  comp = (
                    <li key={iMenu} className="nav-item dropdown active">
                      <a href="#" className="nav-link has-dropdown">
                        <i className={menu.icon} /> <span> {menu.name} </span>
                      </a>{" "}
                      <ul className="dropdown-menu">
                        {" "}
                        {menu.children.map((submenu, iSubmenu) => {
                          if (store.getState().auth.user.type === "feeder") {
                            if (!this.feederUrls.includes(submenu.url))
                              return false;
                          } else if (
                            store.getState().auth.user.type === "manager"
                          ) {
                            if (!this.managerUrls.includes(submenu.url))
                              return false;
                          } else if (
                            store.getState().auth.user.type === "operator"
                          ) {
                            if (!this.operatorUrls.includes(submenu.url))
                              return false;
                          } else if (
                            store.getState().auth.user.type === "reseller"
                          ) {
                            if (!this.resellerUrls.includes(submenu.url))
                              return false;
                          }
                          let subComp;
                          if (submenu.active) {
                            if (submenu.beep) {
                              subComp = (
                                <li key={iSubmenu} className="active">
                                  <NavLink
                                    activeStyle={{
                                      color: " #6777ef",
                                      fontWeight: "600"
                                    }}
                                    exact
                                    className="beep beep-sidebar"
                                    to={submenu.url}
                                  >
                                    {" "}
                                    {submenu.name}{" "}
                                  </NavLink>{" "}
                                </li>
                              );
                            } else {
                              subComp = (
                                <li key={iSubmenu}>
                                  <NavLink
                                    activeStyle={{
                                      color: " #6777ef",
                                      fontWeight: "600"
                                    }}
                                    exact
                                    to={submenu.url}
                                  >
                                    {" "}
                                    {submenu.name}{" "}
                                  </NavLink>{" "}
                                </li>
                              );
                            }
                          } else if (submenu.beep) {
                            subComp = (
                              <li key={iSubmenu}>
                                <NavLink
                                  activeStyle={{
                                    color: " #6777ef",
                                    fontWeight: "600"
                                  }}
                                  exact
                                  className="beep beep-sidebar"
                                  to={submenu.url}
                                >
                                  {" "}
                                  {submenu.name}{" "}
                                </NavLink>{" "}
                              </li>
                            );
                          } else {
                            subComp = (
                              <li key={iSubmenu}>
                                <NavLink
                                  activeStyle={{
                                    color: " #6777ef",
                                    fontWeight: "600"
                                  }}
                                  exact
                                  to={submenu.url}
                                >
                                  {" "}
                                  {submenu.name}{" "}
                                </NavLink>{" "}
                              </li>
                            );
                          }

                          return subComp;
                        })}{" "}
                      </ul>{" "}
                    </li>
                  );
                } else {
                  comp = (
                    <li
                      key={iMenu}
                      className="nav-item dropdown"
                      onClick={() => {
                        if (width < 992)
                          document
                            .querySelector('[data-toggle="sidebar"]')
                            .click();
                      }}
                    >
                      <a href="#" className="nav-link has-dropdown">
                        <i className={menu.icon} /> <span> {menu.name} </span>
                      </a>{" "}
                      <ul className="dropdown-menu">
                        {" "}
                        {menu.children.map((submenu, iSubmenu) => {
                          if (store.getState().auth.user.type === "feeder") {
                            if (!this.feederUrls.includes(submenu.url))
                              return false;
                          } else if (
                            store.getState().auth.user.type === "manager"
                          ) {
                            if (!this.managerUrls.includes(submenu.url))
                              return false;
                          } else if (
                            store.getState().auth.user.type === "operator"
                          ) {
                            if (!this.operatorUrls.includes(submenu.url))
                              return false;
                          } else if (
                            store.getState().auth.user.type === "reseller"
                          ) {
                            if (!this.resellerUrls.includes(submenu.url))
                              return false;
                          }
                          let subComp;
                          if (submenu.active) {
                            if (submenu.beep) {
                              subComp = (
                                <li key={iSubmenu} className="active">
                                  <NavLink
                                    activeStyle={{
                                      color: " #6777ef",
                                      fontWeight: "600"
                                    }}
                                    exact
                                    className="beep beep-sidebar"
                                    to={submenu.url}
                                  >
                                    {" "}
                                    {submenu.name}{" "}
                                  </NavLink>{" "}
                                </li>
                              );
                            } else {
                              subComp = (
                                <li key={iSubmenu} className="active">
                                  <NavLink
                                    activeStyle={{
                                      color: " #6777ef",
                                      fontWeight: "600"
                                    }}
                                    exact
                                    to={submenu.url}
                                  >
                                    {" "}
                                    {submenu.name}{" "}
                                  </NavLink>{" "}
                                </li>
                              );
                            }
                          } else if (submenu.beep) {
                            subComp = (
                              <li key={iSubmenu}>
                                <NavLink
                                  activeStyle={{
                                    color: " #6777ef",
                                    fontWeight: "600"
                                  }}
                                  exact
                                  className="beep beep-sidebar"
                                  to={submenu.url}
                                >
                                  {" "}
                                  {submenu.name}{" "}
                                </NavLink>{" "}
                              </li>
                            );
                          } else {
                            subComp = (
                              <li key={iSubmenu}>
                                <NavLink
                                  activeStyle={{
                                    color: " #6777ef",
                                    fontWeight: "600"
                                  }}
                                  exact
                                  to={submenu.url}
                                >
                                  <i
                                    style={{ marginRight: 0 }}
                                    className={submenu.icon}
                                  />
                                  {submenu.name}{" "}
                                </NavLink>{" "}
                              </li>
                            );
                          }

                          return subComp;
                        })}{" "}
                      </ul>{" "}
                    </li>
                  );
                }
              } else if (menu.active) {
                //
                comp = (
                  <li key={iMenu} className="s">
                    <NavLink
                      activeStyle={{
                        color: " #6777ef",
                        fontWeight: "600"
                      }}
                      exact
                      to={menu.url}
                    >
                      <i className={menu.icon} /> <span> {menu.name} </span>
                    </NavLink>{" "}
                  </li>
                );
              } else {
                //Single Component
                comp = (
                  <li
                    key={iMenu}
                    onClick={() => {
                      if (width < 992)
                        document
                          .querySelector('[data-toggle="sidebar"]')
                          .click();
                    }}
                  >
                    <NavLink
                      activeStyle={{
                        color: " #6777ef",
                        fontWeight: "600"
                      }}
                      exact
                      to={menu.url}
                    >
                      <i className={menu.icon} /> <span> {menu.name} </span>
                    </NavLink>{" "}
                  </li>
                );
              }
              if (
                store.getState().auth.user.type === "feeder" &&
                !menu.dropdown
              ) {
                if (!this.feederUrls.includes(menu.url)) return false;
              } else if (
                store.getState().auth.user.type === "manager" &&
                !menu.dropdown
              ) {
                if (!this.managerUrls.includes(menu.url)) return false;
              } else if (
                store.getState().auth.user.type === "operator" &&
                !menu.dropdown
              ) {
                if (!this.operatorUrls.includes(menu.url)) return false;
              } else if (
                store.getState().auth.user.type === "reseller" &&
                !menu.dropdown
              ) {
                if (!this.resellerUrls.includes(menu.url)) return false;
              }
              return comp;
            })}{" "}
          </ul>{" "}
        </aside>{" "}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(mapStateToProps, {})(SideBar);
