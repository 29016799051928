import React, { Component, createRef } from "react"
import Modal from "./Modal"
import toastr from "toastr"

class NumberChangeModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newMobile: "",
      newMobileAgain: "",
    }
    this.newMobileRef = createRef()
    this.newMobileAgainRef = createRef()
  }

  onChangeHandler = (e) => {
    if (e.target.value.length >= 12) return
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    let { newMobile, newMobileAgain } = this.state
    let data = {
      userID: this.props.data.userID,
      oldMobile: this.props.data.mobile,
      role: this.props.data.role,
      newMobile,
    }
    if (newMobile.length != 11) {
      toastr.error("মোবাইল নাম্বার সঠিক হয়নি")
      this.newMobileRef.current.focus()
      return
    } else if (newMobile != newMobileAgain) {
      toastr.error("মোবাইল নাম্বার ম্যাচ হয়নি")
      this.newMobileAgainRef.current.focus()
      return
    }
    this.props.action(data)
  }

  render() {
    return (
      <Modal onSubmit={this.onSubmit} pending={this.props.pending} id="changeMobile">
        <div class="row">
          <div class="col-sm">
            <div className="text-center mb-2">
              <i className="fas fa-user-circle fa-5x" title="avatar" />
            </div>
            <p className="text-center font-weight-bold  mb-0">{this.props.data.name}</p>
            <p className="text-center mb-0">{this.props.data.company}</p>
            <p className="text-center mb-0">{this.props.data.fullAddress || ""}</p>
            <p className="text-center  mb-2">{this.props.data.mobile}</p>
          </div>
          <div class="col-sm">
            <h5>মোবাইল নাম্বার পরিবর্তন করুন</h5>
            <br />
            <div className="form-group">
              <label htmlFor="mobile">মোবাইল</label>
              <input
                className="form-control"
                ref={this.newMobileRef}
                onChange={this.onChangeHandler}
                value={this.state.newMobile}
                type="text"
                name="newMobile"
                disabled={this.props.pending}
                placeholder="নতুন মোবাইল নাম্বার লিখুন"
              />
            </div>
            <div className="form-group">
              <label htmlFor="mobile">পুনরায় মোবাইল</label>
              <input
                className="form-control"
                ref={this.newMobileAgainRef}
                onChange={this.onChangeHandler}
                value={this.state.newMobileAgain}
                type="text"
                name="newMobileAgain"
                disabled={this.props.pending}
                placeholder="পুনরায় লিখুন"
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default NumberChangeModal
