import axios from "axios";
import $ from "jquery";
import toastr from "toastr";
import { SERVER_URL } from "../config/config";
import store from "../store";
import {
  GET_BILL_REPORT,
  LOADING,
  RESET,
  GET_ERRORS,
  GET_MANAGER_DEPOSIT,
  GET_DUE_REPORT,
  GET_INVOICES,
  GET_WEBHOOK_MESSAGE,
  GET_MESSAGE_LOG
} from "../actions/types";

export const getBillReport = (year, month, type) => dispatch => {
  dispatch({
    type: LOADING
  });

  let url = `${SERVER_URL}/api/bill/web`;
  if (year && (month === 0 || month))
    url = `${SERVER_URL}/api/bill/web?year=${year}&month=${month}`;
  else if (year) url = `${SERVER_URL}/api/bill/web?year=${year}`;

  axios
    .get(url)
    .then(res => {
      dispatch({
        type: RESET
      });
      const { status, data } = res.data;

      if (status) {
        dispatch({
          type: GET_BILL_REPORT,
          payload: data,
          flag: type
        });
      } else {
        toastr.error("Something Went Wrong!");
        dispatch({
          type: GET_ERRORS,
          payload: data
        });
      }
    })
    .catch(err => {
      console.log(err);
      toastr.error("রিপোর্ট লোড ব্যর্থ হয়েছে!!");
    });
};

export const getDeposits = (managerID, year, month) => dispatch => {
  dispatch({
    type: LOADING
  });
  axios
    .get(
      `${SERVER_URL}/api/deposit/manager?managerID=${managerID}&year=${year}&month=${month}&web=${true}`
    )
    .then(res => {
      dispatch({
        type: RESET
      });

      const { status, data } = res.data;
      if (status) {
        dispatch({
          type: GET_MANAGER_DEPOSIT,
          payload: data
        });
      } else {
        toastr.error("Something Went Wrong!");
        dispatch({
          type: GET_ERRORS,
          payload: data
        });
      }
    })
    .catch(err => toastr.error("ডিপোজিট লোড ব্যর্থ হয়েছে!!"));
};

export const getDueReport = (year, month) => dispatch => {
  dispatch({
    type: LOADING
  });

  axios
    .get(`${SERVER_URL}/api/reports/due?year=${year}&month=${month}`)
    .then(res => {
      dispatch({
        type: RESET
      });
      const { status, data } = res.data;

      if (status) {
        dispatch({
          type: GET_DUE_REPORT,
          payload: data
        });
      } else {
        toastr.error("Something Went Wrong!");
        dispatch({
          type: GET_ERRORS,
          payload: data
        });
      }
    })
    .catch(err => {
      console.log(err);
      toastr.error("রিপোর্ট লোড ব্যর্থ হয়েছে!!");
    });
};

export const getInvoices = () => dispatch => {
  dispatch({
    type: LOADING
  });

  axios
    .get(`${SERVER_URL}/api/reports/invoices`)
    .then(res => {
      dispatch({
        type: RESET
      });
      const { status, data } = res.data;

      if (status) {
        dispatch({
          type: GET_INVOICES,
          payload: data
        });
      } else {
        toastr.error("Something Went Wrong!");
        dispatch({
          type: GET_ERRORS,
          payload: data
        });
      }
    })
    .catch(err => {
      console.log(err);
      toastr.error("ইনভয়েস লোড ব্যর্থ হয়েছে!!");
    });
};

export const getWebhookMessages = id => dispatch => {
  let api = `https://shunnoit.top/shunno-payment/api/v1/webhooks/messages?clientApp=BAYANNOPAY&shunnoId=${id}&sort=dsc&limit=100`;

  let config = {
    headers: {
      Authorization:
        "Bearer " +
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJtMHhmY3lreCIsImlhdCI6MTcyNjAzMjc5MiwiZXhwIjo5MDA3MTk5MjU0NzQwOTkxLCJ0eXBlIjoiQVBJX0tFWSJ9.duw_sTEaf0lAdBjLG-ecHBJQ9fL9wx7KZVfPaZXUQYE"
    }
  };

  axios
    .get(api, config)
    .then(res => {
      const { status, data } = res.data;
      console.log(data.data);
      if (status) {
        dispatch({
          type: GET_WEBHOOK_MESSAGE,
          payload: data.data
        });
      } else {
        toastr.error("Something Went Wrong!");
        dispatch({
          type: GET_ERRORS,
          payload: {}
        });
      }
    })
    .catch(err => {
      console.log(err);
      toastr.error("ওয়েবহুক মেসেজ লোড ব্যর্থ হয়েছে!!");
    });
};

export const getMessageLog = senderId => dispatch => {
  const api = `https://shunnoit.top/shunno-sms/bp/${senderId}?limit=${500}`;
  // const api = `http://localhost:4467/bp/${senderId}?limit=${500}`;

  axios
    .get(api)
    .then(res => {
      const { status, data } = res.data;
      console.log(res.data);
      if (status) {
        dispatch({
          type: GET_MESSAGE_LOG,
          payload: data
        });
      } else {
        toastr.error("Something Went Wrong!");
        dispatch({
          type: GET_ERRORS,
          payload: {}
        });
      }
    })
    .catch(err => {
      console.log(err);
      toastr.error("মেসেজ লগ লোড ব্যর্থ হয়েছে!!");
    });
};
