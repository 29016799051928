import moment from "moment";
import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import toastr from "toastr";
import {
  addExpenditures,
  deleteLastExpenditure,
  getAllExpenditures
} from "../../actions/expenditureActions";
import { getAllStaffs } from "../../actions/staffActions";
import { getManagerBalance } from "../../actions/profileActions";
import PdfComponent from "../../components/common/PdfComponent";
import { formattedNumber } from "../../components/common/Utils";
import Modal from "../../components/modals/Modal";
const { SearchBar } = Search;

class Expenditure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expenditurePurpose: { label: "খরচের খাত", value: -1 },
      expenditurePurposeForAdd: { label: "খরচের খাত", value: -1 },
      expenditurePurposeOptions: [],
      staff: { label: "সকল কর্মচারী", value: -1 },
      staffForAdd: { label: "সকল কর্মচারী", value: -1 },
      staffOptions: [],
      amount: "",
      totalTmpAmount: "",
      item: "",
      startDate: new Date(new Date().setDate(1)),
      endDate: new Date(),
      mainTableData: [],
      totalAmount: 0,
      tempTable: []
    };
  }

  componentDidMount() {
    console.log(this.props);
    if (this.props.profile.isLoaded) {
      let expenditurePurposeOptions = [{ label: "খরচের খাত", value: -1 }];
      this.props.expenditure.expenditures.map(purpose => {
        expenditurePurposeOptions.push({
          label: purpose.purposeName,
          value: purpose._id
        });
        return null;
      });
      this.setState({ expenditurePurposeOptions });
    }

    if (this.props.staff.isLoaded) {
      let staffOptions = [{ label: "সকল কর্মচারী", value: -1 }];
      this.props.staff.staffs.map(staff => {
        staffOptions.push({ label: staff.name, value: staff._id });
        return null;
      });
      this.setState({ staffOptions });
    } else {
      this.props.getAllStaffs();
    }

    if (!this.props.expenditure.isLoaded) this.props.getAllExpenditures();

    // if (!this.props.profile.isManagerBalanceLoaded && this.props.auth.user.type === "manager") {
    //   this.props.getManagerBalance()
    // }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    if (nextProps.profile.isLoaded) {
      let expenditurePurposeOptions = [{ label: "খরচের খাত", value: -1 }];
      nextProps.profile.feeder?.expenditurePurposes.map(purpose => {
        expenditurePurposeOptions.push({
          label: purpose.purposeName,
          value: purpose._id
        });
        return null;
      });
      this.setState({ expenditurePurposeOptions });
    }

    if (nextProps.staff.isLoaded) {
      let staffOptions = [{ label: "সকল কর্মচারী", value: -1 }];
      nextProps.staff.staffs.map(staff => {
        staffOptions.push({ label: staff.name, value: staff._id });
        return null;
      });
      this.setState({ staffOptions });
    }

    if (nextProps.expenditure.isLoaded) {
      let totalAmount = 0;
      let mainTableData = [];
      nextProps.expenditure.expenditures.map(row => {
        let sDate = this.state.startDate;
        let eDate = this.state.endDate;
        let startDate = new Date(
          sDate.getFullYear(),
          sDate.getMonth(),
          sDate.getDate()
        );
        let endDate = new Date(
          eDate.getFullYear(),
          eDate.getMonth(),
          eDate.getDate()
        );
        let tmpDate = new Date(row.createdAt);
        let date = new Date(
          tmpDate.getFullYear(),
          tmpDate.getMonth(),
          tmpDate.getDate()
        );
        if (date < startDate || endDate < date) return null;

        let obj = JSON.parse(row.purposeName);
        let key = Object.keys(obj)[0];
        let text = key + " (";
        for (let i = 0; i < obj[key].length; i++) {
          text += obj[key][i].item + "=" + obj[key][i].amount + ", ";
        }
        totalAmount += row.amount;
        mainTableData.push({
          amount: row.amount,
          createdAt: row.createdAt,
          details: text + ")",
          name: row.staffName
        });
        return null;
      });
      this.setState({ totalAmount, mainTableData });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevState) === JSON.stringify(this.state)) return;
    let totalAmount = 0;
    let mainTableData = [];
    this.props.expenditure.expenditures.map(row => {
      let sDate = this.state.startDate;
      let eDate = this.state.endDate;
      let startDate = new Date(
        sDate.getFullYear(),
        sDate.getMonth(),
        sDate.getDate()
      );
      let endDate = new Date(
        eDate.getFullYear(),
        eDate.getMonth(),
        eDate.getDate()
      );
      let tmpDate = new Date(row.createdAt);
      let date = new Date(
        tmpDate.getFullYear(),
        tmpDate.getMonth(),
        tmpDate.getDate()
      );
      // console.log(startDate, date, endDate)
      if (date < startDate || endDate < date) return null;
      if (
        this.state.staff.value !== -1 &&
        this.state.staff.value !== row.staffID
      )
        return null;
      let obj = JSON.parse(row.purposeName);
      let key = Object.keys(obj)[0];
      if (
        this.state.expenditurePurpose.value !== -1 &&
        this.state.expenditurePurpose.value !== row.purposeID
      )
        return null;
      let text = key + " (";
      for (let i = 0; i < obj[key].length; i++) {
        text += obj[key][i].item + "=" + obj[key][i].amount + ", ";
      }
      totalAmount += row.amount;
      mainTableData.push({
        amount: row.amount,
        createdAt: row.createdAt,
        details: text + ")",
        name: row.staffName
      });
      return null;
    });
    this.setState({ totalAmount, mainTableData });
  }
  onChangeHandler = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onAddToTempTable = e => {
    e.preventDefault();
    if (this.state.amount && this.state.item) {
      let tempTable = [...this.state.tempTable];
      let obj = {
        item: this.state.item,
        amount: this.state.amount
      };

      let totalTmpAmount = +this.state.totalTmpAmount + +this.state.amount;
      tempTable.push(obj);
      this.setState({ tempTable, totalTmpAmount, amount: "", item: "" });
    }
  };

  onSubmit = e => {
    e.preventDefault();
    if (
      this.state.tempTable.length === 0 ||
      this.state.staffForAdd.value === -1 ||
      this.state.expenditurePurposeForAdd.value === -1
    ) {
      toastr.error("Failed! maybe any field is empty!");
      return;
    }
    let tempPurposeName = {
      [this.state.expenditurePurposeForAdd.label]: this.state.tempTable
    };
    let data = {
      staffType: "staff",
      staffName: this.state.staffForAdd.label,
      purposeName: JSON.stringify(tempPurposeName),
      amount: this.state.totalTmpAmount.toString(),
      purposeID: this.state.expenditurePurposeForAdd.value,
      staffID: this.state.staffForAdd.value
    };

    // if (!this.props.profile.isManagerBalanceLoaded) {
    //   toastr.error("আবার ট্রাই করুন")
    //   return
    // } else if (this.props.profile.managerBalance.balance < parseInt(this.state.totalTmpAmount)) {
    //   toastr.error("দুঃখিত, আপনার কাছে ব্যাল্যান্স নাই।")
    //   return
    // }

    this.props.addExpenditures(data);
  };

  resetModal = () => {
    this.setState({
      amount: "",
      totalTmpAmount: "",
      item: "",
      tempTable: [],
      staffForAdd: { label: "সকল কর্মচারী", value: -1 },
      expenditurePurposeForAdd: { label: "খরচের খাত", value: -1 }
    });
  };

  render() {
    let columns = [
      {
        dataField: "amount",
        text: "টাকা",
        sort: true,
        headerStyle: { width: "150px" },
        formatter: cell => `৳ ${cell}`
      },
      {
        dataField: "name",
        text: "নাম",
        sort: true,
        headerStyle: { width: "200px" }
      },
      {
        dataField: "details",
        text: "ডিটেইল"
      },
      {
        dataField: "createdAt",
        text: "সময়",
        formatExtraData: {
          pending: this.props.server.requestPending,
          data: this.props.expenditure.expenditures
        },
        formatter: (cell, row, rowIndex, extraData) => {
          let arr = extraData.data;
          let last = arr[arr.length - 1];
          if (new Date(last.createdAt).getTime() === new Date(cell).getTime())
            return (
              <>
                {moment(cell).format("DD/MM/yyyy hh:mm A")}{" "}
                <button
                  type="button"
                  disabled={extraData.pending}
                  className="btn btn-danger"
                  onClick={() => this.props.deleteLastExpenditure(last._id)}
                >
                  <i className="fas fa-trash"></i>
                </button>
              </>
            );
          return moment(cell).format("DD/MM/yyyy hh:mm A");
        },
        sort: true
      }
    ];
    let company =
      this.props.auth.user.type === "feeder"
        ? this.props.auth.user.profile.company
        : this.props.auth.user.feeder.company;
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>খরচ</h4>
            </div>
            <div className="container">
              <div
                style={{ minHeight: 230 }}
                className="row justify-content-center p-3 bg-white align-items-center"
              >
                {this.props.profile.isLoaded &&
                this.props.auth.isAuthenticated ? (
                  <div className="col-12">
                    <div className="clearfix py-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md">
                              <div className="form-group">
                                <DatePicker
                                  className="p-2 w-100"
                                  dateFormat="dd/MM/yyyy"
                                  selected={this.state.startDate}
                                  onChange={startDate =>
                                    this.setState({ startDate })
                                  }
                                  selectsStart
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                />
                              </div>
                            </div>
                            <div className="col-md">
                              <div className="form-group">
                                <DatePicker
                                  className="p-2 w-100"
                                  dateFormat="dd/MM/yyyy"
                                  selected={this.state.endDate}
                                  onChange={endDate =>
                                    this.setState({ endDate })
                                  }
                                  selectsEnd
                                  startDate={this.state.startDate}
                                  endDate={this.state.endDate}
                                  minDate={this.state.startDate}
                                />
                              </div>
                            </div>

                            <div className="col-md">
                              <div className="form-group">
                                <Select
                                  value={this.state.staff}
                                  options={this.state.staffOptions}
                                  onChange={staff => this.setState({ staff })}
                                />
                              </div>
                            </div>

                            <div className="col-md">
                              <div className="form-group">
                                <Select
                                  value={this.state.expenditurePurpose}
                                  options={this.state.expenditurePurposeOptions}
                                  onChange={expenditurePurpose =>
                                    this.setState({ expenditurePurpose })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          {this.props.auth.user.type === "manager" && (
                            <div className="form-group">
                              <button
                                onClick={this.resetModal}
                                className="btn btn-success btn-block"
                                data-toggle="modal"
                                data-target="#Modal"
                              >
                                নতুন খরচ
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <PaginationProvider
                      pagination={paginationFactory({
                        sizePerPageList: [
                          { text: "10", value: 10 },
                          { text: "20", value: 20 },
                          { text: "50", value: 50 },
                          { text: "100", value: 100 },
                          { text: "200", value: 200 },
                          { text: "500", value: 500 },
                          { text: "1000", value: 1000 },
                          { text: "2000", value: 2000 },
                          {
                            text: "All",
                            value: this.props.expenditure.expenditures.length
                          }
                        ]
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          bootstrap4
                          keyField="createdAt"
                          data={this.state.mainTableData.reverse()}
                          columns={columns}
                          search
                        >
                          {props => (
                            <div>
                              <div className="row justify-content-between">
                                <div className="col-md-6">
                                  <strong>
                                    মোটঃ{" "}
                                    {formattedNumber(this.state.totalAmount)}{" "}
                                    টাকা
                                  </strong>
                                  <hr />
                                </div>
                                <div className="col-md-6 text-right">
                                  <SearchBar {...props.searchProps} />
                                  <CSVLink
                                    filename={`খরচ - ${company}.csv`}
                                    data={[
                                      ["টাকা", "নাম", "ডিটেইল", "সময়"],
                                      ...this.state.mainTableData.map(row => [
                                        row.amount,
                                        row.name,
                                        row.details,
                                        moment(row.createdAt).format(
                                          "DD/MM/yyyy hh:mm A"
                                        )
                                      ])
                                    ]}
                                  >
                                    <i
                                      style={{ fontSize: "25px" }}
                                      className="fas fa-file-csv ml-2"
                                    ></i>
                                  </CSVLink>
                                  <ReactToPrint
                                    trigger={() => (
                                      <button
                                        style={{
                                          border: "none",
                                          background: "none"
                                        }}
                                        type="button"
                                      >
                                        <i
                                          style={{ fontSize: "25px" }}
                                          className="fas fa-file-pdf"
                                        ></i>
                                      </button>
                                    )}
                                    content={() => this.componentRef}
                                  />
                                  <div style={{ display: "none" }}>
                                    <PdfComponent
                                      ref={el => (this.componentRef = el)}
                                    >
                                      <table
                                        style={{ color: "#000" }}
                                        className="table table-bordered mt-5 table-md"
                                      >
                                        <tbody>
                                          <tr>
                                            <td colSpan="4">
                                              <h5 className="text-center">
                                                {`Company: ${this.props.auth.user.profile?.company}`}
                                                <br />
                                                {`${
                                                  this.props.auth.user.profile
                                                    ?.address.house
                                                    ? this.props.auth.user
                                                        .profile?.address
                                                        .house + ", "
                                                    : ""
                                                }${
                                                  this.props.auth.user.profile
                                                    ?.address.road
                                                    ? this.props.auth.user
                                                        .profile?.address.road +
                                                      ", "
                                                    : ""
                                                }${
                                                  this.props.auth.user.profile
                                                    ?.address.area
                                                    ? this.props.auth.user
                                                        .profile?.address.area +
                                                      ", "
                                                    : ""
                                                }${
                                                  this.props.auth.user.profile
                                                    ?.address.thana
                                                    ? this.props.auth.user
                                                        .profile?.address
                                                        .thana + ", "
                                                    : ""
                                                }${
                                                  this.props.auth.user.profile
                                                    ?.address.district
                                                    ? this.props.auth.user
                                                        .profile?.address
                                                        .district
                                                    : ""
                                                }`}
                                                <br />
                                                Page: খরচ লিস্ট
                                              </h5>
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>টাকা</th>
                                            <th>নাম</th>
                                            <th>ডিটেইল</th>
                                            <th>সময়</th>
                                          </tr>

                                          {this.state.mainTableData.map(
                                            (row, idx) => {
                                              return (
                                                <tr key={idx}>
                                                  <td>{row.amount}</td>
                                                  <td>{row.name}</td>
                                                  <td>{row.details}</td>
                                                  <td>
                                                    {moment(
                                                      row.createdAt
                                                    ).format(
                                                      "DD/MM/yyyy hh:mm A"
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </PdfComponent>
                                  </div>
                                </div>
                              </div>

                              <BootstrapTable
                                striped
                                hover
                                {...props.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </div>
                ) : (
                  <div class="fa-3x">
                    <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        <Modal
          modalTitle="খরচ এড"
          onSubmit={this.onSubmit}
          pending={this.props.server.requestPending}
          id="Modal"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <Select
                  value={this.state.expenditurePurposeForAdd}
                  isDisabled={this.props.server.requestPending}
                  options={this.state.expenditurePurposeOptions}
                  onChange={expenditurePurposeForAdd =>
                    this.setState({ expenditurePurposeForAdd })
                  }
                />
              </div>
              <div className="form-group">
                <Select
                  value={this.state.staffForAdd}
                  isDisabled={this.props.server.requestPending}
                  options={this.state.staffOptions}
                  onChange={staffForAdd => this.setState({ staffForAdd })}
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.item}
                  type="text"
                  name="item"
                  maxLength="100"
                  placeholder="বিবরণ/আইটেম"
                  disabled={this.props.server.requestPending}
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.amount}
                  type="number"
                  name="amount"
                  maxLength="100"
                  placeholder="এমাউন্ট"
                  disabled={this.props.server.requestPending}
                />
              </div>
              <div className="form-group">
                <button
                  onClick={this.onAddToTempTable}
                  className="btn btn-success w-100"
                  disabled={this.props.server.requestPending}
                >
                  এড
                </button>
              </div>
            </div>
            <div className="col-md-6">
              {this.state.tempTable.map(row => (
                <p>
                  {row.item} - {row.amount}
                </p>
              ))}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile,
    server: state.server,
    staff: state.staff,
    auth: state.auth,
    expenditure: state.expenditure
  };
};

export default connect(mapStateToProps, {
  getAllStaffs,
  getAllExpenditures,
  addExpenditures,
  deleteLastExpenditure,
  getManagerBalance
})(Expenditure);
