export const formattedNumber = (x) => {
  if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  else return x
}
export const bdt = (x) => {
  if (x) {
    let temp = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return `৳ ${temp}`
  } else return `৳ ${x}`
}

var engToBn = { "0": "০", "1": "১", "2": "২", "3": "৩", "4": "৪", "5": "৫", "6": "৬", "7": "৭", "8": "৮", "9": "৯" }

export const toBangla = (num) => {
  var retStr = num.toString()
  for (var x in engToBn) {
    retStr = retStr.replace(new RegExp(x, "g"), engToBn[x])
  }
  return retStr
}
export const months = [
  { label: "-- মাস সিলেক্ট করুন --", value: -1 },
  { label: "জানুয়ারী", value: 0 },
  { label: "ফেব্রুয়ারী", value: 1 },
  { label: "মার্চ", value: 2 },
  { label: "এপ্রিল", value: 3 },
  { label: "মে", value: 4 },
  { label: "জুন", value: 5 },
  { label: "জুলাই", value: 6 },
  { label: "আগস্ট", value: 7 },
  { label: "সেপ্টেম্বর", value: 8 },
  { label: "অক্টোবর", value: 9 },
  { label: "নভেম্বর", value: 10 },
  { label: "ডিসেম্বর", value: 11 },
]

export const paymentStatus = {
  UNPAID: "বকেয়া",
  PARTIAL: "আংশিক পরিশোধ",
  FULL: "পরিশোধ",
  ADVANCED: "অগ্রীম পরিশোধ",
}

export const monthsArr = [
  "জানুয়ারী",
  "ফেব্রুয়ারী",
  "মার্চ",
  "এপ্রিল",
  "মে",
  "জুন",
  "জুলাই",
  "আগস্ট",
  "সেপ্টেম্বর",
  "অক্টোবর",
  "নভেম্বর",
  "ডিসেম্বর",
]

export const advanceMonth = [
  { label: "১ মাস", value: 1 },
  { label: "২ মাস", value: 2 },
  { label: "৩ মাস", value: 3 },
  { label: "৪ মাস", value: 4 },
  { label: "৫ মাস", value: 5 },
  { label: "৬ মাস", value: 6 },
  { label: "৭ মাস", value: 7 },
  { label: "৮ মাস", value: 8 },
  { label: "৯ মাস", value: 9 },
  { label: "১০ মাস", value: 10 },
  { label: "১১ মাস", value: 11 },
  { label: "১২ মাস", value: 12 },
]

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0)

export const numberCheck = (number) => {
  const numberRE = /^[0-9\b]+$/
  return numberRE.test(number)
}

export const rowStyleFormat = (row, rowIdx) => {
  return { backgroundColor: rowIdx % 2 === 0 ? "#EEEEEE" : "#FFFFFF" }
}

export const yearOptions = [
  { label: "২০২৩", value: 2023 },
  { label: "২০২২", value: 2022 },
  { label: "২০২১", value: 2021 },
  { label: "২০২০", value: 2020 },
  { label: "২০১৯", value: 2019 },
]

export const monthOptions = [
  { label: "জানুয়ারী", value: 0 },
  { label: "ফেব্রুয়ারী", value: 1 },
  { label: "মার্চ", value: 2 },
  { label: "এপ্রিল", value: 3 },
  { label: "মে", value: 4 },
  { label: "জুন", value: 5 },
  { label: "জুলাই", value: 6 },
  { label: "আগস্ট", value: 7 },
  { label: "সেপ্টেম্বর", value: 8 },
  { label: "অক্টোবর", value: 9 },
  { label: "নভেম্বর", value: 10 },
  { label: "ডিসেম্বর", value: 11 },
]
