import React from "react";

import { toBangla } from "../../common/Utils";

export default function Footer() {
  return (
    <footer className="main-footer" style={{ marginBottom: "50px" }}>
      <div className="footer-left">
        কপিরাইট &copy; {toBangla(new Date().getFullYear())}{" "}
        <div className="bullet" /> শূন্য আইটি
      </div>{" "}
      {/* <div className="footer-right"> 2.3 .0 </div>{" "} */}
    </footer>
  );
}
