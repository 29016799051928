import axios from "axios"
import $ from "jquery"
import toastr from "toastr"
import {
  ADD_COLLECTOR,
  COLLECTOR_ASSIGN_AREAS,
  GET_ALL_COLLECTORS,
  GET_ERRORS,
  LOADING,
  RESET,
  SET_PROFILE,
  UPDATE_COLLECTOR,
  GET_COLLECTOR_DEPOSIT,
} from "../actions/types"
import { SERVER_URL } from "../config/config"
import store from "../store"

export const getAllCollectors = () => (dispatch) => {
  let api
  if (store.getState().auth.user.type === "feeder") {
    api = `${SERVER_URL}/api/collectors/list`
  } else if (store.getState().auth.user.type === "manager") {
    api = `${SERVER_URL}/api/collectors/list`
  } else if (store.getState().auth.user.type === "operator") {
    api = `${SERVER_URL}/api/operators/get/collectors`
  }
  axios
    .get(api)
    .then((res) => {
      const { status, data } = res.data
      if (status) {
        dispatch({
          type: GET_ALL_COLLECTORS,
          payload: data,
        })
      } else {
        toastr.error("Something Went Wrong!")
        dispatch({
          type: GET_ERRORS,
          payload: data,
        })
      }
    })
    .catch((err) => toastr.error("কালেক্টর লোড ব্যর্থ হয়েছে!!"))
}

export const addCollector = (collectorData) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .post(`${SERVER_URL}/api/collectors/add`, collectorData)
    .then((res) => {
      let { status, data, err } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#CollectorAddEditModal").modal("hide")
        dispatch({
          type: ADD_COLLECTOR,
          payload: data,
        })
        toastr.success("কালেক্টর অ্যাড হয়েছে।")
      } else {
        for (const property in data) {
          toastr.error(`${data[property]}`)
        }

        if (err) {
          for (const property in err) {
            toastr.error(`${err[property]}`)
          }
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("কালেক্টর অ্যাড সফল হয়নি।")
      console.log(err)
    })
}

export const updateCollector = (collector) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(`${SERVER_URL}/api/collectors/edit/${collector._id}`, collector)
    .then((res) => {
      let { status, data, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#CollectorAddEditModal").modal("hide")
        dispatch({
          type: UPDATE_COLLECTOR,
          payload: data,
        })
        toastr.success("কালেক্টর আপডেট হয়েছে।")
      } else {
        for (const property in data) {
          toastr.error(`${data[property]}`)
        }
        if (msg) toastr.error(msg)
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("কালেক্টর আপডেট সফল হয়নি।")
    })
}

export const assignAreasToCollector = (areas, id) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(`${SERVER_URL}/api/collectors/assign/areas/${id}`, areas)
    .then((res) => {
      let { status, data, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#areaAssignModal").modal("hide")
        dispatch({
          type: COLLECTOR_ASSIGN_AREAS,
          payload: data,
        })
        toastr.success(msg)
      } else {
        for (const property in data) toastr.error(`${data[property]}`)
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("আপডেট সফল হয়নি!")
    })
}

export const assignRolesToCollector = (roles, id) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(`${SERVER_URL}/api/collectors/edit/role/${id}`, roles)
    .then((res) => {
      let { status, data, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#profileModal").modal("hide")
        dispatch({
          type: UPDATE_COLLECTOR,
          payload: data,
        })
        toastr.success(msg)
      } else {
        for (const property in data) toastr.error(`${data[property]}`)
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("আপডেট সফল হয়নি!")
    })
}

export const allowedCollectors = (params, profile, that) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(`${SERVER_URL}/api/collectors/allow`, params)
    .then((res) => {
      let { status, data, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#collectorSettingsModal").modal("hide")
        dispatch({
          type: SET_PROFILE,
          feeder: { ...profile, ...data },
        })
        toastr.success(msg)
      } else {
        for (const property in data) toastr.error(`${data[property]}`)
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("এক্টিভ লিস্ট আপডেট সফল হয়নি!")
    })
}

export const getDeposits = (collectorID, year, month) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .get(`${SERVER_URL}/api/deposit/collector?collectorID=${collectorID}&year=${year}&month=${month}&web=${true}`)
    .then((res) => {
      dispatch({
        type: RESET,
      })
      const { status, data } = res.data
      if (status) {
        dispatch({
          type: GET_COLLECTOR_DEPOSIT,
          payload: data,
        })
      } else {
        toastr.error("Something Went Wrong!")
        dispatch({
          type: GET_ERRORS,
          payload: data,
        })
      }
    })
    .catch((err) => {
      console.log(err)
      toastr.error("ডিপোজিট লোড ব্যর্থ হয়েছে!!")
    })
}
