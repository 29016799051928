import axios from "axios"
import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import toastr from "toastr"
import { SERVER_URL } from "../../config/config"
import { monthsArr, formattedNumber } from "../../components/common/Utils"

class Payment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      invoice: {},
    }
  }
  componentDidMount() {
    console.log(this.props)

    let feederID
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.type === "feeder") feederID = this.props.auth.user.profile.feederID
      else feederID = this.props.auth.user.feeder.feederID
    } else {
      feederID = this.props.match.params.short_id
    }

    this.setState({ loading: true })

    axios
      .get(SERVER_URL + "/api/pay/invoice/" + feederID)
      .then((res) => {
        const { status, msg, data } = res.data
        if (status) {
          console.log(data)
          if (this.props.auth.isAuthenticated) {
            data.userType = this.props.auth.user.type
            data.userID = this.props.auth.user.profile.userID
            data.mobile = this.props.auth.user.profile.mobile
            data.name = this.props.auth.user.profile.name
          }
          this.setState({ invoice: data, loading: false })
        } else {
          toastr.error("ইনভয়েস পাওয়া যায়নি।")
          this.setState({ loading: false })
        }
      })
      .catch((err) => {
        toastr.error("Something went wrong!")
        console.log(err)
      })
  }

  initPayment = () => {
    axios
      .post(SERVER_URL + "/api/pay/init", this.state.invoice)
      .then((res) => {
        const { url, status, msg } = res.data
        if (status) window.location.replace(url)
        else toastr.error(msg)
      })
      .catch((err) => {
        toastr.error("Something went wrong!")
        console.log(err)
      })
  }
  render() {
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="login-brand">
              <img src="../logo.png" alt="logo" width="100" className="shadow-light rounded-circle" />
            </div>
            <div className="container pt-4 pb-2 bg-white border-rounded text-center">
              <h4>পেমেন্ট</h4>
              <hr />
            </div>
            <div className="container">
              <div style={{ minHeight: 230 }} className="row justify-content-center p-3 bg-white align-items-center">
                {!this.state.loading ? (
                  <>
                    {this.state.invoice.amount ? (
                      <div className="col-md-12 text-center">
                        <h4>{this.state.invoice?.company}</h4>
                        <br />
                        <h4>
                          {monthsArr[this.state.invoice?.month]}, {this.state.invoice?.year}{" "}
                        </h4>
                        <hr />
                        <br />
                        {this.state.invoice?.prevDue ? (
                          <>
                            <h4>পূর্বের বকেয়াঃ {formattedNumber(this.state.invoice?.prevDue)} Tk</h4>
                            <h4>
                              বর্তমান বিলঃ {formattedNumber(this.state.invoice?.amount - this.state.invoice?.prevDue)}{" "}
                              Tk
                            </h4>
                            <h4>মোট বিলঃ {formattedNumber(this.state.invoice?.amount)} Tk</h4>
                          </>
                        ) : (
                          <h4>বকেয়াঃ {formattedNumber(this.state.invoice?.amount)} Tk</h4>
                        )}
                        <br />
                        <h4>
                          স্ট্যাটাসঃ{" "}
                          <span
                            className={
                              this.state.invoice?.status === "paid" ? "badge badge-success" : "badge badge-danger"
                            }
                          >
                            {this.state.invoice?.status}
                          </span>
                        </h4>
                        <br />
                        <br />
                      </div>
                    ) : (
                      <h4>কোন ইনভয়েস পাওয়া যায়নি</h4>
                    )}

                    {this.state.invoice?.status === "unpaid" && (
                      <div className="col-md-12 text-center">
                        <button type="button" className="btn btn-success p-2" onClick={this.initPayment}>
                          <h3 className="m-1">Pay Now!</h3>
                        </button>
                      </div>
                    )}

                    <div className="col-md-12 text-center">
                      <br />
                      <br />
                      <br />
                      <a href="/">Back To Home</a>
                      {/* <Link to="/">Back To Home</Link> */}
                    </div>
                  </>
                ) : (
                  <div className="fa-3x">
                    <i style={{ fontSize: 40 }} className="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile,
    server: state.server,
  }
}

export default connect(mapStateToProps)(Payment)
