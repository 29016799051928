import React, { Component } from "react"
import { connect } from "react-redux"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "moment"

import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

// Animation
import { easeQuadInOut } from "d3-ease"
import AnimatedProgressProvider from "./AnimatedProgressProvider"
import ChangingProgressProvider from "./ChangingProgressProvider"

import { getDashboard, confirmCollectorDeposit } from "../../actions/dashboardActions"

import { bdt } from "../../components/common/Utils"

const { SearchBar } = Search

const d = new Date()
class CollectorDeposit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collectors: this.props.collectors,
      manager: this.props.manager,
    }
  }

  componentDidMount() {
    this.setState({ collectors: this.props.collectors, manager: this.props.manager })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ collectors: nextProps.collectors, manager: nextProps.manager })
  }

  onConfirmDeposit(collector, status) {
    let conf = false
    if (status === "ACCEPTED") {
      conf = window.confirm("আপনি কি এই জমাটি গ্রহণ করতে চান?")
    }
    if (status === "REJECTED") {
      conf = window.confirm("আপনি কি এই জমাটি বাতিল করতে চান?")
    }
    if (!conf) return

    const confirmDepositData = {
      collectorID: collector._id,
      depositID: collector.depositID,
      status: status,
    }

    console.log(confirmDepositData)

    this.props.confirmCollectorDeposit(confirmDepositData)
  }

  render() {
    const { newCustomer, activeCollector, inactiveCollector } = this.state

    // return <h1>CollectorDeposit</h1>

    return (
      <>
        {this.state.collectors.map((collector) => {
          console.log(collector)
          return collector.pendingDepositFlag == true ? (
            <>
              <div className="col-md-4 text-center">
                <div className="clearfix">
                  <div className="card text-center">
                    <div className="card-body text-center">
                      <p className="h4 card-text font-weight-bold">{collector.name}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center">
                <div className="clearfix">
                  <div className="card text-center">
                    <div className="card-body text-center">
                      <p className="h4 card-text font-weight-bold">{bdt(collector.depositAmount)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 text-right">
                <button
                  type="button"
                  className="btn btn-success btn-lg"
                  style={{ fontSize: "1.3rem" }}
                  onClick={() => this.onConfirmDeposit(collector, "ACCEPTED")}
                  disabled={this.props.server.requestPending}
                >
                  &nbsp;&nbsp;&nbsp;গ্রহণ&nbsp;&nbsp;&nbsp;
                </button>
              </div>
              <div className="col-md-2 text-center">
                <button
                  type="button"
                  className="btn btn-danger btn-lg"
                  style={{ fontSize: "1.3rem" }}
                  onClick={() => this.onConfirmDeposit(collector, "REJECTED")}
                  disabled={this.props.server.requestPending}
                >
                  &nbsp;&nbsp;&nbsp;বাতিল&nbsp;&nbsp;&nbsp;
                </button>
              </div>
            </>
          ) : (
            ""
          )
        })}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  server: state.server,
  profile: state.profile,
})

export default connect(mapStateToProps, {
  confirmCollectorDeposit,
})(CollectorDeposit)
