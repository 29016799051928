import React, { Component } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { connect } from "react-redux"
import moment from "moment"
import axios from "axios"
import toastr from "toastr"

import { getInvoices } from "../../actions/reportActions"
import { monthsArr, formattedNumber, bdt } from "../../components/common/Utils"
import { SERVER_URL } from "../../config/config"

const { SearchBar } = Search

class Invoice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isInvoicesLoaded: false,
      invoices: [],
    }
  }

  componentDidMount() {
    const { report, getInvoices } = this.props

    if (!this.state.isInvoicesLoaded) {
      getInvoices()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.report.isInvoicesLoaded) {
      this.setState({ invoices: nextProps.report.invoices, isInvoicesLoaded: nextProps.report.isInvoicesLoaded })
    }
  }

  initPayment = (invoice) => {
    axios
      .post(SERVER_URL + "/api/pay/init", invoice)
      .then((res) => {
        const { url, status, msg } = res.data
        if (status) window.location.replace(url)
        else toastr.error(msg)
      })
      .catch((err) => {
        toastr.error("Something went wrong!")
        console.log(err)
      })
  }

  columns = [
    {
      dataField: "year",
      text: "বছর",
      style: { wordWrap: "break-word", paddingLeft: "10px", paddingRight: "10px" },
      sort: true,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "month",
      text: "মাস",
      style: { wordWrap: "break-word" },
      headerAlign: "center",
      align: "center",
      sort: true,
      formatter: (cell) => {
        return monthsArr[cell]
      },
    },
    {
      dataField: "amount",
      text: "এমাউন্ট",
      style: { wordWrap: "break-word", paddingLeft: "10px", paddingRight: "10px" },
      sort: true,
      headerAlign: "center",
      align: "center",
      formatter: (cell) => {
        return `${formattedNumber(cell)} Tk`
      },
    },
    {
      dataField: "dueDate",
      text: "লাস্ট ডেট",
      style: { wordWrap: "break-word", paddingLeft: "10px", paddingRight: "10px" },
      sort: true,
      headerAlign: "center",
      align: "center",
      formatter: (cell) => {
        return moment(cell).format("DD MMM YYYY hh:mm A")
      },
    },

    {
      dataField: "status",
      text: "স্ট্যাটাস",
      style: { wordWrap: "break-word", paddingLeft: "10px", paddingRight: "10px" },
      sort: true,
      headerAlign: "center",
      align: "center",
      formatter: (cell) => {
        return cell === "unpaid" ? (
          <span class="badge bg-danger text-white">{cell}</span>
        ) : (
          <span class="badge bg-success text-white">{cell}</span>
        )
      },
    },

    {
      dataField: "paidAt",
      text: "পেমেন্ট ডেট",
      style: { wordWrap: "break-word", paddingLeft: "10px", paddingRight: "10px" },
      sort: true,
      headerAlign: "center",
      align: "center",
      formatter: (cell, row) => {
        return row.status === "paid" ? (
          row.paidAt ? (
            cell ? (
              moment(cell).format("DD MMM YYYY hh:mm A")
            ) : (
              ""
            )
          ) : (
            moment(row.updatedAt).format("DD MMM YYYY hh:mm A")
          )
        ) : (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              this.initPayment(row)
            }}
          >
            <span className="">পেমেন্ট করুন</span>
          </button>
        )
      },
    },
  ]

  render() {
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>ইনভয়েস</h4>
            </div>
            <div className="container">
              <div style={{ minHeight: 230 }} className="row justify-content-center p-3 bg-white align-items-center">
                {this.state.isInvoicesLoaded ? (
                  <div className="col-12">
                    {/* <div className="clearfix py-3">
                      <h4 className="float-left">সকল এরিয়া</h4>
                      <button
                        onClick={() =>
                          this.setState({
                            name: "",
                            update: false,
                          })
                        }
                        className="btn btn-success float-right"
                        data-toggle="modal"
                        data-target="#Modal"
                      >
                        <i class="fas fa-plus" title="নতুন যোগ করুন"></i>
                      </button>
                    </div>
                    <hr /> */}

                    <PaginationProvider
                      pagination={paginationFactory({
                        sizePerPageList: [
                          { text: "10", value: 10 },
                          { text: "20", value: 20 },
                          { text: "50", value: 50 },
                          { text: "100", value: 100 },
                          {
                            text: "All",
                            value: this.state.invoices?.length,
                          },
                        ],
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          bootstrap4
                          keyField="_id"
                          data={this.state.invoices}
                          columns={this.columns}
                          search
                        >
                          {(props) => (
                            <div>
                              <div className="row justify-content-between">
                                <div className="col-md-4">
                                  <strong>মোটঃ {this.state.invoices.length}</strong>
                                </div>
                                <div className="col-md-6 text-right">
                                  <SearchBar {...props.searchProps} />
                                </div>
                              </div>

                              <BootstrapTable striped hover {...props.baseProps} {...paginationTableProps} />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </div>
                ) : (
                  <div class="fa-3x">
                    <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    server: state.server,
    report: state.report,
    auth: state.auth,
  }
}

export default connect(mapStateToProps, {
  getInvoices,
})(Invoice)
