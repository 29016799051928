import moment from "moment";
import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import { getAllCollectors } from "../../actions/collectorActions";
import { getBillReport } from "../../actions/reportActions";
import PdfComponent from "../../components/common/PdfComponent";
import { formattedNumber } from "../../utils/utils";
const { SearchBar } = Search;
const d = new Date();
const firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
const fy = d.getFullYear();
const month = d.getMonth();

let _due = {};
for (let y = 2020; y <= fy; y++) {
  for (let m = 0; m <= 11; m++) {
    _due[y + "_" + m] = true;
    if (y === fy && m === d.getMonth()) break;
  }
}
let dt = new Date();

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collector: { label: "সকল কালেক্টর", value: -1 },
      collectorsOption: [],
      area: { label: "সকল এরিয়া", value: -1 },
      areaOptions: [],
      startDate: firstDay,
      endDate: new Date(),
      bills: [],
      _due,
      date: -1,
      collectorReport: {},
      dueUsers: [],
      filterDishOrIsp: { label: "ডিশ এবং ইন্টারনেট", value: "all" },
      filterBill: { label: "মোট", value: "total" },
      year: fy,
      month: month,
      bills: [],
      bills2: []
    };
  }

  componentDidMount() {
    if (!this.state?.report?.isLoaded) {
      this.props.getBillReport(this.state.year, this.state.month);
    }
    if (!this.props.collector.isLoaded) {
      this.props.getAllCollectors();
    } else {
      // console.log("check>>>>>", this.props.auth.user)
      let collectorObj;
      if (
        this.props.auth.user.type === "operator" ||
        this.props.auth.user.type === "reseller"
      ) {
        collectorObj = {
          value: this.props.auth.user.manager.userID,
          label: this.props.auth.user.manager.name
        };
      } else if (this.props.auth.user.type === "manager") {
        collectorObj = {
          value: this.props.auth.user.id,
          label: this.props.auth.user.profile.name
        };
      } else if (this.props.auth.user.type === "feeder") {
        collectorObj = {
          value: this.props.auth.user.profile.managerUserID,
          label: this.props.auth.user.profile.managerName
        };
      }
      let collectorsOption = [
        { label: "সকল কালেক্টর", value: -1 },
        { label: "অনলাইন পেমেন্ট", value: 1 },
        collectorObj
      ];
      this.props.collector.collectors.map(col => {
        collectorsOption.push({ label: col.name, value: col.userID });
      });
      this.setState({ collectorsOption });
    }

    if (this.props.profile.isLoaded) {
      console.log(this.props);

      let areaOptions = [{ label: "সকল এরিয়া", value: -1 }];
      this.props.profile.feeder.areas.map(area => {
        areaOptions.push({ label: area.name, value: area._id });
      });
      this.setState({ areaOptions });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let collectorObj;
    if (
      this.props.auth.user.type === "operator" ||
      this.props.auth.user.type === "reseller"
    ) {
      collectorObj = {
        value: this.props.auth.user.manager.userID,
        label: this.props.auth.user.manager.name
      };
    } else if (this.props.auth.user.type === "manager") {
      collectorObj = {
        value: this.props.auth.user.id,
        label: this.props.auth.user.profile.name
      };
    } else if (this.props.auth.user.type === "feeder") {
      collectorObj = {
        value: this.props.auth.user.profile.managerUserID,
        label: this.props.auth.user.profile.managerName
      };
    }
    let collectorsOption = [
      { label: "সকল কালেক্টর", value: -1 },
      { label: "অনলাইন পেমেন্ট", value: 1 },
      collectorObj
    ];
    let areaOptions = [{ label: "সকল এরিয়া", value: -1 }];

    if (nextProps.collector.isLoaded) {
      nextProps.collector.collectors.map(col => {
        collectorsOption.push({ label: col.name, value: col.userID });
      });
    }

    if (nextProps.profile.isLoaded) {
      nextProps.profile.feeder.areas.map(area => {
        areaOptions.push({ label: area.name, value: area._id });
      });
    }
    this.setState({ collectorsOption, areaOptions });

    if (nextProps.report.isLoaded && nextProps.report?.bills.length) {
      let totalCollection = 0,
        totalDue = 0,
        check = {};
      nextProps.report.bills.map(bill => {
        totalCollection += bill.amount;
        // check double bill collection of a user, if double then avoid previous due
        if (!check[bill?.lineuser?._id]) {
          check[bill?.lineuser?._id] = 1;
          totalDue += bill.due;
        } else check[bill?.lineuser?._id] = check[bill?.lineuser?._id] + 1;
      });

      this.setState({
        bills: nextProps.report.bills,
        bills2: nextProps.report.bills,
        totalCollection,
        totalDue
      });
    }
  }

  onSubmit = e => {
    e.preventDefault();
    let startTime = new Date(this.state.startDate);
    startTime.setHours(0, 0, 0);

    let endTime = new Date(this.state.endDate);
    endTime.setHours(23, 59, 59, 999);

    // console.log(startTime, endTime)

    startTime = startTime.getTime();
    endTime = endTime.getTime();

    let billsArr = [],
      totalCollection = 0,
      totalDue = 0,
      check = {};

    this.state.bills2.map(bill => {
      if (this.state.area.value === -1) {
      } else {
        if (bill?.lineuser?.assignedArea != this.state.area.value) return;
      }

      if (this.state.collector.value === -1) {
      } else if (this.state.collector.value === 1) {
        if (bill.userType !== "PG") return;
      } else {
        if (this.state.collector.value != bill.by) return;
      }

      if (this.state.filterDishOrIsp.value !== "all") {
        if (!bill?.lineuser?.connectionType) {
          if (this.state.filterDishOrIsp.value == 2) return;
        } else if (
          bill?.lineuser?.connectionType != this.state.filterDishOrIsp.value
        )
          return;
      }

      if (this.state.filterBill.value !== "total") {
        if (
          this.state.filterBill.value === "partial" &&
          bill.status !== "partial"
        )
          return;
        else if (
          this.state.filterBill.value === "paid" &&
          bill.status !== "paid"
        )
          return;
        else if (
          this.state.filterBill.value === "advanced" &&
          bill.status !== "advanced"
        )
          return;
        else if (
          this.state.filterBill.value === "security" &&
          bill.status !== "security"
        )
          return;
      }

      let paidAt = new Date(bill.paidAt);

      if (paidAt.getTime() >= startTime && paidAt.getTime() <= endTime) {
        totalCollection += bill.amount;
        billsArr.push(bill);
        // check double bill collection of a user, if double then avoid previous due
        if (!check[bill?.lineuser?._id]) {
          check[bill?.lineuser?._id] = 1;
          totalDue += bill.due;
        } else check[bill?.lineuser?._id] = check[bill?.lineuser?._id] + 1;
      }
    });

    this.setState({ bills: billsArr, totalCollection, totalDue });
  };

  columns = [
    {
      dataField: "userType",
      text: "",
      sort: true,
      style: {
        wordWrap: "break-word",
        paddingLeft: "2px",
        paddingRight: "2px"
      },
      // headerAlign: "center",
      align: "center",
      headerStyle: { width: "80px" },
      formatter: (cell, row) => {
        let x, y;
        if (row?.lineuser?.connectionType === 1) {
          x = <i className="fas fa-tv"></i>;
        } else if (row?.lineuser?.connectionType === 2) {
          x = <i className="fas fa-wifi"></i>;
        }
        y = cell === "PG" ? <i className="fa fa-paper-plane"></i> : "";
        return (
          <>
            {x} &nbsp; {y}
          </>
        );
      }
    },
    {
      dataField: "lineuser.customerID",
      text: "আইডি",
      sort: true
    },
    {
      dataField: "lineuser.name",
      text: "গ্রাহক",
      sort: true
    },
    {
      dataField: "amount",
      text: "আদায়",
      sort: true
    },
    {
      dataField: "due",
      text: "বাকী",
      sort: true
    },
    {
      dataField: "paidAt",
      text: "তারিখ",
      sort: true,
      formatter: cell => moment(cell).format("DD/MM/yyyy hh:mm A")
    }
  ];
  render() {
    let company =
      this.props.auth.user.type === "feeder"
        ? this.props.auth.user.profile.company
        : this.props.auth.user.feeder.company;
    let feederType =
      this.props.auth.user.type === "feeder"
        ? this.props.auth.user.profile.feederType
        : this.props.auth.user.feeder.feederType;

    const { bills } = this.state;

    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>বিল রিপোর্ট</h4>
            </div>
            <div className="container">
              <div className="row justify-content-center p-3 bg-white align-items-center">
                {this.props.collector.isLoaded &&
                this.props.profile.isLoaded &&
                this.props.report.isLoaded &&
                this.state.bills ? (
                  <div className="col-12">
                    <div className="clearfix py-2">
                      <div className="row">
                        {feederType === 3 && (
                          <div className="col-md-2">
                            <div className="form-group">
                              <Select
                                value={this.state.filterDishOrIsp}
                                options={[
                                  {
                                    label: "ডিশ এবং ইন্টারনেট",
                                    value: "all"
                                  },
                                  {
                                    label: "ডিশ",
                                    value: 1
                                  },
                                  {
                                    label: "ইন্টারনেট",
                                    value: 2
                                  }
                                ]}
                                onChange={filterDishOrIsp =>
                                  this.setState({
                                    filterDishOrIsp
                                  })
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="col-md-2">
                          <div className="form-group">
                            <Select
                              value={this.state.collector}
                              options={this.state.collectorsOption}
                              onChange={collector =>
                                this.setState({ collector })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="form-group">
                            <Select
                              value={this.state.area}
                              options={this.state.areaOptions}
                              onChange={area => this.setState({ area })}
                            />
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="form-group">
                            <Select
                              value={this.state.filterBill}
                              options={[
                                {
                                  label: "মোট",
                                  value: "all"
                                },
                                {
                                  label: "আংশিক",
                                  value: "partial"
                                },
                                {
                                  label: "পরিশোধ",
                                  value: "paid"
                                },
                                {
                                  label: "অগ্রীম",
                                  value: "advanced"
                                },
                                {
                                  label: "সংযোগ ফি",
                                  value: "security"
                                }
                              ]}
                              onChange={filterBill =>
                                this.setState({
                                  filterBill
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="form-group">
                            <DatePicker
                              className="form-control"
                              selected={this.state.startDate}
                              onChange={date =>
                                this.setState({ startDate: date })
                              }
                              maxDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="form-group">
                            <DatePicker
                              className="form-control"
                              selected={this.state.endDate}
                              onChange={date =>
                                this.setState({ endDate: date })
                              }
                              maxDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <button
                              onClick={this.onSubmit}
                              className="btn btn-success w-100"
                              data-toggle="modal"
                              data-target="#operatorSettingsModal"
                            >
                              সাবমিট
                            </button>
                          </div>
                        </div>
                      </div>

                      <PaginationProvider
                        pagination={paginationFactory({
                          sizePerPageList: [
                            { text: "50", value: 50 },
                            { text: "100", value: 100 },
                            { text: "200", value: 200 },
                            { text: "500", value: 500 },
                            { text: "1000", value: 1000 },
                            { text: "2000", value: 2000 }
                          ]
                        })}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            bootstrap4
                            keyField="paidAt"
                            data={bills}
                            columns={this.columns}
                            search
                          >
                            {props => (
                              <div>
                                <div className="row justify-content-between">
                                  <div className="col-md-7">
                                    <div className="mb-3">
                                      <strong>
                                        <div className="row">
                                          <div className="col-md-2">
                                            বিলঃ {bills.length}
                                          </div>
                                          <div className="col-md-3">
                                            আদায়ঃ{" "}
                                            {formattedNumber(
                                              this.state.totalCollection
                                            )}{" "}
                                            টাকা
                                          </div>
                                          <div className="col-md-3">
                                            বকেয়াঃ{" "}
                                            {formattedNumber(
                                              this.state.totalDue
                                            )}{" "}
                                            টাকা
                                          </div>
                                        </div>
                                        <hr />
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="col-md-5 text-right">
                                    <SearchBar {...props.searchProps} />
                                    <CSVLink
                                      filename={`বিল রিপোর্ট - ${company}.csv`}
                                      data={[
                                        [
                                          "আইডি",
                                          "গ্রাহক",
                                          "ঠিকানা",
                                          "মোবাইল",
                                          "আদায়",
                                          "বাকী",
                                          "তারিখ"
                                        ],
                                        ...bills.map(row => [
                                          row?.lineuser?.customerID,
                                          row?.lineuser?.name,
                                          row?.lineuser?.houseOwnerName,
                                          row?.lineuser?.mobile !==
                                          "01700000000"
                                            ? row?.lineuser?.mobile
                                            : "",
                                          row.amount,
                                          row.due,
                                          moment(row.paidAt).format(
                                            "DD/MM/yyyy hh:mm A"
                                          )
                                        ])
                                      ]}
                                    >
                                      <i
                                        style={{ fontSize: "25px" }}
                                        className="fas fa-file-csv ml-2"
                                      ></i>
                                    </CSVLink>
                                    <ReactToPrint
                                      trigger={() => (
                                        <button
                                          style={{
                                            border: "none",
                                            background: "none"
                                          }}
                                          type="button"
                                        >
                                          <i
                                            style={{ fontSize: "25px" }}
                                            className="fas fa-file-pdf"
                                          ></i>
                                        </button>
                                      )}
                                      content={() => this.componentRef}
                                    />
                                    <div style={{ display: "none" }}>
                                      <PdfComponent
                                        ref={el => (this.componentRef = el)}
                                      >
                                        <table
                                          style={{ color: "#000" }}
                                          className="table table-bordered mt-5 table-md"
                                        >
                                          <tbody>
                                            <tr>
                                              <td colSpan="2">
                                                <h5>
                                                  বিলঃ{" "}
                                                  {formattedNumber(
                                                    bills.length
                                                  )}
                                                  <br />
                                                  আদায়ঃ{" "}
                                                  {formattedNumber(
                                                    this.state.totalCollection
                                                  )}{" "}
                                                  টাকা
                                                  <br />
                                                  বকেয়াঃ{" "}
                                                  {formattedNumber(
                                                    this.state.totalDue
                                                  )}{" "}
                                                  টাকা
                                                </h5>
                                              </td>
                                              <td colSpan="5">
                                                <h4 className="text-center">
                                                  <u>বিল রিপোর্ট</u>
                                                </h4>
                                                <h5 className="text-center">
                                                  {`${this.props.auth.user.profile?.company}`}
                                                  <br />
                                                  {`${
                                                    this.props.auth.user.profile
                                                      ?.fullAddress
                                                      ? this.props.auth.user
                                                          .profile?.fullAddress
                                                      : ""
                                                  }`}
                                                  <br />
                                                  <span className="font-italic">
                                                    {moment(dt).format(
                                                      "DD/MM/yyyy hh:mm A"
                                                    )}
                                                  </span>
                                                </h5>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th>আইডি</th>
                                              <th>গ্রাহক</th>
                                              <th>ঠিকানা</th>
                                              <th>মোবাইল</th>
                                              <th>আদায়</th>
                                              <th>বাকী</th>
                                              <th>তারিখ</th>
                                            </tr>

                                            {bills.map((row, idx) => {
                                              return (
                                                <tr key={idx}>
                                                  <td>
                                                    {row?.lineuser?.customerID}
                                                  </td>
                                                  <td>{row?.lineuser?.name}</td>
                                                  <td>
                                                    {
                                                      row?.lineuser
                                                        ?.houseOwnerName
                                                    }
                                                  </td>
                                                  <td>
                                                    {row?.lineuser?.mobile !==
                                                      "01700000000" &&
                                                      row?.lineuser?.mobile}
                                                  </td>
                                                  <td>{row.amount}</td>
                                                  <td>{row.due}</td>
                                                  <td>
                                                    {moment(
                                                      row.createdAt
                                                    ).format(
                                                      "DD/MM/yyyy hh:mm A"
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </PdfComponent>
                                    </div>
                                  </div>
                                </div>

                                <BootstrapTable
                                  striped
                                  hover
                                  {...props.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </div>
                  </div>
                ) : (
                  <div className="fa-3x">
                    <i
                      style={{ fontSize: 40 }}
                      className="fas fa-sync fa-spin"
                    ></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile,
    server: state.server,
    collector: state.collector,
    report: state.report,
    auth: state.auth
  };
};

export default connect(mapStateToProps, {
  getAllCollectors,
  getBillReport
})(Report);
