import axios from "axios"
import $ from "jquery"
import toastr from "toastr"
import { ADD_EXPENDITURES, DELETE_EXPENDITURE, GET_ALL_EXPENDITURES, LOADING, RESET } from "../actions/types"
import { SERVER_URL } from "../config/config"

export const getAllExpenditures = () => (dispatch) => {
  axios
    .get(`${SERVER_URL}/api/expenditure/get/all`)
    .then((res) => {
      const { status, data } = res.data
      if (status) {
        dispatch({
          type: GET_ALL_EXPENDITURES,
          payload: data,
        })
      } else {
        toastr.error("Something Went Wrong!")
      }
    })
    .catch((err) => toastr.error("ব্যয় লোড ব্যর্থ হয়েছে!!"))
}

export const addExpenditures = (obj) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .post(`${SERVER_URL}/api/expenditure/add`, obj)
    .then((res) => {
      let { status, data, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#Modal").modal("hide")
        dispatch({
          type: ADD_EXPENDITURES,
          payload: data,
        })
        toastr.success("অ্যাড হয়েছে।")
      } else {
        for (const property in data) {
          toastr.error(`${data[property]}`)
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("আপডেট সফল হয়নি।")
    })
}


export const deleteLastExpenditure = id => dispatch => {
  dispatch({ type: LOADING })
  axios
    .delete(SERVER_URL + "/api/expenditure/delete/" + id)
    .then((res) => {
      dispatch({ type: RESET })
      const { success } = res.data
      dispatch({ type: DELETE_EXPENDITURE, payload: id })
      toastr.success(success)
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: RESET })
      toastr.error("Something went wrong")
    })
}