import axios from "axios"
import $ from "jquery"
import toastr from "toastr"
import {
  ADD_OPERATOR,
  GET_ALL_OPERATORS,
  LOADING,
  OPERATOR_ASSIGN_AREAS,
  RESET,
  SET_PROFILE,
  UPDATE_OPERATOR,
} from "../actions/types"
import { SERVER_URL } from "../config/config"

export const getAllOperators = () => (dispatch) => {
  axios
    .get(`${SERVER_URL}/api/operators/list`)
    .then((res) => {
      const { status, data } = res.data
      if (status) {
        dispatch({
          type: GET_ALL_OPERATORS,
          payload: data,
        })
      } else {
        toastr.error("Something Went Wrong!")
      }
    })
    .catch((err) => toastr.error("অপারেটর লোড ব্যর্থ হয়েছে!!"))
}

export const addOperator = (operatorData) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .post(`${SERVER_URL}/api/operators/add/`, operatorData)
    .then((res) => {
      let { status, data } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#Modal").modal("hide")
        dispatch({
          type: ADD_OPERATOR,
          payload: data,
        })
        toastr.success("অপারেটর অ্যাড হয়েছে।")
      } else {
        for (const property in data) {
          toastr.error(`${data[property]}`)
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("অপারেটর অ্যাড সফল হয়নি।")
    })
}

export const updateOperator = (operator) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(`${SERVER_URL}/api/operators/edit/${operator._id}`, operator)
    .then((res) => {
      let { status, data } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#Modal").modal("hide")
        dispatch({
          type: UPDATE_OPERATOR,
          payload: data,
        })
        toastr.success("অপারেটর আপডেট হয়েছে।")
      } else {
        for (const property in data) {
          toastr.error(`${data[property]}`)
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("অপারেটর আপডেট সফল হয়নি।")
    })
}

export const allowedOperators = (params, profile) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(`${SERVER_URL}/api/operators/allow/`, params)
    .then((res) => {
      let { status, data, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#operatorSettingsModal").modal("hide")
        dispatch({
          type: SET_PROFILE,
          payload: { ...profile, ...data },
          feeder: { ...profile, ...data },
        })
        toastr.success(msg)
      } else {
        for (const property in data) toastr.error(`${data[property]}`)
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("এক্টিভ অপারেটরের লিস্ট আপডেট সফল হয়নি!")
    })
}

export const assignAreasToOperator = (areas, id) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(`${SERVER_URL}/api/operators/assign/areas/${id}`, areas)
    .then((res) => {
      let { status, data, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#areaAssignModal").modal("hide")
        dispatch({
          type: OPERATOR_ASSIGN_AREAS,
          payload: data,
        })
        toastr.success(msg)
      } else {
        for (const property in data) toastr.error(`${data[property]}`)
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("আপডেট সফল হয়নি!")
    })
}
