import React, { Component } from "react"
import { connect } from "react-redux"
import { NavLink } from "react-router-dom"
import { logout } from "../../../actions/authActions"
import { formattedNumber } from "../../../utils/utils"

export class UserDropdown extends Component {
  render() {
    const { userDetail, auth, logout, profile } = this.props
    let userImg = userDetail.userImg,
      company = "",
      balance = ""
    if (profile.profile.photo) userImg = "data:image/png;base64, " + profile.profile.photo

    if (auth.user.type === "feeder") company = auth?.user?.profile?.company
    else company = auth?.user?.feeder?.company

    if (auth.user.type === "reseller") balance = profile.profile.balance

    let datas = [...userDetail.datas]
    if (auth.user.type === "operator") {
      datas = datas.filter((data) => data.link !== "/account")
    }

    return (
      <li className="dropdown">
        <a href="#" data-toggle="dropdown" className="nav-link dropdown-toggle nav-link-lg nav-link-user">
          <img style={{ height: "30px", width: "30px" }} alt="image" src={userImg} className="rounded-circle mr-2" />
          <div className="d-sm-none d-lg-inline-block">{company}</div>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          {auth.user.type === "reseller" && (
            <a className="dropdown-item has-icon">
              {" "}
              <i class="fas fa-lira-sign"></i> <strong>{formattedNumber(balance)}</strong>
              <hr />
            </a>
          )}
          {datas.map((data, idata) => {
            return (
              <NavLink
                key={idata}
                to={data.link}
                activeStyle={{
                  color: "#6777ef",
                }}
                exact
                className="dropdown-item has-icon"
              >
                <i className={data.icode} /> {data.title}
              </NavLink>
            )
          })}

          <div className="dropdown-divider" />
          <a href="#" className="dropdown-item has-icon text-danger" onClick={logout}>
            <i className={userDetail.logoutIcon} /> {userDetail.logoutTitle}
          </a>
        </div>
      </li>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile,
  }
}
export default connect(mapStateToProps, { logout })(UserDropdown)
