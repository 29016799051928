import moment from "moment"
import React, { Component, createRef } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { connect } from "react-redux"
import toastr from "toastr"
import { changeMobileNumber, resetPassword, sendSingleSMS } from "../../actions/profileActions"
import {
  addReseller,
  allowedResellers,
  assignAreasToReseller,
  deleteResellerRecharge,
  getAllResellers,
  resellerRecharge,
  updateReseller,
} from "../../actions/resellerActions"
import { CheckBox, CheckBoxOperatorSettings } from "../../components/common/CheckBox"
import { formattedNumber } from "../../components/common/Utils"
import AreaAssignModal from "../../components/modals/AreaAssignModal"
import Modal, { ProfileModal } from "../../components/modals/Modal"
import NumberChangeModal from "../../components/modals/NumberChangeModal"
import SendSmsModal from "../../components/modals/SendSmsModal"

const { SearchBar } = Search

const _ROLES = [
  {
    id: "VIEW_CUSTOMER_LIST",
    value: "গ্রাহকলিস্ট দেখতে পারবে",
    isChecked: false,
  },
  {
    id: "CUSTOMER_ADD",
    value: "নতুন গ্রাহক সংযোজন",
    isChecked: false,
  },
  {
    id: "CUSTOMER_EDIT",
    value: "গ্রাহক আপডেট",
    isChecked: false,
  },
  {
    id: "BILL_EDIT",
    value: "গ্রাহকের মাসিক ফি/বিল আপডেট",
    isChecked: false,
  },
  {
    id: "BILL_EDIT_INCREMENT",
    value: "গ্রাহকের মাসিক ফি/বিল আপডেট (শুধুমাত্র বৃদ্ধি করতে পারবে)",
    isChecked: false,
  },
  {
    id: "CUSTOMER_ACTIVATE",
    value: "গ্রাহক এক্টিভ",
    isChecked: false,
  },
  {
    id: "CUSTOMER_DEACTIVATE",
    value: "গ্রাহক বন্ধ",
    isChecked: false,
  },
  {
    id: "PRINT",
    value: "গ্রাহক প্রিন্ট",
    isChecked: false,
  },
  {
    id: "SEND_SMS",
    value: "সেন্ড এসএমএস",
    isChecked: false,
  },
]

function resetRoles() {
  _ROLES.map((role) => (role.isChecked = false))
}

class Reseller extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalTitle: "",
      resellerToModify: {},
      addReseller: false,
      resellersForSettings: [],
      amount: "",
    }

    this.nameRef = createRef()
    this.mobileRef = createRef()
  }
  componentDidMount() {
    const { reseller, getAllResellers } = this.props
    if (!reseller.isLoaded) {
      getAllResellers()
    }
  }
  onChangeHandler = (e) => {
    this.setState({
      resellerToModify: {
        ...this.state.resellerToModify,
        [e.target.name]: e.target.value,
      },
    })
  }

  handleAllChecked = (e) => {
    let roles = this.state.resellerToModify.roles
    roles.forEach((roles) => (roles.isChecked = e.target.checked))
    this.setState({
      resellerToModify: {
        ...this.state.resellerToModify,
        roles,
      },
    })
  }

  handleAllCheckedForSetting = (e) => {
    let resellers = this.state.resellersForSettings
    resellers.forEach((reseller) => (reseller.isChecked = e.target.checked))
    this.setState({ resellersForSettings: resellers })
  }

  handleCheckChieldElement = (e) => {
    let roles = this.state.resellerToModify.roles
    roles.forEach((role) => {
      if (role.value === e.target.value) role.isChecked = e.target.checked
    })
    this.setState({
      resellerToModify: {
        ...this.state.resellerToModify,
        roles,
      },
    })
  }

  handleCheckChieldElementForSetting = (e) => {
    let resellersForSettings = this.state.resellersForSettings
    resellersForSettings.forEach((reseller) => {
      if (reseller.value === e.target.value) reseller.isChecked = e.target.checked
    })
    this.setState({ resellersForSettings })
  }

  showAddResellerModal = () => {
    resetRoles()
    this.setState({
      modalTitle: "রিসেলার অ্যাড করুন",
      resellerToModify: {
        name: "",
        fatherName: "",
        mobile: "",
        email: "",
        fullAddress: "",
        flat: "",
        house: "",
        area: "",
        thana: "",
        district: "",
        nidNumber: "",
        referenceName: "",
        referenceMobile: "",
        roles: _ROLES,
      },
      addReseller: true,
    })
  }

  showEditResellerModal = (reseller) => {
    resetRoles()
    let roles = [..._ROLES]
    roles.forEach((role) => {
      if (reseller.roles.includes(role.id)) role.isChecked = true
    })
    this.setState({
      resellerToModify: {
        _id: reseller._id,
        name: reseller.name,
        fatherName: reseller.fatherName,
        mobile: reseller.mobile,
        email: reseller.email,
        balance: reseller.balance,
        fullAddress: reseller.fullAddress,
        flat: reseller.address?.flat,
        house: reseller.address?.house,
        area: reseller.address?.area,
        thana: reseller.address?.thana,
        district: reseller.address?.district,
        nidNumber: reseller.nid?.number,
        referenceName: reseller.reference?.name,
        referenceMobile: reseller.reference?.mobile,
        roles,
      },
      modalTitle: "রিসেলার এডিট করুন",
      addReseller: false,
    })
  }

  viewHandler = (reseller) => {
    this.setState({
      resellerToModify: {
        recharges: reseller.recharges,
        role: "reseller",
        userID: reseller.userID,
        _id: reseller._id,
        assignedAreas: reseller.assignedAreas,
        name: reseller.name,
        mobile: reseller.mobile,
        balance: reseller.balance,
        flat: reseller.address?.flat,
        house: reseller.address?.house,
        area: reseller.address?.area,
        thana: reseller.address?.thana,
        district: reseller.address?.district,
        createdAt: reseller.createdAt,
        company: this.props.profile.profile.company,
        signature:
          this.props.auth.user.profile.signature ||
          "সিগনেচার পাওয়া যায়নি। প্রোফাইলে কোম্পানীর নাম ও মোবাইল নম্বর ঘরটি পূরণ করুন।",
      },
    })
  }

  resellersForSettingsHandler = () => {
    let areas = this.props.profile.profile.areas
    let allowedResellers = this.props.profile.profile.allowedResellers
    let resellers = this.props.reseller.resellers
    let resellersForSettings = []

    resellers.map((reseller) => {
      let areaNames = ""
      reseller.assignedAreas.map((id) => {
        let temp = areas.find((area) => area._id == id)
        if (temp) areaNames += temp.name + ", "
      })

      resellersForSettings.push({
        id: reseller.userID,
        value: reseller.name,
        isChecked: false,
        areaNames,
      })
    })

    allowedResellers.map((id) => {
      resellersForSettings.map((col) => {
        if (col.id == id) col.isChecked = true
      })
    })
    this.setState({ resellersForSettings })
  }

  onSubmitHandler = (e) => {
    e.preventDefault()
    let { name, mobile } = this.state.resellerToModify
    if (!name) {
      toastr.error("নাম ফিল্ড পুরন করুন")
      this.nameRef.current.focus()
      return
    } else if (!mobile) {
      toastr.error("মোবাইল ফিল্ড পুরন করুন")
      this.mobileRef.current.focus()
      return
    }
    let roles = []
    this.state.resellerToModify.roles.filter((role) => {
      if (role.isChecked) {
        roles.push(role.id)
      }
    })
    if (this.state.addReseller) {
      this.props.addReseller({ ...this.state.resellerToModify, roles })
    } else {
      this.props.updateReseller({ ...this.state.resellerToModify, roles })
    }
  }

  onSubmitResellerSettings = (e) => {
    e.preventDefault()
    let allowedResellers = []
    this.state.resellersForSettings.filter((reseller) => {
      if (reseller.isChecked) allowedResellers.push(reseller.id)
    })
    this.props.allowedResellers({ allowedResellers }, this.props.profile.profile)
  }

  resetPassword = (row) => {
    let sure = window.confirm("Are you sure to reset password?")
    if (sure) this.props.resetPassword({ mobile: row.mobile })
  }

  onSubmitRecharge = (e) => {
    e.preventDefault()
    const { amount, resellerToModify } = this.state
    if (!amount) {
      toastr.error("Amount Not given")
      return
    } else if (!(amount > 99 && amount < 500000)) {
      toastr.error("Amoun must in 100 - 500000")
      return
    }
    this.props.resellerRecharge({ recharge: amount }, resellerToModify._id)
    this.setState({ amount: "" })
  }

  deleteResellerRecharge = (reseller_id, id) => {
    let conf = window.confirm("Are you sure to delete?")
    if (!conf) return
    this.props.deleteResellerRecharge(reseller_id, id)
  }

  columns = [
    {
      dataField: "name",
      text: "নাম",
      sort: true,
    },
    {
      dataField: "mobile",
      text: "মোবাইল",
      sort: true,
    },
    {
      dataField: "balance",
      text: "ব্যালান্স",
      sort: true,
      formatter: (cell) => formattedNumber(cell),
    },
    {
      dataField: "_id",
      text: "Action",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => (
        <ul className="navbar-nav navbar-right">
          <li className="dropdown">
            <a href="#" data-toggle="dropdown" className="nav-link nav-link-lg">
              <i className="fas fa-ellipsis-h" style={{ fontSize: 24 }}></i>
            </a>
            <div style={{ width: 50 }} className="dropdown-menu p-1">
              <a
                type="button"
                onClick={() => this.viewHandler(row)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#profileModal"
              >
                <i className="fas fa-id-card" />
                প্রোফাইল দেখুন
              </a>
              <a
                type="button"
                onClick={() => this.viewHandler(row)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#rechargeModal"
              >
                <i className="fas fa-dollar-sign" />
                রিচার্জ
              </a>
              <a
                type="button"
                onClick={() => this.viewHandler(row)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#sendSMSmodal"
              >
                <i className="fas fa-paper-plane" />
                সেন্ড এসএমএস
              </a>
              <a
                type="button"
                onClick={() => this.showEditResellerModal(row)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#ResellerAddModal"
              >
                <i className="fas fa-edit" />
                এডিট করুন
              </a>
              <a type="button" onClick={() => this.resetPassword(row)} className="dropdown-item has-icon">
                <i className="fas fa-fingerprint" />
                রিসেট পাসওয়ার্ড
              </a>
              <a
                type="button"
                onClick={() => this.viewHandler(row)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#changeMobile"
              >
                <i className="fas fa-mobile" />
                রিসেলার পরিবর্তন
              </a>
              <a
                type="button"
                onClick={() => this.viewHandler(row)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#areaAssignModal"
              >
                <i className="fas fa-map-marker-alt" />
                এরিয়া পরিবর্তন
              </a>
            </div>
          </li>
        </ul>
      ),
    },
  ]

  render() {
    let columnsForRecharge = [
      {
        // style: { height: "41px" },
        // headerStyle: { height: "41px" },
        dataField: "rechargeAmount",
        text: "এমাউন্ট",
        sort: true,
        headerAlign: "center",
        align: "center",
        formatter: (cell) => formattedNumber(cell),
      },
      {
        // style: { height: "41px" },
        // headerStyle: { height: "41px" },
        dataField: "createdAt",
        text: "তারিখ",
        sort: true,
        headerAlign: "center",
        align: "center",
        formatter: (cell) => moment(cell).format("DD/MM/yyyy hh:mm A"),
      },
      {
        // style: { height: "41px" },
        // headerStyle: { height: "41px" },
        dataField: "_id",
        text: "Action",
        formatExtraData: this.props.server.requestPending,
        headerAlign: "center",
        align: "center",
        formatter: (cell, row, rowIndex, requestPending) => {
          return (
            <div>
              <button
                type="button"
                disabled={requestPending || rowIndex > 0}
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteResellerRecharge(this.state.resellerToModify._id, cell)}
              >
                ডিলেট
              </button>
            </div>
          )
        },
      },
    ]
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>রিসেলার</h4>
            </div>
            <div className="container">
              <div style={{ minHeight: 230 }} className="row justify-content-center p-3 bg-white align-items-center">
                {this.props.reseller.isLoaded ? (
                  <div className="col-12">
                    <div className="clearfix py-3">
                      <h4 className="float-left">সকল রিসেলার</h4>
                      <button
                        onClick={this.resellersForSettingsHandler}
                        className="btn btn-success float-right"
                        data-toggle="modal"
                        data-target="#resellerSettingsModal"
                      >
                        <i class="fas fa-cog" title="রিসেলার সেটিংস"></i>
                      </button>

                      <button
                        onClick={this.showAddResellerModal}
                        className="btn btn-success float-right mx-2"
                        data-toggle="modal"
                        data-target="#ResellerAddModal"
                      >
                        <i class="fas fa-user-plus" title="নতুন রিসেলার"></i>
                      </button>
                    </div>
                    <hr />

                    <PaginationProvider
                      pagination={paginationFactory({
                        sizePerPageList: [
                          { text: "10", value: 10 },
                          { text: "20", value: 20 },
                          { text: "50", value: 50 },
                          { text: "100", value: 100 },
                          { text: "200", value: 200 },
                          { text: "500", value: 500 },
                          { text: "1000", value: 1000 },
                          { text: "2000", value: 2000 },
                          {
                            text: "All",
                            value: this.props.reseller.resellers.length,
                          },
                        ],
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          bootstrap4
                          keyField="_id"
                          data={this.props.reseller.resellers}
                          columns={this.columns}
                          search
                        >
                          {(props) => (
                            <div>
                              <div className="row justify-content-between">
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <strong>মোটঃ {this.props.reseller.resellers.length}</strong>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <SearchBar {...props.searchProps} />
                                </div>
                              </div>

                              <BootstrapTable striped hover {...props.baseProps} {...paginationTableProps} />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </div>
                ) : (
                  <div class="fa-3x">
                    <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>

        {/* add/edit modal */}
        <Modal
          modalTitle={this.state.modalTitle}
          onSubmit={this.onSubmitHandler}
          pending={this.props.server.requestPending}
          id="ResellerAddModal"
        >
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="name">নাম</label>
                <input
                  ref={this.nameRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.resellerToModify.name}
                  type="text"
                  name="name"
                  disabled={this.props.server.requestPending}
                />
              </div>

              <div className="form-group">
                <label htmlFor="mobile">মোবাইল</label>
                <input
                  ref={this.mobileRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.resellerToModify.mobile}
                  type="text"
                  name="mobile"
                  disabled={this.props.server.requestPending}
                />
              </div>

              <div className="form-group">
                <label htmlFor="fullAddress">ঠিকানা</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.resellerToModify.fullAddress}
                  type="text"
                  name="fullAddress"
                  disabled={this.props.server.requestPending}
                />
              </div>

              <div className="form-group">
                <label htmlFor="referenceName">রেফারেন্স নাম</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.resellerToModify.referenceName}
                  type="text"
                  name="referenceName"
                  disabled={this.props.server.requestPending}
                />
              </div>

              {/* <div className="form-group">
                <label htmlFor="flat">বাড়ী নং / মহল্লা</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.resellerToModify.flat}
                  type="text"
                  name="flat"
                  disabled={this.props.server.requestPending}
                />
              </div> */}
              {/* <div className="form-group">
                <label htmlFor="house">রোড নং / গ্রাম</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.resellerToModify.house}
                  type="text"
                  name="house"
                  disabled={this.props.server.requestPending}
                />
              </div> */}
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="fatherName">পিতার নাম</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.resellerToModify.fatherName}
                  type="text"
                  name="fatherName"
                  disabled={this.props.server.requestPending}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">ইমেইল</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.resellerToModify.email}
                  type="email"
                  name="email"
                  disabled={this.props.server.requestPending}
                />
              </div>

              <div className="form-group">
                <label htmlFor="nidNumber">জাতীয় পরিচয়পত্র নম্বর</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.resellerToModify.nidNumber}
                  type="text"
                  name="nidNumber"
                  disabled={this.props.server.requestPending}
                />
              </div>

              {/* <div className="form-group">
                <label htmlFor="area">পোষ্ট অফিস</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.resellerToModify.area}
                  type="text"
                  name="area"
                  disabled={this.props.server.requestPending}
                />
              </div> */}

              {/* <div className="form-group">
                <label htmlFor="thana">থানা</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.resellerToModify.thana}
                  type="text"
                  name="thana"
                  disabled={this.props.server.requestPending}
                />
              </div> */}

              {/* <div className="form-group">
                <label htmlFor="district">জেলা</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.resellerToModify.district}
                  type="text"
                  name="district"
                  disabled={this.props.server.requestPending}
                />
              </div> */}

              <div className="form-group">
                <label htmlFor="referenceMobile">রেফারেন্স মোবাইল</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={this.state.resellerToModify.referenceMobile}
                  type="text"
                  name="referenceMobile"
                  disabled={this.props.server.requestPending}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <React.Fragment>
                  <input type="checkbox" onClick={this.handleAllChecked} value="checkedall" /> সবগুলো
                  <ul>
                    {this.state.resellerToModify.roles &&
                      this.state.resellerToModify.roles.map((role) => {
                        return <CheckBox handleCheckChieldElement={this.handleCheckChieldElement} {...role} />
                      })}
                  </ul>
                </React.Fragment>
              </div>
            </div>
          </div>
        </Modal>

        {/* Profile Modal */}
        <ProfileModal id="profileModal" modalWidth="w-50">
          <div className="text-center mb-2">
            <i className="fas fa-user-circle fa-5x" title="avatar" />
          </div>
          <p className="text-center font-weight-bold  mb-0">{this.state.resellerToModify.name}</p>
          <p className="text-center mb-0">{this.state.resellerToModify.company}</p>
          <p className="text-center mb-0">
            {this.state.resellerToModify.flat + ", "}
            {this.state.resellerToModify.house + ", "}
            {this.state.resellerToModify.area + ", "}
            {this.state.resellerToModify.thana + ", "}
            {this.state.resellerToModify.district}
          </p>
          <p className="text-center  mb-2">{this.state.resellerToModify.mobile}</p>
          <p className="text-center font-weight-bold  mb-0">
            সংযোগ তারিখঃ {moment(this.state.resellerToModify.createdAt).format("DD/MM/yyyy")}
          </p>
        </ProfileModal>

        {/* Reseller Settings Modal*/}
        <Modal
          modalTitle="রিসেলার সেটিংস"
          onSubmit={this.onSubmitResellerSettings}
          pending={this.props.server.requestPending}
          id="resellerSettingsModal"
        >
          <React.Fragment>
            <input type="checkbox" onClick={this.handleAllCheckedForSetting} value="checkedall" /> সকল রিসেলার
            <ul>
              {this.state.resellersForSettings.map((reseller) => {
                return (
                  <CheckBoxOperatorSettings
                    handleCheckChieldElement={this.handleCheckChieldElementForSetting}
                    {...reseller}
                  />
                )
              })}
            </ul>
          </React.Fragment>
        </Modal>

        {/* Reseller deposit Modal*/}

        <div className="modal fade" tabIndex="-1" role="dialog" id="rechargeModal">
          <div className={`modal-dialog mw-100 w-75`} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{this.state.resellerToModify.name}</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  disabled={this.props.server.requestPending}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <form onSubmit={this.onSubmitRecharge}>
                  <div className="row">
                    <div className="col-md-4">
                      <h5>ব্যালেন্সঃ {formattedNumber(this.state.resellerToModify.balance)} Tk</h5>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        {/* <label htmlFor="referenceMobile">রিসেলার রিচার্জ</label> */}
                        <input
                          className="form-control mb-2"
                          onChange={(e) => this.setState({ amount: e.target.value })}
                          value={this.state.amount}
                          type="number"
                          name="amount"
                          maxLength="100"
                          placeholder="রিচার্জ পরিমাণ লিখুন"
                          disabled={this.props.server.requestPending}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 text-right">
                      <button
                        style={{ minWidth: 100 }}
                        type="submit"
                        className="btn btn-primary"
                        disabled={this.props.server.requestPending}
                      >
                        {this.props.server.requestPending ? <i className="fas fa-sync fa-spin"></i> : "রিচার্জ করুন"}
                      </button>
                    </div>
                  </div>
                </form>
                <hr />
                <PaginationProvider
                  pagination={paginationFactory({
                    sizePerPageList: [
                      { text: "10", value: 10 },
                      { text: "20", value: 20 },
                      { text: "50", value: 50 },
                    ],
                  })}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      bootstrap4
                      keyField="_id"
                      data={this.state.resellerToModify.recharges || []}
                      columns={columnsForRecharge}
                      search
                    >
                      {(props) => (
                        <div>
                          <BootstrapTable striped hover {...props.baseProps} {...paginationTableProps} />
                        </div>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </div>

              <div className="modal-footer bg-whitesmoke br">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  disabled={this.props.server.requestPending}
                >
                  বন্ধ করুন
                </button>
                {/* <button style={{ minWidth: 100 }} type="submit" className="btn btn-primary" disabled={this.props.server.requestPending}>
                {this.props.server.requestPending ? <i className="fas fa-sync fa-spin"></i> : "সাবমিট"}
              </button> */}
              </div>
            </div>
          </div>
        </div>

        {/* Send sms Modal*/}
        <SendSmsModal
          data={this.state.resellerToModify}
          pending={this.props.server.requestPending}
          action={this.props.sendSingleSMS}
        />

        {/* Number change Modal */}
        <NumberChangeModal
          data={this.state.resellerToModify}
          pending={this.props.server.requestPending}
          action={this.props.changeMobileNumber}
        />

        {/* Area assign  Modal*/}
        <AreaAssignModal
          data={this.state.resellerToModify}
          areas={this.props.profile.profile.areas}
          pending={this.props.server.requestPending}
          action={this.props.assignAreasToReseller}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    server: state.server,
    reseller: state.reseller,
    profile: state.profile,
    auth: state.auth,
  }
}

export default connect(mapStateToProps, {
  assignAreasToReseller,
  getAllResellers,
  addReseller,
  updateReseller,
  allowedResellers,
  sendSingleSMS,
  resetPassword,
  changeMobileNumber,
  resellerRecharge,
  deleteResellerRecharge,
})(Reseller)
