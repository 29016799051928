import React, { Component, createRef } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { connect } from "react-redux"
import Select from "react-select"
import toastr from "toastr"
import { addLineUser, updateLineUser } from "../../actions/lineUserActions"
import Modal from "../../components/modals/Modal"

class AddEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lineUserToAddOrEdit: props.lineUserToAddOrEdit,
      areas: props.areas,
      packages: props.packages,
      resellers: props.resellers,
      addLineUserFlag: false,
    }

    this.resellerIDRef = createRef()
    this.assignedAreaRef = createRef()
    this.connectionTypeRef = createRef()
    this.billPayTypeRef = createRef()
    this.nameRef = createRef()
    this.mobileRef = createRef()
    this.houseOwnerNameRef = createRef()
    this.customerIDRef = createRef()
    this.monthlyBillRef = createRef()
    this.dueAmountRef = createRef()
    this.monthlyCommissionRef = createRef()
    this.mtkProfileRef = createRef()
    this.mtkPasswordRef = createRef()
    this.mtkNameRef = createRef()
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
  //     this.setState({
  //       lineUserToAddOrEdit: nextProps.lineUserToAddOrEdit,
  //     })
  //   }
  //   return true
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    const { requestPending, errorMsg } = nextProps.server
    if (requestPending || errorMsg) return

    let resellerFlag = false,
      mikrotikFlag = false,
      connectionTypeFlag = false,
      billPayTypeFlag = false,
      ispBillFlag = false

    if (nextProps.profile) {
      // console.log(nextProps)
      const { feeder } = nextProps.profile

      if (feeder?.feederType === 3) {
        connectionTypeFlag = true
      }
      if (feeder?.billCollectionType === 3) {
        billPayTypeFlag = true
      }
      if (feeder?.bpSettings?.hasMikrotik === "yes") {
        mikrotikFlag = true
      }
      if (feeder?.hasReseller === "yes") {
        resellerFlag = true
        ispBillFlag = true
        connectionTypeFlag = false
      }
      if (this.props.auth.user.type === "reseller") {
        resellerFlag = false
        ispBillFlag = true
        connectionTypeFlag = false
      }
    }
    if (this.props.lineUserToAddOrEdit.ms !== nextProps.lineUserToAddOrEdit.ms) {
      this.setState({ lineUserToAddOrEdit: nextProps.lineUserToAddOrEdit })
    }
    this.setState({
      addLineUserFlag: nextProps.addLineUserFlag,
      modalTitle: nextProps.modalTitle,
      areas: nextProps.areas,
      packages: nextProps.packages,
      resellers: nextProps.resellers,
      resellerFlag,
      mikrotikFlag,
      connectionTypeFlag,
      billPayTypeFlag,
      ispBillFlag,
    })
  }

  onChangeHandler = (e) => {
    this.setState({
      lineUserToAddOrEdit: {
        ...this.state.lineUserToAddOrEdit,
        [e.target.name]: e.target.value,
      },
    })
  }

  handleDateChange = (name, date) => {
    this.setState({
      lineUserToAddOrEdit: {
        ...this.state.lineUserToAddOrEdit,
        [name]: date,
      },
    })
  }

  onChangeArea = (selectedOption) => {
    this.setState({
      lineUserToAddOrEdit: {
        ...this.state.lineUserToAddOrEdit,
        assignedArea: selectedOption,
      },
    })
  }

  onChangeMtkProfile = (selectedOption) => {
    this.setState({
      lineUserToAddOrEdit: {
        ...this.state.lineUserToAddOrEdit,
        mtkProfile: selectedOption,
        monthlyBill: selectedOption.rate,
        dueAmount: selectedOption.rate,
        monthlyCommission: selectedOption.rate,
      },
    })
  }

  onChangeReseller = (selectedOption) => {
    this.setState({
      lineUserToAddOrEdit: {
        ...this.state.lineUserToAddOrEdit,
        resellerID: selectedOption,
      },
    })
  }

  onSubmitHandler = (e) => {
    e.preventDefault()
    let {
      name,
      mobile,
      houseOwnerName,
      customerID,
      assignedArea,
      connectionType,
      billPayType,
      monthlyBill,
      dueAmount,
      monthlyCommission,
      resellerID,
      mtkPassword,
      mtkProfile,
      mtkName,
      addToMikrotik,
    } = this.state.lineUserToAddOrEdit
    let { addLineUserFlag, resellerFlag, connectionTypeFlag, billPayTypeFlag, ispBillFlag } = this.state

    if (resellerFlag && !resellerID) {
      toastr.error("রিসেলার সিলেক্ট করুন")
      this.resellerIDRef.current.focus()
      return
    } else if (!assignedArea) {
      toastr.error("এরিয়া সিলেক্ট করুন")
      this.assignedAreaRef.current.focus()
      return
    } else if (connectionTypeFlag && !connectionType) {
      toastr.error("সংযোগ টাইপ সিলেক্ট করুন")
      this.connectionTypeRef.current.focus()
      return
    } else if (billPayTypeFlag && !billPayType) {
      toastr.error("বিল টাইপ সিলেক্ট করুন")
      this.billPayTypeRef.current.focus()
      return
    } else if (!customerID) {
      toastr.error("গ্রাহক আইডি ফিল্ড পুরন করুন")
      this.customerIDRef.current.focus()
      return
    } else if (!name) {
      toastr.error("নাম ফিল্ড পুরন করুন")
      this.nameRef.current.focus()
      return
    } else if (!houseOwnerName) {
      toastr.error("পরিচিতি ফিল্ড পুরন করুন")
      this.houseOwnerNameRef.current.focus()
      return
    } else if (mobile) {
      var rule = /(^(01){1}[3456789]{1}(\d){8})$/
      if (!mobile.match(rule)) {
        toastr.error("মোবাইল সঠিক নয়")
        this.mobileRef.current.focus()
        return
      }
    }

    if (addLineUserFlag && !monthlyBill) {
      toastr.error("মাসিক ফি পুরন করুন")
      this.monthlyBillRef.current.focus()
      return
    } else if (addLineUserFlag && monthlyBill) {
      let isNum = /^\d+$/.test(monthlyBill)
      if (!isNum) {
        toastr.error("মাসিক ফি সঠিক নয়")
        this.monthlyBillRef.current.focus()
        return
      }

      if (monthlyBill > 100000) {
        toastr.error("মাসিক ফি সঠিক নয়। সর্বোচ্চঃ 100000")
        this.monthlyBillRef.current.focus()
        return
      }
    }

    if (addLineUserFlag && !dueAmount) {
      toastr.error("বিল পুরন করুন")
      this.dueAmountRef.current.focus()
      return
    } else if (addLineUserFlag && dueAmount) {
      let isNum = /^\d+$/.test(dueAmount)
      if (!isNum) {
        toastr.error("বিল সঠিক নয়")
        this.dueAmountRef.current.focus()
        return
      }

      if (dueAmount > 1000000) {
        toastr.error("বিল সঠিক নয়। সর্বোচ্চঃ 1000000")
        this.dueAmountRef.current.focus()
        return
      }
    }

    if (ispBillFlag && !monthlyCommission) {
      toastr.error("মাসিক আইএসপি বিল পুরন করুন")
      this.monthlyCommissionRef.current.focus()
      return
    } else if (ispBillFlag && monthlyCommission) {
      let isNum = /^\d+$/.test(monthlyCommission)
      if (!isNum) {
        toastr.error("মাসিক আইএসপি বিল সঠিক নয়")
        this.monthlyCommissionRef.current.focus()
        return
      }

      if (ispBillFlag && monthlyCommission > 100000) {
        toastr.error("মাসিক আইএসপি বিল সঠিক নয়। সর্বোচ্চঃ 100000")
        this.monthlyCommissionRef.current.focus()
        return
      }
    }

    if (connectionType == 2 && addToMikrotik) {
      if (!mtkName) {
        toastr.error("মাইক্রোটিক Name পূরণ করুন")
        this.mtkNameRef.current.focus()
        return
      }
      if (!mtkPassword) {
        toastr.error("মাইক্রোটিক Password পূরণ করুন")
        this.mtkPasswordRef.current.focus()
        return
      }
      if (!mtkProfile) {
        toastr.error("মাইক্রোটিক Profile সিলেক্ট করুন")
        this.mtkProfileRef.current.focus()
        return
      }
    } else {
      delete this.state.lineUserToAddOrEdit.resellerID
      delete this.state.lineUserToAddOrEdit.mtk
      delete this.state.lineUserToAddOrEdit.monthlyCommission
    }

    // return
    if (this.state.addLineUserFlag) {
      this.props.addLineUser({ ...this.state.lineUserToAddOrEdit })
    } else {
      this.props.updateLineUser({ ...this.state.lineUserToAddOrEdit })
    }
  }

  render() {
    const {
      lineUserToAddOrEdit,
      areas,
      resellers,
      packages,
      resellerFlag,
      mikrotikFlag,
      connectionTypeFlag,
      billPayTypeFlag,
      ispBillFlag,
    } = this.state
    return (
      <>
        <Modal
          modalTitle={this.state.modalTitle}
          onSubmit={this.onSubmitHandler}
          pending={this.props.server.requestPending}
          id="lineUserAddOrEditModal"
        >
          <div className="row">
            {resellerFlag && (
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="resellerID">*রিসেলার সিলেক্ট করুন</label>
                  <Select
                    ref={this.resellerIDRef}
                    name="resellerID"
                    value={lineUserToAddOrEdit.resellerID}
                    options={resellers}
                    onChange={this.onChangeReseller}
                  />
                </div>
              </div>
            )}

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="assignedArea">*এরিয়া সিলেক্ট করুন</label>
                <Select
                  ref={this.assignedAreaRef}
                  name="assignedArea"
                  value={lineUserToAddOrEdit.assignedArea}
                  options={areas}
                  onChange={this.onChangeArea}
                />
              </div>
            </div>

            {/* Connection Type  */}
            {connectionTypeFlag && (
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="connectionType">*সংযোগ টাইপ</label>
                  <select
                    ref={this.connectionTypeRef}
                    className="form-control"
                    name="connectionType"
                    value={lineUserToAddOrEdit.connectionType}
                    onChange={this.onChangeHandler}
                  >
                    <option value=""> সংযোগ টাইপ নির্বাচন করুন</option>
                    <option value={1}> ডিশ</option>
                    <option value={2}> ইন্টারনেট</option>
                  </select>
                </div>
              </div>
            )}

            {/* BillPayType  */}
            {billPayTypeFlag && (
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="billPayType">*বিল টাইপ</label>
                  <select
                    ref={this.billPayTypeRef}
                    className="form-control"
                    name="billPayType"
                    value={lineUserToAddOrEdit.billPayType}
                    onChange={this.onChangeHandler}
                  >
                    <option value=""> বিল টাইপ নির্বচন করুন</option>
                    <option value={2}> পোস্টপেইড</option>
                    <option value={1}> প্রিপেইড</option>
                  </select>
                </div>
              </div>
            )}
          </div>
          {mikrotikFlag && (connectionTypeFlag ? lineUserToAddOrEdit.connectionType === "2" : true) && (
            <div className="row">
              <div className="col-md-12">
                {/* <p className="text-center font-weight-bold">মাইক্রোটিক</p> */}
                <hr />
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="mtkName">PPPoE Name</label>
                  <input
                    ref={this.mtkNameRef}
                    className="form-control"
                    onChange={this.onChangeHandler}
                    value={lineUserToAddOrEdit.mtkName}
                    type="text"
                    name="mtkName"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="mtkPassword">Password</label>
                  <input
                    ref={this.mtkPasswordRef}
                    className="form-control"
                    onChange={this.onChangeHandler}
                    value={lineUserToAddOrEdit.mtkPassword}
                    type="text"
                    name="mtkPassword"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="assignedArea">Profile</label>
                  <Select
                    ref={this.mtkProfileRef}
                    name="mtkProfile"
                    value={lineUserToAddOrEdit.mtkProfile}
                    options={packages}
                    onChange={this.onChangeMtkProfile}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="mtkComment">Comment</label>
                  <input
                    className="form-control"
                    onChange={this.onChangeHandler}
                    value={lineUserToAddOrEdit.mtkComment}
                    type="text"
                    name="mtkComment"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <hr />
              </div>
            </div>
          )}
          <div className="row">
            {/* Customer ID  */}
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="customerID">*গ্রাহক আইডি</label>
                <input
                  ref={this.customerIDRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.customerID}
                  type="text"
                  name="customerID"
                />
              </div>
            </div>

            {/* Name  */}
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="name">*নাম</label>
                <input
                  ref={this.nameRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.name}
                  type="text"
                  name="name"
                />
              </div>
            </div>

            {/* House Owner Name  */}
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="houseOwnerName">*পরিচিতি</label>
                <input
                  ref={this.houseOwnerNameRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.houseOwnerName}
                  type="text"
                  name="houseOwnerName"
                />
              </div>
            </div>

            {/* Monthly Bill  */}
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="monthlyBill">*মাসিক ফি ( ফ্রী হলে 0 লিখুন )</label>
                <input
                  ref={this.monthlyBillRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.monthlyBill}
                  type="number"
                  name="monthlyBill"
                  disabled={!this.state.addLineUserFlag}
                />
              </div>
            </div>

            {/* Due Amount  */}
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="due">
                  *বিল
                  {/* ( পূর্বের বকেয়াসহ চলতি মাসের বিল। পূর্বের বকেয়া না থাকলে এবং চলতি মাসের বিল না নিলে 0 লিখুন ) */}
                </label>
                <input
                  ref={this.dueAmountRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.dueAmount}
                  type="number"
                  name="dueAmount"
                  disabled={!this.state.addLineUserFlag}
                />
              </div>
            </div>

            {ispBillFlag && (
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="monthlyCommission">
                    মাসিক আইএসপি বিল
                    {/* ( পূর্বের বকেয়াসহ চলতি মাসের বিল। পূর্বের বকেয়া না থাকলে এবং চলতি মাসের বিল না নিলে 0 লিখুন ) */}
                  </label>
                  <input
                    ref={this.monthlyCommissionRef}
                    className="form-control"
                    onChange={this.onChangeHandler}
                    value={lineUserToAddOrEdit.monthlyCommission}
                    type="number"
                    name="monthlyCommission"
                    disabled={this.props.auth.user.type !== "feeder"}
                  />
                </div>
              </div>
            )}

            {/* Mobile  */}
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="mobile">মোবাইল (01712345678)</label>
                <input
                  ref={this.mobileRef}
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.mobile}
                  maxLength="11"
                  type="text"
                  name="mobile"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="email">ইমেইল</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.email}
                  type="email"
                  name="email"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="nidNumber">জাতীয় পরিচয়পত্র নং</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.nidNumber}
                  type="text"
                  name="nidNumber"
                  maxLength="50"
                />
              </div>
            </div>

            {/* Activated At  */}
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="connectionDate">সংযোগ তারিখ</label>
                <br />
                <DatePicker
                  className="form-control"
                  selected={lineUserToAddOrEdit.connectionDate}
                  onChange={this.handleDateChange.bind(this, "connectionDate")}
                  name="connectionDate"
                  maxDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="fullAddress">ঠিকানা</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.fullAddress}
                  type="text"
                  name="fullAddress"
                  maxLength="100"
                />
              </div>
            </div>

            {/* <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="flatNo">ফ্ল্যাট নং / তলা নং</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.flatNo}
                  type="text"
                  name="flatNo"
                  maxLength="50"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="flat">বাড়ী নং / মহল্লা</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.flat}
                  type="text"
                  name="flat"
                  maxLength="50"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="house">রোড / গ্রাম</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.house}
                  type="text"
                  name="house"
                  maxLength="50"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="area">পোষ্ট অফিস</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.area}
                  type="text"
                  name="area"
                  maxLength="50"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="thana">থানা / উপজেলা</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.thana}
                  type="text"
                  name="thana"
                  maxLength="50"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="district">জেলা</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.district}
                  type="text"
                  name="district"
                  maxLength="50"
                />
              </div>
            </div> */}

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="referenceName">রেফারেন্স নাম</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.referenceName}
                  type="text"
                  name="referenceName"
                  maxLength="40"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="referenceMobile">রেফারেন্স মোবাইল</label>
                <input
                  className="form-control"
                  onChange={this.onChangeHandler}
                  value={lineUserToAddOrEdit.referenceMobile}
                  type="text"
                  name="referenceMobile"
                  maxLength="40"
                />
              </div>
            </div>

            {mikrotikFlag && (connectionTypeFlag ? lineUserToAddOrEdit.connectionType === "2" : true) && (
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="pppName">PPP Name</label>
                  <input
                    className="form-control"
                    onChange={this.onChangeHandler}
                    value={lineUserToAddOrEdit.pppName}
                    type="text"
                    name="pppName"
                  />
                </div>
              </div>
            )}

            {lineUserToAddOrEdit.connectionType == "1" && (
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="boxID">বক্স আইডি</label>
                  <input
                    className="form-control"
                    onChange={this.onChangeHandler}
                    value={lineUserToAddOrEdit.boxID}
                    type="text"
                    name="boxID"
                    maxLength="20"
                  />
                </div>
              </div>
            )}

            {this.props.auth.user.type === "manager" && this.state.addLineUserFlag && (
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="securityMoney">সংযোগ ফি</label>
                  <input
                    className="form-control"
                    onChange={this.onChangeHandler}
                    value={lineUserToAddOrEdit.securityMoney}
                    type="number"
                    name="securityMoney"
                    maxLength="20"
                  />
                </div>
              </div>
            )}
          </div>{" "}
          {/*END OF ROW */}
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    feederID: state.auth.user.profile.profileID,
    profile: state.profile,
    lineUser: state.lineUser,
    server: state.server,
    auth: state.auth,
  }
}

export default connect(mapStateToProps, { addLineUser, updateLineUser })(AddEdit)
