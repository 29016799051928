import React from "react"
import "./styles.css"
import YoutubeEmbed from "./YoutubeEmbed"

export default function App() {
  return (
    <div className="main-content">
      <section className="section">
        <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
          <h4>ভিডিও টিউটোরিয়াল</h4>
        </div>
        <div className="container">
          <div style={{ minHeight: 230 }} className="row justify-content-center p-3 bg-white align-items-center">
            <div className="my-5 text-center">
              <h3>
                <a
                  className="font-weight-bold"
                  href="https://play.google.com/store/apps/details?id=com.shunno.project.bayannopay"
                  target="_blank"
                >
                  বায়ান্ন পে মোবাইল এপ ডাউনলোড করতে এখানে ক্লিক করুন
                </a>
              </h3>
            </div>
            <div className="col-md-6 mb-3">
              <YoutubeEmbed embedId="7GW2kqSFByU" />
              <h4 className="h4 mt-2 mb-5">বায়ান্ন পে মোবাইলে এপ কিভাবে আপনার মোবাইলে ইন্সটল করবেন?</h4>
            </div>
            <div className="col-md-6 mb-3">
              <YoutubeEmbed embedId="gi6YgzalT6k" />
              <h4 className="h4 mt-2 mb-5">টিউটোরিয়াল - ১ : বায়ান্ন পে এডমিন প্যানেল</h4>
            </div>
            <div className="col-md-6 mb-3">
              <YoutubeEmbed embedId="IbW2d-LykfM" />
              <h4 className="h4 mt-2 mb-5">টিউটোরিয়াল - ২ : বায়ান্ন পে ম্যানেজার প্যানেল</h4>
            </div>
            <div className="col-md-6 mb-3">
              <YoutubeEmbed embedId="-U9DlCHHmyQ" />
              <h4 className="h4 mt-2 mb-5">টিউটোরিয়াল - ৩ : বায়ান্ন পে কালেক্টর প্যানেল</h4>
            </div>
            <div className="col-md-6 mb-3">
              <YoutubeEmbed embedId="qA2Ugk_vpgA" />
              <h4 className="h4 mt-2 mb-5">টিউটোরিয়াল - ৪ : কালেক্টর ম্যানেজারকে কিভাবে ব্যালেন্স ডিপোজিট করবে?</h4>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
