import React from "react";

const ScrollingMessage = () => {
  return (
    <marquee
      behavior="scroll"
      direction="left"
      style={{
        position: "fixed",
        bottom: "0",
        width: "100%",
        backgroundColor: "#6777EF",
        color: "white",
        padding: "10px 0"
      }}
    >
      সম্মানিত বায়ান্নপে গ্রাহক, সম্প্রতি বিটিআরসি (BTRC) কতৃক সকল প্রকার এসএমএস
      এর মূল্য বৃদ্ধি করার কারণে এসএমএস এর মূল্য বৃদ্ধি পেয়েছে। পরবর্তীতে
      বিটিআরসি (BTRC) কতৃক মূল্যহ্রাস করা হলে আমরা পুনরায় এসএমএস এর মূল্য হ্রাস
      করতে অঙ্গীকারবদ্ধ। আপনাদের সাময়িক অসুবিধার জন্য আমরা আন্তরিকভাবে দুঃখিত।
    </marquee>
  );
};

export default ScrollingMessage;
