import React, { Component } from "react";
import randomColor from "randomcolor";

import { bdt } from "../../../components/common/Utils";

class Card extends Component {
  render() {
    const { user, collection, expenditure, salary, auth } = this.props;

    let i = 0,
      j = 0,
      k = 0;

    let colors = randomColor({
      count: 13,
      // hue: "green",
      luminosity: "dark"
    });

    return (
      <>
        {/* CARD */}
        <div className="col-12 mb-4">
          <hr />
        </div>

        <div
          className="card text-center text-white mb-3 mx-2 rounded"
          style={{
            minWidth: "12rem",
            maxWidth: "12rem",
            backgroundColor: colors[i++]
          }}
        >
          <div className="card-body">
            <h5 className="card-title font-weight-bold h4">
              {user.addedThisMonth.count || 0}
            </h5>
            <p className="card-text font-weight-bold">নতুন সংযোগ</p>
          </div>
        </div>
        <div
          className="card text-center text-white mb-3 mx-2 rounded"
          style={{
            minWidth: "12rem",
            maxWidth: "12rem",
            backgroundColor: colors[i++]
          }}
        >
          <div className="card-body">
            <h5 className="card-title font-weight-bold h4">
              {user.inactiveThisMonth}
            </h5>
            <p className="card-text font-weight-bold">নতুন বন্ধ</p>
          </div>
        </div>

        <div
          className="card text-center text-white mb-3 mx-2 rounded"
          style={{
            minWidth: "14rem",
            maxWidth: "18rem",
            backgroundColor: colors[i++]
          }}
        >
          <div className="card-body">
            <h5 className="card-title font-weight-bold h4">
              {user.total.count}
            </h5>
            <p className="card-text font-weight-bold">সকল গ্রাহক</p>
          </div>
        </div>

        <div
          className="card text-center text-white mb-3 mx-2 rounded"
          style={{
            minWidth: "14rem",
            maxWidth: "18rem",
            backgroundColor: colors[i++]
          }}
        >
          <div className="card-body">
            <h5 className="card-title font-weight-bold h4">
              {user.active.count}
            </h5>
            <p className="card-text font-weight-bold">এক্টিভ গ্রাহক</p>
          </div>
        </div>

        <div
          className="card text-center text-white  mb-3 mx-2 rounded"
          style={{
            minWidth: "12rem",
            maxWidth: "12rem",
            backgroundColor: colors[i++]
          }}
        >
          <div className="card-body">
            <h5 className="card-title font-weight-bold h4">
              {user.free.count}
            </h5>
            <p className="card-text font-weight-bold">ফ্রি গ্রাহক</p>
          </div>
        </div>

        <div
          className="card text-center text-white mb-3 mx-2 rounded"
          style={{
            minWidth: "14rem",
            maxWidth: "18rem",
            backgroundColor: colors[i++]
          }}
        >
          <div className="card-body">
            <h5 className="card-title font-weight-bold h4">
              {user.inactive.count}
            </h5>
            <p className="card-text font-weight-bold">বন্ধ গ্রাহক</p>
          </div>
        </div>

        <div
          className="card text-center text-white mb-3 mx-2 rounded"
          style={{
            minWidth: "14rem",
            maxWidth: "18rem",
            backgroundColor: colors[i++]
          }}
        >
          <div className="card-body">
            <h5 className="card-title font-weight-bold h4">
              {user.deletedThisMonth.count}
            </h5>
            <p className="card-text font-weight-bold">ডিলিট গ্রাহক</p>
          </div>
        </div>

        {auth.user.type !== "operator" && (
          <div
            className="card text-center text-white  mb-3 mx-2 rounded"
            style={{
              minWidth: "14rem",
              maxWidth: "18rem",
              backgroundColor: colors[i++]
            }}
          >
            <div className="card-body">
              <h5 className="card-title font-weight-bold h4">
                {bdt(collection.security.amount)} ({collection.security.count})
              </h5>
              <p className="card-text font-weight-bold">সংযোগ ফি</p>
            </div>
          </div>
        )}

        {auth.user.type !== "operator" && (
          <div
            className="card text-center text-white  mb-3 mx-2 rounded"
            style={{
              minWidth: "14rem",
              maxWidth: "18rem",
              backgroundColor: "#D80068"
            }}
          >
            <div className="card-body">
              <h5 className="card-title font-weight-bold h4">
                {bdt(collection.online.amount)} ({collection.online.count})
              </h5>
              <p className="card-text font-weight-bold">অনলাইন পেমেন্ট</p>
            </div>
          </div>
        )}

        {auth.user.type !== "operator" && (
          <div
            className="card text-center text-white mb-3 mx-2 rounded"
            style={{
              minWidth: "12rem",
              maxWidth: "12rem",
              backgroundColor: colors[i++]
            }}
          >
            <div className="card-body">
              <h5 className="card-title font-weight-bold h4">
                {bdt(expenditure.thisMonth.total)}
              </h5>
              <p className="card-text font-weight-bold">খরচ</p>
            </div>
          </div>
        )}

        {auth.user.type !== "operator" && (
          <div
            className="card text-center text-white mb-3 mx-2 rounded"
            style={{
              minWidth: "12rem",
              maxWidth: "12rem",
              backgroundColor: colors[i++]
            }}
          >
            <div className="card-body">
              <h5 className="card-title font-weight-bold h4">
                {salary.total ? bdt(salary.total) : "৳ 0"}
              </h5>
              <p className="card-text font-weight-bold">স্যালারী</p>
            </div>
          </div>
        )}

        {/* <div className="card text-center text-white bg-info mb-3 mx-2" style={{ minWidth: "12rem", maxWidth: "12rem" }}>
          <div className="card-body">
            <h5 className="card-title">{bdt(expenditure.thisMonth.total)}</h5>
            <p className="card-text font-weight-bold">আজকের কালেকশন</p>
          </div>
        </div> */}
      </>
    );
  }
}

export default Card;
