export const LOADING = "LOADING";
export const LOGIN = "LOGIN";

export const GET_ERRORS = "GET_ERRORS";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_NOTICE = "GET_DASHBOARD_NOTICE";
export const GET_DASHBOARD_STATUS = "GET_DASHBOARD_STATUS";
export const GET_DASHBOARD_SUMMARY = "GET_DASHBOARD_SUMMARY";
export const GET_DASHBOARD_SUMMARY_LOADING = "GET_DASHBOARD_SUMMARY_LOADING";
export const CONFIRM_MANAGER_DEPOSIT = "CONFIRM_MANAGER_DEPOSIT";

export const SET_PROFILE = "SET_PROFILE";
export const GET_INFO = "GET_INFO";
export const GET_MANAGER = "GET_MANAGER";
export const MANAGER_LOADING = "MANAGER_LOADING";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_MANAGER_DEPOSIT = "GET_MANAGER_DEPOSIT";
export const GET_MANAGER_BALANCE = "GET_MANAGER_BALANCE";

// action types for LineUsers
export const GET_ALL_LINE_USERS = "GET_ALL_LINE_USERS";
export const GET_LINEUSER_DETAIL = "GET_LINEUSER_DETAIL";
export const ADD_LINEUSER = "ADD_LINEUSER";
export const UPDATE_LINEUSER = "UPDATE_LINEUSER";
export const DELETE_LINEUSER = "DELETE_LINEUSER";
export const STATUS_UPDATE_LINEUSER = "STATUS_UPDATE_LINEUSER";
export const BILL_COLLECT_LINEUSER = "BILL_COLLECT_LINEUSER";
export const BILL_REPORT_LINEUSER = "BILL_REPORT_LINEUSER";

// action types for Collectiors
export const GET_ALL_COLLECTORS = "GET_ALL_COLLECTORS";
export const ADD_COLLECTOR = "ADD_COLLECTOR";
export const UPDATE_COLLECTOR = "UPDATE_COLLECTOR";
export const UPDATE_COLLECTOR_NUMBER = "UPDATE_COLLECTOR_NUMBER";
export const DELETE_COLLECTOR = "DELETE_COLLECTOR";
export const COLLECTOR_ASSIGN_AREAS = "COLLECTOR_ASSIGN_AREAS";
export const GET_COLLECTOR_DEPOSIT = "GET_COLLECTOR_DEPOSIT";

// action types for Server
export const SEND_REQUEST = "SEND_REQUEST";
export const RESPONSE_SUCCESS = "RESPONSE_SUCCESS";
export const RESPONSE_FAILD = "RESPONSE_FAILD";

// action types for Server
export const RESET = "RESET";
export const ERROR_MSG = "ERROR_MSG";

// action types for Operators
export const GET_ALL_OPERATORS = "GET_ALL_OPERATORS";
export const ADD_OPERATOR = "ADD_OPERATOR";
export const UPDATE_OPERATOR = "UPDATE_OPERATOR";
export const UPDATE_OPERATOR_NUMBER = "UPDATE_OPERATOR_NUMBER";
export const OPERATOR_ASSIGN_AREAS = "OPERATOR_ASSIGN_AREAS";

// action types for Reseller
export const GET_ALL_RESELLERS = "GET_ALL_RESELLERS";
export const ADD_RESELLER = "ADD_RESELLER";
export const UPDATE_RESELLER = "UPDATE_RESELLER";
export const UPDATE_RESELLER_NUMBER = "UPDATE_RESELLER_NUMBER";
export const RESELLER_ASSIGN_AREAS = "RESELLER_ASSIGN_AREAS";

// action types for Staffs
export const GET_ALL_STAFFS = "GET_ALL_STAFFS";
export const ADD_STAFF = "ADD_STAFF";
export const UPDATE_STAFF = "UPDATE_STAFF";
export const GET_STAFF_SALARY = "GET_STAFF_SALARY";

//actions for expenditure
export const GET_ALL_EXPENDITURES = "GET_ALL_EXPENDITURES";
export const ADD_EXPENDITURES = "ADD_EXPENDITURES";
export const SET_EXPENDITURE_PURPOSE = "SET_EXPENDITURE_PURPOSE";
export const DELETE_EXPENDITURE = "DELETE_EXPENDITURE";

// bill report
export const GET_BILL_REPORT = "GET_BILL_REPORT";
export const GET_DUE_REPORT = "GET_DUE_REPORT";
export const GET_INVOICES = "GET_INVOICES";
export const GET_WEBHOOK_MESSAGE = "GET_WEBHOOK_MESSAGE";
export const GET_MESSAGE_LOG = "GET_MESSAGE_LOG";
