import axios from "axios";
import $ from "jquery";
import toastr from "toastr";
import { SERVER_URL } from "../config/config";
import store from "../store";

import {
  GET_DASHBOARD,
  GET_ERRORS,
  LOADING,
  RESET,
  CONFIRM_MANAGER_DEPOSIT,
  GET_DASHBOARD_SUMMARY,
  GET_DASHBOARD_SUMMARY_LOADING,
} from "./types";

export const getDashboard = () => (dispatch) => {
  dispatch({
    type: LOADING,
  });
  console.log("dashboard calling...");

  let api;
  if (
    store.getState().auth.user.type === "feeder" ||
    store.getState().auth.user.type === "manager"
  ) {
    api = `${SERVER_URL}/api/dashboard`;
  } else if (store.getState().auth.user.type === "operator") {
    api = `${SERVER_URL}/api/operators/dashboard`;
  }

  axios
    .get(api)
    .then((res) => {
      const { status, data, dashboard } = res.data;
      console.log("dashboard data", dashboard);
      dispatch({
        type: RESET,
      });
      if (status) {
        dispatch({
          type: GET_DASHBOARD,
          payload: dashboard,
        });
      } else {
        toastr.error("Something Went Wrong!");
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      toastr.error("ড্যাশবোর্ড লোড ব্যর্থ হয়েছে!!");
    });
};
export const getDashboardSummary = (year, month) => (dispatch) => {
  console.log("dashboard summary calling...");

  dispatch({
    type: GET_DASHBOARD_SUMMARY_LOADING,
  });
  dispatch({
    type: LOADING,
  });

  let api;
  if (
    store.getState().auth.user.type === "feeder" ||
    store.getState().auth.user.type === "manager"
  ) {
    api = `${SERVER_URL}/api/dashboard/summary?year=${year}&month=${month}`;
  }

  axios
    .get(api)
    .then((res) => {
      const { status, data, dashboard, msg } = res.data;
      console.log("dashboard summary data", dashboard);

      dispatch({
        type: RESET,
      });

      if (status) {
        dispatch({
          type: GET_DASHBOARD_SUMMARY,
          payload: dashboard,
        });
      } else {
        toastr.error(msg);
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
        dispatch({
          type: GET_DASHBOARD_SUMMARY,
          payload: {},
        });
      }
    })
    .catch((err) => {
      console.log(err);
      toastr.error("রিপোর্ট সামারি লোড ব্যর্থ হয়েছে!!");
    });
};

// Confirm Deposit
export const confirmDeposit = (confirmDepositData) => (dispatch) => {
  dispatch({ type: LOADING });
  axios
    .post(
      SERVER_URL + "/api/feeders/confirm/manager/deposit",
      confirmDepositData
    )
    .then((res) => {
      dispatch({ type: RESET });
      const { status, msg, data } = res.data;
      dispatch(getDashboard());
      if (status) {
        // dispatch({
        //   type: CONFIRM_MANAGER_DEPOSIT,
        //   payload: data,
        // })
        toastr.success(msg);
      }
    })
    .catch((err) => {
      dispatch({ type: RESET });
      toastr.error("Something Went Wrong!");
    });
};

// Pay Deposit To Admin by Manager
export const payDeposit = (data) => (dispatch) => {
  dispatch({ type: LOADING });
  axios
    .post(SERVER_URL + "/api/managers/deposit", data)
    .then((res) => {
      dispatch({ type: RESET });
      const { status, msg, data } = res.data;
      dispatch(getDashboard());
      if (status) {
        // dispatch({
        //   type: CONFIRM_MANAGER_DEPOSIT,
        //   payload: data,
        // })

        $("#ManagerDepositModal").modal("hide");
        toastr.success(msg);
      }
    })
    .catch((err) => {
      dispatch({ type: RESET });
      toastr.error("Something Went Wrong!");
    });
};
// Confirm Collector Deposit
export const confirmCollectorDeposit = (confirmDepositData) => (dispatch) => {
  dispatch({ type: LOADING });
  axios
    .post(
      SERVER_URL + "/api/managers/confirm/collector/deposit",
      confirmDepositData
    )
    .then((res) => {
      dispatch({ type: RESET });
      const { status, msg, data } = res.data;
      dispatch(getDashboard());

      toastr.success(msg);
    })
    .catch((err) => {
      dispatch({ type: RESET });
      toastr.error("Something Went Wrong!");
    });
};
