import $ from "jquery";
import moment from "moment";
import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { connect } from "react-redux";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import toastr from "toastr";

import {
  deleteLineUser,
  deleteLineUserBill,
  getAllLineUsers,
  getDetailLineUser,
  updateLineUserStatus,
  getSingleUserBillReport
} from "../../actions/lineUserActions";
import { getManager, sendSingleSMS } from "../../actions/profileActions";
import { ExportToExcel } from "../../components/common/ExportToExcel";
import PdfComponent from "../../components/common/PdfComponent";
import {
  formattedNumber,
  monthsArr,
  toBangla,
  rowStyleFormat
} from "../../components/common/Utils";
import SendSmsModal from "../../components/modals/SendSmsModal";
import AddEdit from "./AddEdit";
import BillCollect from "./BillCollect";
import BillUpdate from "./BillUpdate";
import Deactivate from "./Deactivate";
import MonthlyBillUpdate from "./MonthlyBillUpdate";
import Profile from "./Profile";
import Report from "./Report";

const toastMessages = {
  CUSTOMER_ADD: "গ্রাহক সংযোগ",
  CUSTOMER_EDIT: "গ্রাহক আপডেট",
  CUSTOMER_DEACTIVATE: "গ্রাহক বন্ধ",
  BILL_EDIT: "বিল আপডেট",
  MONTHLY_FEE_EDIT: "মাসিক ফি আপডেট",
  SEND_SMS: "ম্যাসেজ বোর্ড",
  CUSTOMER_ACTIVATE: "গ্রাহক একটিভ",
  CUSTOMER_DELETE: "গ্রাহক ডিলিট"
};

const { SearchBar } = Search;
const d = new Date();
const fy = d.getFullYear();
var dateString =
  d.getUTCDate() +
  "/" +
  (d.getUTCMonth() + 1) +
  "/" +
  d.getUTCFullYear() +
  " " +
  d.getUTCHours() +
  ":" +
  d.getUTCMinutes() +
  ":" +
  d.getUTCSeconds();

class LineUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: props.match.params.type || "all",
      profile: props.profile,
      lineUser: props.lineUser, // lineUser state from store
      areas: [],
      packages: [],
      resellers: [],
      assignedAreaToSearch: { label: "সকল এরিয়া", value: "all" },
      assignedResellerToSearch: { label: "সকল রিসেলার", value: "all" },
      lineUserToView: {},
      lineUserToAddOrEdit: {},
      addLineUserFlag: false,
      nextBill: null,
      year: { label: toBangla(d.getFullYear()), value: d.getFullYear() },
      month: { label: monthsArr[d.getMonth()], value: d.getMonth() },
      filterDishOrIsp: { label: "ডিশ এবং ইন্টারনেট", value: "all" }
    };
  }

  getLineUserType = () => {
    let type = this.state.page;

    switch (this.props.match.params.type) {
      case "all":
        return "সকল";
      case "unpaid":
        return "বকেয়া";
      case "partial":
        return "আংশিক পরিশোধ";
      case "paid":
        return "পরিশোধ";
      case "advanced":
        return "অগ্রীম পরিশোধ";
      case "free":
        return "ফ্রি";
      case "inactive":
        return "বন্ধ";
      case "active":
        return "এক্টিভ";
      default:
        return "সকল";
    }
  };

  // sortLineUserByArea = (lineUsers, areaID) => {
  //   return lineUsers.filter((lineUser) => lineUser.assignedArea === areaID)
  // }
  // sortLineUserByDishOrIsp = (lineUsers, type) => {
  //   return lineUsers.filter((lineUser) => lineUser.connectionType === type)
  // }
  // sortLineUserByReseller = (lineUsers, resellerID) => {
  //   return lineUsers.filter((lineUser) => lineUser.resellerID === resellerID)
  // }

  sortLineUser = (lineUsers, areaID, resellerID, DishOrIsp, type) => {
    let users = [],
      totalNextBill = 0,
      totalMonthlyBill = 0;

    lineUsers.map(user => {
      let ok = true;
      if (areaID !== "all" && user.assignedArea !== areaID) ok = false;
      if (resellerID !== "all" && user.resellerID !== resellerID) ok = false;
      if (DishOrIsp !== "all" && user.connectionType !== DishOrIsp) ok = false;
      if (ok) {
        totalNextBill += user.nextBill;
        totalMonthlyBill += user.monthlyBill;
        users.push(user);
      }
    });

    return { users, totalNextBill, totalMonthlyBill };
  };

  onChangeHandler = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  componentDidMount() {
    let areas = [],
      resellers = [],
      packages = [];

    if (this.props.profile.isLoaded && this.props.auth.user.type === "feeder") {
      if (
        !this.props.profile.managerLoaded &&
        !this.props.profile.managerLoading
      )
        this.props.getManager(this.props.profile.profile.managers[0]);
    }
    if (this.props.profile.feeder) {
      this.props.profile.feeder.areas &&
        this.props.profile.feeder.areas.length &&
        this.props.profile.feeder.areas.forEach(area => {
          areas.push({ value: area._id, label: area.name });
        });
    }

    if (this.props.profile.profile) {
      this.props.profile.profile.mtkPackages &&
        this.props.profile.profile.mtkPackages.length &&
        this.props.profile.profile.mtkPackages.forEach(pack => {
          packages.push({
            value: pack._id,
            label: pack.name,
            rate: pack.rate
          });
        });
    }

    if (this.props.reseller.isLoaded) {
      this.props.reseller.resellers.map(reseller => {
        resellers.push({ label: reseller.name, value: reseller._id });
      });
      resellers.unshift({
        value: this.props.profile.profile._id,
        label: this.props.profile.profile.company
      });
    }
    this.setState({ areas, resellers, packages });

    if (!this.state.lineUser.isLoaded) {
      this.props.getAllLineUsers();
    } else {
      this.setState({
        profile: this.props.profile,
        lineUser: this.props.lineUser
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let areas = [],
      resellers = [],
      packages = [],
      lineUserDetail = {};

    if (nextProps.profile.isLoaded && nextProps.auth.user.type === "feeder") {
      if (!nextProps.profile.managerLoaded && !nextProps.profile.managerLoading)
        nextProps.getManager(nextProps.profile.profile.managers[0]);
    }

    if (nextProps.profile.feeder) {
      nextProps.profile.feeder.areas &&
        nextProps.profile.feeder.areas.length &&
        nextProps.profile.feeder.areas.forEach(area => {
          areas.push({ value: area._id, label: area.name });
        });
    }
    if (nextProps.profile.profile) {
      nextProps.profile.profile.mtkPackages &&
        nextProps.profile.profile.mtkPackages.length &&
        nextProps.profile.profile.mtkPackages.forEach(pack => {
          packages.push({
            value: pack._id,
            label: pack.name,
            rate: pack.rate
          });
        });
    }

    if (this.props.reseller.resellers && this.props.reseller.resellers.length) {
      this.props.reseller.resellers.forEach(reseller => {
        resellers.push({ value: reseller._id, label: reseller.name });
      });
    }
    resellers.unshift({
      value: this.props.profile.profile._id,
      label: this.props.profile.profile.company
    });

    if (nextProps.lineUser.lineUserDetail) {
      lineUserDetail = nextProps.lineUser.lineUserDetail;
    }

    this.setState({
      profile: nextProps.profile,
      lineUser: nextProps.lineUser,
      areas,
      packages,
      resellers,
      lineUserDetail
    });
  }

  viewHandler = (lineUser, api) => {
    if (api === "report") {
      if (!this.state.lineUserDetail) {
        this.props.getDetailLineUser(lineUser._id);
      } else if (!this.state.lineUserDetail) {
        this.props.getDetailLineUser(lineUser._id);
      } else if (this.state.lineUserDetail.lineUserID != lineUser._id) {
        this.props.getDetailLineUser(lineUser._id);
      }
    }

    if (api === "profile") {
      this.props.getSingleUserBillReport(lineUser._id);
    }

    this.setState({
      nextBill: lineUser.nextBill,
      lineUserToView: {
        id: lineUser._id,
        name: lineUser.name,
        mobile: lineUser.mobile || "",
        customerID: lineUser.customerID,
        houseOwnerName: lineUser.houseOwnerName,
        connectionType: lineUser.connectionType,
        billPayType: lineUser.billPayType,
        monthlyBill: lineUser.monthlyBill,
        nextBill: lineUser.nextBill,
        currentMonthBS: lineUser.currentMonthBS,
        fullAddress: lineUser.fullAddress,
        createdAt: lineUser.createdAt,
        boxID: lineUser.boxID || "",
        connectionDate: lineUser.connectionDate
          ? new Date(lineUser.connectionDate)
          : "",
        company: this.props.profile.feeder.company,
        signature:
          this.props.profile.feeder.signature ||
          "সিগনেচার পাওয়া যায়নি। প্রোফাইলে কোম্পানীর নাম ও মোবাইল নম্বর ঘরটি পূরণ করুন।"
      }
    });
  };

  payBillClickHandler = (lineUser, billType) => {
    // console.log(lineUser)
    let m = d.getMonth(),
      y = d.getFullYear();
    const { feeder } = this.props.profile;

    // prepaid+postpaid Feeder
    if (feeder && feeder.billCollectionType == 3) {
      // postpaid lineUser
      if (lineUser.billPayType == 2) {
        m = d.getMonth() - 1;
        if (d.getMonth() == 0) {
          m = 11;
          y = fy - 1;
        }
      }
      // prepaid lineUser
      else if (lineUser.billPayType == 1) {
      }
      // if lineUser payment is not defined, then load like postpaid
      else {
        m = d.getMonth() - 1;
        if (d.getMonth() == 0) {
          m = 11;
          y = fy - 1;
        }
      }
    }
    // Postpaid Feeder
    else if (feeder && feeder.billCollectionType == 2) {
      m = d.getMonth() - 1;
      if (d.getMonth() == 0) {
        m = 11;
        y = fy - 1;
      }
    }
    // Prepaid Feeder
    else if (feeder && feeder.billCollectionType == 1) {
    }

    this.setState({
      month: m,
      year: y,
      billType: billType ? billType : null,
      advanceMonth: 1,
      selectedMonths: monthsArr[m + 1] ? monthsArr[m + 1] : monthsArr[0],
      lineUserToView: {
        id: lineUser._id,
        name: lineUser.name,
        mobile: lineUser.mobile,
        customerID: lineUser.customerID,
        connectionType: lineUser.connectionType,
        billPayType: lineUser.billPayType,
        monthlyBill: lineUser.monthlyBill,
        nextBill: lineUser.nextBill,
        currentMonthBS: lineUser.currentMonthBS,
        fullAddress: lineUser.fullAddress || "",
        createdAt: lineUser.createdAt,
        connectionDate: lineUser.connectionDate
          ? new Date(lineUser.connectionDate)
          : "",
        company: this.props.profile.feeder.company
        // device: "web2",
      }
    });
  };

  showAddLineUserModal = () => {
    if (
      this.props.auth.user.type !== "feeder" &&
      !this.props.profile.profile.roles.includes("CUSTOMER_ADD")
    ) {
      toastr.error("<h6>এডমিনের কাছে গ্রাহক এড করার পারমিশান নিন</h6>");
      return;
    }
    this.setState({
      modalTitle: "নতুন গ্রাহক",
      addLineUserFlag: true,
      lineUserToAddOrEdit: {
        resellerID: "",
        assignedArea: "",
        connectionType: "",
        billPayType: "",
        customerID: "",
        houseOwnerName: "",
        name: "",
        fatherName: "",
        mobile: "",
        email: "",
        fullAddress: "",
        nidNumber: "",
        referenceName: "",
        referenceMobile: "",
        monthlyBill: "",
        dueAmount: "",
        monthlyCommission: "",
        boxID: "",
        web: true,
        connectionDate: new Date(),
        mtkService: "pppoe",
        mtkProfile: "",
        mtkPassword: "",
        mtkName: "",
        mtkComment: "",
        addToMikrotik:
          this.props.profile?.feeder?.bpSettings?.hasMikrotik === "yes"
            ? true
            : false
      }
    });
    $("#lineUserAddOrEditModal").modal("toggle");
  };

  showEditLineUserModal = (lineUser, role, modalID) => {
    let dt = new Date();
    if (
      role &&
      this.props.auth.user.type !== "feeder" &&
      !this.props.profile.profile.roles?.includes(role)
    ) {
      toastr.error(
        `<h6>এডমিনের কাছে ${toastMessages[role]} করার পারমিশান নিন</h6>`
      );
      return;
    }
    let area = this.state.areas.find(a => a.value === lineUser.assignedArea);
    let reseller = this.state.resellers.find(
      r => r.value === lineUser.resellerID
    );
    let profile = this.state.packages.find(
      p => p.label === lineUser?.mtk?.profile
    );

    let addToMikrotik =
      this.props.profile?.feeder?.bpSettings?.hasMikrotik === "yes"
        ? true
        : false;
    if (lineUser.connectionType != 2) addToMikrotik = false;

    this.setState({
      lineUserToAddOrEdit: {
        id: lineUser._id,
        resellerID: reseller || "",
        assignedArea: area,
        connectionType: lineUser.connectionType,
        billPayType: lineUser.billPayType,
        customerID: lineUser.customerID,
        houseOwnerName: lineUser.houseOwnerName,
        name: lineUser.name,
        fatherName: lineUser.fatherName,
        mobile: lineUser.mobile,
        email: lineUser.email,
        fullAddress: lineUser.fullAddress,
        boxID: lineUser.boxID,
        // flatNo: lineUser.flatNo,
        // flat: lineUser.flat,
        // house: lineUser.house,
        // thana: lineUser.thana,
        // district: lineUser.district,
        nidNumber: lineUser.nidNumber,
        referenceName: lineUser?.reference?.name,
        referenceMobile: lineUser?.reference?.mobile,
        monthlyBill: lineUser.monthlyBill || 0,
        dueAmount: lineUser.nextBill || 0,
        monthlyCommission: lineUser.monthlyCommission || 0,
        web: true,
        connectionDate: lineUser.connectionDate
          ? new Date(lineUser.connectionDate)
          : "",
        mtkName: lineUser.mtk?.name,
        mtkPassword: lineUser.mtk?.password,
        mtkProfile: profile,
        mtkService: lineUser.mtk?.service,
        mtkComment: lineUser.mtk?.comments,
        addToMikrotik,
        ms: dt.getMilliseconds(),
        securityMoney: lineUser.securityMoney
      },
      modalTitle: `গ্রাহক আপডেট: ${lineUser.name}`,
      addLineUserFlag: false
    });
    if (role && modalID) $(modalID).modal("toggle");
  };

  activeOrDelete = (lineUser, role) => {
    if (
      role &&
      this.props.auth.user.type !== "feeder" &&
      !this.props.profile.profile.roles?.includes(role)
    ) {
      toastr.error(
        `<h6>এডমিনের কাছে ${toastMessages[role]} করার পারমিশান নিন</h6>`
      );
      return;
    }

    let lineUserdata = {
      lineUserID: lineUser._id,
      feederID:
        this.props.auth.user.type === "feeder"
          ? this.props.auth.user.profile.feederID
          : this.props.auth.user.feeder.feederID
    };

    // acitvate
    if (role === "CUSTOMER_ACTIVATE") {
      let con = window.confirm(`আপনি কি ${lineUser.name} কে এক্টিভ করতে চান?`);
      if (!con) return;
      lineUserdata.status = 2;
      this.props.updateLineUserStatus(lineUserdata);
    }

    // delete
    if (role === "CUSTOMER_DELETE") {
      let con = window.confirm(`আপনি কি ${lineUser.name} কে ডিলিট করতে চান?`);
      if (!con) return;
      lineUserdata.status = 4;
      this.props.deleteLineUser(lineUserdata);
    }
  };

  defaultSorted = [
    {
      dataField: "customerID",
      order: "asc"
    }
  ];

  render() {
    const now = moment();
    const connectionType = ["", "ডিশ", "ইন্টারনেট"];
    const billPayType = ["", "প্রিপেইড", "পোস্টপেইড"];
    const {
      lineUser,
      areas,
      packages,
      resellers,
      assignedAreaToSearch,
      lineUserToAddOrEdit,
      lineUserToView,
      assignedResellerToSearch,
      filterDishOrIsp
    } = this.state;

    let feederType =
      this.props.auth.user.type === "feeder"
        ? this.props.auth.user.profile.feederType
        : this.props.auth.user.feeder.feederType;
    let company =
      this.props.auth.user.type === "feeder"
        ? this.props.auth.user.profile.company
        : this.props.auth.user.feeder.company;
    let hasReseller;
    if (this.props.auth.user.type === "feeder") {
      hasReseller = this.props.auth.user.profile.hasReseller;
    } else if (this.props.auth.user.type === "manager") {
      hasReseller = this.props.auth.user.feeder.hasReseller;
    }

    const type = this.props.match.params.type || "all";
    // let { lineUsers } = lineUser

    let columns = [
      {
        dataField: "connectionType",
        text: "",
        sort: true,
        style: {
          wordWrap: "break-word",
          paddingLeft: "2px",
          paddingRight: "2px"
        },
        // headerAlign: "center",
        align: "center",
        headerStyle: { width: "35px" },
        formatter: cell =>
          cell === 1 ? (
            <>
              <i class="fas fa-tv"></i>
              {/* &nbsp;<i class="fas fa-tv"></i> */}
            </>
          ) : (
            <>
              <i class="fas fa-wifi"></i>
              {/* &nbsp;<i class="fas fa-wifi"></i> */}
            </>
          )
      },
      {
        dataField: "customerID",
        text: "আইডি",
        sort: true,
        style: {
          wordWrap: "break-word",
          paddingLeft: "10px",
          paddingRight: "10px"
        },
        headerAlign: "center",
        align: "center"
        // headerStyle: { width: "92px" },
      },
      {
        dataField: "name",
        text: "নাম",
        style: {
          wordWrap: "break-word",
          paddingLeft: "10px",
          paddingRight: "10px"
        },
        sort: true,
        headerAlign: "center",
        align: "center"
        // headerStyle: { width: "154px" },
      },
      {
        // headerStyle: { width: "154px" },
        dataField: "houseOwnerName",
        text: " পরিচিতি",
        sort: true,
        headerAlign: "center",
        align: "center",
        style: {
          wordWrap: "break-word",
          paddingLeft: "10px",
          paddingRight: "10px"
        }
      },
      {
        // headerStyle: { width: "145px" },
        dataField: "mobile",
        formatter: cell => (cell !== "01700000000" ? cell : ""),
        style: {
          wordWrap: "break-word",
          paddingLeft: "10px",
          paddingRight: "10px"
        },
        text: "মোবাইল",
        sort: true,
        headerAlign: "center",
        align: "center"
      },
      {
        dataField: "lastBillAt",
        text: "শেষ বিল (দিন আগে)",
        style: {
          wordWrap: "break-word",
          paddingLeft: "10px",
          paddingRight: "10px"
        },
        sort: true,
        headerAlign: "center",
        align: "center",
        formatter: (cell, row) => {
          return cell ? (
            now.diff(moment(cell), "days") + 1 > 30 ? (
              <span className="text-danger">
                {now.diff(moment(cell), "days") + 1 + "d"}
              </span>
            ) : (
              <span className="text-success">
                {now.diff(moment(cell), "days") + 1 + "d"}
              </span>
            )
          ) : (
            "n/a"
          );
        }
        // // headerStyle: { width: "60px" },
      },
      {
        dataField: "monthlyBill",
        text: "মাসিক ফি",
        style: {
          wordWrap: "break-word",
          paddingLeft: "10px",
          paddingRight: "10px"
        },
        sort: true,
        headerAlign: "center",
        align: "center"
        // // headerStyle: { width: "60px" },
      },
      {
        dataField: "nextBill",
        text: "বকেয়া",
        style: {
          wordWrap: "break-word",
          paddingLeft: "10px",
          paddingRight: "10px"
        },
        sort: true,
        headerAlign: "center",
        align: "center"
        // // headerStyle: { width: "60px" },
      },
      {
        dataField: "_id",
        text: "Action",
        align: "center",
        headerAlign: "center",
        formatter: (cell, row) => (
          <ul className="navbar-nav navbar-left">
            <li className="dropdown">
              <a
                href="#"
                data-toggle="dropdown"
                className="nav-link nav-link-lg"
              >
                <i className="fas fa-ellipsis-h" style={{ fontSize: 24 }}></i>
              </a>
              <div style={{ width: 50 }} className="dropdown-menu p-1">
                <a
                  type="button"
                  onClick={() => this.viewHandler(row, "profile")}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#profileModal"
                >
                  <i className="fas fa-id-card" />
                  প্রোফাইল দেখুন
                </a>

                <a
                  type="button"
                  onClick={() => this.viewHandler(row, "report")}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#lineUserReportModal"
                >
                  <i className="fas fa-history" />
                  গ্রাহক রিপোর্ট
                </a>

                {!this.props.match.params.type && (
                  <>
                    <a
                      type="button"
                      onClick={() =>
                        this.showEditLineUserModal(
                          row,
                          "CUSTOMER_EDIT",
                          "#lineUserAddOrEditModal"
                        )
                      }
                      className="dropdown-item has-icon"
                    >
                      <i className="fas fa-edit" />
                      গ্রাহক আপডেট
                    </a>

                    <a
                      type="button"
                      onClick={() =>
                        this.showEditLineUserModal(
                          row,
                          "MONTHLY_FEE_EDIT",
                          "#monthlyBillUpdate"
                        )
                      }
                      className="dropdown-item has-icon"
                    >
                      <i class="fas fa-dollar-sign"></i>
                      মাসিক ফি আপডেট
                    </a>

                    <a
                      type="button"
                      onClick={() =>
                        this.showEditLineUserModal(
                          row,
                          "BILL_EDIT",
                          "#billUpdate"
                        )
                      }
                      className="dropdown-item has-icon"
                    >
                      <i className="fas fa-edit" />
                      বিল আপডেট
                    </a>

                    <a
                      type="button"
                      onClick={() => this.viewHandler(row)}
                      className="dropdown-item has-icon"
                      data-toggle="modal"
                      data-target="#sendSMSmodal"
                    >
                      <i className="fas fa-paper-plane" />
                      সেন্ড এস.এম.এস
                    </a>
                  </>
                )}
                {["manager", "reseller"].includes(this.props.auth.user.type) &&
                  ["unpaid", "partial"].includes(
                    this.props.match.params.type
                  ) && (
                    <a
                      type="button"
                      onClick={() => this.payBillClickHandler(row)}
                      className="dropdown-item has-icon font-weight-bold"
                      data-toggle="modal"
                      data-target="#billCollect"
                    >
                      <i className="fas fa-file-invoice" />
                      বিল সংগ্রহ
                    </a>
                  )}

                {this.props.auth.user.type === "manager" &&
                  this.props.match.params.type === "paid" && (
                    <a
                      type="button"
                      onClick={() => this.payBillClickHandler(row, "advanced")}
                      className="dropdown-item has-icon font-weight-bold"
                      data-toggle="modal"
                      data-target="#billCollect"
                    >
                      <i className="fas fa-file-invoice" />
                      অগ্রীম বিল সংগ্রহ
                    </a>
                  )}

                {["unpaid", "partial", "paid"].includes(
                  this.props.match.params.type
                ) && (
                  <>
                    <div className="dropdown-divider" />
                    <a
                      href="#"
                      onClick={() =>
                        this.showEditLineUserModal(
                          row,
                          "CUSTOMER_DEACTIVATE",
                          "#deactivate"
                        )
                      }
                      className="dropdown-item has-icon text-danger"
                    >
                      <i class="fas fa-stop"></i>বন্ধ করুন
                    </a>
                  </>
                )}

                {this.props.match.params.type === "inactive" && (
                  <>
                    <div className="dropdown-divider" />
                    <a
                      href="#"
                      onClick={() =>
                        this.activeOrDelete(row, "CUSTOMER_ACTIVATE")
                      }
                      className="dropdown-item has-icon text-success"
                    >
                      <i class="fas fa-check"></i>এক্টিভ করুন
                    </a>
                    <a
                      href="#"
                      onClick={() =>
                        this.activeOrDelete(row, "CUSTOMER_DELETE")
                      }
                      className="dropdown-item has-icon text-danger"
                    >
                      <i class="fas fa-stop"></i>ডিলিট করুন
                    </a>
                  </>
                )}
              </div>
            </li>
          </ul>
        )
      }
    ];

    let lineUsers = [],
      totalNextBill = 0,
      totalMonthlyBill = 0;

    if (lineUser.isLoaded) {
      if (type === "all")
        lineUsers = [
          ...lineUser.lineUsers.unpaid,
          ...lineUser.lineUsers.partial,
          ...lineUser.lineUsers.paid,
          ...lineUser.lineUsers.advanced,
          ...lineUser.lineUsers.free,
          ...lineUser.lineUsers.inactive
        ];
      else if (type === "active")
        lineUsers = [
          ...lineUser.lineUsers.unpaid,
          ...lineUser.lineUsers.partial,
          ...lineUser.lineUsers.paid,
          ...lineUser.lineUsers.advanced
        ];
      else if (type === "inactive") lineUsers = lineUser.lineUsers.inactive;
      else if (type === "free") lineUsers = lineUser.lineUsers.free;
      else if (type === "unpaid") {
        lineUsers = lineUser.lineUsers.unpaid;
        // totalMonthlyBill = lineUser.lineUsers.unpaidMonthlyBill
        // totalNextBill = lineUser.lineUsers.unpaidNextBill
      } else if (type === "partial") {
        lineUsers = lineUser.lineUsers.partial;
        // totalNextBill = lineUser.lineUsers.partialNextBill
      } else if (type === "paid") lineUsers = lineUser.lineUsers.paid;
      else if (type === "advanced") lineUsers = lineUser.lineUsers.advanced;

      let result = this.sortLineUser(
        lineUsers,
        assignedAreaToSearch.value,
        assignedResellerToSearch.value,
        filterDishOrIsp.value,
        type
      );

      lineUsers = result.users;
      totalMonthlyBill = result.totalMonthlyBill;
      totalNextBill = result.totalNextBill;
    }

    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>গ্রাহক</h4>
            </div>
            <div className="container">
              <div
                style={{ minHeight: 230 }}
                className="row justify-content-center p-3 bg-white align-items-center"
              >
                {lineUser.isLoaded ? (
                  <div className="col-12">
                    <div className="clearfix">
                      <h4 className="float-left">
                        {this.getLineUserType()} গ্রাহক
                      </h4>
                      <button
                        onClick={this.showAddLineUserModal}
                        className="btn btn-success float-right mx-2"
                      >
                        <i class="fas fa-user-plus" title="নতুন গ্রাহক"></i>
                      </button>
                    </div>
                    <hr />

                    <PaginationProvider
                      pagination={paginationFactory({
                        sizePerPageList: [
                          { text: "100", value: 100 },
                          { text: "200", value: 200 },
                          { text: "500", value: 500 },
                          { text: "1000", value: 1000 },
                          { text: "2000", value: 2000 },
                          { text: "All", value: lineUsers.length }
                        ]
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          bootstrap4
                          keyField="lineUser"
                          data={lineUsers}
                          columns={columns}
                          search
                        >
                          {props => (
                            <div>
                              <div className="row">
                                {feederType === 3 && (
                                  <div className="col-md-4">
                                    <Select
                                      value={filterDishOrIsp}
                                      options={[
                                        {
                                          label: "ডিশ এবং ইন্টারনেট",
                                          value: "all"
                                        },
                                        {
                                          label: "ডিশ",
                                          value: 1
                                        },
                                        {
                                          label: "ইন্টারনেট",
                                          value: 2
                                        }
                                      ]}
                                      onChange={filterDishOrIsp =>
                                        this.setState({
                                          filterDishOrIsp
                                        })
                                      }
                                    />
                                  </div>
                                )}
                                <div className="col-md-4">
                                  <Select
                                    value={assignedAreaToSearch}
                                    options={[
                                      { label: "সকল এরিয়া", value: "all" },
                                      ...areas
                                    ]}
                                    onChange={assignedAreaToSearch =>
                                      this.setState({ assignedAreaToSearch })
                                    }
                                  />
                                </div>
                                {hasReseller === "yes" && (
                                  <div className="col-md-4">
                                    <Select
                                      value={assignedResellerToSearch}
                                      options={[
                                        { label: "সকল রিসেলার", value: "all" },
                                        ...resellers
                                      ]}
                                      onChange={assignedResellerToSearch =>
                                        this.setState({
                                          assignedResellerToSearch
                                        })
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="row mt-3">
                                <div className="col-md-8">
                                  {(this.props.match.params.type === "unpaid" ||
                                    this.props.match.params.type ===
                                      "active") && (
                                    <strong>
                                      মোটঃ {formattedNumber(lineUsers.length)}{" "}
                                      জন | মোট বকেয়াঃ{" "}
                                      {formattedNumber(totalNextBill)} টাকা (
                                      চলতি মাসঃ{" "}
                                      {formattedNumber(totalMonthlyBill)},
                                      পূর্বের বকেয়াঃ{" "}
                                      {formattedNumber(
                                        totalNextBill - totalMonthlyBill
                                      )}{" "}
                                      )
                                    </strong>
                                  )}
                                  {this.props.match.params.type ===
                                    "partial" && (
                                    <strong>
                                      মোটঃ {lineUsers.length} জন | মোট বকেয়াঃ{" "}
                                      {formattedNumber(totalNextBill)} টাকা
                                    </strong>
                                  )}
                                  {this.props.match.params.type !== "partial" &&
                                    this.props.match.params.type !== "unpaid" &&
                                    this.props.match.params.type !==
                                      "active" && (
                                      <strong>
                                        মোটঃ {lineUsers.length} জন
                                      </strong>
                                    )}
                                </div>
                                <div className="col-md-4 text-right">
                                  <SearchBar {...props.searchProps} />
                                  <ExportToExcel
                                    apiData={
                                      feederType === 2
                                        ? [
                                            [
                                              "Date(dd/mm/yy)",
                                              "Customer Name",
                                              "Customer Address",
                                              "Contact No",
                                              "Email",
                                              "Item Name",
                                              "Quantity",
                                              "Unit Price",
                                              "VAT Percentage(%)",
                                              "VAT Amount",
                                              "Discount Percentage(%)",
                                              "Discount Amount",
                                              "Others",
                                              "Total Due Amount",
                                              "User ID"
                                            ],
                                            ...lineUsers.map(item => [
                                              "",
                                              item.name,
                                              item.houseOwnerName,
                                              item.mobile === "01700000000"
                                                ? ""
                                                : item.mobile,
                                              item.email ? item.email : "",
                                              "",
                                              "",
                                              item.monthlyBill,
                                              "",
                                              "",
                                              "",
                                              "",
                                              "",
                                              item.nextBill,
                                              item.customerID
                                            ])
                                          ]
                                        : [
                                            [
                                              "ID",
                                              "Name",
                                              "Address",
                                              "Mobile",
                                              "Monthly Fee",
                                              "Due"
                                            ],
                                            ...lineUsers.map(item => [
                                              item.customerID,
                                              item.name,
                                              item.houseOwnerName,
                                              item.mobile === "01700000000"
                                                ? ""
                                                : item.mobile,
                                              item.monthlyBill,
                                              item.nextBill
                                            ])
                                          ]
                                    }
                                    fileName={`${this.getLineUserType()} গ্রাহক - ${company}`}
                                  />
                                  <ReactToPrint
                                    trigger={() => (
                                      <button
                                        style={{
                                          border: "none",
                                          background: "none"
                                        }}
                                        type="button"
                                      >
                                        <i
                                          style={{
                                            fontSize: "25px",
                                            color: "#f40f02"
                                          }}
                                          className="fas fa-file-pdf"
                                        ></i>
                                      </button>
                                    )}
                                    content={() => this.componentRef}
                                  />
                                  <div style={{ display: "none" }}>
                                    <PdfComponent
                                      ref={el => (this.componentRef = el)}
                                    >
                                      <table
                                        style={{ color: "#000" }}
                                        className="table table-bordered mt-5 table-md"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              colSpan="6"
                                              className="text-center"
                                            >
                                              <span className="text-center">
                                                <strong>{`${this.props.profile?.feeder?.company}`}</strong>
                                                <br />
                                                {`${this.props.profile?.feeder?.fullAddress}`}
                                                <br />
                                                <strong>
                                                  {this.getLineUserType()}{" "}
                                                  গ্রাহক,{" "}
                                                  {assignedAreaToSearch.label}
                                                </strong>
                                                <p>
                                                  {moment(d).format(
                                                    "DD/MM/yyyy hh:mm A"
                                                  )}
                                                </p>
                                              </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>আইডি</th>
                                            <th>নাম</th>
                                            <th>ঠিকানা</th>
                                            <th>মোবাইল</th>
                                            <th>মাসিক ফি</th>
                                            <th>বকেয়া</th>
                                            <th>নোট</th>
                                          </tr>

                                          {lineUsers.map((item, idx) => {
                                            return (
                                              <tr key={idx}>
                                                <td
                                                  style={{ fontSize: "25px" }}
                                                >
                                                  {item.customerID}
                                                </td>
                                                <td
                                                  style={{ fontSize: "25px" }}
                                                >
                                                  {item.name}
                                                </td>
                                                <td
                                                  style={{ fontSize: "25px" }}
                                                >
                                                  {item.houseOwnerName}
                                                </td>
                                                <td
                                                  style={{ fontSize: "25px" }}
                                                >
                                                  {item.mobile === "01700000000"
                                                    ? ""
                                                    : item.mobile}
                                                </td>
                                                <td
                                                  style={{ fontSize: "25px" }}
                                                >
                                                  {item.monthlyBill}
                                                </td>
                                                <td
                                                  style={{ fontSize: "25px" }}
                                                >
                                                  {item.nextBill}
                                                </td>
                                                <td
                                                  style={{ fontSize: "25px" }}
                                                ></td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </PdfComponent>
                                  </div>
                                </div>
                              </div>{" "}
                              {/* End of row */}
                              <BootstrapTable
                                rowStyle={rowStyleFormat}
                                striped
                                hover
                                {...props.baseProps}
                                {...paginationTableProps}
                                defaultSorted={this.defaultSorted}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </div>
                ) : (
                  <div class="fa-3x">
                    <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>

        <AddEdit
          id="lineUserAddOrEditModal"
          lineUserToAddOrEdit={lineUserToAddOrEdit}
          areas={areas}
          packages={packages}
          resellers={resellers}
          addLineUserFlag={this.state.addLineUserFlag}
          modalTitle={this.state.modalTitle}
        />
        {/* Send sms Modal*/}
        <SendSmsModal
          data={this.state.lineUserToView}
          pending={this.props.server.requestPending}
          action={this.props.sendSingleSMS}
        />
        <MonthlyBillUpdate
          id="monthlyBillUpdate"
          lineUserToAddOrEdit={lineUserToAddOrEdit}
        />
        <BillUpdate id="billUpdate" lineUserToAddOrEdit={lineUserToAddOrEdit} />
        <Deactivate id="billUpdate" lineUserToAddOrEdit={lineUserToAddOrEdit} />
        <Profile id="profileModal" lineUserToView={lineUserToView} />
        <Report
          id="lineUserReportModal"
          lineUser={lineUserToView}
          lineUserDetail={this.state.lineUserDetail}
        />
        <BillCollect
          id="billCollect"
          lineUser={lineUserToView}
          month={this.state.month}
          year={this.state.year}
          billType={this.state.billType}
          advanceMonth={this.state.advanceMonth}
          selectedMonths={this.state.selectedMonths}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    feederID: state.auth.user.profile.feederID,
    profile: state.profile,
    lineUser: state.lineUser,
    server: state.server,
    reseller: state.reseller,
    auth: state.auth
  };
};

export default connect(mapStateToProps, {
  getAllLineUsers,
  getDetailLineUser,
  getManager,
  sendSingleSMS,
  updateLineUserStatus,
  deleteLineUser,
  deleteLineUserBill,
  getSingleUserBillReport
})(LineUser);
