import axios from "axios"
import $ from "jquery"
import toastr from "toastr"
import {
  ADD_STAFF,
  GET_ALL_STAFFS,
  LOADING,
  RESET,
  UPDATE_STAFF,
  GET_STAFF_SALARY,
  GET_MANAGER_BALANCE,
} from "../actions/types"
import { SERVER_URL } from "../config/config"

export const getAllStaffs = () => (dispatch) => {
  axios(`${SERVER_URL}/api/staff/get/all/`)
    .then((res) => {
      const { status, data } = res.data
      if (status) {
        dispatch({
          type: GET_ALL_STAFFS,
          payload: data,
        })
      } else {
        toastr.error("Something Went Wrong!")
      }
    })
    .catch((err) => toastr.error("কর্মচারী লোড ব্যর্থ হয়েছে!!"))
}

export const addNewStaff = (staffData) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .post(`${SERVER_URL}/api/staff/add/`, staffData)
    .then((res) => {
      const { status, data } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        $("#Modal").modal("hide")
        dispatch({
          type: ADD_STAFF,
          payload: data,
        })
        toastr.success("কর্মচারী অ্যাড হয়েছে।")
      } else {
        for (const property in data) {
          toastr.error(`${data[property]}`)
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("কর্মচারী অ্যাড ব্যর্থ হয়েছে!!")
    })
}

export const updateStaff = (staffData) => (dispatch) => {
  dispatch({ type: LOADING })
  axios
    .put(`${SERVER_URL}/api/staff/edit/${staffData._id}`, staffData)
    .then((res) => {
      const { status, data, msg } = res.data
      dispatch({ type: RESET })
      if (status) {
        $("#Modal").modal("hide")
        dispatch({ type: UPDATE_STAFF, payload: data })
        toastr.success(msg)
      } else toastr.error(data)
    })
    .catch((err) => {
      dispatch({ type: RESET })
      toastr.error("আপডেট ব্যর্থ হয়েছে!!")
    })
}

export const payStaffSalary = (data) => (dispatch) => {
  dispatch({ type: LOADING })
  axios
    .post(`${SERVER_URL}/api/staff/salary/${data._id}`, data)
    .then((res) => {
      const { status, data, msg } = res.data
      const { staff, salary } = data
      console.log(data)
      dispatch({ type: RESET })
      if (status) {
        $("#paySalaryModal").modal("hide")
        dispatch({
          type: UPDATE_STAFF,
          payload: staff,
          salary,
        })
        toastr.success(msg)
      } else toastr.error(msg)
    })
    .catch((err) => {
      dispatch({ type: RESET })
      toastr.error("পে স্যালারি ব্যর্থ হয়েছে!!")
      console.log(err)
    })
}

export const getSalary = (id) => (dispatch) => {
  dispatch({
    type: GET_STAFF_SALARY,
    payload: [],
    loading: true,
  })

  dispatch({
    type: LOADING,
  })

  axios(`${SERVER_URL}/api/staff/salary/${id}`)
    .then((res) => {
      const { status, data } = res.data
      if (status) {
        dispatch({
          type: GET_STAFF_SALARY,
          payload: data,
        })

        dispatch({
          type: RESET,
        })
      } else {
        toastr.error("Something Went Wrong!")
      }
    })
    .catch((err) => toastr.error("স্যালারী লোড ব্যর্থ হয়েছে!!"))
}
