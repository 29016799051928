import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import { getAllCollectors } from "../../actions/collectorActions";
import {
  addArea,
  deleteArea,
  setProfile,
  updateArea
} from "../../actions/profileActions";
import PdfComponent from "../../components/common/PdfComponent";
import Modal from "../../components/modals/Modal";

const { SearchBar } = Search;

class Area extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      _id: "",
      update: false
    };
  }

  componentDidMount() {
    const { collector, getAllCollectors } = this.props;

    if (!collector.isLoaded) {
      getAllCollectors();
    }
  }

  onChangeHandler = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  onSubmit = e => {
    e.preventDefault();
    if (this.state.update) this.props.updateArea(this.state);
    else this.props.addArea(this.state);
  };

  columns = [
    {
      dataField: "name",
      text: "এরিয়া",
      style: {
        wordWrap: "break-word",
        paddingLeft: "10px",
        paddingRight: "10px"
      },
      sort: true,
      headerAlign: "center",
      align: "center"
    },
    {
      dataField: "_id",
      text: "কালেক্টরস",
      style: { wordWrap: "break-word" },
      headerAlign: "center",
      formatter: cell => {
        let res = [];
        this.props.collector.collectors.map(collector => {
          if (collector.assignedAreas.includes(cell)) res.push(collector.name);
        });
        return res.join(", ");
      }
    },
    {
      dataField: "_id",
      text: "Action",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => (
        <ul className="navbar-nav navbar-right">
          <li className="dropdown">
            <a href="#" data-toggle="dropdown" className="nav-link nav-link-lg">
              <i className="fas fa-ellipsis-h" style={{ fontSize: 24 }}></i>
            </a>
            <div style={{ width: 50 }} className="dropdown-menu p-1">
              <a
                type="button"
                onClick={() =>
                  this.setState({
                    name: row.name,
                    _id: row._id,
                    update: true
                  })
                }
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#Modal"
              >
                <i className="fas fa-edit" />
                নাম পরিবর্তন
              </a>
              <a
                type="button"
                onClick={() => this.props.deleteArea(cell)}
                className="dropdown-item has-icon text-danger"
              >
                <i class="fas fa-trash"></i>ডিলিট করুন
              </a>
            </div>
          </li>
        </ul>
      )
    }
  ];

  render() {
    let company =
      this.props.auth.user.type === "feeder"
        ? this.props.auth.user.profile.company
        : this.props.auth.user.feeder.company;

    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>এরিয়া</h4>
            </div>
            <div className="container">
              <div
                style={{ minHeight: 230 }}
                className="row justify-content-center p-3 bg-white align-items-center"
              >
                {this.props.profile.isLoaded &&
                this.props.collector.isLoaded ? (
                  <div className="col-12">
                    <div className="clearfix py-3">
                      <h4 className="float-left">সকল এরিয়া</h4>
                      <button
                        onClick={() =>
                          this.setState({
                            name: "",
                            update: false
                          })
                        }
                        className="btn btn-success float-right"
                        data-toggle="modal"
                        data-target="#Modal"
                      >
                        <i class="fas fa-plus" title="নতুন যোগ করুন"></i>
                      </button>
                    </div>
                    <hr />

                    <PaginationProvider
                      pagination={paginationFactory({
                        sizePerPageList: [
                          { text: "10", value: 10 },
                          { text: "20", value: 20 },
                          { text: "50", value: 50 },
                          { text: "100", value: 100 },
                          { text: "200", value: 200 },
                          { text: "500", value: 500 },
                          { text: "1000", value: 1000 },
                          { text: "2000", value: 2000 },
                          {
                            text: "All",
                            value: this.props.profile.profile.areas?.length
                          }
                        ]
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          bootstrap4
                          keyField="_id"
                          data={this.props.profile.profile.areas}
                          columns={this.columns}
                          search
                        >
                          {props => (
                            <div>
                              <div className="row justify-content-between">
                                <div className="col-md-4">
                                  <strong>
                                    মোটঃ{" "}
                                    {this.props.profile.profile.areas.length}
                                  </strong>
                                </div>
                                <div className="col-md-6 text-right">
                                  <SearchBar {...props.searchProps} />
                                  <CSVLink
                                    filename={`এরিয়া - ${company}.csv`}
                                    data={[
                                      ["এরিয়া"],
                                      ...this.props.profile.profile.areas.map(
                                        item => [item.name]
                                      )
                                    ]}
                                  >
                                    <i
                                      style={{ fontSize: "25px" }}
                                      className="fas fa-file-csv ml-2"
                                    ></i>
                                  </CSVLink>
                                  <ReactToPrint
                                    trigger={() => (
                                      <button
                                        style={{
                                          border: "none",
                                          background: "none"
                                        }}
                                        type="button"
                                      >
                                        <i
                                          style={{ fontSize: "25px" }}
                                          className="fas fa-file-pdf"
                                        ></i>
                                      </button>
                                    )}
                                    content={() => this.componentRef}
                                  />
                                  <div style={{ display: "none" }}>
                                    <PdfComponent
                                      ref={el => (this.componentRef = el)}
                                    >
                                      <table
                                        style={{ color: "#000" }}
                                        className="table table-bordered mt-5 table-md"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              colSpan="6"
                                              className="text-center"
                                            >
                                              <span className="text-center">
                                                <strong>{`${this.props.auth.user.profile?.company}`}</strong>
                                                <br />
                                                {`${
                                                  this.props.auth.user.profile
                                                    ?.address.house
                                                    ? this.props.auth.user
                                                        .profile?.address
                                                        .house + ", "
                                                    : ""
                                                }${
                                                  this.props.auth.user.profile
                                                    ?.address.road
                                                    ? this.props.auth.user
                                                        .profile?.address.road +
                                                      ", "
                                                    : ""
                                                }${
                                                  this.props.auth.user.profile
                                                    ?.address.area
                                                    ? this.props.auth.user
                                                        .profile?.address.area +
                                                      ", "
                                                    : ""
                                                }${
                                                  this.props.auth.user.profile
                                                    ?.address.thana
                                                    ? this.props.auth.user
                                                        .profile?.address
                                                        .thana + ", "
                                                    : ""
                                                }${
                                                  this.props.auth.user.profile
                                                    ?.address.district
                                                    ? this.props.auth.user
                                                        .profile?.address
                                                        .district
                                                    : ""
                                                }`}
                                                <br />
                                                <strong>
                                                  <ul>সকল এরিয়া</ul>
                                                </strong>
                                              </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>এরিয়া</th>
                                          </tr>

                                          {this.props.profile.profile.areas.map(
                                            (item, idx) => {
                                              return (
                                                <tr key={idx}>
                                                  <td>{item.name}</td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </PdfComponent>
                                  </div>
                                </div>
                              </div>

                              <BootstrapTable
                                striped
                                hover
                                {...props.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </div>
                ) : (
                  <div class="fa-3x">
                    <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        <Modal
          modalTitle={this.state.update ? "এডিট এরিয়া" : "নতুন এরিয়া"}
          onSubmit={this.onSubmit}
          pending={this.props.server.requestPending}
          id="Modal"
        >
          <div className="form-group">
            <input
              className="form-control"
              onChange={this.onChangeHandler}
              value={this.state.name}
              type="text"
              name="name"
              maxLength="100"
              placeholder="এরিয়ার নাম"
              disabled={this.props.server.requestPending}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profile,
    server: state.server,
    collector: state.collector,
    auth: state.auth
  };
};

export default connect(mapStateToProps, {
  setProfile,
  getAllCollectors,
  addArea,
  deleteArea,
  updateArea
})(Area);
