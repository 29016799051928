import { ADD_EXPENDITURES, DELETE_EXPENDITURE, GET_ALL_EXPENDITURES } from "../actions/types";

const initialState = {
  isLoaded: false,
  expenditures: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_EXPENDITURES:
      return {
        isLoaded: true,
        expenditures: action.payload,
      };
    case ADD_EXPENDITURES:
      return {
        ...state,
        expenditures: [...state.expenditures, action.payload],
      };
    case DELETE_EXPENDITURE:
      return {
        ...state,
        expenditures: state.expenditures.filter((item) => item._id !== action.payload),
      }
    default:
      return state;
  }
};