const initialState = {
  isAuthenticated: false,
  user: {},
  status: {},
  invoice: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      }
    case "GET_INFO":
      return {
        ...state,
        date: action.payload,
        isDayOpen: action.payload.isDayOpen,
      }
    case "GET_STATUS":
      return {
        ...state,
        status: action.payload,
        invoice: action.invoice,
        isDayOpen: action.payload.isDayOpen,
      }
    default:
      return state
  }
}
