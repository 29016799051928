import {
  GET_MANAGER,
  MANAGER_LOADING,
  SET_EXPENDITURE_PURPOSE,
  SET_PROFILE,
  UPDATE_PROFILE,
  GET_MANAGER_BALANCE,
} from "../actions/types"

const initialState = {
  isLoaded: false,
  managerLoading: false,
  managerLoaded: false,
  isManagerBalanceLoaded: false,
  profile: {},
  feeder: {},
  manager: {},
  managerBalance: {},
  sms: {
    loading: false,
    sms: {},
  },
  smsPrevMonth: {
    loading: false,
    sms: {},
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        isLoaded: true,
        profile: action.payload ? action.payload : state.profile,
        feeder: action.feeder,
      }
    case UPDATE_PROFILE:
      return {
        ...state,
        isLoaded: true,
        profile: action.payload,
      }
    case SET_EXPENDITURE_PURPOSE:
      return {
        ...state,
        isLoaded: true,
        profile: action.payload,
      }
    case GET_MANAGER:
      return {
        ...state,
        managerLoaded: true,
        managerLoading: false,
        manager: action.payload,
      }
    case MANAGER_LOADING:
      return {
        ...state,
        managerLoading: true,
      }
    case "FEEDER_GET_SMS":
      return {
        ...state,
        sms: action.payload,
      }
    case "FEEDER_GET_SMS_PREVMONTH":
      return {
        ...state,
        smsPrevMonth: action.payload,
      }

    case GET_MANAGER_BALANCE:
      return {
        ...state,
        isManagerBalanceLoaded: true,
        managerBalance: action.payload,
      }
    default:
      return state
  }
}
