import React, { Component } from "react"
import { connect } from "react-redux"
import { getDate } from "../../actions/authActions"
import { getSms, getSmsPrevMonth } from "../../actions/profileActions"
import { formattedNumber, monthsArr, toBangla } from "../../components/common/Utils"

const dt = new Date()
class Account extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sms: {},
      smsPrevMonth: {},
    }
  }

  componentDidMount() {
    if (!this.props.auth.date) {
      this.props.getDate(this.props.history)
    }

    let year = dt.getFullYear()
    let month = dt.getMonth()

    if (this.props.feeder.sms && "loading" in this.props.feeder.sms) {
      this.props.getSms(year, month + 1)
    } else {
      this.setState({ sms: this.props.feeder.sms })
    }
    if (this.props.feeder.smsPrevMonth && "loading" in this.props.feeder.smsPrevMonth) {
      this.props.getSmsPrevMonth(year, month)
    } else {
      this.setState({ smsPrevMonth: this.props.feeder.smsPrevMonth })
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.feeder.sms) {
      this.setState({ sms: nextProps.feeder.sms })
    }
    if (nextProps.feeder.smsPrevMonth) {
      this.setState({ smsPrevMonth: nextProps.feeder.smsPrevMonth })
    }
  }
  render() {
    const { sms, smsPrevMonth } = this.state
    const monthlyServiceCharge = this.props.auth.date && this.props.auth.date.monthlyServiceCharge
    const bpPackage = this.props.auth.date && this.props.auth.date.bpPackage
    const serverType = this.props.auth.date && this.props.auth.date.serverType

    let grandTotal,
      messageBill,
      grandTotalPrevMonth,
      messageBillPrevMonth,
      feederFlag = "old"

    // old feeders
    if (bpPackage === "Old" || serverType === "Old") {
      // current month
      messageBill = sms.billSmsPrice + sms.bulkSmsPrice + sms.alrtSmsPrice + sms.otherSmsPrice
      grandTotal = sms.grandTotal

      // previous month
      messageBillPrevMonth =
        smsPrevMonth.billSmsPrice + smsPrevMonth.bulkSmsPrice + smsPrevMonth.alrtSmsPrice + smsPrevMonth.otherSmsPrice
      grandTotalPrevMonth = smsPrevMonth.grandTotal
    }
    // new feeders
    else {
      feederFlag = "new"
      // current month
      messageBill = sms.bulkSmsPrice + sms.alrtSmsPrice + sms.billSmsPrice + sms.otherSmsPrice
      grandTotal = sms.grandTotal

      // previous month
      messageBillPrevMonth =
        smsPrevMonth.billSmsPrice + smsPrevMonth.bulkSmsPrice + smsPrevMonth.alrtSmsPrice + smsPrevMonth.otherSmsPrice
      grandTotalPrevMonth = smsPrevMonth.grandTotal
    }

    let jsx
    if (sms.loading) {
      jsx = (
        <div class="fa-3x">
          <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
        </div>
      )
    } else {
      jsx = (
        <table className={"table"}>
          <tr>
            <th></th>
            <th>ম্যাসেজ</th>
            <th>এসএমএস</th>
            <th>খরচ</th>
          </tr>

          <tr>
            <td>বিল</td>
            <td>{formattedNumber(sms.bill)}</td>
            <td>{formattedNumber(sms.billSms)}</td>
            <td>{formattedNumber(sms.billSmsPrice)} Tk.</td>
          </tr>
          <tr>
            <td>এলার্ট</td>
            <td>{formattedNumber(sms.alrt)}</td>
            <td>{formattedNumber(sms.alrtSms)}</td>
            <td>{formattedNumber(sms.alrtSmsPrice)} Tk.</td>
          </tr>

          <tr>
            <td>বাল্ক/কাস্টম</td>
            <td>{formattedNumber(sms.bulk)}</td>
            <td>{formattedNumber(sms.bulkSms)}</td>
            <td>{formattedNumber(sms.bulkSmsPrice)} Tk.</td>
          </tr>
          <tr>
            <td>অন্যান্য</td>
            <td>{formattedNumber(sms.other)}</td>
            <td>{formattedNumber(sms.otherSms)}</td>
            <td>{formattedNumber(sms.otherSmsPrice)} Tk.</td>
          </tr>

          <tr>
            <td></td>
            <td></td>
            <td>ম্যাসেজ বিলঃ</td>
            <td className={"turquoise-color"}>
              <strong>{formattedNumber(messageBill)} Tk.</strong>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>সার্ভিস চার্জঃ</td>
            <td className={"turquoise-color"}>
              <strong>{monthlyServiceCharge} Tk.</strong>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <th>মোট বিলঃ</th>
            <td className={"red-color"}>
              <strong>{formattedNumber(grandTotal)} Tk.</strong>
            </td>
          </tr>
        </table>
      )
    }

    let m2 = dt.getMonth()
    let y2 = dt.getFullYear()

    if (m2 === 0) {
      m2 = 11
      y2--
    } else {
      m2--
    }

    let jsx2
    if (smsPrevMonth.loading) {
      jsx2 = (
        <div class="fa-3x">
          <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
        </div>
      )
    } else {
      jsx2 = (
        <table className={"table"}>
          <tr>
            <th></th>
            <th>ম্যাসেজ</th>
            <th>এসএমএস</th>
            <th>খরচ</th>
          </tr>

          <tr>
            <td>বিল</td>
            <td>{formattedNumber(smsPrevMonth.bill)}</td>
            <td>{formattedNumber(smsPrevMonth.billSms)}</td>
            <td>{formattedNumber(smsPrevMonth.billSmsPrice)} Tk.</td>
          </tr>
          <tr>
            <td>এলার্ট</td>
            <td>{formattedNumber(smsPrevMonth.alrt)}</td>
            <td>{formattedNumber(smsPrevMonth.alrtSms)}</td>
            <td>{formattedNumber(smsPrevMonth.alrtSmsPrice)} Tk.</td>
          </tr>

          <tr>
            <td>বাল্ক/কাস্টম</td>
            <td>{formattedNumber(smsPrevMonth.bulk)}</td>
            <td>{formattedNumber(smsPrevMonth.bulkSms)}</td>
            <td>{formattedNumber(smsPrevMonth.bulkSmsPrice)} Tk.</td>
          </tr>
          <tr>
            <td>অন্যান্য</td>
            <td>{formattedNumber(smsPrevMonth.other)}</td>
            <td>{formattedNumber(smsPrevMonth.otherSms)}</td>
            <td>{formattedNumber(smsPrevMonth.otherSmsPrice)} Tk.</td>
          </tr>

          <tr>
            <td></td>
            <td></td>
            <td>ম্যাসেজ বিলঃ</td>
            <td className={"turquoise-color"}>
              <strong>{formattedNumber(messageBillPrevMonth)} Tk.</strong>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>সার্ভিস চার্জঃ</td>
            <td className={"turquoise-color"}>
              <strong>{monthlyServiceCharge} Tk.</strong>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <th>মোট বিলঃ</th>
            <td className={"red-color"}>
              <strong>{formattedNumber(grandTotalPrevMonth)} Tk.</strong>
            </td>
          </tr>
        </table>
      )
    }
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white border-rounded text-center">
              <h4>একাউন্ট</h4>
            </div>
            <div className="container">
              <div className="row p-3 bg-white align-items-start">
                <div className="col-md text-center">
                  <p className="pt-2" />
                  <h3 className={"text-center"}>
                    {monthsArr[dt.getMonth()]} - {toBangla(dt.getFullYear())}
                  </h3>
                  {jsx}
                  <p className="pt-5" />
                  <h3 className={"text-center"}>
                    {monthsArr[m2]} - {toBangla(y2)}
                  </h3>
                  {jsx2}
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  feeder: state.profile,
})
export default connect(mapStateToProps, {
  getSms,
  getSmsPrevMonth,
  getDate,
})(Account)
