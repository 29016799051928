import moment from "moment";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import PdfComponent from "../../components/common/PdfComponent";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {
  formattedNumber,
  monthsArr,
  paymentStatus
} from "../../components/common/Utils";
import { deleteLineUserBill } from "../../actions/lineUserActions";
import ReactToPrint from "react-to-print";

function formatMonth(cell, status) {
  if (cell === "99") return "";
  else if (cell && status === "advanced")
    return monthsArr[cell] + " পর্যন্ত অগ্রীম পরিশোধ";
  else return monthsArr[cell];
}

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lineUser: props.lineUser,
      bills: [],
      amount: "",
      status: "",
      month: "",
      year: "",
      paidAt: "",
      due: ""
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.lineUser.isLoaded && nextProps.lineUser?.bills?.length) {
      this.setState({ bills: nextProps.lineUser.bills });
    } else {
      this.setState({ bills: [] });
    }
  }

  deleteBillHandler = bill => {
    console.log(bill);
    if (this.props.auth.user.profile.canDeleteBill === "no") return;
    let con = window.confirm(
      `${this.props.lineUserToView.name} এর ${bill.amount} টাকা বিল ডিলিট করতে চান?`
    );
    if (con) {
      this.props.deleteLineUserBill(bill);
    }
  };

  // handlePrint = (row) => {
  //   this.setState({
  //     amount: row.amount,
  //     type: row.type,
  //     month: row.month,
  //     year: row.year,
  //     paidAt: row.paidAt,
  //     due: row.due,
  //   });
  // };

  // Bills table
  billsColumn = [
    {
      dataField: "status",
      text: "টাইপ",
      formatter: (cell, row) => {
        if (cell === "security") return "সংযোগ ফি";
        else if (cell === "advanced") return "অগ্রীম";
        else if (cell === "partial") return "আংশিক";
        else if (cell === "paid") return "পরিশোধ";
      }
    },
    {
      dataField: "month",
      text: "মাস",
      formatter: (cell, row) => formatMonth(cell, row.status)
    },
    {
      dataField: "year",
      text: "বছর"
    },
    {
      dataField: "amount",
      text: "আদায়",
      formatter: (cell, row) => formattedNumber(cell)
    },
    {
      dataField: "due",
      text: "বকেয়া",
      formatter: cell => formattedNumber(cell)
    },
    {
      dataField: "paidAt",
      text: "তারিখ",
      formatter: cell => moment(cell).format("DD/MM/yyyy hh:mm:ss A")
    },
    {
      dataField: "paidAt",
      text: "",
      headerStyle: { width: "30px" },
      formatter: (cell, row) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {this.props.auth.user.profile.canDeleteBill === "yes" ? (
              <i
                class="far fa-times-circle  bill-delete"
                onClick={() => this.deleteBillHandler(row)}
              ></i>
            ) : (
              ""
            )}
            <ReactToPrint
              trigger={() => (
                <button
                  style={{
                    border: "none",
                    background: "none"
                  }}
                  type="button"
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fas fa-file-pdf"
                    // onClick={() => this.handlePrint(row)}
                    onClick={() =>
                      this.setState({
                        amount: row.amount,
                        status: row.status,
                        month: row.month,
                        year: row.year,
                        paidAt: row.paidAt,
                        due: row.due
                      })
                    }
                  ></i>
                </button>
              )}
              content={() => this.componentRef}
            />
          </div>
        );
      }
    }
  ];

  render() {
    const { bills, amount, status, month, due, paidAt, year } = this.state;
    const { lineUserToView, auth } = this.props;
    console.log(lineUserToView);
    // console.log(paidAt);
    console.log(bills);
    // console.log(this.state.amount);
    console.log(this.props.auth.user.profile.address);
    // console.log(this.props.lineUser.lineUsers.partial);

    return (
      <div
        className="modal fade"
        id={this.props.id}
        tabindex="-1"
        role="dialog"
      >
        <div className="modal-dialog mw-100 w-75" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close close-fixed"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <div className="profile mb-2">
                <div className="text-center mb-2">
                  <i className="fas fa-user-circle fa-5x" title="avatar" />
                </div>
                <p className="text-center font-weight-bold mb-0">
                  {lineUserToView.customerID}
                </p>
                <p className="text-center font-weight-bold  mb-0">
                  {lineUserToView.name}
                </p>
                <p className="text-center font-weight-bold  mb-2">
                  {lineUserToView.mobile}
                </p>
                <p className="text-center font-weight-bold  mb-2">
                  {lineUserToView.houseOwnerName}
                </p>

                <p className="text-center mb-1 font-italic">
                  {lineUserToView.fullAddress || ""}
                </p>

                <p className="text-center mb-0">
                  সংযোগ টাইপঃ{" "}
                  <strong>
                    {lineUserToView.connectionType === 1 ? "ডিশ" : "ইন্টারনেট"}
                  </strong>
                </p>
                <p className="text-center mb-0">
                  পেমেন্ট টাইপঃ{" "}
                  <strong>
                    {lineUserToView.billPayType === 1
                      ? "প্রিপেইড"
                      : "পোস্টপেইড"}
                  </strong>
                </p>
                {lineUserToView.boxID && (
                  <p className="text-center mb-0">
                    বক্স আইডিঃ <strong>{lineUserToView.boxID}</strong>
                  </p>
                )}
                <p className="text-center mb-0">
                  মাসিক ফিঃ <strong>{lineUserToView.monthlyBill} টাকা</strong>
                </p>
                <p className="text-center mb-0">
                  বিলঃ <strong>{lineUserToView.nextBill} টাকা</strong>
                </p>

                <p className="text-center mb-2">
                  সংযোগ তারিখঃ{" "}
                  <strong>
                    {lineUserToView.connectionDate
                      ? moment(lineUserToView.connectionDate).format(
                          "DD/MM/yyyy"
                        )
                      : "নাই"}
                  </strong>
                </p>
                <p className="text-center font-italic">
                  পেমেন্ট স্ট্যাটাসঃ{" "}
                  <strong>
                    {paymentStatus[lineUserToView.currentMonthBS]}
                  </strong>
                </p>
              </div>

              {this.props?.server?.requestPending ? (
                <div className="fa-3x text-center">
                  <i
                    style={{ fontSize: 40 }}
                    className="fas fa-sync fa-spin"
                  ></i>
                </div>
              ) : bills.length > 0 ? (
                <BootstrapTable
                  bootstrap4
                  striped
                  hover
                  columns={this.billsColumn}
                  data={bills}
                  keyField={"paidAt"}
                  pagination={paginationFactory()}
                />
              ) : (
                <p className="text-center font-italic">
                  *** বিলের তথ্য পাওয়া যায়নি ***
                </p>
              )}
            </div>
          </div>
        </div>
        <div style={{ display: "none" }}>
          <PdfComponent
            ref={el => {
              this.componentRef = el;
            }}
          >
            <div
              style={{ textAlign: "justify", marginTop: "3rem", color: "#000" }}
            >
              <div
                style={{
                  background: "#e0e0e0",
                  color: "black",
                  padding: "1rem",
                  borderRadius: "20px"
                }}
              >
                <p className="text-center mb-2 h3">অফিস কপি</p>
                <p className="text-center mb-2 h2">
                  {auth.user.profile.company}
                </p>
                <p className="text-center mb-2 h4">
                  {this.props.auth.user.profile.address.area}
                </p>
              </div>

              <div style={{ height: "500px" }}>
                <div className="profile d-flex justify-content-between mt-4">
                  <div>
                    <p className="  m-2 mb-0 h4">
                      গ্রাহকঃ <strong>{lineUserToView.name}</strong>
                    </p>
                    <p className=" m-2 mb-2 h4">
                      আইডিঃ <strong>{lineUserToView.customerID}</strong>
                    </p>
                  </div>
                  <div>
                    <p className=" m-2 mb-3 h4">
                      মোবাইলঃ{" "}
                      <storng style={{ fontWeight: "650" }}>
                        {lineUserToView.mobile}
                      </storng>
                    </p>
                    <p className=" m-2 mb-3 h4">
                      পরিচিতিঃ{" "}
                      <storng style={{ fontWeight: "650" }}>
                        {lineUserToView.houseOwnerName}
                      </storng>
                    </p>
                  </div>
                </div>

                <table
                  className="mt-4"
                  style={{
                    border: "1px solid gray",
                    width: "100%",
                    color: "#000"
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        border: "1px solid gray",
                        border: "1px solid gray",
                        textAlign: "center"
                      }}
                    >
                      <th style={{ border: "1px solid gray" }}>Description</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ border: "1px solid gray" }}>
                        <div
                          style={{
                            marginLeft: "1.5rem",
                            marginTop: "1rem",
                            fontSize: "1.2rem"
                          }}
                        >
                          <p>
                            পেমেন্ট স্ট্যাটাসঃ
                            <stong
                              className="font-italic font-weight-bold ml-1"
                              style={{ color: "green" }}
                            >
                              {paymentStatus[lineUserToView.currentMonthBS]}
                            </stong>
                          </p>
                          <p>
                            পেমেন্টের সময়ঃ
                            <strong
                              className="font-italic"
                              style={{ fontWeight: "700" }}
                            >
                              {moment(paidAt).format("lll")}
                            </strong>
                          </p>
                          <p>
                            পেমেন্ট টাইপঃ
                            <strong>
                              {lineUserToView.billPayType === 1
                                ? "প্রিপেইড"
                                : "পোস্টপেইড"}
                            </strong>
                          </p>
                          <p>
                            মাসঃ{" "}
                            <storng style={{ fontWeight: "700" }}>
                              {formatMonth(month, status)}, {year} (পর্যন্ত)
                            </storng>
                          </p>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{ marginLeft: "1.5rem", fontSize: "1.2rem" }}
                        >
                          <p>
                            {" "}
                            মোট বিলঃ{" "}
                            <strong>
                              {formattedNumber(amount + due)} টাকা
                            </strong>
                          </p>
                          <p>
                            {" "}
                            পরিশোধঃ{" "}
                            <strong style={{ color: "blue" }}>
                              {formattedNumber(amount)} টাকা
                            </strong>
                          </p>
                          <p className="text-danger">
                            বকেয়াঃ <strong>{formattedNumber(due)}</strong> টাকা
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div
                  className="d-flex justify-content-between h3"
                  style={{ position: "relative" }}
                ></div>

                <div
                  className="d-flex justify-content-between h3"
                  style={{ marginTop: "8rem" }}
                >
                  <div>
                    <hr />
                    <p>গ্রাহক স্বাক্ষর</p>
                  </div>

                  <div>
                    <hr />
                    <p>প্রতিষ্ঠানের স্বাক্ষর</p>
                  </div>
                </div>
              </div>

              <div style={{ margin: "4rem" }}>
                <hr />
              </div>

              <div>
                <div
                  style={{
                    background: "#e0e0e0",
                    color: "black",
                    padding: "1rem",
                    borderRadius: "20px"
                  }}
                >
                  <p className="text-center mb-2 h3">গ্রাহক কপি</p>
                  <p className="text-center mb-2 h2">
                    {auth.user.profile.company}
                  </p>
                  <p className="text-center mb-2 h4">
                    {this.props.auth.user.profile.address.area}
                  </p>
                </div>
                <div style={{ height: "500px" }}>
                  <div className="profile d-flex justify-content-between mt-4">
                    <div>
                      <p className="  m-2 mb-0 h4">
                        গ্রাহকঃ <strong>{lineUserToView.name}</strong>
                      </p>
                      <p className=" m-2 mb-2 h4">
                        আইডিঃ <strong>{lineUserToView.customerID}</strong>
                      </p>
                    </div>
                    <div>
                      <p className=" m-2 mb-3 h4">
                        মোবাইলঃ{" "}
                        <storng style={{ fontWeight: "650" }}>
                          {lineUserToView.mobile}
                        </storng>
                      </p>
                      <p className=" m-2 mb-3 h4">
                        পরিচিতিঃ{" "}
                        <storng style={{ fontWeight: "650" }}>
                          {lineUserToView.houseOwnerName}
                        </storng>
                      </p>
                    </div>
                  </div>

                  <table
                    className="mt-4"
                    style={{
                      border: "1px solid gray",
                      width: "100%",
                      color: "#000"
                    }}
                  >
                    <thead>
                      <tr
                        style={{
                          border: "1px solid gray",
                          border: "1px solid gray",
                          textAlign: "center"
                        }}
                      >
                        <th style={{ border: "1px solid gray" }}>
                          Description
                        </th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ border: "1px solid gray" }}>
                          <div
                            style={{
                              marginLeft: "1.5rem",
                              marginTop: "1rem",
                              fontSize: "1.2rem"
                            }}
                          >
                            <p>
                              পেমেন্ট স্ট্যাটাসঃ
                              <stong
                                className="font-italic font-weight-bold ml-1"
                                style={{ color: "green" }}
                              >
                                {paymentStatus[lineUserToView.currentMonthBS]}
                              </stong>
                            </p>
                            <p>
                              পেমেন্টের সময়ঃ
                              <strong
                                className="font-italic"
                                style={{ fontWeight: "700" }}
                              >
                                {moment(paidAt).format("lll")}
                              </strong>
                            </p>
                            <p>
                              পেমেন্ট টাইপঃ
                              <strong>
                                {lineUserToView.billPayType === 1
                                  ? "প্রিপেইড"
                                  : "পোস্টপেইড"}
                              </strong>
                            </p>
                            <p>
                              মাসঃ{" "}
                              <storng style={{ fontWeight: "700" }}>
                                {formatMonth(month, status)}, {year} (পর্যন্ত)
                              </storng>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{ marginLeft: "1.5rem", fontSize: "1.2rem" }}
                          >
                            <p>
                              {" "}
                              মোট বিলঃ{" "}
                              <strong>
                                {formattedNumber(amount + due)} টাকা
                              </strong>
                            </p>
                            <p>
                              {" "}
                              পরিশোধঃ{" "}
                              <strong style={{ color: "blue" }}>
                                {formattedNumber(amount)} টাকা
                              </strong>
                            </p>
                            <p className="text-danger">
                              বকেয়াঃ <strong>{formattedNumber(due)}</strong>{" "}
                              টাকা
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div
                    className="d-flex justify-content-between h3"
                    style={{ position: "relative" }}
                  ></div>

                  <div
                    className="d-flex justify-content-between h3"
                    style={{ marginTop: "8rem" }}
                  >
                    <div>
                      <hr />
                      <p>গ্রাহক স্বাক্ষর</p>
                    </div>

                    <div>
                      <hr />
                      <p>প্রতিষ্ঠানের স্বাক্ষর</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PdfComponent>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    feederID: state.auth.user.profile.profileID,
    profile: state.profile,
    lineUser: state.lineUser,
    server: state.server,
    auth: state.auth
  };
};

export default connect(mapStateToProps, { deleteLineUserBill })(Profile);
