import React, { Component } from "react"
import CountUp from "react-countup"

import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

// Animation
import { easeQuadInOut } from "d3-ease"
import AnimatedProgressProvider from "./AnimatedProgressProvider"
import ChangingProgressProvider from "./ChangingProgressProvider"

class DashboardMeter extends Component {
  render() {
    const { manager, user, collection, auth } = this.props

    let duration = 1.25,
      percentage =
        auth.user.type === "feeder"
          ? (collection.total.amount * 100) / user.expected.total
          : (manager.totalCollection * 100) / user.expected.total

    percentage = percentage ? percentage : 0

    return (
      <>
        {/* METER */}
        <div className="card text-center mb-3 mx-2" style={{ minWidth: "18rem", maxWidth: "18rem" }}>
          <div className="card-body">
            <p className="card-title h4 font-weight-bold">
              <CountUp start={0} end={user.expected.total} duration={duration} separator="," prefix="৳ " />
            </p>
            <p className="card-text font-weight-bold h4">সম্ভাব্য</p>
          </div>
        </div>
        <div className="card text-center mb-3 mx-2" style={{ minWidth: "14rem", maxWidth: "14rem" }}>
          <div className="card-body">
            <AnimatedProgressProvider
              valueStart={0}
              valueEnd={percentage}
              duration={1.4}
              easingFunction={easeQuadInOut}
            >
              {(value) => {
                const roundedValue = Math.round(value)
                return (
                  <CircularProgressbar
                    value={value}
                    text={`${roundedValue}%`}
                    styles={buildStyles({ pathTransition: "none" })}
                  />
                )
              }}
            </AnimatedProgressProvider>
          </div>
        </div>
        <div className="card text-center mb-3 mx-2" style={{ minWidth: "18rem", maxWidth: "18rem" }}>
          <div className="card-body">
            <p className="card-title h4 font-weight-bold">
              <CountUp
                start={0}
                end={auth.user.type === "feeder" ? collection.total.amount : manager.totalCollection}
                duration={duration}
                separator=","
                prefix="৳ "
              />
            </p>
            <p className="card-text font-weight-bold h4">কালেকশন</p>
          </div>
        </div>
      </>
    )
  }
}

export default DashboardMeter
