import axios from "axios"
import $ from "jquery"
import toastr from "toastr"
import {
  GET_FEEDER,
  GET_MANAGER,
  LOADING,
  MANAGER_LOADING,
  RESET,
  SET_EXPENDITURE_PURPOSE,
  SET_PROFILE,
  UPDATE_COLLECTOR_NUMBER,
  UPDATE_OPERATOR_NUMBER,
  UPDATE_PROFILE,
  GET_MANAGER_BALANCE,
} from "../actions/types"
import { SERVER_URL } from "../config/config"
import store from "../store"

// Set Profile
export const setProfile = () => (dispatch) => {
  let api
  if (store.getState().auth.user.type === "feeder") {
    api = `${SERVER_URL}/api/profile/`
  } else if (store.getState().auth.user.type === "manager") {
    api = `${SERVER_URL}/api/profile/`
  } else if (store.getState().auth.user.type === "operator") {
    api = `${SERVER_URL}/api/profile`
  } else if (store.getState().auth.user.type === "reseller") {
    api = `${SERVER_URL}/api/profile`
  }

  axios
    .get(api)
    .then((res) => {
      let { status } = res.data
      let data = res.data
      if (status) {
        let feeder = {},
          profile = {}
        if (store.getState().auth.user.type === "feeder") {
          profile = res.data
          feeder = res.data
        } else if (store.getState().auth.user.type === "manager") {
          feeder = res.data.feederID
          delete res.data.feederID
          profile = res.data
        } else if (store.getState().auth.user.type === "operator") {
          feeder = data.feederID
          delete data.feederID
          profile = data
          let areas = feeder.areas.filter((area) => profile.assignedAreas.includes(area._id))
          feeder = {
            ...feeder,
            areas,
          }
        } else if (store.getState().auth.user.type === "reseller") {
          feeder = data.feederID
          delete data.feederID
          profile = data
          let areas = feeder.areas.filter((area) => profile.assignedAreas.includes(area._id))
          feeder = {
            ...feeder,
            areas,
          }
        }

        dispatch({
          type: SET_PROFILE,
          payload: profile,
          feeder,
        })
      } else {
        toastr.error(data.msg)
      }
    })
    .catch((err) => {
      console.log(err)
      toastr.error("প্রোফাইল সেট ব্যর্থ হয়েছে!!")
    })
}

//add area
export const addArea = (name) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .post(SERVER_URL + "/api/feeders/area/add/", name)
    .then((res) => {
      dispatch({
        type: RESET,
      })
      const { status, data } = res.data
      if (status) {
        dispatch({
          type: SET_PROFILE,
          payload: data,
          feeder: data,
        })
        $("#Modal").modal("hide")
        toastr.success("এরিয়া এড সফল হয়েছে!")
      } else {
        toastr.error("Something Went Wrong!")
      }
    })
    .catch((err) => toastr.error("এরিয়া এড ব্যর্থ হয়েছে!!"))
}

//delete area
export const deleteArea = (id) => (dispatch) => {
  axios
    .delete(SERVER_URL + "/api/feeders/area/delete/" + id)
    .then((res) => {
      const { status, data } = res.data
      if (status) {
        dispatch({
          type: SET_PROFILE,
          payload: data,
          feeder: data,
        })
        toastr.success("এরিয়া ডিলেট হয়েছে!")
      } else {
        toastr.error("<h6>" + data.name + "</h6>")
      }
    })
    .catch((err) => toastr.error("এরিয়া ডিলেট ব্যর্থ হয়েছে!!"))
}
//update area
export const updateArea = (obj) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(SERVER_URL + "/api/feeders/area/edit/" + obj._id, obj)
    .then((res) => {
      const { status, data } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        dispatch({
          type: SET_PROFILE,
          payload: data,
          feeder: data,
        })
        $("#Modal").modal("hide")
        toastr.success("এরিয়া আপডেট হয়েছে!")
      } else {
        toastr.error("Something Went Wrong!")
      }
    })
    .catch((err) => toastr.error("এরিয়া আপডেট ব্যর্থ হয়েছে!!"))
}

// actions for Expenditure Purpose
export const addExpenditurePurpose = (name) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .post(SERVER_URL + "/api/expenditure/purpose/add/", name)
    .then((res) => {
      dispatch({
        type: RESET,
      })
      const { status, data } = res.data
      if (status) {
        dispatch({
          type: SET_EXPENDITURE_PURPOSE,
          payload: data,
        })
        $("#Modal").modal("hide")
        toastr.success("খরচের খাত এড সফল হয়েছে!")
      } else {
        toastr.error("Something Went Wrong!")
      }
    })
    .catch((err) => {
      console.log(err)
      toastr.error("খরচের খাত এড ব্যর্থ হয়েছে!!")
    })
}

export const updateExpenditurePurpose = (obj) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(SERVER_URL + "/api/expenditure/purpose/edit/" + obj._id, obj)
    .then((res) => {
      const { status, data } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        dispatch({
          type: SET_EXPENDITURE_PURPOSE,
          payload: data,
        })
        $("#Modal").modal("hide")
        toastr.success("খরচের খাত আপডেট হয়েছে!")
      } else {
        toastr.error("Something Went Wrong!")
      }
    })
    .catch((err) => toastr.error("খরচের খাত আপডেট ব্যর্থ হয়েছে!!"))
}

export const deleteExpenditurePurpose = (id) => (dispatch) => {
  axios
    .delete(SERVER_URL + "/api/expenditure/purpose/delete/" + id)
    .then((res) => {
      const { status, data } = res.data
      if (status) {
        dispatch({
          type: SET_EXPENDITURE_PURPOSE,
          payload: data,
        })
        toastr.success("খরচের খাত ডিলেট হয়েছে!")
      } else {
        toastr.error("Something Went Wrong!")
      }
    })
    .catch((err) => toastr.error("খরচের খাত ডিলেট ব্যর্থ হয়েছে!!"))
}

export const sendSingleSMS = (data) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .post(SERVER_URL + "/api/sms/single/send", data)
    .then((res) => {
      const { status, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        toastr.success(msg)
        $("#sendSMSmodal").modal("hide")
      } else toastr.error("Something Went Wrong!")
    })
    .catch((err) => toastr.error("Try again later!!"))
}

// check Sms Before Send
export const checkSmsBeforeSend = (data) => (dispatch) => {
  dispatch({ type: LOADING })
  axios
    .post(`${SERVER_URL}/api/sms/bulk/check`, data)
    .then((res) => {
      if (res.data.status) {
        let temp = `মেসেজ পাঠাতে চাইলে OK বাটনে ক্লিক করুন। 
                    গ্রাহকঃ ${res.data.totalUser} জন
                    মেসেজ পাবেঃ ${res.data.smsSent} জন
                    মেসেজ সাইজঃ  ${res.data.smsSize}
                    মোট খরচঃ ${res.data.totalSmsPrice} টাকা`
        let con = window.confirm(temp)
        if (con) {
          axios.post(`${SERVER_URL}/api/sms/bulk/send`, data).then((res) => {
            if (res.data.status) {
              toastr.success(res.data.msg)
              dispatch({ type: RESET })
            }
          })
        } else {
          dispatch({ type: RESET })
        }
      } else {
        toastr.error(`Error: ${res.data.msg}`)
        dispatch({ type: RESET })
      }
    })
    .catch((err) => {
      toastr.error(`Error: ${err}`)
      dispatch({ type: RESET })
    })
}

// get manager
export const getManager = (id) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  dispatch({
    type: MANAGER_LOADING,
  })
  axios
    .get(SERVER_URL + `/api/managers/${id}`)
    .then((res) => {
      const { status, data } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        dispatch({
          type: GET_MANAGER,
          payload: data,
        })
      } else toastr.error("Something Went Wrong!")
    })
    .catch((err) => toastr.error("Try again later!!"))
}

// Pay Deposit
export const payDeposit = (depositData) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .post(SERVER_URL + "/api/managers/deposit", depositData)
    .then((res) => {
      dispatch({
        type: RESET,
      })
      let { status, data, msg } = res.data
      if (status) {
        let feeder = {},
          profile = {}
        feeder = data.feederID
        delete data.feederID
        profile = data
        dispatch({
          type: SET_PROFILE,
          payload: profile,
          feeder,
        })
        toastr.success(msg)
      }
    })
    .catch((err) => toastr.error("Try again later!!"))
}

//reset password
export const resetPassword = (mobile) => (dispatch) => {
  axios
    .put(SERVER_URL + "/api/profile/password/reset", mobile)
    .then((res) => {
      const { status, msg } = res.data
      if (status) toastr.success(msg)
      else toastr.error("Something Went Wrong!")
    })
    .catch((err) => toastr.error("রিসেট পাসওয়ার্ড ব্যর্থ হয়েছে!!"))
}

//change mobile number
export const changeMobileNumber = (data) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(SERVER_URL + "/api/profile/mobile/change", data)
    .then((res) => {
      const { status, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        let type
        if (data.role === "collector") type = UPDATE_COLLECTOR_NUMBER
        else if (data.role === "operator") type = UPDATE_OPERATOR_NUMBER
        dispatch({
          type,
          payload: data,
        })
        $("#changeMobile").modal("hide")
        toastr.success(msg)
      } else toastr.error(msg)
    })
    .catch((err) => toastr.error("Update failed!!"))
}

//reset password
export const updateProfilePassword = (data) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(SERVER_URL + "/api/users/updatePassword", data)
    .then((res) => {
      dispatch({
        type: RESET,
      })
      const { status, msg } = res.data
      if (status) toastr.success(msg)
      else toastr.error(msg)
    })
    .catch((err) => toastr.error("পাসওয়ার্ড আপডেট ব্যর্থ হয়েছে!!"))
}

// update Profile
export const updateProfile = (data) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(`${SERVER_URL}/api/profile/update`, data)
    .then((res) => {
      const { status, data, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        dispatch({
          type: UPDATE_PROFILE,
          payload: data,
        })
        toastr.success(msg)
      } else toastr.error(msg)
    })
    .catch((err) => toastr.error("প্রোফাইল সেট ব্যর্থ হয়েছে!!"))
}

// Get sms stat of current month
export const getSms = (year, month) => (dispatch) => {
  axios
    .get(`${SERVER_URL}/api/sms/monthly?year=${year}&month=${month}`)
    .then((res) => {
      dispatch({
        type: "FEEDER_GET_SMS",
        payload: res.data.data,
      })
    })
    .catch((err) => toastr.error(err))
}

// Get sms stat of previous month
export const getSmsPrevMonth = (year, month) => (dispatch) => {
  axios
    .get(`${SERVER_URL}/api/sms/monthly?year=${year}&month=${month}`)
    .then((res) => {
      dispatch({
        type: "FEEDER_GET_SMS_PREVMONTH",
        payload: res.data.data,
      })
    })
    .catch((err) => toastr.error(err))
}

export const assignRolesToManager = (roles, id) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  dispatch({
    type: MANAGER_LOADING,
  })
  axios
    .put(`${SERVER_URL}/api/managers/edit/role/${id}`, roles)
    .then((res) => {
      let { status, data, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        dispatch({
          type: GET_MANAGER,
          payload: data,
        })
        toastr.success(msg)
      } else {
        for (const property in data) toastr.error(`${data[property]}`)
      }
    })
    .catch((err) => {
      dispatch({
        type: RESET,
      })
      toastr.error("আপডেট সফল হয়নি!")
    })
}

// MIKROTIK

// Add a mikrotik
export const addMikrotik = (mtkData) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .post(`${SERVER_URL}/api/mikrotik/add`, mtkData)
    .then((res) => {
      const { status, data, msg } = res.data

      dispatch({
        type: RESET,
      })
      if (status) {
        $("#mikrotikConfigModal").modal("hide")
        dispatch({
          type: UPDATE_PROFILE,
          payload: data,
        })
        toastr.success(msg)
      } else toastr.error("Something Went Wrong!")
    })
    .catch((err) => toastr.error("মাইক্রোটিক কনফিগারেশন ব্যর্থ হয়েছে!!"))
}

// Test mikrotik connection
export const testMikrotikConnection = () => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .get(`${SERVER_URL}/api/mikrotik/test/connection`)
    .then((res) => {
      dispatch({
        type: RESET,
      })
      if (res.data.status) {
        let name = res.data.data && res.data.data.name ? res.data.data.name : ""

        toastr.success(`${name} Connection Success.`)
      } else {
        toastr.error("Sorry, Connection Failed")
      }
    })
    .catch((err) => {
      console.log(err)
      toastr.error("Sorry, Connection Failed")
    })
}

// Sync Mikrotik PPP packages
export const mtkPppPackageSync = () => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .get(`${SERVER_URL}/api/mikrotik/ppp-packages/sync`)
    .then((res) => {
      const { status, data, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        dispatch({
          type: SET_PROFILE,
          feeder: data,
        })
        toastr.success(msg)
      } else toastr.error(msg)
    })
    .catch((err) => {
      console.log(err)
      toastr.error("Sorry, Package Sync Failed!")
    })
}

export const mtkPackageUpdate = (mtkData) => (dispatch) => {
  dispatch({
    type: LOADING,
  })
  axios
    .put(`${SERVER_URL}/api/mikrotik/ppp-package/update/${mtkData._id}`, mtkData)
    .then((res) => {
      const { status, data, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        dispatch({
          type: SET_PROFILE,
          feeder: data,
        })
        $("#packageUpdateModal").modal("hide")
        toastr.success(msg)
      } else toastr.error(msg)
    })
    .catch((err) => {
      console.log(err)
      toastr.error("Sorry, Operation Failed!")
    })
}

export const mtkPackageDelete = (mtkData) => (dispatch) => {
  dispatch({ type: LOADING })
  axios
    .delete(`${SERVER_URL}/api/mikrotik/ppp-package/${mtkData._id}`)
    .then((res) => {
      const { status, data } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        dispatch({
          type: SET_PROFILE,
          feeder: data,
        })
        toastr.success(`${mtkData.name} প্যাকেজ ডিলিট সফল হয়েছে`)
      }
    })
    .catch((err) => {
      console.log(err)
      toastr.error("Sorry, Operation Failed!")
    })
}

// update QUEUE package rate
export const updateMtkQPackage = (data) => (dispatch) => {
  dispatch({
    type: LOADING,
  })

  axios
    .put(`${SERVER_URL}/api/mikrotik/queue-package`, data)
    .then((res) => {
      const { status, data, msg } = res.data
      dispatch({
        type: RESET,
      })
      if (status) {
        dispatch({
          type: SET_PROFILE,
          feeder: data,
        })
        $("#queuePackageModal").modal("hide")
        toastr.success(msg)
      } else toastr.error(msg)
    })
    .catch((err) => {
      console.log(err)
      toastr.error("Sorry, Operation Failed!")
    })
}

// Sync ppp users from mikrotik
export const syncPPPUsers = () => (dispatch) => {
  dispatch({ type: LOADING })
  axios
    .get(`${SERVER_URL}/api/mikrotik/pppusers/sync`)
    .then((res) => {
      const { status, msg } = res.data
      dispatch({ type: RESET })
      if (status) toastr.success(msg)
    })
    .catch((err) => {
      console.log(err)
      toastr.error("Sorry, Operation Failed!")
    })
}

// Sync queue users from mikrotik
export const syncQUsers = () => (dispatch) => {
  dispatch({ type: LOADING })
  axios
    .get(`${SERVER_URL}/api/mikrotik/queue/sync`)
    .then((res) => {
      const { status, msg } = res.data
      dispatch({ type: RESET })
      if (status) toastr.success(msg)
    })
    .catch((err) => {
      console.log(err)
      toastr.error("Sorry, Operation Failed!")
    })
}

export const getManagerBalance = () => (dispatch) => {
  dispatch({
    type: LOADING,
  })

  axios(`${SERVER_URL}/api/managers/get/balance`)
    .then((res) => {
      const { status, data } = res.data
      if (status) {
        dispatch({
          type: GET_MANAGER_BALANCE,
          payload: data,
        })

        dispatch({
          type: RESET,
        })
      } else {
        toastr.error("Something Went Wrong!")
      }
    })
    .catch((err) => toastr.error("ম্যানেজার ব্যাল্যান্স লোড ব্যর্থ হয়েছে!!"))
}
