import moment from "moment"
import React, { Component } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Select from "react-select"
import { connect } from "react-redux"
import { getDeposits } from "../../actions/collectorActions"
import { formattedNumber, monthsArr, toBangla, yearOptions, monthOptions } from "../../components/common/Utils"

const { SearchBar } = Search

const d = new Date()
let year = d.getFullYear()
let month = d.getMonth()

class CollectorDeposit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deposits: [],
      totalDeposit: 0,
      collector: { label: props?.collector?.collectors[0]?.name, value: props?.collector?.collectors[0]?._id },
      collectorsOption: [],
      year: { label: toBangla(year), value: year },
      month: { label: monthsArr[month], value: month },
    }
  }
  componentDidMount() {
    const { collector, getDeposits } = this.props
    if (!collector?.deposits?.length) {
      getDeposits(this.props?.collector?.collectors[0]?._id, this.state.year.value, this.state.month.value)
    } else {
      let collectorsOption = [],
        totalDeposit = 0
      this.props.collector.collectors.map((col) => {
        collectorsOption.push({ label: col.name, value: col._id })
      })

      collector.deposits.map((d) => (d.status === 'ACCEPTED' ? totalDeposit += d.amount : ''))

      this.setState({ collectorsOption, deposits: collector.deposits, totalDeposit })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.collector.isLoaded) {
      let totalDeposit = 0
      nextProps?.collector?.deposits?.length && nextProps?.collector?.deposits?.map((d) => (d.status === 'ACCEPTED' ? totalDeposit += d.amount : ''))
      this.setState({ deposits: nextProps.collector.deposits, totalDeposit })
    }

    let collectorsOption = []

    if (nextProps.collector.isLoaded) {
      nextProps.collector.collectors.map((col) => {
        collectorsOption.push({ label: col.name, value: col._id })
      })
    }
    this.setState({ collectorsOption })
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.props.getDeposits(this.state.collector.value, this.state.year.value, this.state.month.value)
  }

  columns = [
    {
      style: { height: "41px" },
      headerStyle: { height: "41px" },
      dataField: "collector",
      text: "নাম",
      sort: true,
      formatter: (cell) => cell.name,
    },
    {
      style: { height: "41px" },
      headerStyle: { height: "41px" },
      dataField: "amount",
      text: "জমা",
      sort: true,
      formatter: (cell, row) =>
        row.forceDeposit === "yes" ? <strong>৳{formattedNumber(cell)}</strong> : `৳${formattedNumber(cell)}`,
    },
    {
      style: { height: "41px" },
      headerStyle: { height: "41px" },
      dataField: "status",
      text: "স্ট্যাটাস",
      sort: true,
      formatter: (cell) => {
        let status
        if (cell === "PENDING") {
          status = (
            <h6>
              <span class="badge badge-warning text-dark">{cell}</span>
            </h6>
          )
        }
        if (cell === "ACCEPTED") {
          status = (
            <h6>
              <span class="badge badge-success">{cell}</span>
            </h6>
          )
        }
        if (cell === "REJECTED") {
          status = (
            <h6>
              <span class="badge badge-danger">{cell}</span>
            </h6>
          )
        }
        return status
      },
    },
    {
      style: { height: "41px" },
      headerStyle: { height: "41px" },
      dataField: "depositedAt",
      text: "তারিখ",
      sort: true,
      formatter: (cell) => moment(cell).format("DD/MM/yyyy hh:mm A"),
    },
  ]

  render() {
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white border-rounded text-center">
              <h4>কালেক্টর ডিপোজিট</h4>
            </div>
            <div className="container">
              <div className="row justify-content-center p-3 bg-white align-items-start">
                {!this.props.server.requestPending ? (
                  <>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Select
                          value={this.state.year}
                          options={yearOptions}
                          onChange={(year) => this.setState({ year })}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <Select
                          value={this.state.month}
                          options={monthOptions}
                          onChange={(month) => this.setState({ month })}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Select
                          value={this.state.collector}
                          options={this.state.collectorsOption}
                          onChange={(collector) => this.setState({ collector })}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <button onClick={this.onSubmit} className="btn btn-success w-100">
                          সাবমিট
                        </button>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <strong>মোটঃ {formattedNumber(this.state.totalDeposit)} টাকা</strong>
                    </div>
                    <div className="col-md-8"></div>
                    {this.state?.deposits?.length ? (
                      <PaginationProvider
                        pagination={paginationFactory({
                          sizePerPageList: [
                            { text: "20", value: 20 },
                            { text: "50", value: 50 },
                            { text: "100", value: 100 },
                          ],
                        })}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            bootstrap4
                            keyField="_id"
                            data={this.state.deposits}
                            columns={this.columns}
                            search
                          >
                            {(props) => (
                              <div>
                                <div className="row justify-content-between mt-3">
                                  <div className="col-md-6"></div>
                                  <div className="col-md-6 text-right">
                                    <SearchBar {...props.searchProps} />
                                  </div>
                                </div>
                                <BootstrapTable striped hover {...props.baseProps} {...paginationTableProps} />
                              </div>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    ) : (
                      <div className="col-md-12">
                        <p className="text-center">কোন ডিপোজিট পাওয়া যায়নি</p>
                      </div>
                    )}
                  </>
                ) : (
                  <div class="fa-3x">
                    <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  server: state.server,
  collector: state.collector,
})

export default connect(mapStateToProps, { getDeposits })(CollectorDeposit)
