import { GET_ALL_STAFFS, ADD_STAFF, UPDATE_STAFF, GET_STAFF_SALARY } from "../actions/types"

const initialState = {
  isLoaded: false,
  isSalaryLoaded: false,
  isManagerLoaded: false,
  staffs: [],
  salaries: [],
  manager: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STAFFS:
      return {
        ...state,
        isLoaded: true,
        staffs: action.payload,
      }
    case ADD_STAFF:
      return {
        ...state,
        staffs: [...state.staffs, action.payload],
      }
    case UPDATE_STAFF:
      let index = state.staffs.findIndex((col) => col._id === action.payload._id)
      let updated = [...state.staffs.slice(0, index), action.payload, ...state.staffs.slice(index + 1)]
      return {
        ...state,
        staffs: updated,
      }

    case GET_STAFF_SALARY:
      return {
        ...state,
        isSalaryLoaded: action.loading ? false : true,
        salaries: action.payload,
      }

    default:
      return state
  }
}
