import React from "react"

export default (props) => {
  let modalWidth = props.modalWidth ? props.modalWidth : "w-75"
  return (
    <div className="modal fade" tabIndex="-1" role="dialog" id={props.id}>
      <div className={`modal-dialog mw-100 ${modalWidth}`} role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.modalTitle}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" disabled={props.pending}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={props.onSubmit}>
            <div className="modal-body">{props.children}</div>

            <div className="modal-footer bg-whitesmoke br">
              <button type="button" className="btn btn-danger" data-dismiss="modal" disabled={props.pending}>
                বন্ধ করুন
              </button>
              <button style={{ minWidth: 100 }} type="submit" className="btn btn-primary" disabled={props.pending}>
                {props.pending ? <i className="fas fa-sync fa-spin"></i> : "সাবমিট"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export const ProfileModal = (props) => {
  let modalWidth = props.modalWidth ? props.modalWidth : "w-75"

  return (
    <div className="modal fade" id={props.id} tabindex="-1" role="dialog">
      <div className={`modal-dialog  mw-100 ${modalWidth}`} role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="close close-fixed" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}
