import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line no-undef
import { BrowserRouter } from "react-router-dom";

import App from "./App";

// // Template JS File
import "bootstrap/dist/css/bootstrap.min.css";
// toastr css
import "toastr/build/toastr.min.css";

import * as jQuery from "jquery";

import "./assets/css/style.css";
import "@fortawesome/fontawesome-free/css/all.css";

import "@mdi/font/css/materialdesignicons.min.css";
import * as serviceWorker from "./serviceWorker";

window.jQuery = jQuery;
window.$ = jQuery;

require("popper.js/dist/popper.min");
require("jquery/dist/jquery.min");
require("bootstrap/dist/js/bootstrap.min");
require("jquery-ui-dist/jquery-ui.min.js");
require("jquery.nicescroll/dist/jquery.nicescroll.js");

//
// JS Libraies

require("./js/js/scripts");
require("./js/js/custom");
require("./js/js/stisla");

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>{" "}
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
