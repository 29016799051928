import moment from "moment"
import React, { Component } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { connect } from "react-redux"
import Select from "react-select"
import { getDeposits } from "../../actions/reportActions"

import { formattedNumber, monthsArr, toBangla, yearOptions, monthOptions } from "../../components/common/Utils"

const { SearchBar } = Search

const d = new Date()
let year = d.getFullYear()
let month = d.getMonth()

class ManagerDeposit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      managerDeposits: [],
      totalDeposit: 0,
      year: { label: toBangla(year), value: year },
      month: { label: monthsArr[month], value: month },
    }
  }

  componentDidMount() {
    const { report, getDeposits, auth } = this.props
    if (!report.managerDeposits.length) {
      getDeposits(auth.user.profile.managerID, this.state.year.value, this.state.month.value)
    } else {
      let totalDeposit = 0
      report.managerDeposits.map((d) => (d.status === 'ACCEPTED' ? totalDeposit += d.amount : ''))

      this.setState({
        managerDeposits: report.managerDeposits,
        totalDeposit,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.report.managerDeposits) {
      let totalDeposit = 0
      nextProps.report.managerDeposits.map((d) => (d.status === 'ACCEPTED' ? totalDeposit += d.amount : ''))
      this.setState({ managerDeposits: nextProps.report.managerDeposits, totalDeposit })
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { getDeposits, auth } = this.props
    getDeposits(auth.user.profile.managerID, this.state.year.value, this.state.month.value)
  }

  depositColumns = [
    {
      style: { height: "41px" },
      headerStyle: { height: "41px" },
      dataField: "amount",
      text: "জমা",
      sort: true,
      formatter: (cell) => `৳${formattedNumber(cell)}`,
    },
    {
      style: { height: "41px" },
      headerStyle: { height: "41px" },
      dataField: "status",
      text: "স্ট্যাটাস",
      sort: true,
      formatter: (cell) => {
        let status
        if (cell === "PENDING") {
          status = (
            <h6>
              <span class="badge badge-warning text-dark">{cell}</span>
            </h6>
          )
        }
        if (cell === "ACCEPTED") {
          status = (
            <h6>
              <span class="badge badge-success">{cell}</span>
            </h6>
          )
        }
        if (cell === "REJECTED") {
          status = (
            <h6>
              <span class="badge badge-danger">{cell}</span>
            </h6>
          )
        }
        return status
      },
    },
    {
      style: { height: "41px" },
      headerStyle: { height: "41px" },
      dataField: "depositedAt",
      text: "তারিখ",
      sort: true,
      formatter: (cell) => moment(cell).format("DD/MM/yyyy hh:mm A"),
    },
  ]

  render() {
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white border-rounded text-center">
              <h4>ম্যানেজার ডিপোজিট</h4>
            </div>
            <div className="container">
              <div className="row justify-content-center p-3 bg-white align-items-start">
                {!this.props.server.requestPending ? (
                  <>
                    <div className="col-md-4">
                      <div className="form-group">
                        <Select
                          value={this.state.year}
                          options={yearOptions}
                          onChange={(year) => this.setState({ year })}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <Select
                          value={this.state.month}
                          options={monthOptions}
                          onChange={(month) => this.setState({ month })}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <button onClick={this.onSubmit} className="btn btn-success w-100">
                          সাবমিট
                        </button>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <strong>মোটঃ {formattedNumber(this.state.totalDeposit)} টাকা</strong>
                    </div>
                    <div className="col-md-8"></div>

                    {this.state.managerDeposits.length ? (
                      <PaginationProvider
                        pagination={paginationFactory({
                          sizePerPageList: [
                            { text: "20", value: 20 },
                            { text: "50", value: 50 },
                            { text: "100", value: 100 },
                          ],
                        })}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            bootstrap4
                            keyField="_id"
                            data={this.state.managerDeposits}
                            columns={this.depositColumns}
                            search
                          >
                            {(props) => (
                              <div>
                                <div className="row justify-content-between">
                                  <div className="col-md-4"></div>
                                  <div className="col-md-6 text-right">
                                    <SearchBar {...props.searchProps} />
                                  </div>
                                </div>
                                <BootstrapTable striped hover {...props.baseProps} {...paginationTableProps} />
                              </div>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    ) : (
                      <div className="col-md-12">
                        <p className="text-center">কোন ডিপোজিট পাওয়া যায়নি</p>
                      </div>
                    )}
                  </>
                ) : (
                  <div class="fa-3x">
                    <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  server: state.server,
  profile: state.profile,
  report: state.report,
})

export default connect(mapStateToProps, { getDeposits })(ManagerDeposit)
