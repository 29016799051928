import React, { Component } from "react"
import { CheckBox } from "../common/CheckBox"
import Modal from "./Modal"

class AreaAssignModal extends Component {
  constructor(props) {
    super(props)
    this.state = { areas: [], data: { name: "" } }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps)
    if (nextProps.pending) return
    const areas =
      nextProps.areas &&
      nextProps?.areas.map((area) => ({
        id: area._id,
        value: area.name,
        isChecked: false,
      }))

    if (!nextProps?.data?.assignedAreas) return

    nextProps?.data?.assignedAreas.length &&
      nextProps?.data?.assignedAreas.map((area) => {
        areas.map((area2) => {
          if (area2.id == area) area2.isChecked = true
        })
      })

    this.setState({ data: nextProps.data, areas })
  }

  handleAllChecked = (e) => {
    let areas = this.state.areas
    areas.forEach((area) => (area.isChecked = e.target.checked))
    this.setState({ areas })
  }

  handleCheckChieldElement = (e) => {
    let areas = this.state.areas
    areas.forEach((area) => {
      if (area.value === e.target.value) area.isChecked = e.target.checked
    })
    this.setState({ areas })
  }

  onSubmitAssignArea = (e) => {
    e.preventDefault()
    let assignedAreas = []
    this.state.areas.filter((area) => {
      if (area.isChecked) {
        assignedAreas.push(area.id)
      }
    })

    this.props.action({ assignedAreas }, this.props.data._id)
  }

  render() {
    return (
      <Modal
        onSubmit={this.onSubmitAssignArea}
        modalTitle={"এরিয়া - " + this.state.data.name}
        pending={this.props.pending}
        id="areaAssignModal"
      >
        <React.Fragment>
          <input type="checkbox" onClick={this.handleAllChecked} value="checkedall" /> সকল এরিয়া
          <ul>
            {this.state.areas.map((area) => {
              return <CheckBox handleCheckChieldElement={this.handleCheckChieldElement} {...area} />
            })}
          </ul>
        </React.Fragment>
      </Modal>
    )
  }
}

export default AreaAssignModal
