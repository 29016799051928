import moment from "moment";
import React, { Component, createRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import toastr from "toastr";
import {
  addNewStaff,
  getAllStaffs,
  payStaffSalary,
  updateStaff,
  getSalary
} from "../../actions/staffActions";
import { getManagerBalance } from "../../actions/profileActions";
import PdfComponent from "../../components/common/PdfComponent";
import {
  monthsArr,
  toBangla,
  formattedNumber
} from "../../components/common/Utils";
import Modal from "../../components/modals/Modal";
const { SearchBar } = Search;

class Staff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: "",
      staffToModify: {},
      addStaff: false
    };
    this.nameRef = createRef();
    this.mobileRef = createRef();
  }

  componentDidMount() {
    if (!this.props.staff.isLoaded) {
      this.props.getAllStaffs();
    }
    if (
      !this.props.profile.isManagerBalanceLoaded &&
      this.props.auth.user.type === "manager"
    ) {
      this.props.getManagerBalance();
    }
  }
  onChangeHandler = e => {
    if (["amount", "salary"].includes(e.target.name) && e.target.value < 0)
      return;
    this.setState({
      staffToModify: {
        ...this.state.staffToModify,
        [e.target.name]: e.target.value
      }
    });
  };

  showAddStaffModal = () => {
    this.setState({
      modalTitle: "কর্মচারী অ্যাড করুন",
      staffToModify: {
        name: "",
        fatherName: "",
        mobile: "",
        fullAddress: "",
        salary: ""
      },
      addStaff: true
    });
  };

  showEditStaffModal = (staff, flag) => {
    const dt = new Date();
    this.setState({
      staffToModify: {
        salary: staff.salary,
        due: staff.due,
        recievedSalaries: staff.recievedSalaries,
        _id: staff._id,
        name: staff.name,
        fatherName: staff.fatherName,
        mobile: staff.mobile,
        fullAddress: staff.fullAddress,
        year: dt.getFullYear(),
        month: dt.getMonth(),
        amount: staff.due > 0 ? staff.due : "0",
        remarks: ""
      },
      modalTitle: "কর্মচারী এডিট করুন",
      addStaff: false
    });

    if (flag === "get_salary") {
      this.props.getSalary(staff._id);
    }
  };

  onSubmitHandler = e => {
    e.preventDefault();
    let { name, mobile } = this.state.staffToModify;
    if (!name) {
      toastr.error("নাম ফিল্ড পুরন করুন");
      this.nameRef.current.focus();
      return;
    } else if (!mobile) {
      toastr.error("মোবাইল ফিল্ড পুরন করুন");
      this.mobileRef.current.focus();
      return;
    }
    if (this.state.addStaff) {
      this.props.addNewStaff(this.state.staffToModify);
    } else {
      this.props.updateStaff(this.state.staffToModify);
    }
  };
  onSubmitPaySalary = e => {
    e.preventDefault();
    let { amount, remarks, due, year, month, _id } = this.state.staffToModify;
    if (!amount) {
      toastr.error("এমাউন্ট ফিল্ড পুরন করুন");
      return;
    } else if (!remarks) {
      toastr.error("নোট ফিল্ড পুরন করুন");
      return;
    }

    if (!this.props.profile.isManagerBalanceLoaded) {
      toastr.error("আবার ট্রাই করুন");
      return;
    } else if (this.props.profile.managerBalance.balance < amount) {
      toastr.error("দুঃখিত, আপনার কাছে ব্যাল্যান্স নাই।");
      return;
    }

    let data = {
      amount,
      remarks,
      due: due.toString(),
      year: year.toString(),
      month: month.toString(),
      _id
    };
    this.props.payStaffSalary(data);
  };

  columns = [
    {
      dataField: "name",
      text: "নাম",
      sort: true
    },
    {
      dataField: "mobile",
      text: "মোবাইল",
      sort: true
    },
    {
      dataField: "fullAddress",
      text: "ঠিকানা",
      sort: true
    },
    {
      dataField: "salary",
      text: "স্যালারি",
      formatter: cell => formattedNumber(cell),
      sort: true
    },
    {
      dataField: "due",
      text: "পাওনা",
      formatter: cell => formattedNumber(cell),
      sort: true
    },
    {
      dataField: "_id",
      text: "Action",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => (
        <ul className="navbar-nav navbar-right">
          <li className="dropdown">
            <a href="#" data-toggle="dropdown" className="nav-link nav-link-lg">
              <i className="fas fa-ellipsis-h" style={{ fontSize: 24 }}></i>
            </a>
            <div style={{ width: 50 }} className="dropdown-menu p-1">
              <a
                type="button"
                onClick={() => this.showEditStaffModal(row)}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#Modal"
              >
                <i className="fas fa-edit" />
                আপডেট
              </a>
              <a
                type="button"
                onClick={() => this.showEditStaffModal(row, "get_salary")}
                className="dropdown-item has-icon"
                data-toggle="modal"
                data-target="#salaryListModal"
              >
                <i className="fas fa-hand-holding-usd" />
                স্যালারি লিস্ট
              </a>
              {this.props.auth.user.type === "manager" && (
                <a
                  type="button"
                  onClick={() => this.showEditStaffModal(row)}
                  className="dropdown-item has-icon"
                  data-toggle="modal"
                  data-target="#paySalaryModal"
                >
                  <i className="fas fa-hand-holding-usd" />
                  পে স্যালারি
                </a>
              )}
            </div>
          </li>
        </ul>
      )
    }
  ];

  columnsForSalary = [
    {
      dataField: "amount",
      text: "এমাউন্ট",
      formatter: cell => formattedNumber(cell)
    },
    {
      dataField: "year",
      text: "বছর",
      formatter: cell => toBangla(cell)
    },
    {
      dataField: "month",
      text: "মাস",
      formatter: cell => monthsArr[cell]
    },
    {
      dataField: "remarks",
      text: "নোট"
    },
    {
      dataField: "createdAt",
      text: "সময়",
      formatter: cell => moment(cell).format("hh:mm:ss A")
    }
  ];

  render() {
    console.log(this.props.staff.staffs);

    let company =
      this.props.auth.user.type === "feeder"
        ? this.props.auth.user.profile.company
        : this.props.auth.user.feeder.company;
    return (
      <>
        <div className="main-content">
          <section className="section">
            <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
              <h4>কর্মচারী</h4>
            </div>
            <div className="container">
              <div className="row justify-content-center p-3 bg-white align-items-center">
                {this.props.staff.isLoaded ? (
                  <div className="col-12">
                    <div className="clearfix py-3">
                      <h4 className="float-left">কর্মচারী লিস্ট</h4>
                      <button
                        onClick={this.showAddStaffModal}
                        className="btn btn-success float-right"
                        data-toggle="modal"
                        data-target="#Modal"
                      >
                        নতুন কর্মচারী
                      </button>
                    </div>
                    <hr />

                    <PaginationProvider
                      pagination={paginationFactory({
                        sizePerPageList: [
                          { text: "10", value: 10 },
                          { text: "20", value: 20 },
                          { text: "50", value: 50 },
                          { text: "100", value: 100 },
                          { text: "200", value: 200 },
                          { text: "500", value: 500 },
                          { text: "1000", value: 1000 },
                          { text: "2000", value: 2000 },
                          {
                            text: "All",
                            value: this.props.staff.staffs.length
                          }
                        ]
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          bootstrap4
                          keyField="_id"
                          data={this.props.staff.staffs}
                          columns={this.columns}
                          search
                        >
                          {props => (
                            <div>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <strong>
                                      মোটঃ {this.props.staff.staffs.length}
                                    </strong>
                                  </div>
                                </div>
                                <div className="col-md-8 text-right">
                                  <SearchBar {...props.searchProps} />
                                  <CSVLink
                                    filename={`কর্মচারী লিস্ট - ${company}.csv`}
                                    data={[
                                      [
                                        "স্ট্যাটাস",
                                        "নাম",
                                        "মোবাইল",
                                        "ঠিকানা",
                                        "পিতার নাম",
                                        "স্যালারি",
                                        "সময়"
                                      ],
                                      ...this.props.staff.staffs.map(staff => [
                                        staff.status,
                                        staff.name,
                                        staff.mobile,
                                        staff.fullAddress,
                                        staff.fatherName,
                                        staff.salary,
                                        moment(staff.createdAt).format(
                                          "DD/MM/yyyy hh:mm A"
                                        )
                                      ])
                                    ]}
                                  >
                                    <i
                                      style={{ fontSize: "25px" }}
                                      className="fas fa-file-csv ml-2"
                                    ></i>
                                  </CSVLink>
                                  <ReactToPrint
                                    trigger={() => (
                                      <button
                                        style={{
                                          border: "none",
                                          background: "none"
                                        }}
                                        type="button"
                                      >
                                        <i
                                          style={{ fontSize: "25px" }}
                                          className="fas fa-file-pdf"
                                        ></i>
                                      </button>
                                    )}
                                    content={() => this.componentRef}
                                  />
                                  <div style={{ display: "none" }}>
                                    <PdfComponent
                                      ref={el => (this.componentRef = el)}
                                    >
                                      <table
                                        style={{ color: "#000" }}
                                        className="table table-bordered mt-5 table-md"
                                      >
                                        <tbody>
                                          <tr>
                                            <td colSpan="7">
                                              <h5 className="text-center">
                                                {`Company: ${this.props.auth.user.profile?.company}`}
                                                <br />
                                                {`${this.props.profile?.feeder?.fullAddress}`}
                                                <br />
                                                Page: কর্মচারী লিস্ট
                                              </h5>
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>স্ট্যাটাস</th>
                                            <th>নাম</th>
                                            <th>মোবাইল</th>
                                            <th>ঠিকানা</th>
                                            <th>পিতার নাম</th>
                                            <th>স্যালারি</th>
                                            <th>সময়</th>
                                          </tr>

                                          {this.props.staff.staffs.map(
                                            (staff, idx) => {
                                              return (
                                                <tr key={idx}>
                                                  <td>{staff.status}</td>
                                                  <td>{staff.name}</td>
                                                  <td>{staff.mobile}</td>
                                                  <td>{staff.fullAddress}</td>
                                                  <td>{staff.fatherName}</td>
                                                  <td>{staff.salary}</td>
                                                  <td>
                                                    {moment(
                                                      staff.createdAt
                                                    ).format(
                                                      "DD/MM/yyyy hh:mm A"
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </PdfComponent>
                                  </div>
                                </div>
                              </div>

                              <BootstrapTable
                                striped
                                hover
                                {...props.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </div>
                ) : (
                  <div class="fa-3x">
                    <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        <Modal
          modalTitle={this.state.modalTitle}
          onSubmit={this.onSubmitHandler}
          pending={this.props.server.requestPending}
          id="Modal"
        >
          <div className="form-group">
            <label htmlFor="mobile">মোবাইল</label>
            <input
              ref={this.mobileRef}
              className="form-control"
              onChange={this.onChangeHandler}
              value={this.state.staffToModify.mobile}
              type="text"
              name="mobile"
              disabled={this.props.server.requestPending}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">নাম</label>
            <input
              ref={this.nameRef}
              className="form-control"
              onChange={this.onChangeHandler}
              value={this.state.staffToModify.name}
              type="text"
              name="name"
              disabled={this.props.server.requestPending}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">
              স্যালারি{" "}
              {!this.state.addStaff && (
                <span className="text-muted">(আপডেট মাসের শুরুতে করুন)</span>
              )}
            </label>
            <input
              className="form-control"
              onChange={this.onChangeHandler}
              value={this.state.staffToModify.salary}
              type="number"
              name="salary"
              disabled={this.props.server.requestPending}
            />
          </div>
          {!this.state.addStaff && this.props.auth.user.type === "feeder" && (
            <div className="form-group">
              <label htmlFor="name">পাওনা</label>
              <input
                className="form-control"
                onChange={this.onChangeHandler}
                value={this.state.staffToModify.due}
                type="number"
                name="due"
                disabled={this.props.server.requestPending}
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="fatherName">পিতার নাম</label>
            <input
              className="form-control"
              onChange={this.onChangeHandler}
              value={this.state.staffToModify.fatherName}
              type="text"
              name="fatherName"
              disabled={this.props.server.requestPending}
            />
          </div>
          <div className="form-group">
            <label htmlFor="flat">ঠিকানা</label>
            <input
              className="form-control"
              onChange={this.onChangeHandler}
              value={this.state.staffToModify.fullAddress}
              type="text"
              name="fullAddress"
              disabled={this.props.server.requestPending}
            />
          </div>
        </Modal>

        {/* salary list */}
        <Modal
          modalTitle={"স্যালারি - " + this.state.staffToModify.name}
          // onSubmit={this.onSubmitPaySalary}
          // pending={this.props.server.requestPending}
          id="salaryListModal"
        >
          {!this.props.server.requestPending ? (
            <PaginationProvider
              pagination={paginationFactory({
                sizePerPageList: [
                  { text: "10", value: 10 },
                  { text: "20", value: 20 },
                  { text: "50", value: 50 }
                ]
              })}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  bootstrap4
                  keyField="_id"
                  data={this.props.staff.salaries || []}
                  columns={this.columnsForSalary}
                  search
                >
                  {props => (
                    <div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <strong>
                              মোটঃ {this.props.staff.salaries?.length}
                            </strong>
                          </div>
                        </div>
                      </div>

                      <BootstrapTable
                        striped
                        hover
                        {...props.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          ) : (
            <div class="fa-3x text-center">
              <i style={{ fontSize: 40 }} class="fas fa-sync fa-spin"></i>
            </div>
          )}
        </Modal>

        {/* salary pay modal */}
        <Modal
          modalTitle={"পে স্যালারি - " + this.state.staffToModify.name}
          onSubmit={this.onSubmitPaySalary}
          pending={this.props.server.requestPending}
          id="paySalaryModal"
        >
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>বাকি</label>
                <input
                  className="form-control"
                  value={this.state.staffToModify.due}
                  type="text"
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>বছর</label>
                <input
                  className="form-control"
                  value={toBangla(this.state.staffToModify.year || 0)}
                  type="text"
                  disabled
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>মাস</label>
                <input
                  className="form-control"
                  value={monthsArr[this.state.staffToModify.month]}
                  type="text"
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>এমাউন্ট</label>
                <input
                  onChange={this.onChangeHandler}
                  name="amount"
                  className="form-control"
                  value={this.state.staffToModify.amount}
                  type="number"
                  disabled={this.props.server.requestPending}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>নোট</label>
                <input
                  onChange={this.onChangeHandler}
                  name="remarks"
                  className="form-control"
                  value={this.state.staffToModify.remarks}
                  type="text"
                  disabled={this.props.server.requestPending}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    staff: state.staff,
    server: state.server,
    profile: state.profile
  };
};

export default connect(mapStateToProps, {
  getAllStaffs,
  addNewStaff,
  updateStaff,
  payStaffSalary,
  getSalary,
  getManagerBalance
})(Staff);
