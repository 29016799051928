import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import { login } from "../../actions/authActions";
export class Login extends Component {
  constructor() {
    super();
    this.state = {
      mobile: "",
      password: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmit(e) {
    e.preventDefault();
    const userData = {
      mobile: this.state.mobile,
      password: this.state.password
    };
    this.props.login(userData);
  }
  componentDidMount() {
    $(".needs-validation").submit(function(event) {
      let form = $(this);
      if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.addClass("was-validated");
    });
  }

  render() {
    return (
      <div id="app">
        <section className="section">
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                <div className="login-brand">
                  <img
                    src="../logo.png"
                    alt="logo"
                    width="100"
                    className="shadow-light rounded-circle"
                  />
                </div>

                <div className="card card-primary">
                  <div className="card-header">
                    <h4 style={{ margin: "0 auto" }}>বায়ান্ন পে</h4>
                  </div>

                  <div className="card-body">
                    <form
                      // method="POST"
                      // action="#"
                      noValidate
                      className="needs-validation"
                      onSubmit={this.onSubmit}
                    >
                      <div className="form-group">
                        <label htmlFor="email">মোবাইল</label>
                        <input
                          id="mobile"
                          type="text"
                          className="form-control"
                          name="mobile"
                          tabIndex="1"
                          required
                          autoFocus
                          value={this.state.mobile}
                          onChange={this.onChange}
                        />
                        <div className="invalid-feedback">
                          মোবাইল নম্বর পূরণ করুন
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="d-block">
                          <label htmlFor="password" className="control-label">
                            পাসওয়ার্ড
                          </label>
                          <div className="float-right">
                            {/* <Link
                              to={"/auth/forget-password"}
                              className="text-small"
                            >
                              Forgot Password?
                            </Link> */}
                          </div>
                        </div>
                        <input
                          id="password"
                          type="password"
                          className="form-control"
                          name="password"
                          tabIndex="2"
                          required
                          value={this.state.password}
                          onChange={this.onChange}
                        />
                        <div className="invalid-feedback">
                          পাসওয়ার্ড পূরণ করুন
                        </div>
                      </div>

                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg btn-block"
                          tabIndex="4"
                        >
                          লগিন
                        </button>
                        <Link to={"/auth/register"}>
                          <button
                            type="submit"
                            className="btn btn-success btn-lg mt-2 btn-block"
                            tabIndex="4"
                          >
                            রেজিস্টার
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="mt-5 text-center">
                  <h3>
                    <a
                      className="font-weight-bold"
                      href="https://play.google.com/store/apps/details?id=com.shunno.project.bayannopay"
                      target="_blank"
                    >
                      বায়ান্ন পে মোবাইল এপ ডাউনলোড করতে এখানে ক্লিক করুন
                    </a>
                  </h3>
                </div>

                {/* <div className="mt-5 text-muted text-center">
                  Don't have an account? <Link to={"/auth/register"}>Create One</Link>
                </div>
                <div className="simple-footer">Copyright &copy; Stisla 2018</div> */}
              </div>
            </div>
            <br />
            <br />
          </div>
        </section>
        <div style={{ textAlign: "center" }}>
          <a href="https://bayannopay.com">Home</a> &nbsp;|&nbsp;
          <a href="https://bayannopay.com/about">About</a> &nbsp;|&nbsp;
          <a href="https://bayannopay.com/privacyPolicy">Privacy Policy</a>
          &nbsp;|&nbsp;
          <a href="https://bayannopay.com/termAndCondition">
            Terms & Conditions
          </a>
          &nbsp;|&nbsp;
          <a href="https://bayannopay.com/return">Return & Refund Policy</a>
          <img
            src="../sslcommerz.png"
            alt="ssl commerz"
            height="200px"
            width="90%"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { login })(Login);
