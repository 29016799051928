import React, { Component } from "react"
import { connect } from "react-redux"
import CountUp from "react-countup"

class Manager extends Component {
  constructor(props) {
    super(props)
    this.state = {
      amount: props?.manager?.balance,
    }
  }

  render() {
    const { manager, auth } = this.props

    let duration = 1.25

    return (
      <>
        <div className="col-12">
          <div className="clearfix pt-5">
            <h4 className="text-center">ম্যানেজার</h4>
          </div>
          <hr />
        </div>

        <div
          className="card text-center  text-white mb-3 mx-2 rounded-pill"
          style={{ minWidth: "12rem", maxWidth: "12rem", backgroundColor: "#b57f50" }}
        >
          <div className="card-body">
            <p className="card-title h4 font-weight-bold">
              <CountUp start={0} end={manager.connectionFee} duration={duration} separator="," prefix="৳ " />
            </p>
            <p className="card-text font-weight-bold h5">সংযোগ ফি</p>
          </div>
        </div>

        <div
          className="card text-center  text-white bg-primary mb-3 mx-2 rounded-pill"
          style={{ minWidth: "12rem", maxWidth: "12rem" }}
        >
          <div className="card-body">
            <p className="card-title h4 font-weight-bold">
              <CountUp start={0} end={manager.lineuserBill} duration={duration} separator="," prefix="৳ " />
            </p>
            <p className="card-text font-weight-bold h5">গ্রাহক</p>
          </div>
        </div>

        <div
          className="card text-center  text-white bg-info mb-3 mx-2 rounded-pill"
          style={{ minWidth: "12rem", maxWidth: "12rem" }}
        >
          <div className="card-body">
            <p className="card-title h4 font-weight-bold">
              <CountUp start={0} end={manager.depositCollection} duration={duration} separator="," prefix="৳ " />
            </p>
            <p className="card-text font-weight-bold h5">কালেক্টর</p>
          </div>
        </div>

        <div
          className="card text-center text-white bg-success mb-3 mx-2 rounded-pill"
          style={{ minWidth: "12rem", maxWidth: "12rem" }}
        >
          <div className="card-body">
            <p className="card-title h4 font-weight-bold">
              <CountUp start={0} end={manager.totalCollection} duration={duration} separator="," prefix="৳ " />
            </p>
            <p className="card-text font-weight-bold h5">কালেকশন</p>
          </div>
        </div>

        <div
          className="card text-center  text-white mb-3 mx-2 rounded-pill"
          style={{ minWidth: "12rem", maxWidth: "12rem", backgroundColor: "#36AD98" }}
        >
          <div className="card-body">
            <p className="card-title h4 font-weight-bold">
              <CountUp start={0} end={manager.totalDeposit} duration={duration} separator="," prefix="৳ " />
            </p>
            <p className="card-text font-weight-bold h5">ডিপোজিট</p>
          </div>
        </div>

        <div
          className="card text-center text-white mb-3 mx-2 rounded-pill"
          style={{ minWidth: "12rem", maxWidth: "12rem", backgroundColor: "#164993" }}
        >
          <div className="card-body">
            <p className="card-title h4 font-weight-bold">
              <CountUp start={0} end={manager.balance} duration={duration} separator="," prefix="৳ " />
            </p>
            <p className="card-text font-weight-bold h5">ব্যালেন্স</p>
          </div>
        </div>

        {/* dummy */}
        {auth.user.type !== "feeder" && auth.user.type !== "manager" && (
          <div
            className="card text-center text-white mb-3 mx-2 rounded-pill"
            style={{ minWidth: "12rem", maxWidth: "12rem", backgroundColor: "#164993" }}
          >
            <div className="card-body">
              <p className="card-title h4 font-weight-bold">
                <CountUp
                  start={0}
                  end={manager.pendingDeposit ? manager.depositAmount : manager.balance}
                  duration={duration}
                  separator=","
                  prefix="৳ "
                />
              </p>
              <p className="card-text font-weight-bold h5">ব্যালেন্স</p>
            </div>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  server: state.server,
})

export default connect(mapStateToProps, {})(Manager)
