import {
  GET_ALL_OPERATORS,
  ADD_OPERATOR,
  UPDATE_OPERATOR,
  OPERATOR_ASSIGN_AREAS,
  UPDATE_OPERATOR_NUMBER
} from "../actions/types";

const initialState = {
  isLoaded: false,
  operators: [],
};

export default (state = initialState, action) => {
  let index, updated
  switch (action.type) {
    case GET_ALL_OPERATORS:
      return {
        isLoaded: true,
        operators: action.payload,
      };
    case ADD_OPERATOR:
      return {
        ...state,
        operators: [...state.operators, action.payload],
      };

    case UPDATE_OPERATOR:
      index = state.operators.findIndex(
        (col) => col._id === action.payload._id
      );
      updated = [
        ...state.operators.slice(0, index),
        action.payload,
        ...state.operators.slice(index + 1),
      ];
      return {
        ...state,
        operators: updated,
      };

    case UPDATE_OPERATOR_NUMBER:
      index = state.operators.findIndex(
        (col) => col.userID === action.payload.userID
      );
      updated = [
        ...state.operators.slice(0, index),
        { ...state.operators[index], mobile: action.payload.newMobile },
        ...state.operators.slice(index + 1),
      ];
      return {
        ...state,
        operators: updated,
      };

    case OPERATOR_ASSIGN_AREAS:
      index = state.operators.findIndex(
        (col) => col._id === action.payload._id
      );

      updated = [
        ...state.operators.slice(0, index),
        action.payload,
        ...state.operators.slice(index + 1),
      ];

      return {
        ...state,
        operators: updated,
      };

    default:
      return state;
  }
};
